import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledDropdownItemButton = styled(UI.Button).attrs({
    theme: "transparent",
})`
    width: 100%;
    text-align: left;

    padding ${UI.Tokens.SIZES[1]};
    border: none;

    span {
        font-size: ${UI.Tokens.TYPOGRAPHY.preview.desktop.fontSize};
        line-height: ${UI.Tokens.TYPOGRAPHY.preview.desktop.lineHeight};
        font-weight: normal;
    }

    svg {
        width: 20px;
        height: 20px;
    }
`;
