import React from "react";

import { Button, Card, Feedback, Icon, Text } from "@accurx/design";
import { toast } from "react-toastify";

import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
} from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { supportUrls } from "../../constants";
import { goToLink } from "../../utils";
import {
    SetupPatientTriageArticleWrapper,
    SetupPatientTriageCopyButtonWrapper,
} from "./SetupPatientTriage.styles";
import { SetupPatientTriageProps } from "./SetupPatientTriage.types";

export const SetupPatientTriage = ({
    practiceLink,
}: SetupPatientTriageProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const copyHandler = () => {
        ChainAnalyticsTracker.trackPatientTriageSetupPracticeLinkCopyButtonClick(
            {
                ...analyticsLoggedInProps,
                objectName: PatientTriageSettingObjectName.PRACTICE_LINK,
                objectType: PatientTriageSettingObjectType.BUTTON,
            },
        );
        toast(
            Feedback({
                colour: "success",
                title: "Patient Triage link copied",
                content:
                    "Share this with your patients to start using Patient Triage",
            }),
        );
        navigator.clipboard.writeText(practiceLink);
    };

    const goToLinkAndTrack = (url: string) => {
        const optionSelected = url
            .substring(url.indexOf("-") + 1)
            .replace("patient-triage", "")
            .replace(/-/g, " ");
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...analyticsLoggedInProps,
                objectName: AnalyticsMapper.mapToPascalCase(optionSelected),
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementResourceClick(
            analyticsProps,
        );
        goToLink(url);
    };

    return (
        <>
            <Card
                isElevated
                spacing={3}
                variant="regular"
                header={
                    <Text skinny variant="subtitle" as="h3">
                        Your practice and patients can start benefiting from
                        Patient Triage as soon as you add it to your website
                    </Text>
                }
            >
                <div className="row align-items-start">
                    <div className="col-sm-auto">
                        <div>
                            <Icon name="Link" size={7} />
                        </div>
                    </div>
                    <div className="col">
                        <Text variant="label">
                            This is your unique practice link
                        </Text>
                        <Text variant="body">
                            You'll need to give it to your IT team to implement
                            on your website.
                        </Text>

                        <Text variant="body">
                            You could promote Patient Triage on your website as
                            a button, or in a banner or even by simply adding a
                            link. But remember, the more obvious you make this,
                            the more your patients will be likely to find it and
                            use it.
                        </Text>
                    </div>
                </div>
                <SetupPatientTriageCopyButtonWrapper>
                    <Button
                        onClick={copyHandler}
                        dimension="large"
                        theme="secondary"
                        text={practiceLink}
                        icon={{ name: "Copy", placement: "end" }}
                    />
                </SetupPatientTriageCopyButtonWrapper>
            </Card>

            <SetupPatientTriageArticleWrapper>
                <Text variant="subtitle" as="h2">
                    Patient Triage support articles
                </Text>
                <Button
                    dimension="large"
                    theme="secondary"
                    text="How to get started"
                    icon={{ name: "Document" }}
                    onClick={() => goToLinkAndTrack(supportUrls.GET_STARTED)}
                />
                <Button
                    dimension="large"
                    theme="secondary"
                    text="How to manage patient requests in the Accurx Desktop Inbox"
                    icon={{ name: "Document" }}
                    onClick={() =>
                        goToLinkAndTrack(supportUrls.PATIENT_REQUESTS_INBOX)
                    }
                />
                <Button
                    dimension="large"
                    theme="secondary"
                    text="Who gets notified about new requests?"
                    icon={{ name: "Document" }}
                    onClick={() => goToLinkAndTrack(supportUrls.NEW_REQUESTS)}
                />
                <Button
                    dimension="large"
                    theme="secondary"
                    text="How does patient identification work?"
                    icon={{ name: "Document" }}
                    onClick={() =>
                        goToLinkAndTrack(supportUrls.PATIENT_INDENTIFICATION)
                    }
                />
                <Button
                    dimension="large"
                    theme="secondary"
                    text="What information do patients submit?"
                    icon={{ name: "Document" }}
                    onClick={() =>
                        goToLinkAndTrack(supportUrls.PATIENT_DATA_SUBMIT)
                    }
                />
            </SetupPatientTriageArticleWrapper>
        </>
    );
};
