import styled from "styled-components";

import { AssigneeOnlineIndicator } from "../AssigneeOnlineIndicator/AssigneeOnlineIndicator";

export const StyledAssigneeAvatarWrapper = styled.span`
    position: relative;
`;

export const StyledAssigneeOnlineIndicator = styled(AssigneeOnlineIndicator)`
    position: absolute;
    right: -2px;
    bottom: -2px;
`;
