// eslint-disable-next-line no-restricted-imports
import { AppSettings, Log, LogSinks, httpClient2 } from "@accurx/shared";
import { createBrowserHistory } from "history";

/**
 * Setup Logging
 *
 * This is where we configure where our Log statements will go. If you wish to
 * consume Log data and send it to a new destination, create yourself a LogSink,
 * initialise it here and add include it via Log.addSink(new MySink())
 */
export const setupLoggingProviders = (
    history: ReturnType<typeof createBrowserHistory>,
) => {
    // only log to console if we're not on production
    if (AppSettings.accurxEnvironment !== "production") {
        Log.addSink(new LogSinks.Console());
    }

    const isSentryEnabled = AppSettings.getWebClientSentrySettingsIsEnabled();
    const environment =
        AppSettings.getWebClientSentrySettingsEnvironment() ?? "unknown";
    const sentryDSN = AppSettings.getWebClientSentrySettingsDSN();

    // Enable Sentry
    if (isSentryEnabled === true && sentryDSN) {
        const sentryLogSink = new LogSinks.Sentry(
            { minimumLevel: "warn" },
            {
                browserHistory: history,
                dsn: sentryDSN,
                environment,
                tracesSamplingRate: 0.01,
                errorsToIgnore: [
                    "Event `CustomEvent` (type=error) captured as exception",
                ],
            },
        );

        Log.addSink(sentryLogSink);
    } else {
        // Sentry is not enabled so Log and return undefined
        Log.info("Sentry is not enabled", {
            tags: { environment: environment },
        });
    }

    // now do AppInsights
    const appInsightsKey = AppSettings.getAppInsightsLoggingSinkKey();
    if (appInsightsKey) {
        Log.addSink(
            new LogSinks.AppInsights(
                { minimumLevel: "info" },
                { instrumentationKey: appInsightsKey, browserHistory: history },
            ),
        );

        Log.addSink(
            new LogSinks.AppInsights(
                { maximumLevel: "debug" },
                { instrumentationKey: appInsightsKey, debugSamplingRate: 1 },
            ),
        );
    }
};

const getRequestTags = ({
    request,
    path,
    params,
}: {
    request: Request;
    path: string;
    params?: Record<string, string | number | boolean | undefined>;
}) => {
    const url = new URL(request.url, window.location.origin);
    const correlationId = request.headers.get("AccurxCorrelationId") ?? "";

    const tags: Record<string, string> = {
        logger: "httpClient2",
        "api.correlationId": correlationId,
        "api.endpoint": path,
        "api.method": request.method,
    };

    if (url.search) {
        tags["api.query"] = url.search;
    }

    if (params) {
        for (const param in params) {
            const value = params[param];
            if (value) {
                tags[`api.params.${param}`] = value.toString();
            }
        }
    }

    return tags;
};

export const setupHttpClient2Logging = () => {
    /**
     * Log every request start at an info level so they appear in our
     * AppInsights traces.
     */
    const onRequestStart = httpClient2.callbacks.onRequestStart(
        ({ init, request }) => {
            const { path, params } = init;

            Log.info(`HttpClient request starting`, {
                tags: getRequestTags({ request, path, params }),
            });
        },
    );

    /**
     * Log successful responses at an info level so they appear in our
     * AppInsights traces.
     */
    const onSuccessResponse = httpClient2.callbacks.onSuccessResponse(
        ({ init, request, response }) => {
            const { path, params } = init;

            Log.info(`HttpClient request succeeded`, {
                tags: {
                    ...getRequestTags({ request, path, params }),
                    "api.statusCode": response.status,
                },
            });
        },
    );

    /**
     * return a teardown function.
     */
    return () => {
        onRequestStart();
        onSuccessResponse();
    };
};
