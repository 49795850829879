import { NativeFetchError } from "domains/native/errors";
import { SubscribeWindowCloseAttempt } from "domains/native/schemas/SubscribeWindowCloseAttemptSchema";
import { QueryResult } from "domains/native/types";
import { Action, Reducer } from "redux";

import { initialState } from "../store";

const subscriptionName = "SubscribeWindowCloseAttempt";

export const reducer: Reducer<
    QueryResult<SubscribeWindowCloseAttempt, NativeFetchError>,
    Action
> = (state = initialState[subscriptionName], action: Action) => {
    if (action.type !== subscriptionName) {
        return state;
    }

    const currentAttempt = state.status === "success" ? state.data.attempt : 0;

    return {
        status: "success",
        data: { attempt: currentAttempt + 1 },
    };
};
