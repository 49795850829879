import * as UI from "@accurx/design";

import { ComposeAssigneeSelectorComponent } from "../../types/Compose.types";

type ComposeAssigneeSelectorProps = {
    label: string;
    CustomComponent?: ComposeAssigneeSelectorComponent;
};

export const ComposeAssigneeSelector = ({
    label,
    CustomComponent,
}: ComposeAssigneeSelectorProps) => {
    if (CustomComponent) {
        return <CustomComponent currentAssignee={{ type: "User", id: "1" }} />;
    }

    return (
        <UI.Ds.Badge radius="round" color="greyscale">
            <strong>{label}</strong>
        </UI.Ds.Badge>
    );
};
