import { CanMessagePracticeResponse } from "@accurx/api/clinician-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { Conversation, WorkspaceParticipant } from "@accurx/concierge/types";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { useQueries } from "@tanstack/react-query";
import some from "lodash/some";

/**
 * Some workspaces may have had Clinician Messaging Inbound disabled - perhaps
 * they closed down, got the Accumail feature disabled, or just stopped using it
 * and got marked as inactive. This hook finds all the other workspaces that are
 * participants in the conversation and checks wether they have Clinician
 * Messaging enabled via the API.
 */
export const useClinicianMessagingWorkspaceAvailabilityQuery = ({
    conversation,
}: {
    conversation: Conversation;
}) => {
    const { orgId: workspaceId } = useCurrentWorkspace();

    const otherWorkspaceParticipants = conversation.participants.filter(
        (p): p is WorkspaceParticipant =>
            p.type === "Workspace" && p.workspaceId !== workspaceId,
    );

    const queries = useQueries({
        queries: otherWorkspaceParticipants.map((participant) => ({
            queryKey: [
                "HasClinicianMessagingEnabled",
                { workspaceId: participant.workspaceId },
            ],
            queryFn: async () => {
                const response = await httpClient.getReturnJsonSafeAsync(
                    getApiEndpoint({
                        path: "/api/clinicianmessaging/PracticeInformation/:workspaceId/enabled",
                        params: {
                            workspaceId: participant.workspaceId.toString(),
                        },
                        query: `?organisationId=${workspaceId}`,
                    }),
                );
                return returnDataOrThrow<CanMessagePracticeResponse>(response);
            },
        })),
    });

    const unavailableWorkspaces = otherWorkspaceParticipants.filter(
        (_, index) => {
            const query = queries[index];
            return (
                query.status === "error" ||
                (query.status === "success" && !query.data.enabled)
            );
        },
    );

    const isLoading = some(queries, (query) => query.status === "loading");

    const areAllWorkspacesUnavailable =
        otherWorkspaceParticipants.length > 0 &&
        unavailableWorkspaces.length === otherWorkspaceParticipants.length;

    return {
        isLoading,
        unavailableWorkspaces,
        areAllWorkspacesUnavailable,
    };
};
