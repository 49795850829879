import { PatientThreadTicketCommandResult } from "@accurx/api/ticket";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type MarkNoteAsWrittenToRecordOptions = {
    workspaceId: number;
    floreyNoteId: string;
};

export const saveFloreyPdfToRecord: ConciergeFetcher<
    MarkNoteAsWrittenToRecordOptions,
    PatientThreadTicketCommandResult
> = async ({ workspaceId, floreyNoteId }) => {
    const response = await TicketApiClient.saveFloreyPdfToRecord({
        organisationId: workspaceId,
        floreyNoteId,
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);
    return { updates, response };
};
