import { useCurrentWorkspace } from "@accurx/auth";
import {
    Ds,
    ErrorSummaryProvider,
    Feedback,
    Option,
    Text,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";

import { getOverviewPagePath } from "../../Routes";
import { FormValues, ReminderFormFields } from "../../components";
import {
    useCreateReminder,
    useReminderConfigOptions,
    useRemindersAndFilterOptions,
} from "../../hooks";
import { useAvailableFilters } from "../../hooks/useAvailableFilters";
import { mapFormValuesToRequest } from "../../utils";
import { Page } from "./Page";

export const CreateReminderPage = () => {
    useAccurxWebTitle("Create an appointment reminder");

    const history = useHistory();
    const location = useLocation<Optional<{ selectedClinics?: string[] }>>();
    const { orgId: id, organisationName } = useCurrentWorkspace();
    const workspaceId = id.toString();

    const availableFilters = useAvailableFilters();
    const { data, status } = useRemindersAndFilterOptions({
        workspaceId,
    });
    const { data: configData, status: configStatus } = useReminderConfigOptions(
        { workspaceId },
    );
    const {
        mutate: createReminder,
        isError,
        error,
    } = useCreateReminder({ workspaceId });

    if (status === "loading" || configStatus === "loading") {
        return (
            <Page>
                <Ds.Spinner />
            </Page>
        );
    }

    if (status === "error" || configStatus === "error") {
        return (
            <Page>
                <Feedback
                    colour="error"
                    iconName="Failed"
                    title="There was an issue retrieving reminder information"
                >
                    <Text skinny>
                        There was an issue retrieving the information, try again
                        later.
                    </Text>
                </Feedback>
            </Page>
        );
    }

    const onSubmit = (formValues: FormValues) => {
        createReminder(mapFormValuesToRequest(formValues), {
            onSuccess: () => {
                history.push(getOverviewPagePath(workspaceId));
                toast(<Feedback colour="success">Reminder added!</Feedback>);
            },
        });
    };

    const initialSelectedClinics: Option[] = (
        location.state?.selectedClinics || []
    ).map((code) => ({
        label: code,
        value: code,
    }));

    return (
        <Page>
            <ErrorSummaryProvider>
                <ReminderFormFields
                    initialValues={{
                        selectedSlotTypes: initialSelectedClinics,
                    }}
                    workspaceName={organisationName}
                    existingReminders={data.appointmentReminders}
                    siteNames={data.siteNames}
                    slotTypes={data.slotTypes}
                    appointmentTypes={data.appointmentTypes}
                    workspaceHasMultipleSites={data.siteNames.length > 1}
                    submitButtonText="Set reminder live"
                    onSubmit={onSubmit}
                    changeDeadline={configData.changeDeadline}
                    supportedChanges={configData.supportedChanges}
                    availableFilters={availableFilters}
                    cancelButton={{ show: false }}
                    deleteButton={{ show: false }}
                    isError={isError}
                    error={error as Error}
                    layout="two-columns"
                    workspaceId={workspaceId.toString()}
                />
            </ErrorSummaryProvider>
        </Page>
    );
};
