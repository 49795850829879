import { useEffect, useRef } from "react";

import {
    getEmbedMode,
    useIsNativeSignalRConnectionAvailable,
} from "@accurx/native";

import { HubClient, createHubClient } from "shared/hubClient/HubClient";

export type OptionalHubClient = HubClient | null;

type Props = {
    currentUserId: string | null;
};

export const useOptionalHubClient = ({
    currentUserId,
}: Props): OptionalHubClient => {
    const { embedMode } = getEmbedMode();
    const isNativeSignalRConnectionAvailable =
        useIsNativeSignalRConnectionAvailable();

    /**
     * We want to use HubClient when:
     * 1. Embedded in Web
     * 2. SignalR via Native Bridge is not possible (use HubClient as a fallback even when embedded in Desktop e.g. unsupported desktop version)
     */
    const shouldUseHubClient =
        embedMode === "Web" || !isNativeSignalRConnectionAvailable;

    // if `isHubClientOptional` is false, we always want to create a HubClient connection
    const hubClient = useRef<OptionalHubClient>(
        shouldUseHubClient ? createHubClient() : null,
    );

    useEffect(() => {
        const hubClientInstance = hubClient.current;

        // Reconnect to HubClient when the user changes
        if (currentUserId && hubClientInstance) {
            void hubClientInstance.connect();

            return () => {
                void hubClientInstance.disconnect();
            };
        }
    }, [currentUserId]);

    return hubClient.current;
};
