import { useCallback } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Log } from "@accurx/shared";

import { mutateSignalRInvoke } from "../api/endpoints/mutateSignalRInvoke";
import { NativeTransportError } from "../errors";
import { useNativeTransport } from "./useNativeTransport";

type SignalRInvoke = ({
    methodName,
    payload,
}: {
    methodName: string;
    payload: object;
}) => Promise<void>;

// A hook to trigger a SignalR method via the native bridge
export const useSignalRInvoke = () => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useCallback<SignalRInvoke>(
        async ({ methodName, payload }) => {
            if (!transport) {
                throw new NativeTransportError(
                    "useSignalRInvoke: No native transport",
                );
            }

            try {
                await mutateSignalRInvoke(
                    {
                        transport,
                        workspaceId,
                    },
                    { methodName, payload },
                );
            } catch (err) {
                Log.error(
                    "MutateSignalRInvoke: Failed sending a SignalR message to the Native Bridge",
                    {
                        tags: {
                            methodName,
                            error: (err as Error).message,
                        },
                    },
                );
            }
        },
        [transport, workspaceId],
    );
};
