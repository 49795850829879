import { useEffect } from "react";

import * as UI from "@accurx/design";
import { Feedback, Pill } from "@accurx/inbox-design-library";
import {
    PatientRecordAttachment,
    useMedicalRecordConnection,
    usePatientRecordAttachmentsQuery,
    usePersistAttachmentUsageMutation,
} from "@accurx/native";
import { QuickViewPortal, useQuickViewContext } from "@accurx/quick-view";
import { DateHelpers } from "@accurx/shared";
import { PatientExternalId } from "domains/compose/types";

import {
    StyledEmptyMessage,
    StyledInnerContainer,
    StyledItemButton,
    StyledItemDate,
    StyledItemName,
    StyledList,
    StyledListItem,
    StyledTitleContainer,
} from "./PatientRecordAttachmentSelector.styles";
import { formatPatientRecordAttachmentName } from "./formatPatientRecordAttachmentName";

type PatientRecordAttachmentSelectorProps = {
    onSelect: (attachment: PatientRecordAttachment) => void;
    patientName: string;
    patientExternalIds: PatientExternalId[];
};

export const PatientRecordAttachmentSelector = ({
    onSelect,
    patientName,
    patientExternalIds,
}: PatientRecordAttachmentSelectorProps) => {
    const { onClose } = useQuickViewContext();
    const medicalRecordConnection = useMedicalRecordConnection();
    const { status, data } = usePatientRecordAttachmentsQuery(
        { patientExternalIds },
        { refetchOnMount: true },
    );
    const systemName = medicalRecordConnection.system ?? "the patient record";
    const { mutate: persistAttachmentUsage } =
        usePersistAttachmentUsageMutation();

    useEffect(() => {
        persistAttachmentUsage();
    }, [persistAttachmentUsage]);

    return (
        <>
            <QuickViewPortal.Header>
                <Pill.SecondaryButton dimension="small" onClick={onClose}>
                    <Pill.Icon name="Cross" colour="blue" size={3} />
                    <Pill.Text>Close</Pill.Text>
                </Pill.SecondaryButton>
            </QuickViewPortal.Header>
            <QuickViewPortal.Body>
                <StyledTitleContainer>
                    <UI.Text as="h2" variant="label" skinny>
                        Attach file from {systemName}
                    </UI.Text>
                </StyledTitleContainer>
                {status === "error" && (
                    <StyledInnerContainer>
                        <Feedback
                            colour="error"
                            title={`We couldn't fetch attachments for ${patientName} from ${systemName}`}
                        />
                    </StyledInnerContainer>
                )}
                {status === "loading" && (
                    <StyledInnerContainer>
                        <UI.Spinner />
                    </StyledInnerContainer>
                )}
                {status === "success" && data.attachments.length === 0 && (
                    <StyledInnerContainer>
                        <StyledEmptyMessage>
                            No attachments found for{" "}
                            <strong>{patientName}</strong> in {systemName}
                        </StyledEmptyMessage>
                    </StyledInnerContainer>
                )}
                {status === "success" && data.attachments.length > 0 && (
                    <StyledList aria-label="Patient record attachments">
                        {data.attachments.map((attachment) => {
                            const attachmentName =
                                formatPatientRecordAttachmentName(attachment);
                            return (
                                <StyledListItem key={attachment.documentId}>
                                    <StyledItemButton
                                        onClick={() => onSelect(attachment)}
                                        aria-label={attachmentName}
                                    >
                                        <StyledItemName>
                                            {attachmentName}
                                        </StyledItemName>
                                        <StyledItemDate>
                                            {attachment.addedAt
                                                ? DateHelpers.formatDateRelativeToToday(
                                                      attachment.addedAt,
                                                  )
                                                : null}
                                        </StyledItemDate>
                                    </StyledItemButton>
                                </StyledListItem>
                            );
                        })}
                    </StyledList>
                )}
            </QuickViewPortal.Body>
        </>
    );
};
