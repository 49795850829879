import { useCurrentWorkspace } from "@accurx/auth";
import { NavTabs } from "@accurx/navigation";
import { AppSettings } from "@accurx/shared";
import { useHistory, useLocation } from "react-router-dom";

import {
    ReportingPageViewProps,
    trackReportingPageView,
} from "app/analytics/FlemingAnalytics/ReportingEvents";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { RequestsByDayTab } from "app/organisations/reporting/patientTriage/RequestsByDayTab";
import { LiveAssignmentDashboard } from "app/practices/patientTriage/components/Dashboards/LiveAssignmentDashboard";
import { RequestsByOutcome } from "app/practices/patientTriage/components/Dashboards/RequestsByOutcome/RequestsByOutcome";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { PowerBIReport } from "../components/PowerBIReport";

const TABS = {
    overview: { tabId: "overview", text: "Overview" },
    "short-term-planning": {
        tabId: "short-term-planning",
        text: "Short-term Planning",
    },
    "long-term-planning": {
        tabId: "long-term-planning",
        text: "Long-term Planning",
    },
    "form-access": { tabId: "form-access", text: "Form Acess" },
    "requests-by-assignee": {
        tabId: "requests-by-assignee",
        text: "Requests by Assignee",
    },
    "requests-by-day": { tabId: "requests-by-day", text: "Requests by Day" },
    "requests-by-outcomes": {
        tabId: "requests-by-outcomes",
        text: "Requests by Outcomes",
    },
} as const;
type TabId = keyof typeof TABS;

const REPORT_NAME =
    AppSettings.accurxEnvironment === "production"
        ? "Online Consultation"
        : "Online Consultation [DEV]";

const TRACKING_REPORT_NAME = "PatientTriageReport";
const ORIGIN_PROPS = {
    navigationOrigin: "Web",
    pageOrigin: "PatientTriageReport",
} as const;

export const PatientTriagePage = () => {
    const { orgId } = useCurrentWorkspace();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { state } = useLocation<
        Partial<ReportingPageViewProps> | undefined
    >();
    const history = useHistory();

    const handleTabDisplay = (nextTabId: string) => {
        trackReportingPageView({
            ...analyticsLoggedInProps,
            navigationOrigin: state?.navigationOrigin ?? "URL",
            pageOrigin: state?.pageOrigin,
            currentTab: TABS[nextTabId as TabId].text,
            reportName: TRACKING_REPORT_NAME,
        });
    };

    return (
        <>
            <NavSubMenuComponent backCallback={history.goBack} />
            <StyledContainer>
                <PageHeader
                    title="Patient Triage"
                    type={PageHeaderType.ListPage}
                />
                <NavTabs
                    baseRoute={ROUTES_WORKSPACE.reportingPatientTriage}
                    defaultTabId={TABS["overview"].tabId}
                    onTabDisplay={handleTabDisplay}
                    routeState={ORIGIN_PROPS}
                >
                    <NavTabs.Tabs tabs={Object.values(TABS)} />

                    <NavTabs.Panel tabId={TABS["overview"].tabId}>
                        <PowerBIReport
                            pageName="Overview"
                            reportName={REPORT_NAME}
                            pageOrigin={TRACKING_REPORT_NAME}
                            leftAligned
                        />
                    </NavTabs.Panel>
                    <NavTabs.Panel tabId={TABS["short-term-planning"].tabId}>
                        <PowerBIReport
                            pageName="Short-term Planning"
                            reportName={REPORT_NAME}
                            pageOrigin={TRACKING_REPORT_NAME}
                            leftAligned
                        />
                    </NavTabs.Panel>
                    <NavTabs.Panel tabId={TABS["long-term-planning"].tabId}>
                        <PowerBIReport
                            pageName="Long-term Planning"
                            reportName={REPORT_NAME}
                            pageOrigin={TRACKING_REPORT_NAME}
                            leftAligned
                        />
                    </NavTabs.Panel>
                    <NavTabs.Panel tabId={TABS["form-access"].tabId}>
                        <PowerBIReport
                            pageName="Form Access"
                            reportName={REPORT_NAME}
                            pageOrigin={TRACKING_REPORT_NAME}
                            leftAligned
                        />
                    </NavTabs.Panel>
                    <NavTabs.Panel tabId={TABS["requests-by-assignee"].tabId}>
                        <LiveAssignmentDashboard
                            organisationId={orgId.toString()}
                        />
                    </NavTabs.Panel>
                    <NavTabs.Panel tabId={TABS["requests-by-day"].tabId}>
                        <RequestsByDayTab workspaceId={orgId} />
                    </NavTabs.Panel>
                    <NavTabs.Panel tabId={TABS["requests-by-outcomes"].tabId}>
                        <RequestsByOutcome practiceId={orgId.toString()} />
                    </NavTabs.Panel>
                </NavTabs>
            </StyledContainer>
        </>
    );
};
