import { ReactNode, forwardRef } from "react";

import * as UI from "@accurx/design";

import { StyledAttachSelector } from "./AttachSelector.styles";
import { AttachDocumentButton } from "./components/AttachDocumentButton";

type AttachSelectorProps = {
    children: ReactNode;
    isOpen: boolean;
    toggle: () => void;
    iconOnlyButton?: boolean;
    disabled?: boolean;
};

export const AttachSelector = forwardRef<
    HTMLButtonElement,
    AttachSelectorProps
>(
    (
        { children, isOpen, toggle, disabled = false, iconOnlyButton = false },
        ref,
    ) => (
        <UI.Popover open={isOpen} onOpenChange={toggle}>
            <UI.PopoverTrigger asChild={true}>
                <AttachDocumentButton
                    toggle={toggle}
                    ref={ref}
                    iconOnly={iconOnlyButton}
                    disabled={disabled}
                />
            </UI.PopoverTrigger>
            <StyledAttachSelector.PopoverContent align="start">
                <StyledAttachSelector.Header>
                    <UI.Text skinny variant="label" colour="night">
                        Attach
                    </UI.Text>
                </StyledAttachSelector.Header>
                <StyledAttachSelector.AttachOptionsContainer>
                    {children}
                </StyledAttachSelector.AttachOptionsContainer>
            </StyledAttachSelector.PopoverContent>
        </UI.Popover>
    ),
);
