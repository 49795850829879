import { sanitizeAndLogError } from "domains/native/errors";
import { NativeTransport } from "domains/native/types";

export type EndpointContext = {
    transport: NativeTransport;
    workspaceId: number;
};

type EndpointArgs<TPayload> = TPayload extends undefined
    ? [context: EndpointContext]
    : [context: EndpointContext, payload: TPayload];

export type Endpoint<TData, TPayload> = (
    ...args: EndpointArgs<TPayload>
) => Promise<TData>;

/**
 * Acts as a factory for creating new Native API endpoints. It provides:
 * - Enforcing consistent structure for endpoints
 * - Out-of-the-box typing
 * - Error logging
 *
 * @param name - Will be used when logging errors thrown inside the request
 * @param endpoint - The Endpoint function
 * @returns An endpoint function wrapped with error logging
 */
export const createEndpoint = <TData, TPayload = undefined>(
    name: string,
    endpoint: Endpoint<TData, TPayload>,
): Endpoint<TData, TPayload> => {
    return ((...args: EndpointArgs<TPayload>) => {
        return endpoint(...args).catch((err) => {
            const error = sanitizeAndLogError(err, {
                domain: "Native API Request",
                endpoint: name,
            });

            throw error;
        });
    }) as Endpoint<TData, TPayload>;
};
