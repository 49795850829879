import { useState } from "react";

import { AppointmentReminder } from "@accurx/api/appointment";
import { FeatureName, useFeatureFlag } from "@accurx/auth";
import {
    Button,
    Card,
    Ds,
    Feedback,
    Flex,
    Icon,
    Popover,
    PopoverContent,
    PopoverItemButton,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import { getCreatePagePath } from "../../Routes";
import { useMessageParts } from "../../hooks";
import { useDeleteReminder } from "../../hooks/useDeleteReminder";
import { ChangeLinkType, TemplateType } from "../../types";
import { DeleteReminderModal } from "../DeleteReminderModal";

const StyledPopoverButtonText = styled(Text).attrs({
    skinny: true,
    variant: "label",
})`
    text-align: left;
`;

export interface PreviewCardProps {
    reminder: AppointmentReminder;
    badgeText?: string;
    onEdit: () => void;
    workspaceId: number;
    workspaceHasMultipleSites: boolean;
    changeLinkType: ChangeLinkType;
    workspaceName?: string;
}

export const PreviewCard = ({
    reminder,
    badgeText,
    onEdit,
    workspaceId,
    workspaceName,
    workspaceHasMultipleSites,
    changeLinkType,
}: PreviewCardProps) => {
    const history = useHistory();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { mutateAsync, status } = useDeleteReminder({
        workspaceId: workspaceId.toString(),
        reminderId: reminder.id,
    });
    const headerText =
        reminder.slotTypes[0] +
        (reminder.slotTypes.length > 1
            ? ` (+ ${reminder.slotTypes.length - 1} more)`
            : "");

    const useClinicCodesEnabled = useFeatureFlag(
        FeatureName.AppointmentRemindersUseClinicCodes,
    );

    const { fullMessage } = useMessageParts({
        templateType: reminder.type as TemplateType,
        customMessage: reminder.customMessageBody || "",
        postAppointmentMessageBody: reminder.postAppointmentCustomMessage || "",
        workspaceHasMultipleSites,
        workspaceName: workspaceName || "",
        changeLinkType,
    });

    const navigateToCreateFormWIthClinicCodes = () => {
        history.push(getCreatePagePath(workspaceId), {
            selectedClinics: reminder.slotTypes,
        });
    };

    return (
        <>
            <Card
                header={
                    <Flex
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Flex flexDirection="column" alignItems="flex-start">
                            <Text variant="label" skinny>
                                {headerText}
                            </Text>
                            {badgeText && (
                                <Ds.Badge color="greyscale">
                                    {badgeText}
                                </Ds.Badge>
                            )}
                        </Flex>

                        {useClinicCodesEnabled && (
                            <Popover>
                                <PopoverTrigger>
                                    <Button
                                        theme="secondary"
                                        icon={{
                                            name: "More",
                                            style: "Fill",
                                            title: "Reminder actions",
                                        }}
                                    />
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverItemButton
                                        onClick={
                                            navigateToCreateFormWIthClinicCodes
                                        }
                                    >
                                        <Flex gap="1">
                                            <Icon size={6} name="PenToSquare" />
                                            <StyledPopoverButtonText>
                                                Use clinic codes for a new
                                                reminder
                                            </StyledPopoverButtonText>
                                        </Flex>
                                    </PopoverItemButton>
                                </PopoverContent>
                            </Popover>
                        )}
                    </Flex>
                }
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    gap="2"
                >
                    <Text skinny>{fullMessage}</Text>

                    <Flex
                        gap="2"
                        flexWrap={{ xs: "wrap", md: "nowrap" }}
                        justifyContent="flex-end"
                    >
                        <Button
                            icon={{ name: "Pencil", colour: "blue" }}
                            theme="secondary"
                            text="Edit"
                            dimension="medium"
                            onClick={onEdit}
                        />
                        <Button
                            icon={{ name: "Bin", colour: "red" }}
                            theme="secondary"
                            text="Delete"
                            dimension="medium"
                            onClick={() => {
                                setIsDeleteModalOpen(true);
                            }}
                        />
                        <DeleteReminderModal
                            isOpen={isDeleteModalOpen}
                            onCancel={() => setIsDeleteModalOpen(false)}
                            areButtonsDisabled={status === "loading"}
                            onDelete={() => {
                                mutateAsync()
                                    .then(() => {
                                        setIsDeleteModalOpen(false);
                                        toast(
                                            <Feedback colour="success">
                                                Reminder deleted!
                                            </Feedback>,
                                        );
                                    })
                                    .catch(() => {
                                        setIsDeleteModalOpen(false);
                                        toast(
                                            <Feedback colour="error">
                                                Could not delete reminder,
                                                refresh the page and try again
                                            </Feedback>,
                                        );
                                    });
                            }}
                        />
                    </Flex>
                </Flex>
            </Card>
        </>
    );
};
