export const AssigneeSelector = ({
    currentAssignee,
}: {
    currentAssignee: { id: string; type: "User" | "Team" };
}) => {
    console.log({ currentAssignee });
    return (
        <div style={{ color: "orange", background: "green" }}>Assign plz</div>
    );
};
