import { TeamSummary } from "domains/concierge/types";

import { OnUserGroupChanged } from "../api/ticket/subscriptions/OnUserGroupChanged";
import { useConciergeDispatch, useConciergeGetState } from "../context";
import { useSignalRSubscription } from "../hooks/useSignalRSubscription";
import { actions } from "../store";

export const ConciergeTeamUpdatesInitializer = ({
    recalculateUnreadCounts,
}: {
    recalculateUnreadCounts: boolean;
}) => {
    const dispatch = useConciergeDispatch();
    const getState = useConciergeGetState();

    const onUserGroupChanged = (teamUpdate: TeamSummary) => {
        const beforeState = getState();

        dispatch(
            actions.processUpdates({
                source: "SignalR:OnUserGroupChanged",
                teams: [teamUpdate],
                users: [],
                patients: [],
                conversations: [],
            }),
        );

        if (recalculateUnreadCounts) {
            const afterState = getState();

            // When team membership changes we need to recalculate unread counts
            // because patient unread counts may have changed. If the user
            // toggled notifications on the current device then we will have
            // already recalculated unread counts. So only recalculate them if
            // this is a background update and the membership state in the
            // update does not match the membership state we currently have.
            const currentUserMembershipChanged =
                beforeState.conversations.teamMembership[teamUpdate.id] !==
                afterState.conversations.teamMembership[teamUpdate.id];
            if (currentUserMembershipChanged) {
                dispatch(actions.conversations.recalculateUnreadCounts());
            }
        }
    };

    useSignalRSubscription((args) =>
        OnUserGroupChanged({
            ...args,
            onUserGroupChanged,
        }),
    );

    return null;
};
