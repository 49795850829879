import { PreviewFloreyEnrolmentResponse } from "@accurx/api/patient-messaging";
import {
    Log,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

type UseQuestionnairePreviewLinkResponse = {
    link: string;
};

export const useQuestionnairePreviewLinkQuery = (
    {
        workspaceId,
        questionnaireId,
    }: { workspaceId: number; questionnaireId: string },
    options?: UseQueryOptions<UseQuestionnairePreviewLinkResponse, Error>,
) => {
    return useQuery({
        queryKey: [
            "QuestionnairePreviewLink",
            { workspaceId, questionnaireId },
        ],
        queryFn: async () => {
            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/patientmessaging/previewfloreyenrolment",
                }),
                {
                    organisationId: `${workspaceId}`,
                    conditionId: questionnaireId,
                },
            );

            const { enrolmentUrl } =
                returnDataOrThrow<PreviewFloreyEnrolmentResponse>(response);

            if (!enrolmentUrl) {
                const error = new Error("Questionnaire preview link missing");
                Log.error(error, {
                    tags: { questionnaireId },
                });
                throw error;
            }

            return { link: enrolmentUrl };
        },
        ...options,
    });
};
