import { useCallback } from "react";

import { useInitialSummariesPoller } from "../api/ticket/pollers/useInitialSummariesPoller";
import { useConciergeDispatch } from "../context";
import { actions } from "../store";
import { ConciergeUpdates } from "../types/ConciergeUpdates";

/**
 * Sets up poller to fetch list of active users and teams
 */
export const ConciergeInitialSummariesInitializer = ({
    recalculateUnreadCounts,
}: {
    recalculateUnreadCounts: boolean;
}) => {
    const dispatch = useConciergeDispatch();

    const onInitialSummariesSuccess = useCallback(
        (updates: ConciergeUpdates) => {
            dispatch(
                actions.processUpdates({
                    source: "Poller:InitialSummaries",
                    ...updates,
                }),
            );

            if (recalculateUnreadCounts) {
                // Once we have team membership information we need to force refresh
                // unread counts as they are affected by team membership
                dispatch(actions.conversations.recalculateUnreadCounts());
            }
        },
        [dispatch, recalculateUnreadCounts],
    );

    useInitialSummariesPoller({ onSuccess: onInitialSummariesSuccess });
    return null;
};
