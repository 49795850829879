import { useEffect } from "react";

import { TabProps, Tabs, Text } from "@accurx/design";
import { AppSettings } from "@accurx/shared";
import { Redirect, useHistory, useParams } from "react-router";
import { generatePath, useLocation } from "react-router-dom";

import {
    ReportingPageViewProps,
    trackReportingPageView,
} from "app/analytics/FlemingAnalytics/ReportingEvents";
import { LiveAssignmentDashboard } from "app/practices/patientTriage/components/Dashboards/LiveAssignmentDashboard";
import { RequestsByOutcome } from "app/practices/patientTriage/components/Dashboards/RequestsByOutcome/RequestsByOutcome";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES, ROUTES_ORGS } from "shared/Routes";

import { PowerBIReport } from "../../../reporting/components/PowerBIReport";
import { MainPanel } from "../../settings/SettingsPage.styles";
import { Breadcrumb, Breadcrumbs, Container, Header } from "../../shared";
import { getTabName } from "../utils";
import { RequestsByDayTab } from "./RequestsByDayTab";

const TABS: TabProps[] = [
    { text: "Overview", tabId: "overview" },
    { text: "Short-term Planning", tabId: "short-term-planning" },
    { text: "Long-term Planning", tabId: "long-term-planning" },
    { text: "Form Access", tabId: "form-access" },
    { text: "Requests by Assignee", tabId: "requests-by-assignee" },
    { text: "Requests by Day", tabId: "requests-by-day" },
    { text: "Requests by Outcomes", tabId: "requests-by-outcomes" },
];

const ORIGIN_PROPS = {
    navigationOrigin: "Web",
    pageOrigin: "PatientTriageReport",
} as const;

const REPORT_NAME =
    AppSettings.accurxEnvironment === "production"
        ? "Online Consultation"
        : "Online Consultation [DEV]";

export const PatientTriageReportPage = () => {
    const { orgId, tab: tabId = "overview" } = useParams<{
        orgId: string;
        tab: string;
    }>();
    const { state } = useLocation<
        Partial<ReportingPageViewProps> | undefined
    >();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const history = useHistory();

    useEffect(() => {
        trackReportingPageView({
            ...analyticsLoggedInProps,
            navigationOrigin: state?.navigationOrigin ?? "URL",
            pageOrigin: state?.pageOrigin,
            currentTab: getTabName(TABS, tabId),
            reportName: ORIGIN_PROPS.pageOrigin,
        });
        // Only track page view on mount and when tab changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabId]);

    const onTabChange = (tabId: string) => {
        history.push(
            generatePath(ROUTES_ORGS.reportPatientTriage, {
                orgId,
                tab: tabId,
            }),
            ORIGIN_PROPS,
        );
    };

    if (!orgId) {
        return <Redirect to={ROUTES.home} />;
    }

    return (
        <Tabs selected={tabId} onTabChange={onTabChange}>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb
                        text="Reporting"
                        path={ROUTES_ORGS.reporting}
                        state={ORIGIN_PROPS}
                    />
                    <Breadcrumb
                        text="Patient Triage"
                        path={ROUTES_ORGS.reportPatientTriage}
                    />
                </Breadcrumbs>
                <Text as="h1" variant="title">
                    Patient Triage
                </Text>
                <Header.TabList tabs={TABS} />
            </Header>

            <Tabs.Panel tabId="overview">
                <PowerBIReport
                    pageName="Overview"
                    reportName={REPORT_NAME}
                    pageOrigin={ORIGIN_PROPS.pageOrigin}
                />
            </Tabs.Panel>
            <Tabs.Panel tabId="short-term-planning">
                <PowerBIReport
                    pageName="Short-term Planning"
                    reportName={REPORT_NAME}
                    pageOrigin={ORIGIN_PROPS.pageOrigin}
                />
            </Tabs.Panel>
            <Tabs.Panel tabId="long-term-planning">
                <PowerBIReport
                    pageName="Long-term Planning"
                    reportName={REPORT_NAME}
                    pageOrigin={ORIGIN_PROPS.pageOrigin}
                />
            </Tabs.Panel>
            <Tabs.Panel tabId="form-access">
                <PowerBIReport
                    pageName="Form Access"
                    reportName={REPORT_NAME}
                    pageOrigin={ORIGIN_PROPS.pageOrigin}
                />
            </Tabs.Panel>
            <Tabs.Panel tabId="requests-by-assignee">
                <Container>
                    <MainPanel>
                        <LiveAssignmentDashboard organisationId={orgId} />
                    </MainPanel>
                </Container>
            </Tabs.Panel>
            <Tabs.Panel tabId="requests-by-day">
                <Container>
                    <MainPanel>
                        <RequestsByDayTab workspaceId={parseInt(orgId, 10)} />
                    </MainPanel>
                </Container>
            </Tabs.Panel>
            <Tabs.Panel tabId="requests-by-outcomes">
                <Container>
                    <MainPanel>
                        <RequestsByOutcome practiceId={orgId} />
                    </MainPanel>
                </Container>
            </Tabs.Panel>
        </Tabs>
    );
};
