import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

import { PatientTriageStatus } from "app/practices/patientTriage/types";

export const getPatientTriageStatus = (
    workspaceId: string,
): Promise<IWrappedResult<PatientTriageStatus>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/portal/:workspaceId/settings/patientInitiated/Status",
            params: { workspaceId },
        }),
    );

export const usePatientTriageStatusQuery = ({
    workspaceId,
}: {
    workspaceId: string;
}) =>
    useQuery({
        queryKey: ["PatientTriage", "Status", { workspaceId }],
        queryFn: async () => {
            const response = await getPatientTriageStatus(workspaceId);
            return returnDataOrThrow(response);
        },
        refetchOnMount: true,
    });
