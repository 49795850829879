import { StyledInfoBox } from "./BodyInfoBox.styles";
import { InfoBoxIcon } from "./InfoBoxIcon";
import { InfoBoxLink } from "./InfoBoxLink";
import { InfoBoxText } from "./InfoBoxText";

export const BodyInfoBox = {
    Wrapper: StyledInfoBox.Wrapper,
    Header: StyledInfoBox.Header,
    Footer: StyledInfoBox.Footer,
    Icon: InfoBoxIcon,
    Link: InfoBoxLink,
    Text: InfoBoxText,
};
