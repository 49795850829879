import React from "react";

import * as UI from "@accurx/design";

import { useConversationPreviewActionsContext } from "./PreviewActions";

export const ToggleStatus = () => {
    const {
        analytics,
        conversation,
        markAsDone,
        markAsOpen,
        onActionCompleted,
    } = useConversationPreviewActionsContext();
    const handleMarkAsDone = () => {
        analytics.trackConversationActionButtonClick("Done");
        markAsDone();
    };

    const handleMarkAsOpen = () => {
        analytics.trackConversationActionButtonClick("Reopen");
        markAsOpen();
    };

    const isDone = conversation.status === "Done";
    const handleToggleStatus = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (isDone) {
            handleMarkAsOpen();
        } else {
            handleMarkAsDone();
        }
        onActionCompleted();
    };
    return (
        <UI.PopoverItemButton
            onClick={handleToggleStatus}
            aria-label={
                isDone ? "Reopen conversation" : "Mark conversation as done"
            }
        >
            <UI.Icon name={isDone ? "Upload" : "Tick"} size={3} />
            <UI.Text as="span" skinny colour="zinc" variant="note">
                {isDone ? "Reopen" : "Mark done"}
            </UI.Text>
        </UI.PopoverItemButton>
    );
};
