import { StyledHeaderText } from "./AssigneeSelectorForm.styles";

export const AssigneeRadioGroupLabel = ({
    labelText,
}: {
    labelText: string;
}): JSX.Element => {
    return (
        <StyledHeaderText skinny variant="note" colour="metal">
            {labelText}
        </StyledHeaderText>
    );
};
