import { Log } from "@accurx/shared";
import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ConversationGroupCacheEntry } from "domains/concierge/internal/types/ConversationGroupCacheEntry";
import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";

const isCacheEntryStale = (
    cacheEntry: ConversationGroupCacheEntry,
    since: string,
): boolean => {
    return !!cacheEntry.unusedSince && cacheEntry.unusedSince < since;
};

/**
 * Delete stale conversation groups that
 * don't have subscribers
 */
export const garbageCollect: CaseReducer<
    ConversationsState,
    PayloadAction<{
        /** @format date-time */
        since: string;
    }>
> = (state, action) => {
    let groupsDeleted = 0;

    for (const groupId in state.groups.items) {
        // Never delete a group with active subscribers
        if (state.groups.subscriberCounts[groupId]) {
            continue;
        }

        const cacheEntry = state.groups.cache[groupId];

        // If the group isn't cached delete it
        if (
            !cacheEntry ||
            isCacheEntryStale(cacheEntry, action.payload.since)
        ) {
            delete state.groups.items[groupId];
            delete state.groups.cache[groupId];
            groupsDeleted++;
        }
    }

    const groupsRemaining = Object.keys(state.groups.items).length;

    if (groupsDeleted > 0 || groupsRemaining > 0) {
        Log.debug("Conversation groups garbage collected", {
            tags: {
                product: "Inbox",
                groupsDeleted,
                groupsRemaining,
            },
        });
    }

    return state;
};
