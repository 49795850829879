import { ReactNode, useEffect, useState } from "react";

import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { Accordion, Sidenav } from "@accurx/navigation";
import { AppSettings } from "@accurx/shared";
import { generatePath, matchPath, useLocation } from "react-router-dom";

import { isAccubookEnabled } from "app/accubook/utils/isAccubookEnabled";
import { isPatientTriageEnabled } from "app/reporting/utils/isPatientTriageEnabled";
import { ROUTES_BASE, ROUTES_PRIMARY, ROUTES_WORKSPACE } from "shared/Routes";

import { SecondaryNavLink } from "../SecondaryNavLink";
import { SecondaryNavToggle } from "../SecondaryNavToggle";

type AccordionId =
    | ""
    | "Templates"
    | "Patient Triage"
    | "Appointments"
    | "Reporting"
    | "Account settings"
    | "AccuBook";

export const ManageNavigation = () => {
    const workspace = useCurrentWorkspace();
    const { orgId: workspaceId } = workspace;
    const { pathname } = useLocation();

    const isWayfinderEnabled = useFeatureFlag(FeatureName.Wayfinder);
    const isRecordAccessEnabled = useFeatureFlag(FeatureName.RecordAccessBeta);
    const isAppointmentRemindersAlphaEnabled = useFeatureFlag(
        FeatureName.AppointmentRemindersAlpha,
    );
    const isAppointmentRemindersUhlEnabled = useFeatureFlag(
        FeatureName.AppointmentRemindersUhl,
    );
    const isEmbeddedPowerBiPOCEnabled = useFeatureFlag(
        FeatureName.EmbeddedPowerBiPOC,
    );
    const isIntegratedClinicListsEnabled = useFeatureFlag(
        FeatureName.IntegratedClinicLists,
    );
    const isWorkspaceUserManagementEnabled = useFeatureFlag(
        FeatureName.WorkspaceUserManagement,
    );
    const isPatientTriageModuleEnabled = isPatientTriageEnabled(workspace);
    const isAccubookModuleEnabled = isAccubookEnabled(workspace);

    const [activeSection, setActiveSection] = useState<AccordionId>("");

    useEffect(() => {
        const isMatchTemplates = matchPath(pathname, {
            path: [
                ROUTES_WORKSPACE.workspacePersonalTemplates,
                ROUTES_WORKSPACE.workspaceSharedTemplates,
            ],
        });
        const isMatchPatientTriage = matchPath(pathname, {
            path: ROUTES_WORKSPACE.workspacePatientTriage,
        });
        const isMatchAppointments = matchPath(pathname, {
            path: ROUTES_WORKSPACE.workspaceAppointmentReminders,
        });
        const isMatchReporting = matchPath(pathname, {
            path: ROUTES_WORKSPACE.reporting,
        });
        const isMatchAccubook = matchPath(pathname, {
            path: ROUTES_WORKSPACE.accubook,
        });
        const isMatchAccountSettings = matchPath(pathname, {
            path: ROUTES_PRIMARY.account,
        });
        if (isMatchTemplates) {
            setActiveSection("Templates");
        } else if (isMatchPatientTriage) {
            setActiveSection("Patient Triage");
        } else if (isMatchAppointments) {
            setActiveSection("Appointments");
        } else if (isMatchReporting) {
            setActiveSection("Reporting");
        } else if (isMatchAccubook) {
            setActiveSection("AccuBook");
        } else if (isMatchAccountSettings) {
            setActiveSection("Account settings");
        }
    }, [pathname]);

    return (
        <Sidenav.Content>
            <Sidenav.Title>Manage</Sidenav.Title>
            <Accordion.Root
                value={activeSection}
                onValueChange={(val) => setActiveSection(val as AccordionId)}
                collapsible
            >
                <SecondaryNavAccordion
                    name="Templates"
                    icon={{ name: "Messages" }}
                    isOpen={activeSection === "Templates"}
                    onClick={() => setActiveSection("Templates")}
                >
                    <SecondaryNavLink
                        icon={{ name: "Message" }}
                        to={generatePath(
                            ROUTES_WORKSPACE.workspacePersonalTemplates,
                            { workspaceId },
                        )}
                    >
                        My templates
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        icon={{ name: "Messages" }}
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceSharedTemplates,
                            { workspaceId },
                        )}
                    >
                        Shared templates
                    </SecondaryNavLink>
                </SecondaryNavAccordion>

                <SecondaryNavLink
                    icon={{ name: "TickDouble" }}
                    to={generatePath(
                        ROUTES_WORKSPACE.workspaceDeliveryReceipts,
                        { workspaceId },
                    )}
                >
                    Text message status
                </SecondaryNavLink>
                {isWayfinderEnabled && (
                    <SecondaryNavLink
                        icon={{ name: "Photo" }}
                        to={generatePath(ROUTES_WORKSPACE.workspaceResources, {
                            workspaceId,
                        })}
                    >
                        Resources
                    </SecondaryNavLink>
                )}
                {isRecordAccessEnabled && (
                    <SecondaryNavLink
                        icon={{ name: "Record" }}
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceMedicalRecords,
                            { workspaceId },
                        )}
                    >
                        Medical records
                    </SecondaryNavLink>
                )}
                {isAppointmentRemindersAlphaEnabled &&
                    isAppointmentRemindersUhlEnabled &&
                    isIntegratedClinicListsEnabled && (
                        <SecondaryNavAccordion
                            name="Appointments"
                            icon={{ name: "Bell" }}
                            isOpen={activeSection === "Appointments"}
                            onClick={() => setActiveSection("Appointments")}
                        >
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspaceAppointmentRemindersManage,
                                    { workspaceId },
                                )}
                            >
                                Manage reminders
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspaceAppointmentRemindersSent,
                                    { workspaceId },
                                )}
                            >
                                Sent reminders
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspaceAppointmentRemindersChanges,
                                    { workspaceId },
                                )}
                            >
                                Appointment changes
                            </SecondaryNavLink>
                        </SecondaryNavAccordion>
                    )}
                {AppSettings.accurxEnvironment !== "production" &&
                    isPatientTriageModuleEnabled && (
                        <SecondaryNavAccordion
                            name="Patient Triage"
                            icon={{ name: "Doctor" }}
                            isOpen={activeSection === "Patient Triage"}
                            onClick={() => setActiveSection("Patient Triage")}
                        >
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspacePatientTriageSettings,
                                    { workspaceId },
                                )}
                            >
                                Settings
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.workspacePatientTriageSetup,
                                    { workspaceId },
                                )}
                            >
                                Set up
                            </SecondaryNavLink>
                        </SecondaryNavAccordion>
                    )}
                {isWorkspaceUserManagementEnabled && (
                    <SecondaryNavLink
                        icon={{ name: "Person" }}
                        to={generatePath(
                            ROUTES_WORKSPACE.workspaceUserManagement,
                            { workspaceId },
                        )}
                    >
                        Users
                    </SecondaryNavLink>
                )}
                <SecondaryNavAccordion
                    name="Reporting"
                    icon={{ name: "Chart" }}
                    isOpen={activeSection === "Reporting"}
                    onClick={() => setActiveSection("Reporting")}
                >
                    <SecondaryNavLink
                        to={generatePath(ROUTES_WORKSPACE.reporting, {
                            workspaceId,
                        })}
                        isExact
                    >
                        Overview
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={generatePath(ROUTES_WORKSPACE.reportingTeamUsage, {
                            workspaceId,
                        })}
                    >
                        Team usage
                    </SecondaryNavLink>
                    {isEmbeddedPowerBiPOCEnabled && (
                        <>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.reportingPatientTriage,
                                    { workspaceId },
                                )}
                            >
                                Patient triage
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.reportingResponseRates,
                                    { workspaceId },
                                )}
                            >
                                Response rates
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.reportingFragments,
                                    { workspaceId },
                                )}
                            >
                                Fragments
                            </SecondaryNavLink>
                        </>
                    )}
                </SecondaryNavAccordion>
                {AppSettings.accurxEnvironment !== "production" &&
                    isAccubookModuleEnabled && (
                        <SecondaryNavAccordion
                            name="AccuBook"
                            icon={{ name: "Syringe" }}
                            isOpen={activeSection === "AccuBook"}
                            onClick={() => setActiveSection("AccuBook")}
                        >
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.accubookManagePatients,
                                    { workspaceId },
                                )}
                            >
                                Manage patients
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.accubookManageClinics,
                                    { workspaceId },
                                )}
                            >
                                Manage clinics
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.accubookBookedPatients,
                                    { workspaceId },
                                )}
                            >
                                Booked patients
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.accubookDeliverySites,
                                    { workspaceId },
                                )}
                            >
                                Delivery sites
                            </SecondaryNavLink>
                            <SecondaryNavLink
                                to={generatePath(
                                    ROUTES_WORKSPACE.accubookDashboard,
                                    { workspaceId },
                                )}
                            >
                                Dashboard
                            </SecondaryNavLink>
                        </SecondaryNavAccordion>
                    )}

                <Sidenav.Divider />
                <SecondaryNavLink
                    icon={{ name: "Cog" }}
                    to={generatePath(
                        ROUTES_WORKSPACE.workspaceGeneralSettings,
                        { workspaceId },
                    )}
                >
                    Workspace settings
                </SecondaryNavLink>

                <SecondaryNavAccordion
                    name="Account settings"
                    icon={{ name: "Cog" }}
                    isOpen={activeSection === "Account settings"}
                    onClick={() => setActiveSection("Account settings")}
                >
                    <SecondaryNavLink to={ROUTES_BASE.accountUserNameAndEmail}>
                        Name and email
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserWorkspaceAccess}
                    >
                        Workspace access
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserManageSignature}
                    >
                        Message signature
                    </SecondaryNavLink>
                    <SecondaryNavLink to={ROUTES_BASE.accountUserCookies}>
                        Cookies
                    </SecondaryNavLink>
                    <SecondaryNavLink to={ROUTES_BASE.accountUserSecurity}>
                        Security
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={ROUTES_BASE.accountUserTermsAndConditions}
                    >
                        Terms and conditions
                    </SecondaryNavLink>
                </SecondaryNavAccordion>
            </Accordion.Root>
        </Sidenav.Content>
    );
};

const SecondaryNavAccordion = ({
    name,
    icon,
    isOpen,
    onClick,
    children,
}: {
    name: string;
    icon?: Pick<Ds.IconProps, "name" | "appearance" | "rotate">;
    isOpen: boolean;
    onClick?: () => void;
    children?: ReactNode;
}) => (
    <Accordion.Item.Level2 value={name}>
        <Accordion.Header>
            <Accordion.Trigger asChild>
                <SecondaryNavToggle
                    icon={icon}
                    isOpen={isOpen}
                    onClick={onClick}
                >
                    {name}
                </SecondaryNavToggle>
            </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
            <Accordion.Indented>{children}</Accordion.Indented>
        </Accordion.Content>
    </Accordion.Item.Level2>
);
