import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { startNativeSubscription, useNativeTransport } from "@accurx/native";

/** Handles the lifecyle of native subscriptions required to connect
 * the compose page to the medical record.
 */
export const NativeSubscriptionsProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const transport = useNativeTransport();
    const workspace = useCurrentWorkspace();
    const workspaceId = workspace.orgId;

    useEffect(() => {
        if (!transport) return;

        const unsubscribeCurrentPatient = startNativeSubscription({
            subscription: "SubscribeCurrentPatient",
            transport,
            workspaceId,
        });
        const unsubscribeMedicalRecordConnection = startNativeSubscription({
            subscription: "SubscribeMedicalRecordConnection",
            transport,
            workspaceId,
        });

        const unsubscribeWindowOpenStatus = startNativeSubscription({
            subscription: "SubscribeWindowOpenStatus",
            transport,
            workspaceId,
        });

        const unsubscribeWindowCloseAttempt = startNativeSubscription({
            subscription: "SubscribeWindowCloseAttempt",
            transport,
            workspaceId,
        });

        return () => {
            unsubscribeMedicalRecordConnection();
            unsubscribeCurrentPatient();
            unsubscribeWindowOpenStatus();
            unsubscribeWindowCloseAttempt();
        };
    }, [transport, workspaceId]);

    return <>{children}</>;
};
