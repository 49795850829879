import { useCurrentWorkspace } from "@accurx/auth";
import { useQuestionnaireTemplatesQuery } from "@accurx/message-templates";
import { Log } from "@accurx/shared";
import { useCompose } from "domains/compose/context";

import { OrganisationEntityChangedType } from "shared/hubClient/payload.types";

import { useSignalRSubscription } from "./useSignalRSubscription";

/** Listens for SignalR events that say questionnaires have been updated and refetches data */
export const useQuestionnairesSubscription = () => {
    const { state } = useCompose();
    const canUseQuestionnaires = state.canUseQuestionnaires;
    const workspace = useCurrentWorkspace();
    const { refetch } = useQuestionnaireTemplatesQuery(
        {
            workspaceId: workspace.orgId,
        },
        {
            enabled: false,
        },
    );

    useSignalRSubscription(({ transport }) => {
        // Do not subscribe to questionnaire changes if
        // user should not see them to begin with
        if (!canUseQuestionnaires) {
            return () => undefined;
        }
        const subscription = transport.subscribe({
            methodName: "OrganisationEntityChanged",
            eventFilter: (event) =>
                event.payload.organisationId === workspace.orgId,
            onEvent: (event) => {
                const isFloreyChange =
                    event.payload.type ===
                    OrganisationEntityChangedType.FloreyTemplate;

                if (isFloreyChange) {
                    Log.info("Refetching questionnaires after update");
                    void refetch();
                }
            },
        });

        return subscription.unsubscribe;
    });
};
