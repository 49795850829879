import { useIsComposing } from "@accurx/compose";
import { Button, Ds, Flex } from "@accurx/design";
import { useMutateCloseWindow } from "@accurx/native";

import { useActiveCloseAttempt } from "./useActiveCloseAttempt";

export const DiscardChangesModal = () => {
    const isComposing = useIsComposing();
    const { mutate: closeWindow } = useMutateCloseWindow();

    const { activeCloseAttempt, onCloseAttemptActioned } =
        useActiveCloseAttempt();

    if (activeCloseAttempt && !isComposing) {
        /* When the user has closed the window and there's no message being
         * composed we can immediately close the window without prompt */
        closeWindow();
        onCloseAttemptActioned();
    }

    return (
        <Ds.Modal
            open={activeCloseAttempt && isComposing}
            onOpenChange={(open) => {
                if (!open) {
                    onCloseAttemptActioned();
                }
            }}
        >
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>You've written something</Ds.Modal.Title>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            type="button"
                            onClick={() => onCloseAttemptActioned()}
                            text="Back to message"
                        />
                        <Button
                            theme="primary"
                            type="button"
                            onClick={() => {
                                closeWindow();
                                onCloseAttemptActioned();
                            }}
                            text="Bin it"
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
