import { UserGroupType } from "@accurx/api/clinician-messaging";
import { mapUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapUserGroupToTeamSummary";
import { TeamSummary } from "domains/concierge/types";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";

export const getTeams = async (arg: {
    workspaceId: number;
}): Promise<TeamSummary[]> => {
    const response = await ClinicianMessagingApiClient.fetchUserGroups(arg);

    const result: TeamSummary[] = [];

    for (const summary of response.userGroups) {
        // In clinician messaging we currently only care about the ToAssign team
        if (summary.userGroupType !== UserGroupType.ToAssign) {
            continue;
        }

        const team = mapUserGroupToTeamSummary({
            id: summary.userGroupId,
            name: "Accumail Unassigned",
            type: UserGroupType.ToAssign,
            isMember: !!summary.currentUserIsInUserGroup,
            canBeAssignedTo: true,
        });

        if (team) {
            result.push(team);
        }
    }

    return result;
};
