import { useMemo } from "react";

import { useUser } from "@accurx/concierge/hooks/data/useUser";
import {
    ItemCreatedByUser,
    StateChangeItem,
} from "@accurx/concierge/schemas/ConversationItemSchema";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type StateChangeProps = {
    conversationItem: StateChangeItem;
};

export const StateChange = ({ conversationItem }: StateChangeProps) => {
    const userId = (conversationItem.createdBy as ItemCreatedByUser).id;
    const participant = useUser({ userId });

    const summary = useMemo(() => {
        switch (conversationItem.changeType) {
            case "ReOpened":
                return "Reopened this conversation";

            case "Done":
                return "Marked this conversation done";

            case "Urgent":
                return "Marked this conversation urgent";

            case "NonUrgent":
                return "Marked this conversation as not urgent";

            case "PatientMatch":
                return "Matched this conversation to a patient";

            case "PatientUnmatch":
                return "Unmatched this conversation from a patient";
        }
    }, [conversationItem]);

    if (!summary) {
        return null;
    }

    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    <UI.VisuallyHidden as="span">
                        Responded by
                    </UI.VisuallyHidden>
                    {participant?.isCurrentUser
                        ? "You"
                        : participant?.displayName}
                    :
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    {summary}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
