import * as React from "react";

import { Tabs, Text } from "@accurx/design";

import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { LoadingWrapper } from "app/sharedComponents/LoadingWrapper";
import { LoadingStatus } from "shared/LoadingStatus";

import { Breadcrumb } from "../breadcrumb/Breadcrumb";
import { PatientTriageSettings } from "./PatientTriageSettings";
import {
    Dashboard,
    SetupPatientTriage,
    SetupPatientTriageContainer,
    WhatIsPatientTriage,
} from "./components";
import { patientTriageUrls } from "./constants/PracticePatientTriage";
import { PatientTriageProps, TabIds } from "./types";

export class PatientTriage extends React.Component<
    PatientTriageProps & { deepLinkActivePage: TabIds | null },
    { sentLoadedAnalytics: boolean | null }
> {
    constructor(
        props: PatientTriageProps & { deepLinkActivePage: TabIds | null },
    ) {
        super(props);
        this.state = { sentLoadedAnalytics: null };
    }

    componentWillUpdate(): void {
        document.title = "Manage patient triage | Accurx";
    }

    componentDidMount(): void {
        this.setState({
            sentLoadedAnalytics: false,
        });
    }

    componentDidUpdate(): void {
        // Loading status data
        const { loadingStatus, practiceId, actions } = this.props;

        if (loadingStatus === LoadingStatus.Initial && practiceId) {
            actions.getPatientTriageStatus(practiceId);
        }

        // Sending analytics
        if (this.state.sentLoadedAnalytics === false) {
            const { status, accountState, analyticsState } = this.props;
            if (loadingStatus === LoadingStatus.Loaded) {
                const analyticsProps: ChainAnalyticsTracker.PatientTriagePageLoadedProps =
                    {
                        ...AnalyticsMapper.getAnalyticsLoggedInProps(
                            accountState,
                            analyticsState,
                        ),
                        isEnabled: status.enabled.toString(),
                    };
                ChainAnalyticsTracker.trackPatientTriagePageLoaded(
                    analyticsProps,
                );
                this.setState({ sentLoadedAnalytics: true });
            }
        }
    }

    handleSetPage(optionSelected: TabIds, url: string): void {
        if (this.props.deepLinkActivePage !== optionSelected) {
            const { accountState, analyticsState } = this.props;
            const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementItemProps =
                {
                    ...AnalyticsMapper.getAnalyticsLoggedInProps(
                        accountState,
                        analyticsState,
                    ),
                    optionSelected:
                        AnalyticsMapper.mapToPascalCase(optionSelected),
                };

            ChainAnalyticsTracker.trackPatientTriageManagementMenuItemClicked(
                analyticsProps,
            );
        }
        this.props.history.push(url);
    }

    onTabChange = (tabId: string): void => {
        switch (tabId) {
            case TabIds.WhatIsPatientTriage:
                this.handleSetPage(
                    TabIds.WhatIsPatientTriage,
                    patientTriageUrls.patientTriage,
                );
                return;
            case TabIds.SetUpPatientTriage:
                this.handleSetPage(
                    TabIds.SetUpPatientTriage,
                    patientTriageUrls.patientTriageSetUp,
                );
                return;
            case TabIds.Settings:
                this.handleSetPage(
                    TabIds.Settings,
                    patientTriageUrls.patientTriageSettings,
                );
                return;
            case TabIds.Dashboard:
                this.handleSetPage(
                    TabIds.Dashboard,
                    patientTriageUrls.patientTriageDashboard,
                );
                return;
            default:
                console.error(`tabId ${tabId} not recognised`);
                return;
        }
    };

    render(): JSX.Element {
        const { loadingStatus, status } = this.props;

        const tabs = Object.values(TabIds).map((value) => {
            return { tabId: value, text: value };
        });

        const selectedTab =
            this.props.deepLinkActivePage ?? TabIds.WhatIsPatientTriage;

        return (
            <LoadingWrapper loadingStatus={loadingStatus}>
                <Tabs selected={selectedTab} onTabChange={this.onTabChange}>
                    <NavSubMenuComponent contentExtendsToBottomEdge={true}>
                        <Breadcrumb title="Patient Triage" wrapper={false} />
                        <Text variant="title" as="h1" skinny className="mt-2">
                            Patient Triage
                        </Text>
                        <Tabs.TabList tabs={tabs} />
                    </NavSubMenuComponent>
                    {tabs.map(({ tabId }) => (
                        <Tabs.Panel key={tabId} tabId={tabId}>
                            {tabId === TabIds.WhatIsPatientTriage && (
                                <WhatIsPatientTriage
                                    practiceLink={status.url}
                                />
                            )}
                            {tabId === TabIds.SetUpPatientTriage && (
                                <SetupPatientTriageContainer>
                                    <SetupPatientTriage
                                        practiceLink={status.url}
                                    />
                                </SetupPatientTriageContainer>
                            )}
                            {tabId === TabIds.Settings && (
                                <div className="mb-4 px-4">
                                    <PatientTriageSettings {...this.props} />
                                </div>
                            )}
                            {tabId === TabIds.Dashboard && <Dashboard />}
                        </Tabs.Panel>
                    ))}
                </Tabs>
            </LoadingWrapper>
        );
    }
}
