import * as UI from "@accurx/design";
import {
    ChooseClinicianValue,
    ClinicianOption,
} from "domains/self-book/utils/types";

import { RadioTextWrapper } from "../AppointmentType/AppointmentType.styles";
import { CustomRadio } from "../CustomRadio";
import {
    ErrorPositioningWrapper,
    SpacingWrapper,
    StyledSearchSelect,
} from "../SelfbookConfigurationForm.styles";
import {
    FeedbackSpacingWrapper,
    StyledFeedback,
    StyledHeader,
} from "./ChooseClinician.styles";

type ChooseClinicianProps = {
    options: UI.Option[];
    initialOption:
        | {
              clinicians: ClinicianOption[];
              clinicianType: ChooseClinicianValue;
          }
        | undefined;
    onChange(
        selected: ClinicianOption[] | undefined,
        chooseClinicianType: ChooseClinicianValue,
    ): void;
    errors: string[] | undefined;
    isSlotTypeAvailabilityError?: boolean;
};

type ChooseClinicianOption = {
    id: string;
    label: string;
    value: ChooseClinicianValue;
};

const radioInputs: ChooseClinicianOption[] = [
    { id: "anyClinician", label: "Any clinician", value: "AnyClinician" },
    {
        id: "specificClinicians",
        label: "Specific clinicians",
        value: "SpecificClinicians",
    },
];

export const ChooseClinician = ({
    options,
    initialOption,
    onChange,
    errors,
    isSlotTypeAvailabilityError = false,
}: ChooseClinicianProps) => {
    const label = "Clinicians available for booking";
    const id = "choose-clinician-filter";

    const onChooseClinicianTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const clinicianType = event.target.value as ChooseClinicianValue;
        onChange(initialOption?.clinicians, clinicianType);
    };

    return (
        <SpacingWrapper>
            <StyledSearchSelect>
                <UI.FormFieldWrapper
                    label={
                        <StyledHeader>
                            <UI.Text variant="note" skinny>
                                {label}
                            </UI.Text>
                        </StyledHeader>
                    }
                    labelProps={{ htmlFor: id }}
                    errors={errors?.map((item) => {
                        return (
                            <ErrorPositioningWrapper>
                                <UI.Text variant="preview">{item}</UI.Text>
                            </ErrorPositioningWrapper>
                        );
                    })}
                >
                    {isSlotTypeAvailabilityError ? (
                        <FeedbackSpacingWrapper>
                            <StyledFeedback>
                                No clinicians available for this slot type.
                            </StyledFeedback>
                        </FeedbackSpacingWrapper>
                    ) : (
                        <>
                            {radioInputs.map((item) => (
                                <UI.Cell key={item.value}>
                                    <CustomRadio
                                        name={"choose-clinician-type"}
                                        id={item.value}
                                        label={
                                            <RadioTextWrapper>
                                                <UI.Text
                                                    variant="preview"
                                                    skinny
                                                >
                                                    {item.label}
                                                </UI.Text>
                                            </RadioTextWrapper>
                                        }
                                        value={item.value}
                                        checkedValue={
                                            initialOption?.clinicianType
                                        }
                                        onChange={onChooseClinicianTypeChange}
                                    />
                                </UI.Cell>
                            ))}
                            {initialOption?.clinicianType ===
                                "SpecificClinicians" && (
                                <UI.SearchSelect
                                    id={id}
                                    label={label}
                                    options={options}
                                    dimension="medium"
                                    // Types here are inaccurate, returns MultiSelectOption for multi select
                                    onChangeHandler={(selected) =>
                                        onChange(
                                            selected as ClinicianOption[],
                                            initialOption.clinicianType,
                                        )
                                    }
                                    defaultCopy={{
                                        placeholder: "Search for a name",
                                    }}
                                    initSelectedValues={
                                        initialOption.clinicians
                                    }
                                    error={errors && errors.length > 0}
                                    theme="MULTISELECT"
                                />
                            )}
                        </>
                    )}
                </UI.FormFieldWrapper>
            </StyledSearchSelect>
        </SpacingWrapper>
    );
};
