import React, { useEffect, useState } from "react";

import { useAccurxWebTitle } from "@accurx/navigation";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import { StyledLayoutWithFooter } from "app/batchMessage/gp/BatchMessage.styles";
import {
    BatchRoute,
    findBatchRoute,
} from "app/batchMessage/gp/BatchMessage.utils";
import { BatchMessageHeader } from "app/batchMessage/gp/components/BatchMessageHeader";
import { BatchMessageScheduleForm } from "app/batchMessage/gp/components/schedule/BatchMessageScheduleForm";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";

export const BatchMessageSchedule = (): JSX.Element => {
    useAccurxWebTitle("Schedule batch message");

    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const [scheduleMessageValid, setScheduleMessageValid] = useState(true);

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchSchedulePageView(
            analyticsLoggedInProps,
        );
    }, [analyticsLoggedInProps]);

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: true,
        });
    };

    const routes = findBatchRoute(BatchRoute.SCHEDULE);
    return (
        <StyledLayoutWithFooter>
            <Breadcrumb title="Batch Messaging" />
            <div className="d-flex flex-column align-items-center my-4">
                <BatchMessageHeader title={"Schedule message"} stepNumber={3} />
                <div className="col-12 col-lg-6 px-0">
                    <BatchMessageScheduleForm
                        setScheduleMessageValid={setScheduleMessageValid}
                    />
                </div>
            </div>
            <StepsFooter
                backText={"Back"}
                backLink={routes.back}
                backClickFunction={handleBack}
                forwardText={"Review"}
                forwardLink={routes.forward}
                disabled={scheduleMessageValid}
            />
        </StyledLayoutWithFooter>
    );
};

export default BatchMessageSchedule;
