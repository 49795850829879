import { useEffect, useRef } from "react";

import * as UI from "@accurx/design";
import { QuestionnaireTemplate } from "@accurx/message-templates";
import { useMedicalRecordConnection } from "@accurx/native";
import { ActionStack } from "domains/compose/components/Compose/components/ActionStack/ActionStack";
import { BodyInfoBox } from "domains/compose/components/Compose/components/BodyInfoBox";
import { MessageContentPlaceholder } from "domains/compose/components/Compose/components/MessageContentPlaceholder/MessageContentPlaceholder";
import { getTemplateSnomedCodes } from "domains/compose/utils";

import {
    StyledTextWithNewLines,
    StyledWrapper,
} from "./TemplatePreview.styles";

type QuestionnaireTemplatePreviewProps = {
    template: QuestionnaireTemplate;
    greeting: string;
    signature: string;
};

export const QuestionnaireTemplatePreview = ({
    template,
    greeting,
    signature,
}: QuestionnaireTemplatePreviewProps) => {
    const { status, capabilities } = useMedicalRecordConnection();

    /**
     * Screenreader lose focus and revert back to the top of window when navigating to preview.
     * This ref moves focus back to the component and reads out the title.
     */
    const sectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (sectionRef.current) {
            sectionRef.current.focus();
        }
    });

    // The api for questionnaire templates doesn't contain SNOMED terms so we need to fetch them async
    const templateSnomedCodes = capabilities?.saveToRecord
        ? getTemplateSnomedCodes({
              type: "QuestionnaireTemplate",
              value: template,
          })
        : [];

    return (
        <section
            aria-label={`${template.title} preview`}
            tabIndex={-1}
            ref={sectionRef}
        >
            <StyledWrapper>
                <UI.Ds.Flex flexDirection={"column"} gap="1">
                    <UI.Text skinny variant="label" as="h2">
                        {template.title}
                    </UI.Text>
                    {status === "Connected" && !!template.supportArticleUrl && (
                        <div>
                            <BodyInfoBox.Link
                                href={template.supportArticleUrl}
                                openInNewTab
                            >
                                <UI.Link.Text text="SNOMED codes and questions" />
                                <UI.Link.Icon />
                            </BodyInfoBox.Link>
                        </div>
                    )}
                </UI.Ds.Flex>
                <UI.Ds.Flex flexDirection="column" gap="3">
                    <div>
                        <StyledTextWithNewLines>
                            {greeting}
                        </StyledTextWithNewLines>
                        <StyledTextWithNewLines>
                            {/* 
                                        Trim so that extra newlines 
                                        at beginning and end don't make 
                                        the space between parts look too big 
                                    */}
                            {template.body.trim()}
                        </StyledTextWithNewLines>
                    </div>
                    <MessageContentPlaceholder
                        isQuestionnaire={true}
                        isPatientResponseEnabled={false}
                        hasAttachments={false}
                        nhsAdviceLink={null}
                        hasSelfBookLink={false}
                    />
                    <StyledTextWithNewLines>{signature}</StyledTextWithNewLines>
                </UI.Ds.Flex>
                <ActionStack
                    questionnaire={{
                        template,
                        withPreviewLink: true,
                    }}
                    snomedCodes={templateSnomedCodes}
                    isPatientResponseEnabled={false}
                    attachments={[]}
                    nhsAdviceLink={null}
                    selfBookLink={null}
                />
            </StyledWrapper>
        </section>
    );
};
