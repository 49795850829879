import { QuestionnaireTemplate } from "@accurx/message-templates";
import {
    Attachment as AttachmentType,
    SelfBookLink as SelfBookLinkType,
} from "domains/compose/reducer.types";
import {
    NHSAdviceLink,
    SnomedCode as SnomedCodeType,
} from "domains/compose/types";

import { StyledActionStack } from "./ActionStack.styles";
import { Attachment } from "./components/Attachment";
import { NhsAdvice } from "./components/NhsAdvice";
import { PatientResponse } from "./components/PatientResponse";
import { Questionnaire } from "./components/Questionnaire";
import { ResponseAssignee } from "./components/ResponseAssignee";
import { SelfBookLink } from "./components/SelfBookLink";
import { SnomedCode } from "./components/SnomedCode";

type ActionStackProps = {
    /**
     * When passed, it renders the assignee row when
     * - patient response is enabled
     * - questionnaire is added
     *
     * When not passed, the assignee row is not going to be displayed
     * */
    assigneeLabel?: JSX.Element;
    nhsAdviceLink: NHSAdviceLink | null;
    /** [OPTIONAL] when not passed through,
     * there won't be remove functionality
     * */
    onNhsAdviceRemove?: () => void;
    isPatientResponseEnabled: boolean;
    attachments: AttachmentType[];
    /** [OPTIONAL] when not passed through,
     * there won't be remove functionality
     * */
    onAttachmentRemove?: (attachment: AttachmentType) => void;
    selfBookLink: SelfBookLinkType | null;
    /** [OPTIONAL] when not passed through,
     * there won't be remove functionality */
    onSelfBookLinkRemove?: () => void;
    questionnaire: {
        template: QuestionnaireTemplate;
        isPending?: boolean;
        warning?: string;
        /**
         * [OPTIONAL]: displays the questionnaire title
         * with a link to preview
         * */
        withPreviewLink?: boolean;
    } | null;
    snomedCodes: SnomedCodeType[];
};

export const ActionStack = (props: ActionStackProps) => {
    const topRow = {
        nhsAdviceLink: props.nhsAdviceLink,
        isPatientResponseEnabled: props.isPatientResponseEnabled,
        attachments: props.attachments.length > 0 ? props.attachments : null,
        questionnaire: props.questionnaire,
        selfBookLink: props.selfBookLink,
    };

    const bottomRow = {
        assignee:
            !!props.assigneeLabel &&
            (topRow.isPatientResponseEnabled || topRow.questionnaire !== null)
                ? props.assigneeLabel
                : null,
        snomedCodes: props.snomedCodes.length > 0 ? props.snomedCodes : null,
    };

    const shouldRenderTopRow = Object.values(topRow).find((val) => !!val);
    const shouldRenderBottomRow = Object.values(bottomRow).find((val) => !!val);

    if (!shouldRenderTopRow && !shouldRenderBottomRow) {
        return null;
    }

    return (
        <StyledActionStack.Container>
            {shouldRenderTopRow && (
                <StyledActionStack.Section>
                    {topRow.nhsAdviceLink && (
                        <NhsAdvice
                            name={topRow.nhsAdviceLink.name}
                            url={topRow.nhsAdviceLink.url}
                            onRemoveClick={props.onNhsAdviceRemove}
                        />
                    )}
                    {topRow.isPatientResponseEnabled && <PatientResponse />}
                    {topRow.attachments &&
                        topRow.attachments.map((attachment) => (
                            <Attachment
                                key={attachment.id}
                                attachment={attachment}
                                onRemoveClick={props.onAttachmentRemove}
                            />
                        ))}
                    {topRow.questionnaire && (
                        <Questionnaire
                            name={topRow.questionnaire.template.title}
                            id={topRow.questionnaire.template.id}
                            withPreviewLink={
                                topRow.questionnaire.withPreviewLink === true
                            }
                            isPending={topRow.questionnaire.isPending}
                            warning={topRow.questionnaire.warning}
                        />
                    )}
                    {topRow.selfBookLink && (
                        <SelfBookLink
                            selfBookLink={topRow.selfBookLink}
                            onRemoveClick={props.onSelfBookLinkRemove}
                        />
                    )}
                </StyledActionStack.Section>
            )}
            {shouldRenderTopRow && shouldRenderBottomRow && (
                <StyledActionStack.Divider />
            )}
            {shouldRenderBottomRow && (
                <StyledActionStack.Section>
                    {bottomRow.assignee && (
                        <ResponseAssignee assigneeLabel={bottomRow.assignee} />
                    )}
                    {bottomRow.snomedCodes &&
                        bottomRow.snomedCodes.map(({ id, term }) => (
                            <SnomedCode
                                key={id}
                                term={term ?? undefined}
                                id={id}
                            />
                        ))}
                </StyledActionStack.Section>
            )}
        </StyledActionStack.Container>
    );
};
