import { DateHelpers } from "@accurx/shared";
import { addDays, format, isToday, isTomorrow } from "date-fns";

import { TimeFrameInput } from "./types";

const addTimeFrameToDate = (availableDate: Date, timeFrame: TimeFrameInput) => {
    const timeValue = timeFrame.value
        ? parseInt(timeFrame.value, 10)
        : undefined;
    if (timeValue === undefined) {
        return;
    }
    const daysToAdd = timeFrame.type === "weeks" ? timeValue * 7 : timeValue;
    return addDays(availableDate, daysToAdd - 1);
};

const appointmentAvailabilityIsOneDay = (
    timeFrame: TimeFrameInput,
): boolean => {
    return timeFrame.value === "1" && timeFrame.type === "days";
};

const appointmentAvailabilityIsToday = (
    sendTime: Date,
    timeFrame: TimeFrameInput,
): boolean => {
    return isToday(sendTime) && appointmentAvailabilityIsOneDay(timeFrame);
};

const appointmentAvailabilityIsTomorrow = (
    sendTime: Date,
    timeFrame: TimeFrameInput,
): boolean => {
    return isTomorrow(sendTime) && appointmentAvailabilityIsOneDay(timeFrame);
};

export const availabilityPrimaryText = (
    sendAt: string,
    timeFrame: TimeFrameInput,
): string => {
    const sendTime = DateHelpers.createDateFromDateString(sendAt).toDate();

    if (appointmentAvailabilityIsToday(sendTime, timeFrame)) {
        return "Only show appointments available ";
    }

    if (appointmentAvailabilityIsTomorrow(sendTime, timeFrame)) {
        return "Only show appointments available ";
    }

    if (appointmentAvailabilityIsOneDay(timeFrame)) {
        return "Only show appointments available on ";
    }
    return "Only show appointments available from ";
};

export const availabilityText = (
    sendAt: string,
    timeFrame: TimeFrameInput,
): string | undefined => {
    const sendTime = DateHelpers.createDateFromDateString(sendAt).toDate();
    const availabilityPeriodEnd = addTimeFrameToDate(sendTime, timeFrame);

    if (availabilityPeriodEnd === undefined) {
        return;
    }

    if (isToday(sendTime)) {
        return appointmentAvailabilityIsToday(sendTime, timeFrame)
            ? "Today"
            : `Today - ${format(availabilityPeriodEnd, "E d MMM y")}`;
    }

    if (isTomorrow(sendTime)) {
        return appointmentAvailabilityIsTomorrow(sendTime, timeFrame)
            ? "Tomorrow"
            : `Tomorrow - ${format(availabilityPeriodEnd, "E d MMM y")}`;
    }

    if (appointmentAvailabilityIsOneDay(timeFrame)) {
        return `${format(sendTime, "E d MMM y")}`;
    }

    return `${format(sendTime, "E d MMM y")} - ${format(
        availabilityPeriodEnd,
        "E d MMM y",
    )}`;
};
