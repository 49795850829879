import { RegionClassification } from "@accurx/api/portal";
import { Link, Text } from "@accurx/design";

import {
    regionalise111Link,
    regionalise999Link,
} from "../../utils/RegionaliseLink.utils";

export type UrgentHelpSectionProps = {
    region: RegionClassification;
};

export const UrgentHelpSection = ({ region }: UrgentHelpSectionProps) => {
    if (region === RegionClassification.NorthernIreland) {
        return (
            <Text>
                If you need more urgent help, call your GP. If your GP practice
                is closed,{" "}
                <Link openInNewTab href={regionalise111Link(region)}>
                    <Link.Text text="contact the GP out of hours service" />
                    <Link.Icon />
                </Link>
                . In an emergency{" "}
                <Link openInNewTab href={regionalise999Link(region)}>
                    <Link.Text text="call 999" />
                    <Link.Icon />
                </Link>
            </Text>
        );
    }
    return (
        <Text>
            If you need more urgent help, call your GP. If your GP practice is
            closed, visit{" "}
            <Link openInNewTab href={regionalise111Link(region)}>
                <Link.Text text="NHS 111 online" />
                <Link.Icon />
            </Link>
            or call 111. In an emergency{" "}
            <Link openInNewTab href={regionalise999Link(region)}>
                <Link.Text text="call 999" />
                <Link.Icon />
            </Link>
        </Text>
    );
};
