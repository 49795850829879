import { useCurrentWorkspace } from "@accurx/auth";
import { Text } from "@accurx/design";

import { ChangeRequests } from "../../components/ChangeRequests";
import { PageContainer } from "../OverviewPage/components/PageContainer.styles";

/**
 * This page is purposely designed for the new unified navigation
 */
export const AppointmentChangesPage = () => {
    const { orgId: workspaceId } = useCurrentWorkspace();

    return (
        <PageContainer>
            <Text as="h1" variant="subtitle" skinny>
                Appointment changes
            </Text>
            <ChangeRequests workspaceId={String(workspaceId)} />
        </PageContainer>
    );
};
