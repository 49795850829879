import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Card, Text, Tokens } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { calculateFragments } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    fetchAccurxMessageTemplates,
    fetchOrgMessageTemplates,
    fetchUserMessageTemplates,
    removeUploadedBatchFile,
    updateBatchFile,
    updateMessage,
    updateSelectedBatchOptionToSaveToRecord,
    updateSelectedTemplateCanReply,
    updateSelectedTemplateId,
    updateSelectedTemplateName,
    updateSelectedTemplateOwner,
    updateSelectedTemplateSnomed,
} from "app/batchMessage/gp/BatchMessage.actions";
import {
    StyledLayoutWithFooter,
    StyledTextareaAutosize,
} from "app/batchMessage/gp/BatchMessage.styles";
import {
    BatchSnomedCode,
    BatchType,
    MessageTemplate,
    MessageTemplateOwner,
} from "app/batchMessage/gp/BatchMessage.types";
import {
    BatchRoute,
    findBatchRoute,
} from "app/batchMessage/gp/BatchMessage.utils";
import { BatchMessageHeader } from "app/batchMessage/gp/components/BatchMessageHeader";
import { ComposeMsgInfo } from "app/batchMessage/gp/components/ComposeMsgInfo";
import {
    ComposeOptionToSaveToRecord,
    useSetSaveBatchToRecord,
} from "app/batchMessage/gp/components/ComposeOptionToSaveToRecord";
import {
    DEFAULT_TEMPLATE_HEADER,
    FOOTER_DOCUMENT,
    PLACEHOLDER_LINK_AUTO,
} from "app/batchMessage/gp/components/compose/BatchMessageCompose.constants";
import { Z_INDEX } from "app/batchMessage/gp/components/compose/BatchMessageCompose.styles";
import { MessageErrorType } from "app/batchMessage/gp/components/compose/BatchMessageCompose.types";
import {
    calculateMessageLength,
    isValidMessage,
} from "app/batchMessage/gp/components/compose/BatchMessageCompose.utils";
import { ComposeTemplateDropdown } from "app/batchMessage/gp/components/compose/ComposeTemplateDropdown";
import { SnomedCodeSelect } from "app/batchMessage/gp/components/snomedCodes/SnomedCodeSelect";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { MessageComposeWrapper } from "../components/MessageComposeWrapper";
import { BatchMessageFileUpload } from "./BatchMessageFileUpload";

export const ComposeSms = (): JSX.Element => {
    useAccurxWebTitle("Write a batch SMS message");

    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Feature flags
    const waitingListValidationEnabled = useIsFeatureEnabled(
        FeatureName.WebWaitingListValidation,
    );

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const practiceName = useAppSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices?.selectedPractice,
            )?.name ?? "",
    );
    const initialSelectedTemplateCanReply = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedTemplateCanReply,
    );

    const initialSelectedTemplateOwner = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedTemplateOwner,
        shallowEqual,
    );

    const initialSelectedTemplateId = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedTemplateId,
        shallowEqual,
    );

    const gettingUserTemplatesStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingUserTemplatesStatus,
        shallowEqual,
    );
    const gettingOrgTemplatesStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingOrgTemplatesStatus,
        shallowEqual,
    );
    const gettingAccurxTemplatesStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingAccurxTemplatesStatus,
        shallowEqual,
    );
    const maximumPatientGreetingLength = useAppSelector(
        ({ batchMessage }) =>
            batchMessage.batchMessageDetails.maximumPatientGreetingLength,
    );
    const patientMessage = useAppSelector(
        ({ batchMessage }) => batchMessage.patientMessage,
    );
    const selectedTemplateName = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedTemplateName,
    );
    const snomedCodes = useAppSelector(
        ({ batchMessage }) => batchMessage.snomedCodes,
        shallowEqual,
    );
    const selectedTemplateSnomedCode = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedTemplateSnomedCode,
    );
    const uploadedFileName = useAppSelector(
        ({ batchMessage }) => batchMessage.uploadedFileName,
    );

    // States: to be saved to redux
    const [messageBody, setMessageBody] = useState(patientMessage);
    const [selectedTemplateCanReply, setSelectedTemplateCanReply] = useState(
        initialSelectedTemplateCanReply,
    );
    const [selectedTemplateDisplay, setSelectedTemplateDisplay] = useState(
        selectedTemplateName || DEFAULT_TEMPLATE_HEADER,
    );
    const [selectedTemplateOwner, setSelectedTemplateOwner] = useState(
        initialSelectedTemplateOwner,
    );

    const [selectedTemplateId, setSelectedTemplateId] = useState(
        initialSelectedTemplateId,
    );

    const [
        selectedTemplateSnomedConceptId,
        setSelectedTemplateSnomedConceptId,
    ] = useState(selectedTemplateSnomedCode);

    // States: local only
    const [validMessageBody, setValidMessageBody] = useState(false);
    const [messageErrorMessage, setMessageErrorMessage] = useState("");
    const [messageErrorType, setMessageErrorType] = useState(
        MessageErrorType.None,
    );
    const [characterCount, setCharacterCount] = useState(0);

    const checkMessageTextAndSetState = useCallback(
        (textToCheck: string) => {
            const totalCharacterCount = calculateMessageLength(
                BatchType.MESSAGE,
                maximumPatientGreetingLength,
                textToCheck.length,
                practiceName.length,
                !!uploadedFileName,
            );
            setCharacterCount(totalCharacterCount);

            const validation = isValidMessage(textToCheck, totalCharacterCount);
            setValidMessageBody(validation.validMessageBody);
            setMessageErrorMessage(validation.messageErrorMessage);
            setMessageErrorType(validation.messageErrorType);
        },
        [maximumPatientGreetingLength, uploadedFileName, practiceName],
    );

    const checkAndSetMessageText = (messageText: string) => {
        checkMessageTextAndSetState(messageText);
        setMessageBody(messageText);
    };

    const updateMessageText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        checkAndSetMessageText(e.target.value);
    };

    // Load 3 types of templates for the dropdown
    useEffect(() => {
        if (
            gettingAccurxTemplatesStatus === UpdatingStatus.Initial &&
            practiceId
        ) {
            dispatch(fetchAccurxMessageTemplates(practiceId));
        }
    }, [dispatch, practiceId, gettingAccurxTemplatesStatus]);
    useEffect(() => {
        if (
            gettingOrgTemplatesStatus === UpdatingStatus.Initial &&
            practiceId
        ) {
            dispatch(fetchOrgMessageTemplates(practiceId));
        }
    }, [dispatch, practiceId, gettingOrgTemplatesStatus]);
    useEffect(() => {
        if (
            gettingUserTemplatesStatus === UpdatingStatus.Initial &&
            practiceId
        ) {
            dispatch(fetchUserMessageTemplates(practiceId));
        }
    }, [dispatch, practiceId, gettingUserTemplatesStatus]);

    // Check message length and count characters
    useEffect(() => {
        checkMessageTextAndSetState(messageBody);
    }, [checkMessageTextAndSetState, messageBody]);

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchPatientMessageReviewPageView({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
        });
    }, [analyticsLoggedInProps, history.location.pathname]);

    const { shouldSaveBatchToRecord, setShouldSaveBatchToRecord } =
        useSetSaveBatchToRecord();

    const checkAndSetSnomedCode = (codes: BatchSnomedCode[]) => {
        if (codes !== null && codes.length > 0) {
            const snomedCode = snomedCodes.find(
                (code) => code.conceptId === codes[0].conceptId,
            );
            if (snomedCode !== undefined) {
                setSelectedTemplateSnomedConceptId(snomedCode.conceptId);
                return;
            }
        }
        setSelectedTemplateSnomedConceptId("");
    };

    const selectTemplate = (
        template: MessageTemplate,
        templateOwner: MessageTemplateOwner,
    ) => {
        setSelectedTemplateDisplay(template.title);
        setSelectedTemplateOwner(templateOwner);
        setSelectedTemplateId(parseInt(template.id, 10));
        setSelectedTemplateCanReply(template.allowReplyByDefault);
        checkAndSetMessageText(template.body);
        checkAndSetSnomedCode(template.snomedCodes);

        if (template.attachments && template.attachments.length > 0) {
            if (template.attachments.length > 1) {
                console.warn(
                    "Multiple attachments are allowed, make changes to the dispatch action of updateBatchFile",
                );
            }
            const [file] = template.attachments;

            dispatch(
                updateBatchFile({
                    attachmentId: `${file.id}`,
                    fileLink: `/api/patientMessaging/template/message/${practiceId}/${template.id}/attachment/${file.id}`,
                    fileName: file.fileName,
                    isFileAttachmentFromTemplate: true,
                }),
            );
        } else {
            dispatch(removeUploadedBatchFile());
        }
    };

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: false,
        });
    };

    const handleSave = () => {
        // TODO: This needs to be refactored into one action
        dispatch(updateMessage(messageBody));
        dispatch(updateSelectedTemplateCanReply(selectedTemplateCanReply));
        dispatch(
            updateSelectedTemplateName(
                selectedTemplateDisplay === DEFAULT_TEMPLATE_HEADER
                    ? ""
                    : selectedTemplateDisplay,
            ),
        );
        dispatch(updateSelectedTemplateId(selectedTemplateId));
        dispatch(updateSelectedTemplateOwner(selectedTemplateOwner));
        dispatch(updateSelectedTemplateSnomed(selectedTemplateSnomedConceptId));
        dispatch(
            updateSelectedBatchOptionToSaveToRecord(shouldSaveBatchToRecord),
        );
    };

    /**
     * calculate fragments by passing in the body of the
     * batch message as well as padding it to length we assume it is
     * accounting for things like practice name and message footer
     */
    const fragmentCount = calculateFragments(
        `${messageBody}${"a".repeat(
            Math.abs(characterCount - messageBody.length),
        )}`,
    );

    const routes = findBatchRoute(BatchRoute.COMPOSE);
    return (
        <StyledLayoutWithFooter>
            <Breadcrumb title="Batch Messaging Sms" />
            <div className="row mb-4">
                <div className="col-12 col-lg-8 offset-lg-2 text-center mb-4">
                    <BatchMessageHeader
                        title={"Write a message"}
                        stepNumber={1}
                    />
                    <Text>
                        Do not include patients who have declined consent for
                        contact by SMS or email.
                    </Text>
                </div>
                <div className="col-12 col-lg-6 offset-lg-3">
                    <Card isElevated={false} spacing={1.5}>
                        <ComposeTemplateDropdown
                            selectTemplate={selectTemplate}
                            selectedTemplateCanReply={selectedTemplateCanReply}
                            selectedTemplateDisplay={selectedTemplateDisplay}
                        />
                        <Text
                            as="label"
                            variant="label"
                            props={{ htmlFor: "message-body" }}
                        >
                            Message
                        </Text>
                        <Text>
                            This message will be sent to multiple patients, so
                            please do not include information relating to
                            individual patients.
                        </Text>
                        <MessageComposeWrapper>
                            <StyledTextareaAutosize
                                id="message-body"
                                style={{
                                    outline: "none",
                                    color: Tokens.COLOURS.greyscale.zinc,
                                    marginTop: `${Tokens.SIZES[1]}`,
                                    marginBottom: `${Tokens.SIZES[1]}`,
                                }}
                                value={messageBody}
                                onChange={updateMessageText}
                                placeholder={"Please enter a message"}
                                data-testid="message-body"
                                disabled={false}
                                rows={4}
                            />
                            {!!uploadedFileName && (
                                <Text data-testid="message-footer">
                                    {FOOTER_DOCUMENT + PLACEHOLDER_LINK_AUTO}
                                </Text>
                            )}
                        </MessageComposeWrapper>
                        <ComposeMsgInfo
                            characterCount={characterCount}
                            fragmentCount={fragmentCount}
                            messageErrorMessage={messageErrorMessage}
                            messageErrorType={messageErrorType}
                        />
                        <BatchMessageFileUpload />
                        <div className="mb-4 mt-4">
                            <ComposeOptionToSaveToRecord
                                shouldSaveBatchToRecord={
                                    shouldSaveBatchToRecord
                                }
                                setShouldSaveBatchToRecord={
                                    setShouldSaveBatchToRecord
                                }
                                batchType={BatchType.MESSAGE}
                            />
                        </div>
                        {waitingListValidationEnabled === false && (
                            <SnomedCodeSelect
                                batchSnomedSelect="SMS - Invite"
                                selectedTemplateDisplay={
                                    selectedTemplateDisplay
                                }
                                selectedSnomedCode={
                                    selectedTemplateSnomedConceptId
                                }
                                setSelectedTemplateSnomedConceptId={
                                    setSelectedTemplateSnomedConceptId
                                }
                            />
                        )}
                    </Card>

                    {/* Temp fix for <SnomedCodeSelect> while we fix the design library.
                    The <select> component should take in account screen collision and make sure there's enough space to display content in full */}
                    <div style={{ marginBottom: "16rem" }}></div>
                </div>

                <StepsFooter
                    backText={"Back"}
                    backLink={routes.back}
                    backClickFunction={handleBack}
                    forwardText={"Add a patient list"}
                    forwardLink={routes.forward}
                    forwardClickFunction={handleSave}
                    disabled={!validMessageBody}
                    zIndex={Z_INDEX.footer}
                />
            </div>
        </StyledLayoutWithFooter>
    );
};

export default ComposeSms;
