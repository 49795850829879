import React from "react";

import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { userflowIds } from "domains/compose/userflow";

import { ToolippedButton } from "../../../../TooltippedButton/TooltippedButton";

type AttachDocumentButtonProps = {
    toggle: () => void;
    iconOnly?: boolean;
    disabled?: boolean;
};

type Ref = HTMLButtonElement;

export const AttachDocumentButton = React.forwardRef<
    Ref,
    AttachDocumentButtonProps
>(({ toggle, disabled = false, iconOnly = false }, ref) => {
    return (
        <ToolippedButton
            toggle={toggle}
            disabled={disabled}
            ariaLabel="Attach files"
            userFlowId={userflowIds.attachButton}
            iconOnly={iconOnly}
            tooltipContent="Attach"
            ref={ref}
        >
            <Pill.Icon
                name="Paperclip"
                colour="blue"
                useDisabledColour={disabled}
                theme="Line"
                props={{
                    "aria-hidden": true,
                }}
            />
            {!iconOnly && (
                <UI.Text as="span" skinny variant="preview">
                    Attach
                </UI.Text>
            )}
        </ToolippedButton>
    );
});
