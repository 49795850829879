import { useEffect } from "react";

import {
    useInboxLink,
    useSearchParams,
    useViewportSize,
} from "domains/inbox/hooks/util";
import { useHistory } from "react-router";

import { useLayout } from "../Layout/context";
import { ComposeAreaVisibilityProvider } from "./ComposeAreaVisibilityContext/ComposeAreaVisibilityProvider";
import { ConversationView } from "./ConversationView";
import { ConversationViewEmptyState } from "./components";

/**
 * <ConversationViewPage />
 *
 * This is the public component. It loads the current conversation and renders
 * it. Most of that it defers resonsibility for to its children. The things it
 * takes personal responsibility for are:
 * - Knowing what the current conversation ID is based on the URL
 * - If there's no current conversation rendering an empty state
 * - Making sure the layout looks correct at different breakpoints
 * - Making sure the correct conversation component is used
 */
export const ConversationViewPage = ({
    showEmptyState = true,
}: {
    showEmptyState?: boolean;
}) => {
    const { conversationId } = useSearchParams();
    const { rightPane } = useLayout();
    const viewportSize = useViewportSize();
    const isMobile = viewportSize === "xs" || viewportSize === "sm";
    const history = useHistory();
    const link = useInboxLink();

    useEffect(() => {
        rightPane.setIsOpen(!!conversationId);
    }, [conversationId, rightPane]);

    if (conversationId) {
        const handleCloseConversation = () => {
            history.push(
                link.update({
                    conversationId: null,
                }),
            );
        };

        return (
            <ComposeAreaVisibilityProvider>
                <ConversationView
                    conversationId={conversationId}
                    onClickBack={handleCloseConversation}
                    onMarkAsUnreadSuccess={handleCloseConversation}
                />
            </ComposeAreaVisibilityProvider>
        );
    }

    return !isMobile && showEmptyState ? <ConversationViewEmptyState /> : null;
};
