import { useEffect, useRef } from "react";

import { TeamSummary, UserSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";

import {
    StyledAssigneeAvatarWrapper,
    StyledAssigneeOnlineIndicator,
} from "./AssigneeRadioLabel.styles";

type AssigneeRadioLabelProps = {
    assignee: TeamSummary | UserSummary;
    assigneeType: "Team" | "User";
    isCurrentAssignee: boolean;
};

export const AssigneeRadioLabel = ({
    assignee,
    assigneeType,
    isCurrentAssignee,
}: AssigneeRadioLabelProps) => {
    const scrollToRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        scrollToRef.current?.scrollIntoView({
            block: "center",
        });
    }, []);

    return (
        <UI.Flex
            gap="1"
            as="span"
            ref={isCurrentAssignee ? scrollToRef : undefined}
        >
            <StyledAssigneeAvatarWrapper>
                <span aria-hidden={true}>
                    <UI.Avatar
                        size="small"
                        name={assignee.displayName}
                        colour="white"
                    />
                </span>

                {assigneeType === "User" && (
                    <StyledAssigneeOnlineIndicator
                        userId={assignee.id}
                        displayName={assignee.displayName}
                    />
                )}
            </StyledAssigneeAvatarWrapper>
            <UI.Text as="span" skinny variant="preview" colour="night">
                {"isCurrentUser" in assignee
                    ? `${assignee.displayName} (You)`
                    : assignee.displayName}
            </UI.Text>
        </UI.Flex>
    );
};
