import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Feedback } from "@accurx/design";

import { usePatientTriageStatusQuery } from "app/hooks/queries/usePatientTriageStatusQuery";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { SetupPatientTriage } from "app/practices/patientTriage/components";

export const SetupPatientTriagePage = () => {
    const { orgId } = useCurrentWorkspace();
    const { status, data } = usePatientTriageStatusQuery({
        workspaceId: orgId.toString(),
    });

    return (
        <StyledContainer>
            <PageHeader
                title="Set up Patient Triage"
                type={PageHeaderType.ListPage}
            />
            {status === "loading" && <Ds.Spinner />}
            {status === "error" && (
                <Feedback
                    title="Unable to receive Patient Triage status, please try again"
                    colour="error"
                />
            )}
            {status === "success" && (
                <SetupPatientTriage practiceLink={data.url} />
            )}
        </StyledContainer>
    );
};
