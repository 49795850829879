import { useCurrentWorkspace } from "@accurx/auth";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";

import { mutateCloseWindow } from "../api/endpoints/mutateCloseWindow";

export const useMutateCloseWindow = (
    options?: UseMutationOptions<
        unknown,
        NativeFetchError | NativeTransportError
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async () => {
        if (!transport) {
            throw new NativeTransportError(
                "MutateCloseWindow: Called with no native transport",
            );
        }

        await mutateCloseWindow({ transport, workspaceId });
    }, options);
};
