import React, { ReactNode } from "react";

import * as UI from "@accurx/design";
import { Tooltip } from "@accurx/inbox-design-library";

import {
    StyledButton,
    StyledTooltipTriggerWrapper,
} from "./ToolippedButton.styles";

type TooltippedButtonProps = {
    toggle: () => void;
    iconOnly?: boolean;
    disabled?: boolean;
    tooltipContent: string;
    children: ReactNode;
    ariaLabel: string;
    userFlowId?: string;
    ["data-testid"]?: string;
};

type Ref = HTMLButtonElement;

export const ToolippedButton = React.forwardRef<Ref, TooltippedButtonProps>(
    (
        {
            toggle,
            disabled = false,
            iconOnly = false,
            tooltipContent,
            ariaLabel,
            userFlowId,
            children,
            ...props
        },
        ref,
    ) => {
        return (
            <Tooltip.Root>
                <StyledTooltipTriggerWrapper>
                    <Tooltip.Trigger tabIndex={-1} asChild={true}>
                        <StyledButton
                            radius="full"
                            onClick={toggle}
                            ref={ref}
                            aria-label={ariaLabel}
                            data-userflow-id={userFlowId}
                            $showIconOnly={iconOnly}
                            disabled={disabled}
                            data-testid={props["data-testid"]}
                        >
                            {children}
                        </StyledButton>
                    </Tooltip.Trigger>
                </StyledTooltipTriggerWrapper>
                {iconOnly && (
                    <Tooltip.Content>
                        <UI.Text colour="white" variant="note" skinny>
                            {tooltipContent}
                        </UI.Text>
                    </Tooltip.Content>
                )}
            </Tooltip.Root>
        );
    },
);
