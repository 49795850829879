import {
    AppointmentAvailabilityPeriod,
    AppointmentAvailabilityPeriodUnits,
} from "./types";

const availabilityPeriodToDays: Record<
    AppointmentAvailabilityPeriodUnits,
    (period: AppointmentAvailabilityPeriod) => number
> = {
    days(period: AppointmentAvailabilityPeriod): number {
        return period.value;
    },
    weeks(period: AppointmentAvailabilityPeriod): number {
        return period.value * 7;
    },
};

export function convertAvailabilityPeriodToDays(
    period: AppointmentAvailabilityPeriod,
) {
    return availabilityPeriodToDays[period.units](period);
}

export function convertAvailabilityPeriodToIsoDuration(
    period: AppointmentAvailabilityPeriod,
) {
    return `P${convertAvailabilityPeriodToDays(period)}D`;
}
