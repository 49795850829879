export enum UserGroupType {
    None = 0,
    Team = 1,
    FailedDeliveryReceipts = 2,
    Florey = 3,
    PatientSingleResponse = 4,
    ToAssign = 5,
    DefaultRoles = 6,
    PracticeGroup = 7,
}

export type PatientThreadUserGroupChanged = {
    organisationId: Optional<number>;
    userGroup: Optional<{
        id: Optional<string>;
        name: Optional<string>;
        groupType: Optional<UserGroupType>;
    }>;
    userMembership: Optional<
        {
            userId: Optional<string>;
            userHasConfirmedMemberShip: Optional<boolean>;
        }[]
    >;
};

// backwards-compatibility with PascalCase output
export type PatientThreadUserGroupChangedLegacy = {
    OrganisationId: Optional<number>;
    UserGroup: Optional<{
        Id: Optional<string>;
        Name: Optional<string>;
        GroupType: Optional<UserGroupType>;
    }>;
    UserMembership: Optional<
        {
            UserId: Optional<string>;
            UserHasConfirmedMemberShip: Optional<boolean>;
        }[]
    >;
};

export type VideoConsultationStatusChanged = {
    consultationId: string;
    patientListId: number;
};

// backwards-compatibility with PascalCase output
export type VideoConsultationStatusChangedLegacy = {
    ConsultationId: string;
    PatientListId: number;
};

// SocketEvents.OnInviteStatusChanged Websocket defined in BE
export type VaccineInviteStatusPushUpdate = {
    organisationId: number;
    inviteId: string;
    currentInviteStatus: string;
};

// backwards-compatibility with PascalCase output
export type VaccineInviteStatusPushUpdateLegacy = {
    InviteId: string;
    CurrentInviteStatus: string;
};

// DTOs for typing send match DTOs on SignalR
export type NoteTypingSend = {
    organisationId: Optional<number>;
    patient: Optional<{ accuRxId: Optional<string> }>;
    ticketIdentity: Optional<{
        type: Optional<TicketIdentityType>;
        id: Optional<string>;
    }>;
};

export type NoteTypingReceive = {
    organisationId: Optional<number>;
    patientId: Optional<string>;
    sender: Optional<{
        accuRxId: Optional<string>;
        email: Optional<string>;
        name: Optional<string>;
    }>;
    ticketIdentity: Optional<{
        type: Optional<TicketIdentityType>;
        id: Optional<string>;
    }>;
};

// This is 2 seconds to match the Desktop Inbox
export const TypingSendThrottleMs = 2000;

export type ThreadActiveSend = {
    organisationId: Optional<number>;
    patient: Optional<{ accuRxId: Optional<string> }>;
    ticketIdentity: Optional<{
        type: Optional<TicketIdentityType>;
        id: Optional<string>;
    }>;
};

export enum TicketIdentityType {
    None,
    SMS,
    Note,
    HealthcareEmail,
    PatientEmail,
    NhsAppMessage,
}

export type ThreadActiveReceive = {
    organisationId: Optional<number>;
    patientId: Optional<string>;
    sender: {
        accuRxId: Optional<string>;
        email: Optional<string>;
        name: Optional<string>;
    };
    ticketIdentity: Optional<{
        type: Optional<TicketIdentityType>;
        id: Optional<string>;
    }>;
};

export enum MachineChangeStatusReceiveStatus {
    None = 0,
    OnConnected = 1,
    OnDisconnected = 2,
    StatusUpdateActive = 3,
    StatusUpdateIdle = 4,
}

export type MachineChangeStatusReceive = {
    status: MachineChangeStatusReceiveStatus;
    organisationId: Optional<number>;
    fromUser: Optional<{
        accuRxId: string;
        email: string;
        name: string;
    }>;
};

export enum ConnectedStatusStatus {
    None = 0,
    StatusUpdateActive = 1,
    StatusUpdateIdle = 2,
}

export type ConnectedStatus = {
    status: ConnectedStatusStatus;
    organisationId: Optional<number>;
    notifySingleUserId: Optional<string>;
    machineIdentifier: Optional<string>;
};

export type ReconnectPushRequest = {
    workspaceId: Optional<number>;
};

export type HubReconnectDataInbox = {
    unreadPatientThreadNoteIds: Optional<string[]>;
    patientThreadUserUserGroupIds: Optional<string[]>;
    organisationId: Optional<number>;
};

export enum UserStatus {
    Unapproved = 0,
    Approved = 1,
    Archived = 2,
    Deleted = 3,
}

export type UserApprovalChange = {
    organisationId: number;
    userId: number;
    userStatus: UserStatus;
};

export const enum OrganisationEntityChangedType {
    None = 0,
    Settings = 1,
    Content = 2,
    NoticeBoard = 3,
    Templates = 4,
    UserStatusChange = 5,
    FloreyTemplate = 6,
}

export type OrganisationEntityChanged = {
    organisationId: Optional<number>;
    type: Optional<OrganisationEntityChangedType>;
};
