import { AssignResponse, AssigneeType } from "@accurx/api/clinician-messaging";
import { mapConversationCommandResponseToConciergeUpdate } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationCommandResponseToConciergeUpdate";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { AssigneeSummary } from "domains/concierge/types";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";

type UpdateAssigneeWithNoteOptions = {
    assignee: AssigneeSummary;
    notes?: string | null;
    workspaceId: string;
    workspaceConversationId: string;
};

const mapConversationAssigneeToClinicianMessagingAssignee = (
    arg: AssigneeSummary,
) => {
    return arg.type === "Team"
        ? {
              type: AssigneeType.UserGroup,
              id: parseInt(arg.id, 10),
          }
        : arg.type === "User"
        ? {
              type: AssigneeType.User,
              id: parseInt(arg.id, 10),
          }
        : {
              type: AssigneeType.None,
          };
};

export const updateAssigneeWithNote: ConciergeFetcher<
    UpdateAssigneeWithNoteOptions,
    AssignResponse
> = async (arg) => {
    const assignee = mapConversationAssigneeToClinicianMessagingAssignee(
        arg.assignee,
    );

    const response = await ClinicianMessagingApiClient.assignWithNote({
        assigneeId: assignee.id,
        assigneeType: assignee.type,
        notes: arg.notes,
        workspaceConversationId: arg.workspaceConversationId,
        workspaceId: arg.workspaceId,
    });

    const updates = mapConversationCommandResponseToConciergeUpdate(response);

    return { updates, response };
};
