import { ChangeEvent, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import {
    useMedicalRecordConnection,
    useNativeTrackingFields,
    useUploadPatientRecordAttachmentMutation,
} from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { DateHelpers } from "@accurx/shared";
import { formatPatientName } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { AttachFileFromPatientRecordButton } from "domains/compose/components/Compose/components/Attach/components/AttachSelector/components/AttachFileFromPatientRecordButton";
import { PatientRecordAttachmentSelector } from "domains/compose/components/Compose/components/Attach/components/PatientRecordAttachmentSelector/PatientRecordAttachmentSelector";
import { useCompose } from "domains/compose/context";
import { useCharacterCount } from "domains/compose/hooks/useCharacterCount";
import { Attachment } from "domains/compose/reducer.types";
import { NHSAdviceLink } from "domains/compose/types";
import {
    getNativeUploadErrorMessage,
    getValidPatientNhsNumber,
} from "domains/compose/utils";
import { mapSelfBookFormDataToSelfBookLink } from "domains/compose/utils/mapSelfBookFormDataToSelfBookLink";
import { v4 as uuid } from "uuid";

import { SelfbookConfigurationQuickView } from "../../../self-book/components/SelfbookConfigurationQuickView/SelfbookConfigurationQuickView";
import {
    StyledContainer,
    StyledFlexGrowContainer,
    StyledScrollableFixedHeightContainer,
} from "./Compose.styles";
import { ComposeActionStack } from "./ComposeActionStack";
import { AttachmentFailedMessage } from "./components/Attach/components/AttachFeedback/AttachmentFailedMessage";
import { AttachSelector } from "./components/Attach/components/AttachSelector/AttachSelector";
import { AttachFileFromDesktopButton } from "./components/Attach/components/AttachSelector/components/AttachFileFromDesktopButton";
import { AttachNHSAdviceButton } from "./components/Attach/components/AttachSelector/components/AttachNHSAdviceButton";
import { formatPatientRecordAttachmentName } from "./components/Attach/components/PatientRecordAttachmentSelector/formatPatientRecordAttachmentName";
import {
    MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
    SUPPORTED_FILE_EXTENSIONS,
} from "./components/Attach/upload.constants";
import { useUploadFromDesktop } from "./components/Attach/useUploadFromDesktop";
import { BookingInvite } from "./components/BookingInvite/BookingInvite";
import { ComposeAssigneeSelector } from "./components/ComposeAssigneeSelector/ComposeAssigneeSelector";
import { ComposeErrors } from "./components/ComposeErrors/ComposeErrors";
import { ComposeTextArea } from "./components/ComposeTextArea/ComposeTextArea";
import { ContactDetailSelector } from "./components/ContactDetailSelector/ContactDetailSelector";
import { ExpandMinimiseBar } from "./components/ExpandMinimiseBar/ExpandMinimiseBar";
import { Templates } from "./components/MessageTemplates/Templates";
import { NHSAdviceSelector } from "./components/NhsAdviceSelector/NhsAdviceSelector";
import { AllowReplyCheckbox } from "./components/PatientResponseCheckbox/AllowReplyCheckbox";
import { SaveToRecordCheckbox } from "./components/SaveToRecordCheckbox/SaveToRecordCheckbox";
import { ScheduledSendInfo } from "./components/ScheduledSendInfo/ScheduledSendInfo";
import { SendMessage } from "./components/SendMessage/SendMessage";
import type { ComposeProps } from "./types";

export const Compose = ({
    contactDetails = [],
    assigneeLabel = "You",
    assigneeSelector,
    conversationId,
    patient,
    patientMatchesCurrentMedicalRecordPatient,
    onMessageSend,
    isMessageSending,
    onUserTyping,
    onMinimiseClick,
    isHeightRestricted,
    onExpandClick,
}: ComposeProps) => {
    const track = useAnalytics();
    const connection = useMedicalRecordConnection();
    const nativeTrackingFields = useNativeTrackingFields();
    const attachSelectorButtonRef = useRef<HTMLButtonElement>(null);

    const isBookingInviteEnabled = useFeatureFlag(
        "SelfBookFromComposeSendLink",
    );

    const canSaveToRecord =
        connection.status === "Connected" &&
        connection.capabilities.saveToRecord === true;

    const { state, dispatch } = useCompose();
    const uploadPatientRecordAttachment =
        useUploadPatientRecordAttachmentMutation();

    const [isAttachSelectorOpen, setIsAttachSelectorOpen] = useState(false);
    const [showPatientRecordAttachments, setShowPatientRecordAttachments] =
        useState(false);
    const [showSelfbookConfiguration, setShowSelfbookConfiguration] =
        useState(false);
    const { onUpload } = useUploadFromDesktop("PatientMessaging");
    const [uploadErrors, setUploadErrors] = useState<string[] | null>(null);
    const [showNhsAdviceSelector, setShowNhsAdviceSelector] = useState(false);

    const hasQuestionnaire = state.template.type === "QuestionnaireTemplate";
    const hasReachedUploadLimit =
        !hasQuestionnaire &&
        state.attachments.length === state.maxAttachmentCount;
    const selfBookLinkSelected = state.selfBookLink !== null;
    const shouldDisableAttachments =
        hasQuestionnaire ||
        hasReachedUploadLimit ||
        selfBookLinkSelected ||
        showSelfbookConfiguration;
    const hasReachedNHSAdviceLinkLimit = !!state.nhsAdviceLink;
    const shouldDisableNHSAdvice =
        hasQuestionnaire ||
        hasReachedNHSAdviceLinkLimit ||
        selfBookLinkSelected ||
        showSelfbookConfiguration;
    const isPatientResponseCheckboxEnabled =
        !hasQuestionnaire && !selfBookLinkSelected;

    const { characterCount, fragmentCount, isUnicode } = useCharacterCount();

    const patientResposeCheckboxOnChange = () => {
        track("PatientMessageResponse Option Select", {
            ...nativeTrackingFields,
            oldValue: state.isPatientResponseEnabled,
            newValue: !state.isPatientResponseEnabled,
        });
        dispatch({
            type: "UPDATE_IS_PATIENT_RESPONSE_ENABLED",
            payload: {
                isPatientResponseEnabled: !state.isPatientResponseEnabled,
            },
        });
    };

    const uploadFile = async (file: File) => {
        const fileName = file.name;
        const fileSize = file.size;
        const tempAttachment: Attachment = {
            name: fileName,
            origin: "TempUpload",
            id: uuid(),
            size: fileSize,
        };
        dispatch({
            type: "ADD_ATTACHMENT",
            payload: { attachment: tempAttachment },
        });

        const result = await onUpload(file, {
            maxFileSizeInMegabytes: MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
            allowedFileExtensions: SUPPORTED_FILE_EXTENSIONS,
        });

        if (result.status === "error") {
            dispatch({
                type: "REMOVE_ATTACHMENT",
                payload: { attachment: tempAttachment },
            });
            setUploadErrors(result.result.errors);
        } else {
            dispatch({
                type: "UPDATE_ATTACHMENT",
                payload: {
                    attachmentId: tempAttachment.id,
                    attachmentOrigin: "TempUpload",
                    attachment: {
                        origin: "Upload",
                        name: fileName,
                        size: fileSize,
                        ...result.result,
                    },
                },
            });
        }
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setIsAttachSelectorOpen(false);

        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            void uploadFile(file);
        }
    };

    const onSelectNHSAdvice = (nhsAdviceLink: NHSAdviceLink) => {
        dispatch({ type: "ADD_NHS_ADVICE_LINK", payload: { nhsAdviceLink } });
        setShowNhsAdviceSelector(false);
    };

    return (
        <StyledContainer>
            <ExpandMinimiseBar
                onMinimiseClick={onMinimiseClick}
                onExpandClick={onExpandClick}
                isHeightRestricted={isHeightRestricted ?? false}
            >
                <ContactDetailSelector
                    contactDetails={contactDetails}
                    shouldDisableEmail={
                        showSelfbookConfiguration || selfBookLinkSelected
                    }
                />
            </ExpandMinimiseBar>
            <StyledFlexGrowContainer>
                <UI.Grid rows="2">
                    <UI.Item>
                        <Templates
                            disabled={
                                showSelfbookConfiguration || state.isScheduling
                            }
                            patientExternalIds={patient.externalIds}
                        />
                    </UI.Item>
                </UI.Grid>
                <ComposeErrors />
                <StyledScrollableFixedHeightContainer
                    $isHeightRestricted={isHeightRestricted || false}
                >
                    <ComposeTextArea onUserTyping={onUserTyping} />

                    <ComposeActionStack
                        assigneeLabel={
                            <ComposeAssigneeSelector
                                label={assigneeLabel}
                                CustomComponent={assigneeSelector}
                            />
                        }
                        canSaveToRecord={canSaveToRecord}
                    />
                </StyledScrollableFixedHeightContainer>
                <UI.Flex flexDirection="column" gap="1.5">
                    <ScheduledSendInfo />
                    <UI.Flex
                        justifyContent="space-between"
                        flexWrap="wrap"
                        gap="1"
                    >
                        {uploadErrors !== null && (
                            <UI.Item flex="1 0 100%">
                                <AttachmentFailedMessage
                                    errors={uploadErrors}
                                />
                            </UI.Item>
                        )}
                        {/* The 100 flex grow here was the only way we could figure out how to get the save to record checkbox to sit nicely up against the send button. */}
                        <UI.Item flex="100">
                            <UI.Flex gap="1" justifyContent="space-between">
                                <UI.Item>
                                    <UI.Flex
                                        justifyContent="flex-start"
                                        gap="0.5"
                                    >
                                        <UI.Item>
                                            <AllowReplyCheckbox
                                                checked={
                                                    state.isPatientResponseEnabled
                                                }
                                                onChange={
                                                    patientResposeCheckboxOnChange
                                                }
                                                disabled={
                                                    !isPatientResponseCheckboxEnabled ||
                                                    showSelfbookConfiguration ||
                                                    !!state.selfBookLink ||
                                                    state.template.type ===
                                                        "PendingQuestionnaireTemplate"
                                                }
                                            />
                                        </UI.Item>
                                        <UI.Item>
                                            <AttachSelector
                                                ref={attachSelectorButtonRef}
                                                isOpen={isAttachSelectorOpen}
                                                toggle={() => {
                                                    // only track when its being opened
                                                    if (!isAttachSelectorOpen) {
                                                        track(
                                                            "Attachment Button Click",
                                                            {
                                                                ...nativeTrackingFields,
                                                                conversationParticipant:
                                                                    state.conversationParticipant,
                                                                eventVersion: 2,
                                                            },
                                                        );
                                                    }

                                                    setIsAttachSelectorOpen(
                                                        (prev) => !prev,
                                                    );
                                                }}
                                                iconOnlyButton={
                                                    isBookingInviteEnabled
                                                }
                                                disabled={
                                                    showSelfbookConfiguration ||
                                                    selfBookLinkSelected ||
                                                    state.template.type ===
                                                        "PendingQuestionnaireTemplate" ||
                                                    hasQuestionnaire ||
                                                    (shouldDisableAttachments &&
                                                        shouldDisableNHSAdvice)
                                                }
                                            >
                                                <UI.Flex
                                                    flexDirection="column"
                                                    gap="1"
                                                >
                                                    {hasReachedUploadLimit &&
                                                        !hasReachedNHSAdviceLinkLimit && (
                                                            <Feedback
                                                                colour="warning"
                                                                title="File attachment limit reached"
                                                            />
                                                        )}
                                                    <AttachFileFromDesktopButton
                                                        isDisabled={
                                                            shouldDisableAttachments
                                                        }
                                                        accepts={`.${SUPPORTED_FILE_EXTENSIONS.join(
                                                            ",.",
                                                        )}`}
                                                        onClick={() => {
                                                            track(
                                                                "Attachment MenuItem Click",
                                                                {
                                                                    eventVersion: 2,
                                                                    conversationParticipant:
                                                                        state.conversationParticipant,
                                                                    attachmentType:
                                                                        "LocalFile",
                                                                    ...nativeTrackingFields,
                                                                },
                                                            );

                                                            setUploadErrors(
                                                                null,
                                                            );
                                                        }}
                                                        onChange={
                                                            handleFileUpload
                                                        }
                                                    />
                                                    <AttachFileFromPatientRecordButton
                                                        isDisabled={
                                                            shouldDisableAttachments
                                                        }
                                                        patientNhsNumber={getValidPatientNhsNumber(
                                                            patient.externalIds,
                                                        )}
                                                        onClick={() => {
                                                            track(
                                                                "Attachment MenuItem Click",
                                                                {
                                                                    conversationParticipant:
                                                                        state.conversationParticipant,
                                                                    attachmentType:
                                                                        "MedicalRecord",
                                                                    ...nativeTrackingFields,
                                                                },
                                                            );

                                                            setShowPatientRecordAttachments(
                                                                true,
                                                            );
                                                            setIsAttachSelectorOpen(
                                                                false,
                                                            );
                                                        }}
                                                    />

                                                    <AttachNHSAdviceButton
                                                        onClick={() => {
                                                            track(
                                                                "Attachment MenuItem Click",
                                                                {
                                                                    conversationParticipant:
                                                                        state.conversationParticipant,
                                                                    attachmentType:
                                                                        "NHSAdvice",
                                                                    ...nativeTrackingFields,
                                                                },
                                                            );

                                                            setShowNhsAdviceSelector(
                                                                true,
                                                            );
                                                        }}
                                                        isDisabled={
                                                            shouldDisableNHSAdvice
                                                        }
                                                        showWarning={
                                                            hasReachedNHSAdviceLinkLimit &&
                                                            !hasReachedUploadLimit
                                                        }
                                                    />
                                                </UI.Flex>
                                            </AttachSelector>
                                        </UI.Item>

                                        {isBookingInviteEnabled && (
                                            <UI.Item>
                                                <BookingInvite
                                                    onClick={() =>
                                                        setShowSelfbookConfiguration(
                                                            true,
                                                        )
                                                    }
                                                />
                                            </UI.Item>
                                        )}
                                    </UI.Flex>
                                </UI.Item>

                                {canSaveToRecord && (
                                    <UI.Item>
                                        <SaveToRecordCheckbox />
                                    </UI.Item>
                                )}
                            </UI.Flex>
                        </UI.Item>

                        <UI.Item flex="1">
                            <SendMessage
                                conversationId={conversationId}
                                patient={patient}
                                patientMatchesCurrentMedicalRecordPatient={
                                    patientMatchesCurrentMedicalRecordPatient
                                }
                                characterCount={characterCount}
                                fragmentCount={fragmentCount}
                                isUnicode={isUnicode}
                                onMessageSend={onMessageSend}
                                isLoading={isMessageSending}
                            />
                        </UI.Item>
                    </UI.Flex>
                </UI.Flex>

                <QuickViewPortal
                    isOpen={showPatientRecordAttachments}
                    onClose={() => setShowPatientRecordAttachments(false)}
                >
                    <PatientRecordAttachmentSelector
                        patientExternalIds={patient.externalIds}
                        patientName={formatPatientName({
                            firstName: patient.firstName,
                            familyName: patient.familyName,
                            prefixName: patient.prefixName,
                        })}
                        onSelect={(attachment) => {
                            setShowPatientRecordAttachments(false);
                            setUploadErrors(null);

                            const attachmentName =
                                formatPatientRecordAttachmentName(attachment);
                            const tempAttachment: Attachment = {
                                id: attachment.documentId,
                                name: attachmentName,
                                origin: "TempUpload",
                            };

                            dispatch({
                                type: "ADD_ATTACHMENT",
                                payload: {
                                    attachment: tempAttachment,
                                },
                            });

                            uploadPatientRecordAttachment.mutate(
                                {
                                    documentId: attachment.documentId,
                                    patientExternalIds: patient.externalIds,
                                },
                                {
                                    onError: (error) => {
                                        dispatch({
                                            type: "REMOVE_ATTACHMENT",
                                            payload: {
                                                attachment: tempAttachment,
                                            },
                                        });
                                        setUploadErrors([
                                            getNativeUploadErrorMessage(error),
                                        ]);
                                    },
                                    onSuccess: (data) => {
                                        dispatch({
                                            type: "UPDATE_ATTACHMENT",
                                            payload: {
                                                attachmentId: tempAttachment.id,
                                                attachmentOrigin: "TempUpload",
                                                attachment: {
                                                    origin: "Upload",
                                                    id: data.serverId,
                                                    name: attachmentName,
                                                    previewUrl: data.previewUrl,
                                                },
                                            },
                                        });
                                    },
                                },
                            );
                        }}
                    />
                </QuickViewPortal>
                <SelfbookConfigurationQuickView
                    showSelfbookConfiguration={showSelfbookConfiguration}
                    sendAt={
                        state.sendAt?.sendAtDateTime ??
                        DateHelpers.getCurrentTimeStamp()
                    }
                    setShowSelfbookConfiguration={(showConfig: boolean) =>
                        setShowSelfbookConfiguration(showConfig)
                    }
                    onSelfbookConfigComplete={(selfbookConfigData) => {
                        const selfBookLink =
                            mapSelfBookFormDataToSelfBookLink(
                                selfbookConfigData,
                            );

                        dispatch({
                            type: "ADD_SELF_BOOK_LINK",
                            payload: {
                                selfBookLink,
                            },
                        });
                    }}
                />
                <QuickViewPortal
                    onClose={() => setShowNhsAdviceSelector(false)}
                    isOpen={showNhsAdviceSelector}
                    returnFocusRef={attachSelectorButtonRef}
                    autoFocus={false}
                >
                    <NHSAdviceSelector onSelectNHSAdvice={onSelectNHSAdvice} />
                </QuickViewPortal>
            </StyledFlexGrowContainer>
        </StyledContainer>
    );
};
