import { forwardRef } from "react";

import { Pill } from "@accurx/inbox-design-library";

import { ToolippedButton } from "../TooltippedButton/TooltippedButton";

export const BookingInviteButton = forwardRef<
    HTMLButtonElement,
    {
        userflowId?: string;
        onClick: () => void;
        disabled?: boolean;
        "data-testid"?: string;
    }
>(({ onClick, userflowId, disabled = false, ...props }, ref) => (
    <ToolippedButton
        toggle={onClick}
        disabled={disabled}
        ariaLabel="Add a Self-Book invite"
        userFlowId={userflowId}
        iconOnly
        tooltipContent="Booking invite"
        ref={ref}
        data-testid={props["data-testid"]}
    >
        <Pill.Icon
            name="Calendar"
            colour="blue"
            theme="Line"
            useDisabledColour={disabled}
            props={{
                "aria-hidden": true,
            }}
        />
    </ToolippedButton>
));
