import { useHistory } from "react-router-dom";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";

import { TeamUsage } from "../components/TeamUsage";

export const TeamUsagePage = () => {
    const history = useHistory();

    return (
        <>
            <NavSubMenuComponent backCallback={history.goBack} />
            <StyledContainer>
                <PageHeader title="Team usage" type={PageHeaderType.ListPage} />
                <TeamUsage />
            </StyledContainer>
        </>
    );
};
