import { useAnalytics } from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";
import { useCompose } from "domains/compose/context";
import { Attachment as AttachmentType } from "domains/compose/reducer.types";
import { getTemplateSnomedCodes } from "domains/compose/utils";

import { ActionStack } from "./components/ActionStack/ActionStack";

export const ComposeActionStack = ({
    assigneeLabel,
    canSaveToRecord,
}: {
    assigneeLabel?: JSX.Element;
    canSaveToRecord: boolean;
}) => {
    const { state, dispatch } = useCompose();
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const snomedCodes =
        state.template.value && canSaveToRecord
            ? getTemplateSnomedCodes(state.template)
            : [];

    const onNhsAdviceRemoveClick = () => {
        track("NhsAdviceLinkRemove Button Click", {
            ...nativeTrackingFields,
            conversationParticipant: state.conversationParticipant,
        });
        dispatch({
            type: "REMOVE_NHS_ADVICE_LINK",
        });
    };

    const onAttachmentRemoveClick = (attachment: AttachmentType) => {
        track("AttachmentRemove Button Click", {
            ...nativeTrackingFields,
            conversationParticipant: state.conversationParticipant,
            eventVersion: 2,
        });
        dispatch({
            type: "REMOVE_ATTACHMENT",
            payload: { attachment },
        });
    };

    const onSelfBookLinkRemove = () => {
        track("ConversationBookingLinkRemove Button Click", {
            ...nativeTrackingFields,
            appOrigin: "QuickReply",
        });
        dispatch({
            type: "REMOVE_SELF_BOOK_LINK",
        });
    };

    return (
        <ActionStack
            assigneeLabel={assigneeLabel}
            isPatientResponseEnabled={state.isPatientResponseEnabled}
            nhsAdviceLink={state.nhsAdviceLink}
            onNhsAdviceRemove={onNhsAdviceRemoveClick}
            attachments={state.attachments}
            onAttachmentRemove={onAttachmentRemoveClick}
            selfBookLink={state.selfBookLink}
            onSelfBookLinkRemove={onSelfBookLinkRemove}
            questionnaire={
                state.template.type === "QuestionnaireTemplate"
                    ? {
                          template: state.template.value,
                          withPreviewLink: false,
                          isPending: false,
                          warning: state.template.warning,
                      }
                    : state.template.type === "PendingQuestionnaireTemplate"
                    ? {
                          template: state.template.pendingValue,
                          withPreviewLink: false,
                          isPending: true,
                      }
                    : null
            }
            snomedCodes={snomedCodes}
        />
    );
};
