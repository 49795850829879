import omit from "lodash/omit";

import { composeInitialMessageBody } from "./composeMessage";
import { EMPTY_STATE, SELF_BOOK_MESSAGE_BODY } from "./constants";
import {
    Attachment,
    ComposeActions,
    ComposeActionsTypes,
    ComposeStateType,
} from "./reducer.types";
import { hasError } from "./utils/hasError";

const reducerFunction = (
    state: ComposeStateType,
    action: ComposeActions,
): ComposeStateType => {
    switch (action.type) {
        case ComposeActionsTypes.UpdateContactDetails:
            return {
                ...state,
                contactDetails: action.payload.contactDetails,
            };
        case ComposeActionsTypes.UpdateMessageBody:
            return {
                ...state,
                messageBody: action.payload.text,
            };
        case ComposeActionsTypes.UpdateMessageSignature:
            return {
                ...state,
                messageSignature: action.payload.text,
            };
        case ComposeActionsTypes.AddAttachment:
            if (state.attachments.length >= state.maxAttachmentCount) {
                return state;
            }
            return {
                ...state,
                attachments: [...state.attachments, action.payload.attachment],
            };
        case ComposeActionsTypes.UpdateAttachment:
            return {
                ...state,
                attachments: state.attachments.map((att) => {
                    if (
                        att.id === action.payload.attachmentId &&
                        att.origin === action.payload.attachmentOrigin
                    ) {
                        return action.payload.attachment;
                    }
                    return att;
                }),
            };
        case ComposeActionsTypes.RemoveAttachment: {
            return {
                ...state,
                attachments: state.attachments.filter(
                    (attachment) =>
                        !(
                            attachment.id === action.payload.attachment.id &&
                            attachment.origin ===
                                action.payload.attachment.origin
                        ),
                ),
            };
        }
        case ComposeActionsTypes.UpdateIsPatientResponseEnabled:
            return {
                ...state,
                isPatientResponseEnabled:
                    action.payload.isPatientResponseEnabled,
            };
        case ComposeActionsTypes.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case ComposeActionsTypes.SetIsScheduling:
            return {
                ...state,
                isScheduling: action.payload.isScheduling,
            };
        case ComposeActionsTypes.AddMessageTemplate: {
            const templateAttachments: Attachment[] = (
                action.payload.template.attachments || []
            )
                .slice(0, state.maxAttachmentCount)
                .map((att) => {
                    return {
                        origin: "Template",
                        name: att.name ?? "File name missing",
                        id: att.id,
                        previewUrl: att.previewUrl,
                        source: "PatientMessaging",
                        size: att.size,
                    };
                });

            return {
                ...state,
                // Replace existing attachments with template attachments
                attachments: templateAttachments,
                template: {
                    value: omit(action.payload.template, ["attachments"]),
                    type: "MessageTemplate",
                },
                messageBody: composeInitialMessageBody({
                    messageBody: action.payload.template.body,
                    messageGreeting: state.originalGreeting,
                }),
                isPatientResponseEnabled:
                    action.payload.template.allowReplyByDefault ||
                    EMPTY_STATE.isPatientResponseEnabled,
                // Signature gets overridden back to its original version
                messageSignature: state.originalSignature,
                nhsAdviceLink: null,
                selfBookLink: null,
            };
        }
        case ComposeActionsTypes.AddQuestionnaireTemplate: {
            return {
                ...state,
                // Remove existing attachments
                attachments: EMPTY_STATE.attachments,
                // Patient response should be reset (to false)
                isPatientResponseEnabled: EMPTY_STATE.isPatientResponseEnabled,
                template: {
                    value: action.payload.template,
                    type: "QuestionnaireTemplate",
                    warning: action.payload.warning,
                },
                messageBody: composeInitialMessageBody({
                    messageBody: action.payload.template.body,
                    messageGreeting: state.originalGreeting,
                }),
                // Signature gets overridden back to its original version
                messageSignature: state.originalSignature,
                nhsAdviceLink: null,
                selfBookLink: null,
            };
        }
        case ComposeActionsTypes.AddPendingQuestionnaireTemplate: {
            return {
                ...state,
                template: {
                    pendingValue: action.payload.pendingTemplate,
                    type: "PendingQuestionnaireTemplate",
                },
            };
        }
        case ComposeActionsTypes.RemoveTemplate:
            return {
                ...state,
                // Removing a template clears all the things that
                // would make part of the body of the message
                messageBody: composeInitialMessageBody({
                    messageBody: state.originalBody,
                    messageGreeting: state.originalGreeting,
                }),
                messageSignature: state.originalSignature,
                template: EMPTY_STATE.template,
                attachments: EMPTY_STATE.attachments,
                isPatientResponseEnabled: EMPTY_STATE.isPatientResponseEnabled,
                isSaveToRecordEnabled: EMPTY_STATE.isSaveToRecordEnabled,
            };
        case ComposeActionsTypes.UpdateIsSaveToRecordEnabled:
            return {
                ...state,
                isSaveToRecordEnabled: action.payload.isSaveToRecordEnabled,
            };
        case ComposeActionsTypes.UpdateSendAt:
            return {
                ...state,
                sendAt: action.payload.sendAt,
            };
        case ComposeActionsTypes.AddSelfBookLink: {
            // We don't overide the message body if the user has already started editing
            const hasBodyBeenEdited =
                composeInitialMessageBody({
                    messageBody: state.originalBody,
                    messageGreeting: state.originalGreeting,
                }) !== state.messageBody;

            return {
                ...state,
                selfBookLink: action.payload.selfBookLink,
                messageBody: hasBodyBeenEdited
                    ? state.messageBody
                    : composeInitialMessageBody({
                          messageBody: SELF_BOOK_MESSAGE_BODY,
                          messageGreeting: state.originalGreeting,
                      }),
            };
        }
        case ComposeActionsTypes.RemoveSelfBookLink:
            return {
                ...state,
                selfBookLink: null,
            };
        case ComposeActionsTypes.AddNHSAdviceLink:
            return {
                ...state,
                nhsAdviceLink: action.payload.nhsAdviceLink,
            };
        case ComposeActionsTypes.RemoveNHSAdviceLink:
            return {
                ...state,
                nhsAdviceLink: null,
            };
        default:
            return state;
    }
};

export const composeReducer = (
    state: ComposeStateType,
    action: ComposeActions,
): ComposeStateType => {
    // Clear the errors in state when performing a new action
    const copiedState = !hasError(state.errors)
        ? state
        : {
              ...state,
              errors: {},
          };

    return reducerFunction(copiedState, action);
};
