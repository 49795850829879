import { ChangeEvent, useRef, useState } from "react";

import { TeamSummary, UserSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { useFuzzyFilter } from "@accurx/hooks";
import { Input, Pill } from "@accurx/inbox-design-library";
import { userflowIds } from "domains/inbox/util";

import { AssigneeRadioGroupLabel } from "./AssigneeRadioGroupLabel";
import { AssigneeRadioLabel } from "./AssigneeRadioLabel";
import {
    StyledAssigneeListWrapper,
    StyledAssigneeLists,
    StyledAssigneeNoteTextArea,
    StyledContainer,
    StyledFeedback,
    StyledHeaderText,
    StyledHorizontalBorder,
    StyledIconWrapper,
    StyledNoteContainer,
    StyledOuterTextarea,
    StyledPopoverContentHeader,
    StyledPopoverFooter,
    StyledStickyHeader,
} from "./AssigneeSelectorForm.styles";
import { ValidAssigneeSummary } from "./AssigneeSelectorForm.types";

const ASSIGNEE_SELECTOR_FORM_ID = "assignee-selector" as const;

export type AssigneeSelectorFormProps = {
    currentAssignee?: ValidAssigneeSummary;
    users: UserSummary[];
    teams?: TeamSummary[];
    toAssignTeam?: TeamSummary;
    onCancel: () => void;
    onSubmit: (args: {
        assignee: ValidAssigneeSummary;
        noteText?: string;
    }) => void;
    onSelectedAssigneeChanged: (args: {
        assignee: ValidAssigneeSummary;
        noteText?: string;
    }) => void;
    isLoading?: boolean;
    /**
     * When false, it only allows reassignment
     */
    canAddNote: boolean;
};

export const AssigneeSelectorForm = ({
    currentAssignee,
    users: allUsers,
    teams: allTeams = [],
    toAssignTeam,
    onCancel,
    onSubmit,
    onSelectedAssigneeChanged,
    isLoading,
    canAddNote,
}: AssigneeSelectorFormProps): JSX.Element => {
    const assigneeListRef = useRef<HTMLLIElement | null>(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedAssignee, setSelectedAssignee] = useState<
        ValidAssigneeSummary | undefined
    >(currentAssignee);
    const [shouldShowNoteSection, setShouldShowNoteSection] = useState(false);
    const [noteText, setNoteText] = useState("");
    const [showNoAssigneeSelectedError, setShowNoAssigneeSelectedError] =
        useState(false);

    /**
     * Apply filters on toAssign team (wrapped in an array where it is the only
     * element) as well so that the search behaviour is maintained for the to
     * assign team.
     */
    const toAssignTeamFiltered = useFuzzyFilter(
        toAssignTeam ? [toAssignTeam] : [],
        searchTerm,
        {
            keys: ["displayName"],
            threshold: 0.2,
        },
    );
    const users = useFuzzyFilter(allUsers, searchTerm, {
        keys: ["displayName"],
        threshold: 0.2,
    });
    const teams = useFuzzyFilter(allTeams, searchTerm, {
        keys: ["displayName"],
        threshold: 0.2,
    });

    const handleCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
        const type = e.target.dataset.assigneeType as "Team" | "User";
        const id = e.target.value;
        const assignee = {
            type,
            id,
        };

        setSelectedAssignee(assignee);

        setShowNoAssigneeSelectedError(false);

        // Once the user selects a different assignee, the note section pops up - it'll only disappear when the selector is closed
        setShouldShowNoteSection(true);

        onSelectedAssigneeChanged({ assignee, noteText });
    };

    const onClickAssignButton = () => {
        if (!selectedAssignee) {
            setShowNoAssigneeSelectedError(true);
            return;
        }

        onSubmit({
            assignee: selectedAssignee,
            noteText: noteText,
        });
    };

    return (
        <>
            <StyledStickyHeader>
                <StyledPopoverContentHeader>
                    <UI.Text variant="label" skinny>
                        Assign this conversation
                    </UI.Text>
                </StyledPopoverContentHeader>
                <StyledContainer>
                    <UI.VisuallyHidden as="span">
                        <label htmlFor="assignee-list">
                            Search for assignee
                        </label>
                    </UI.VisuallyHidden>
                    {/* This is to let screen reader users know this is a live search */}
                    <UI.VisuallyHidden id="assignee-search-description">
                        Results will update as you type.
                    </UI.VisuallyHidden>
                    <Input
                        isSearchInput
                        aria-describedby="assignee-search-description"
                        value={searchTerm}
                        id="assignee-list"
                        name="assignee-list"
                        onChange={(e) => {
                            e.preventDefault();
                            setSearchTerm(e.target.value);

                            // reset scroll position
                            if (assigneeListRef.current) {
                                assigneeListRef.current.scrollTop = 0;
                            }
                        }}
                        placeholder="Search for a colleague or team"
                    />
                    {showNoAssigneeSelectedError && (
                        <StyledFeedback
                            props={{ id: "no-assignee-error" }}
                            title="Select an assignee to continue."
                            colour="error"
                        />
                    )}
                </StyledContainer>
                <StyledHorizontalBorder />
            </StyledStickyHeader>
            <StyledAssigneeLists ref={assigneeListRef}>
                <form
                    id={ASSIGNEE_SELECTOR_FORM_ID}
                    onSubmit={(e) => {
                        e.preventDefault();
                        onClickAssignButton();
                    }}
                >
                    {toAssignTeamFiltered.length > 0 && (
                        <StyledAssigneeListWrapper>
                            <UI.RadioGroup
                                formFieldWrapperProps={{
                                    label: (
                                        <AssigneeRadioGroupLabel labelText="" />
                                    ),
                                    "aria-describedby":
                                        showNoAssigneeSelectedError
                                            ? "no-assignee-error"
                                            : undefined,
                                }}
                                name="team-assignee"
                                onChange={handleCheckedChange}
                                checkedValue={
                                    selectedAssignee?.type === "Team"
                                        ? selectedAssignee.id
                                        : undefined
                                }
                                theme="bordered"
                                checkPlacement="top-right"
                                radioInputs={toAssignTeamFiltered.map(
                                    (team) => {
                                        return {
                                            id: `team-${team.id}`,
                                            "aria-label": team.displayName,
                                            label: (
                                                <AssigneeRadioLabel
                                                    isCurrentAssignee={
                                                        currentAssignee?.type ===
                                                            "Team" &&
                                                        currentAssignee.id ===
                                                            team.id
                                                    }
                                                    assignee={team}
                                                    assigneeType="Team"
                                                />
                                            ),
                                            value: team.id,
                                            "data-assignee-type": "Team",
                                        };
                                    },
                                )}
                            />
                        </StyledAssigneeListWrapper>
                    )}
                    {teams.length > 0 && (
                        <StyledAssigneeListWrapper>
                            <UI.RadioGroup
                                formFieldWrapperProps={{
                                    label: (
                                        <AssigneeRadioGroupLabel labelText="Teams" />
                                    ),
                                    "aria-describedby":
                                        showNoAssigneeSelectedError
                                            ? "no-assignee-error"
                                            : undefined,
                                }}
                                name="team-assignee"
                                onChange={handleCheckedChange}
                                checkedValue={
                                    selectedAssignee?.type === "Team"
                                        ? selectedAssignee.id
                                        : undefined
                                }
                                theme="bordered"
                                checkPlacement="top-right"
                                radioInputs={teams.map((team) => {
                                    return {
                                        id: `team-${team.id}`,
                                        "aria-label": team.displayName,
                                        label: (
                                            <AssigneeRadioLabel
                                                isCurrentAssignee={
                                                    currentAssignee?.type ===
                                                        "Team" &&
                                                    currentAssignee.id ===
                                                        team.id
                                                }
                                                assignee={team}
                                                assigneeType="Team"
                                            />
                                        ),
                                        value: team.id,
                                        "data-assignee-type": "Team",
                                    };
                                })}
                            />
                        </StyledAssigneeListWrapper>
                    )}

                    {users.length > 0 && (
                        <StyledAssigneeListWrapper>
                            <UI.RadioGroup
                                formFieldWrapperProps={{
                                    label: (
                                        <AssigneeRadioGroupLabel labelText="Colleagues" />
                                    ),
                                    "aria-describedby":
                                        showNoAssigneeSelectedError
                                            ? "no-assignee-error"
                                            : undefined,
                                }}
                                name="user-assignee"
                                onChange={handleCheckedChange}
                                checkedValue={
                                    selectedAssignee?.type === "User"
                                        ? selectedAssignee.id
                                        : undefined
                                }
                                theme="bordered"
                                checkPlacement="top-right"
                                radioInputs={users.map((user) => {
                                    return {
                                        id: `user-${user.id}`,
                                        "aria-label": user.isCurrentUser
                                            ? `${user.displayName} (You)`
                                            : user.displayName,
                                        label: (
                                            <AssigneeRadioLabel
                                                isCurrentAssignee={
                                                    currentAssignee?.type ===
                                                        "User" &&
                                                    currentAssignee.id ===
                                                        user.id
                                                }
                                                assignee={user}
                                                assigneeType="User"
                                            />
                                        ),
                                        value: user.id,
                                        "data-assignee-type": "User",
                                    };
                                })}
                            />
                        </StyledAssigneeListWrapper>
                    )}
                </form>

                {toAssignTeamFiltered.length === 0 &&
                    teams.length === 0 &&
                    users.length === 0 && (
                        <StyledHeaderText skinny variant="note" colour="metal">
                            No results for '{searchTerm}'
                        </StyledHeaderText>
                    )}

                <UI.VisuallyHidden>
                    <div aria-live="assertive" aria-atomic="true">
                        {toAssignTeamFiltered.length === 0 &&
                            teams.length === 0 &&
                            users.length === 0 &&
                            `No users or teams match your search`}
                    </div>
                </UI.VisuallyHidden>
            </StyledAssigneeLists>
            <UI.Grid as="li">
                {canAddNote && shouldShowNoteSection && (
                    <>
                        <StyledHorizontalBorder />
                        <StyledNoteContainer>
                            <UI.Flex flexDirection="column" gap="0.5">
                                <StyledOuterTextarea>
                                    <StyledAssigneeNoteTextArea
                                        form={ASSIGNEE_SELECTOR_FORM_ID}
                                        value={noteText}
                                        onChange={(
                                            e: ChangeEvent<HTMLTextAreaElement>,
                                        ) => {
                                            setNoteText(e.target.value);
                                        }}
                                        aria-label="Assignment note"
                                        maxLength={2000}
                                        placeholder="Write your note here..."
                                    />
                                </StyledOuterTextarea>
                                <UI.Flex alignItems="center">
                                    <StyledIconWrapper>
                                        <UI.Icon
                                            name="LockLocked"
                                            size={3}
                                            colour="metal"
                                            theme="Fill"
                                        />
                                    </StyledIconWrapper>
                                    <UI.Text
                                        skinny
                                        variant="preview"
                                        colour="metal"
                                    >
                                        Only visible to colleagues in your
                                        workspace
                                    </UI.Text>
                                </UI.Flex>
                            </UI.Flex>
                        </StyledNoteContainer>
                    </>
                )}

                <UI.Cell>
                    <StyledPopoverFooter justifyContent="space-between">
                        <UI.Item>
                            <Pill.SecondaryButton
                                onClick={onCancel}
                                dimension="small"
                            >
                                <Pill.Text>Cancel</Pill.Text>
                            </Pill.SecondaryButton>
                        </UI.Item>

                        <UI.Item>
                            <Pill.PrimaryButton
                                disabled={isLoading}
                                data-userflow-id={
                                    userflowIds.conversationView
                                        .confirmAssignButton
                                }
                                dimension="small"
                                type="submit"
                                form={ASSIGNEE_SELECTOR_FORM_ID}
                            >
                                <Pill.Icon
                                    name="Assign"
                                    colour="white"
                                    size={3}
                                    theme="Fill"
                                    isLoading={isLoading}
                                />
                                <Pill.Text>Assign</Pill.Text>
                            </Pill.PrimaryButton>
                        </UI.Item>
                    </StyledPopoverFooter>
                </UI.Cell>
            </UI.Grid>
        </>
    );
};
