import { useState } from "react";

import { useNativeSubscription } from "@accurx/native";

export const useActiveCloseAttempt = () => {
    const currentCloseAttempt =
        useNativeSubscription("SubscribeWindowCloseAttempt").data?.attempt ??
        null;

    /* Keep track of the last close attempt actioned, so we can know if theres
     * an active one and tell the difference if a new attempt is made.
     */
    const [lastCloseAttemptActioned, setLastCloseAttemptActioned] = useState<
        number | null
    >(currentCloseAttempt);

    return {
        activeCloseAttempt: currentCloseAttempt !== lastCloseAttemptActioned,
        onCloseAttemptActioned: () =>
            setLastCloseAttemptActioned(currentCloseAttempt),
    };
};
