import React, {
    ChangeEvent,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import {
    Button,
    Feedback,
    Icon,
    Input,
    StackPanel,
    Text,
} from "@accurx/design";
import Fuse from "fuse.js";
import debounce from "lodash/debounce";

import { MessageTemplateOwner } from "api/FlemingDtos";
import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { PresetTemplateSection } from "shared/Templates";

import { mapToTemplateSection } from "./MessageTemplate.helper";
import {
    StyledContainer,
    StyledHeaderContainer,
    StyledLabelWrapper,
    StyledSearchContainer,
    StyledSectionHeading,
} from "./MessageTemplate.styles";
import { useMessageTemplates } from "./MessageTemplates.hooks";
import { Template, TemplateInList } from "./MessageTemplates.types";
import { MessageTemplateList } from "./components/MessageTemplateList";

const defaultSearchOption = {
    isCaseSensitive: false,
    includeScore: false,
    includeMatches: false,
    ignoreLocation: false,
    threshold: 0.4,
};

const searchOptionsTemplate = {
    ...defaultSearchOption,
    keys: ["sectionHeading", "title", "body"],
};

const searchOptionsUserTemplate = {
    ...defaultSearchOption,
    keys: ["title", "body"],
};

export type TemplateBody = string;

export type MessageTemplatesProps = {
    onClickCreateTemplate?: () => void;
    onClickUseTemplate: (template: Template) => void;
    workspaceId: number;
    handleOnClickPreviewTemplate: (template: Template) => void;
};

type DisplayedTemplates = {
    user: TemplateInList[];
    preset: PresetTemplateSection[];
};

export const MessageTemplates = ({
    onClickCreateTemplate,
    onClickUseTemplate,
    workspaceId,
    handleOnClickPreviewTemplate,
}: MessageTemplatesProps): JSX.Element => {
    const titleInputRef = useRef<HTMLInputElement>(null);
    const {
        presetTemplates,
        userTemplates,
        isPresetTemplatesLoading,
        isUserTemplatesLoading,
        hasPresetTemplatesError,
        hasUserTemplatesError,
    } = useMessageTemplates(workspaceId);

    useLayoutEffect(() => {
        // Move focus to input once templates are fully loaded
        if (!isPresetTemplatesLoading && !isUserTemplatesLoading) {
            titleInputRef.current?.focus();
        }
    }, [isPresetTemplatesLoading, isUserTemplatesLoading]);

    const [displayedTemplates, setDisplayedTemplates] =
        useState<DisplayedTemplates>({
            user: userTemplates,
            preset: mapToTemplateSection(presetTemplates),
        });

    const [searchText, setSearchText] = useState("");

    const templateSearchContext = useMemo(() => {
        return new Fuse(presetTemplates, searchOptionsTemplate);
    }, [presetTemplates]);

    const userTemplateSearchContext = useMemo(() => {
        return new Fuse(userTemplates, searchOptionsUserTemplate);
    }, [userTemplates]);

    const loggedInProps = useFlemingLoggedInAnalytics();
    const handleOnUseTemplateClick = (
        template: Template,
        fromQuickView = false,
    ) => {
        FlemingAnalyticsTracker.trackMessageTemplateUseButtonClick({
            ...loggedInProps,
            hasError: false,
            searchString: searchText || "None",
            templateGroup: template.group ?? null,
            templateName: template.title,
            fromQuickView: fromQuickView,
            pageOrigin: "WebInboxMessageTemplatePage",
            templateType: "sms",
            countAttachment: template.attachedDocuments?.length ?? 0,
        });
        onClickUseTemplate(template);
    };

    const debounceTemplateSearch = useMemo(
        () =>
            debounce((searchValue) => {
                if (searchValue === "") {
                    setDisplayedTemplates({
                        user: userTemplates,
                        preset: mapToTemplateSection(presetTemplates),
                    });
                    return;
                }

                const searchResultTemplate =
                    templateSearchContext.search(searchValue);
                const matchingTemplates = searchResultTemplate.map(
                    ({ item }) => item,
                );

                const searchResultUserTemplate =
                    userTemplateSearchContext.search(searchValue);
                const matchingUserTemplates = searchResultUserTemplate.map(
                    ({ item }) => item,
                );

                setDisplayedTemplates({
                    user: matchingUserTemplates,
                    preset: mapToTemplateSection(matchingTemplates),
                });
            }, 250),
        [templateSearchContext, userTemplateSearchContext], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onTemplateSearch = (e: ChangeEvent<HTMLInputElement>): void => {
        setSearchText(e.target.value);
        debounceTemplateSearch(e.target.value);
    };

    useEffect(
        () => {
            setDisplayedTemplates({
                ...displayedTemplates,
                user: userTemplates,
                preset: mapToTemplateSection(presetTemplates),
            });
            return () => {
                // fix: for test memory leak
                debounceTemplateSearch.cancel();
            };
        },
        [userTemplates, presetTemplates], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const hasNoSearchResult =
        searchText.length > 0 &&
        displayedTemplates.preset.length === 0 &&
        displayedTemplates.user.length === 0;

    const renderTemplates = () => (
        <>
            <StyledLabelWrapper>
                <StackPanel
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    gutter={1}
                >
                    <Icon
                        name="Person"
                        halo={{
                            colour: "blue",
                            luminosity: "high",
                        }}
                        size={4}
                        theme="Fill"
                    />
                    <Text as="h2" variant="label" skinny>
                        Custom templates
                    </Text>
                </StackPanel>
            </StyledLabelWrapper>
            {hasUserTemplatesError ? (
                <Feedback
                    colour="error"
                    title="Sorry, there was a problem fetching your templates"
                >
                    <Text skinny>Please reload this page to try again</Text>
                </Feedback>
            ) : (
                <MessageTemplateList
                    templates={displayedTemplates.user.map(
                        ({ id, title, body, attachedDocuments, owner }) => ({
                            id: id || undefined,
                            title,
                            body,
                            isPreset: false,
                            attachedDocuments,
                            owner:
                                owner === MessageTemplateOwner.User
                                    ? "User"
                                    : "Organisation",
                        }),
                    )}
                    isLoading={isUserTemplatesLoading}
                    onClickUseTemplate={handleOnUseTemplateClick}
                    onClickPreviewTemplate={handleOnClickPreviewTemplate}
                />
            )}
            <StyledLabelWrapper>
                <StackPanel
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    gutter={1}
                >
                    <Icon
                        name="Team"
                        halo={{
                            colour: "blue",
                            luminosity: "high",
                        }}
                        size={4}
                    />
                    <Text as="h2" variant="label" skinny>
                        Accurx templates
                    </Text>
                </StackPanel>
            </StyledLabelWrapper>
            {hasPresetTemplatesError ? (
                <Feedback
                    colour="error"
                    title="Sorry, there was a problem fetching Accurx templates"
                >
                    <Text skinny>Please reload this page to try again</Text>
                </Feedback>
            ) : displayedTemplates.preset.length === 0 &&
              !isPresetTemplatesLoading ? (
                <Feedback
                    colour="information"
                    content="Your list is empty"
                    iconName="Info"
                />
            ) : (
                displayedTemplates.preset.map(
                    ({ sectionHeading, templateList }, index) => {
                        return (
                            <React.Fragment key={`${index}-${sectionHeading}`}>
                                <StyledSectionHeading
                                    forwardedAs="h3"
                                    variant="label"
                                >
                                    {sectionHeading}
                                </StyledSectionHeading>
                                <MessageTemplateList
                                    templates={templateList.map(
                                        ({ title, body }) => ({
                                            title: title,
                                            body: body,
                                            isPreset: true,
                                            group: sectionHeading,
                                        }),
                                    )}
                                    isLoading={isPresetTemplatesLoading}
                                    onClickUseTemplate={
                                        handleOnUseTemplateClick
                                    }
                                    onClickPreviewTemplate={
                                        handleOnClickPreviewTemplate
                                    }
                                />
                            </React.Fragment>
                        );
                    },
                )
            )}
        </>
    );

    return (
        <>
            <StyledContainer>
                <StackPanel
                    orientation="vertical"
                    verticalContentAlignment="space-between"
                >
                    <StyledHeaderContainer>
                        <div>
                            <Text as="h1" variant="subtitle" skinny>
                                Message patient templates
                            </Text>
                            <Text variant="body" colour="metal">
                                Pre-written messages you can edit.
                            </Text>
                        </div>
                        <Button
                            theme="secondary"
                            dimension="medium"
                            text="Create new template"
                            icon={{ name: "Document" }}
                            onClick={() => onClickCreateTemplate?.()}
                        />
                    </StyledHeaderContainer>
                    <StyledSearchContainer>
                        <Input
                            ref={titleInputRef}
                            aria-label="search templates"
                            id="search-templates"
                            isSearchInput={true}
                            placeholder="Search templates"
                            value={searchText}
                            onChange={onTemplateSearch}
                            disabled={
                                isPresetTemplatesLoading ||
                                isUserTemplatesLoading
                            }
                        />
                    </StyledSearchContainer>
                    {hasNoSearchResult ? (
                        <Text variant="body" skinny>
                            {`Sorry, we can’t find any results for ‘${searchText}’. Please try again.`}
                        </Text>
                    ) : (
                        renderTemplates()
                    )}
                </StackPanel>
            </StyledContainer>
        </>
    );
};
