import { MouseEventHandler } from "react";

import { useMedicalRecordConnection } from "@accurx/native";
import { DropdownItem } from "domains/compose/components/DropdownItem";

type AttachFileFromPatientRecordButtonProps = {
    patientNhsNumber: string | null | undefined;
    isDisabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const AttachFileFromPatientRecordButton = ({
    isDisabled,
    patientNhsNumber,
    onClick,
}: AttachFileFromPatientRecordButtonProps) => {
    const { status, system, capabilities } = useMedicalRecordConnection();

    if (status !== "Connected") {
        return null;
    }

    // Some medical records don't support attachments at all
    if (capabilities.attachDocumentsFromRecord === "NotSupported") {
        return null;
    }

    // Some medical records only support attachments for patients with an NHS
    // Number
    if (
        capabilities.attachDocumentsFromRecord === "SupportedWithNhsNumber" &&
        !patientNhsNumber
    ) {
        return null;
    }

    return (
        <DropdownItem.Button
            aria-label={`Upload ${system} file`}
            text={`${system} file`}
            onClick={onClick}
            disabled={isDisabled}
            theme={"transparent"}
            icon={{
                name: "Paperclip",
                colour: isDisabled ? "stone" : "zinc",
            }}
        />
    );
};
