import * as UI from "@accurx/design";
import { Feedback } from "@accurx/inbox-design-library";
import { useCompose } from "domains/compose/context";
import { hasError } from "domains/compose/utils/hasError";

export const ComposeErrors = () => {
    const { state } = useCompose();

    if (!hasError(state.errors)) {
        return null;
    }

    return (
        <UI.Flex flexDirection="column" gap="0.5">
            {state.errors.messageLength === "MESSAGE_TOO_LONG" && (
                <Feedback
                    title="Message is too long to send via SMS"
                    iconName="Failed"
                    colour="error"
                />
            )}
            {state.errors.messageLength === "EMPTY_MESSAGE" && (
                <Feedback
                    title="Write a message before sending"
                    iconName="Failed"
                    colour="error"
                />
            )}

            {!!state.errors.placeholderRemains && (
                <Feedback
                    title="Please complete the template message"
                    iconName="Failed"
                    colour="error"
                />
            )}

            {!!state.errors.attachmentLoading && (
                <Feedback
                    title="Wait for the attachments to complete uploading before sending"
                    iconName="Failed"
                    colour="error"
                />
            )}

            {!!state.errors.questionnaireLoading && (
                <UI.Feedback
                    title="Wait for the questionnaire to complete loading before sending"
                    iconName="Failed"
                    colour="error"
                />
            )}
        </UI.Flex>
    );
};
