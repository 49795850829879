import { useCurrentWorkspace } from "@accurx/auth";
import { Text } from "@accurx/design";

import { DeliveryReceipts } from "../../components/DeliveryReceipts";
import { PageContainer } from "../OverviewPage/components/PageContainer.styles";

/**
 * This page is purposely designed for the new unified navigation
 */
export const SentRemindersPage = () => {
    const { orgId: workspaceId } = useCurrentWorkspace();

    return (
        <PageContainer>
            <Text as="h1" variant="subtitle" skinny>
                Sent reminders
            </Text>
            <DeliveryReceipts workspaceId={String(workspaceId)} />
        </PageContainer>
    );
};
