import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { ContentClassificationsResponse } from "@accurx/api/florey-builder";

import { getContentClassifications } from "api/FloreyBuilder/FloreyBuilderApi";

export const GET_CONTENT_CLASSIFICATIONS_CACHE_KEY =
    "GetContentClassifications";

export const useGetContentClassificationsQuery = createQueryHook<
    void,
    ContentClassificationsResponse
>(() => ({
    queryKey: [GET_CONTENT_CLASSIFICATIONS_CACHE_KEY, {}],
    queryFn: async () => {
        const response = await getContentClassifications();
        return returnDataOrThrow(response);
    },
}));
