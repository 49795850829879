import { useContext } from "react";

import { ComposeAreaVisibilityContext } from "./ComposeAreaVisibilityProvider";

export const useComposeAreaVisibility = () => {
    const state = useContext(ComposeAreaVisibilityContext);

    if (state === null) {
        throw new Error(
            "useComposeAreaVisibility can only be used inside the ComposeAreaVisibilityProvider",
        );
    }

    return {
        ...state,
        isAnyComposeAreaOpen:
            state.replyState === "Open" || state.noteState === "Open",
    };
};
