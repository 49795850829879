import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledTemplatesScroller = styled(UI.Flex).attrs({
    flexDirection: "column",
})`
    flex: 1;
    overflow-y: scroll;
`;

export const StyledTabContainer = styled(UI.Flex).attrs({
    flexDirection: "column",
})`
    height: 100%;
`;

export const StyledTemplateSearchContainer = styled(UI.Flex).attrs({
    flexDirection: "column",
    gap: "2",
})`
    padding: ${UI.Tokens.SIZES[2]};
    flex-shrink: 0;
`;

export const StyledPaddedBody = styled.div`
    padding: ${UI.Tokens.SIZES[3]} ${UI.Tokens.SIZES[2]};
`;

export const StyledFilterContainer = styled.div`
    padding: 0 ${UI.Tokens.SIZES[2]} ${UI.Tokens.SIZES[2]};
`;
