import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import * as actions from "app/practices/patientTriage/PatientTriage.actions";
import { PatientTriageSettings } from "app/practices/patientTriage/PatientTriageSettings";

const mapStateToProps = (state: ApplicationState) => {
    const practice = state.practices.items.find(
        ({ id }) => String(id) === state.practices.selectedPractice,
    );
    return {
        accountState: state.account,
        analyticsState: state.sessionAnalytics,
        practices: state.practices,
        practiceId: state.practices.selectedPractice,
        practiceName: practice?.name || "",
        loadingStatus: state.patientTriage.loadingStatus,
        updatingStatus: state.patientTriage.updatingStatus,
        triageSettingsUpdatingStatus:
            state.patientTriage.triageSettingsUpdatingStatus,
        status: state.patientTriage.status,
        features: practice?.features || [],
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export const PatientTriageSettingsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientTriageSettings);
