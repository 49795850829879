import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeFetchError,
    NativeTransportError,
    useNativeTransport,
} from "@accurx/native";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    MutateSelfBookConfigurationPayload,
    mutateSelfBookConfiguration,
} from "domains/self-book/native/api/mutateSelfBookConfiguration";
import { convertAvailabilityPeriodToIsoDuration } from "domains/self-book/utils/appointmentAvailabilityPeriodConversions";
import { AppointmentAvailabilityPeriod } from "domains/self-book/utils/types";

export type UseChangeSelfBookConfigurationMutationPayload = Omit<
    MutateSelfBookConfigurationPayload,
    "availabilityPeriod"
> & {
    appointmentAvailabilityPeriod: AppointmentAvailabilityPeriod;
};

export const useChangeSelfBookConfigurationMutation = (
    options?: UseMutationOptions<
        void,
        NativeFetchError | NativeTransportError,
        UseChangeSelfBookConfigurationMutationPayload
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async (variables) => {
        if (!transport) {
            throw new NativeTransportError(
                "ChangeSelfBookConfigurationMutation: Called with no native transport",
            );
        }

        const { appointmentAvailabilityPeriod, ...rest } = variables;
        const payload = {
            ...rest,
            // Desktop expects availability as an iso string
            availabilityPeriod: convertAvailabilityPeriodToIsoDuration(
                variables.appointmentAvailabilityPeriod,
            ),
        };

        await mutateSelfBookConfiguration(
            {
                transport,
                workspaceId,
            },
            payload,
        );
    }, options);
};
