import { PatientRecordAttachment } from "@accurx/native";

export function formatPatientRecordAttachmentName(
    attachment: PatientRecordAttachment,
): string {
    if (!attachment.descriptiveText) return attachment.fileName;

    return attachment.descriptiveText !== attachment.fileName
        ? `${attachment.descriptiveText} (${attachment.fileName})`
        : attachment.descriptiveText;
}
