import {
    Dispatch,
    ReactNode,
    createContext,
    useContext,
    useReducer,
} from "react";

import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

import { composeInitialMessageBody } from "./composeMessage";
import { EMPTY_STATE } from "./constants";
import { composeReducer } from "./reducer";
import {
    ComposeActions,
    ComposeErrorType,
    ComposeStateType,
    ContactDetail,
} from "./reducer.types";

export const ComposeContext = createContext<{
    state: ComposeStateType;
    isComposing: boolean;
    dispatch: Dispatch<ComposeActions>;
} | null>(null);

type ComposeProviderSettings = {
    greeting: string;
    editableBody: string;
    editableSignature: string;
    contactDetails?: Partial<ContactDetail>;
    maxAttachmentCount: number;
    errors?: ComposeErrorType;
    canUseQuestionnaires: boolean;
    conversationParticipant: "WithHcp" | "WithPatient";
};

const isEqualWithExclusions = (
    state1: ComposeStateType,
    state2: ComposeStateType,
    exclusions: (keyof ComposeStateType)[],
) => isEqual(omit(state1, exclusions), omit(state2, exclusions));

export const ComposeProvider = ({
    children,
    settings,
}: {
    children: ReactNode;
    settings: ComposeProviderSettings;
}) => {
    const initialState: ComposeStateType = {
        ...EMPTY_STATE,
        originalGreeting: settings.greeting,
        originalBody: settings.editableBody,
        originalSignature: settings.editableSignature,
        messageBody: composeInitialMessageBody({
            messageBody: settings.editableBody,
            messageGreeting: settings.greeting,
        }),
        messageSignature: settings.editableSignature,
        contactDetails: {
            ...EMPTY_STATE.contactDetails,
            ...settings.contactDetails,
        },
        maxAttachmentCount: settings.maxAttachmentCount,
        errors: settings.errors ?? EMPTY_STATE.errors,
        canUseQuestionnaires: settings.canUseQuestionnaires,
        conversationParticipant: settings.conversationParticipant,
    };
    const [state, dispatch] = useReducer(composeReducer, initialState);
    const isComposing = !isEqualWithExclusions(state, initialState, [
        "isScheduling",
    ]);

    return (
        <ComposeContext.Provider
            value={{
                state,
                isComposing,
                dispatch,
            }}
        >
            {children}
        </ComposeContext.Provider>
    );
};

export const useCompose = () => {
    const context = useContext(ComposeContext);

    if (context === null) {
        throw new Error("useCompose must be used within an ComposeProvider");
    }
    return context;
};
