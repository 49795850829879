import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledOuterContainer = styled.div`
    padding: 0 ${UI.Tokens.SIZES[3]} ${UI.Tokens.SIZES[3]};
    position: relative; // create a new stacking context so that the top box-shadow remains visible
`;

export const StyledLoadingInnerContainer = styled.div`
    display: flex;
    gap: ${UI.Tokens.SIZES[1]};

    background: ${UI.Tokens.COLOURS.greyscale.white};
    border-radius: ${UI.Tokens.BORDERS.radius};
    padding: ${UI.Tokens.SIZES[0.5]};
    border: ${UI.Tokens.BORDERS.normal.silver};
    box-shadow: 0px ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[2]}
        rgba(67.83, 72.3, 125.97, 0.32);

    align-items: center;
`;

export const StyledPaddedSkeleton = styled.div`
    width: 100%;
    padding: ${UI.Tokens.SIZES[1]} 0;
`;
