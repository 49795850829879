import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useHistory } from "react-router";

import { getCreatePagePath, getEditPagePath } from "../../Routes";
import { RemindersList } from "../../components/RemindersList/RemindersList";
import { CreateReminderButton } from "../OverviewPage/components/CreateReminderButton";
import { PageContainer } from "../OverviewPage/components/PageContainer.styles";

/**
 * This page is purposely designed for the new unified navigation
 */
export const ManageRemindersPage = () => {
    useAccurxWebTitle("Manage reminders");

    const { orgId: workspaceId, organisationName } = useCurrentWorkspace();
    const history = useHistory();

    return (
        <PageContainer>
            <Ds.Flex justifyContent="space-between">
                <Text as="h1" variant="subtitle" skinny>
                    Manage reminders
                </Text>
                <CreateReminderButton
                    onClick={() => {
                        history.push(getCreatePagePath(workspaceId));
                    }}
                />
            </Ds.Flex>
            <RemindersList
                onCreate={() => {
                    history.push(getCreatePagePath(workspaceId));
                }}
                onEdit={(reminder) => {
                    history.push(getEditPagePath(workspaceId, reminder.id));
                }}
                workspaceId={workspaceId}
                workspaceName={organisationName}
            />
        </PageContainer>
    );
};
