import { useState } from "react";

import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { QuickViewPortal } from "@accurx/quick-view";
import { useSelfBookConfigurationAnalytics } from "domains/self-book/hooks/useSelfbookAnalytics";
import { SelfbookConfigurationPayload } from "domains/self-book/utils/types";

import { SelfbookConfigurationForm } from "../SelfbookConfigurationForm";

type SelfbookConfiguratioQuickViewProps = {
    showSelfbookConfiguration: boolean;
    sendAt: string;
    setShowSelfbookConfiguration: (showConfig: boolean) => void;
    onSelfbookConfigComplete: (
        selfbookConfigData: SelfbookConfigurationPayload,
    ) => void;
};

export const SelfbookConfigurationQuickView = ({
    showSelfbookConfiguration,
    sendAt,
    setShowSelfbookConfiguration,
    onSelfbookConfigComplete,
}: SelfbookConfiguratioQuickViewProps) => {
    const appOrigin = "UnifiedInbox";

    const analytics = useSelfBookConfigurationAnalytics({
        appOrigin: appOrigin,
    });

    const workspace = useCurrentWorkspace();

    const isCrossOrgEnabled = useFeatureFlag("HasCrossOrgBooking");
    const isAppointmentAvailabilityEnabled = useFeatureFlag(
        "SelfBookTimeHorizon",
    );

    const [isTelephoneFlow, setIsTelephoneFlow] = useState<boolean>(false);

    const resetFormState = () => {
        setIsTelephoneFlow(false);
        setShowSelfbookConfiguration(false);
    };

    return (
        <QuickViewPortal
            isOpen={showSelfbookConfiguration}
            onClose={resetFormState}
        >
            <QuickViewPortal.Header>
                <Pill.SecondaryButton
                    dimension="small"
                    onClick={() => {
                        resetFormState();
                        analytics.trackBookingInviteCloseButtonClick();
                    }}
                >
                    <Pill.Icon name="Cross" colour="blue" size={3} />
                    <Pill.Text>Close</Pill.Text>
                </Pill.SecondaryButton>
                {isTelephoneFlow && (
                    <Pill.SecondaryButton
                        dimension="small"
                        onClick={() => {
                            setIsTelephoneFlow(false);
                        }}
                    >
                        <Pill.Icon
                            name="ArrowTail"
                            colour="blue"
                            size={3}
                            rotation="left"
                        />
                        <Pill.Text>Back</Pill.Text>
                    </Pill.SecondaryButton>
                )}
            </QuickViewPortal.Header>

            <SelfbookConfigurationForm
                orgId={workspace.orgId}
                organisationName={workspace.organisationName}
                showCrossOrg={isCrossOrgEnabled}
                showAppointmentAvailability={isAppointmentAvailabilityEnabled}
                isTelephoneFlow={isTelephoneFlow}
                appOrigin={appOrigin}
                sendAt={sendAt}
                header={
                    <UI.Text variant="label" skinny>
                        Add a Self-Book link
                    </UI.Text>
                }
                onSelfbookConfigComplete={(
                    selfBookConfig,
                    defaultAppointmentAvailabilityPeriod,
                ) => {
                    if (
                        selfBookConfig.appointmentType === "FaceToFace" ||
                        isTelephoneFlow
                    ) {
                        analytics.trackBookingAddConfirmButtonClick({
                            selfBookConfig,
                            defaultAppointmentAvailabilityPeriod,
                        });
                        onSelfbookConfigComplete(selfBookConfig);
                        resetFormState();
                    } else {
                        setIsTelephoneFlow(true);
                    }
                }}
            />
        </QuickViewPortal>
    );
};
