import { RegionClassification } from "@accurx/api/portal";
import { SharedUrls } from "@accurx/shared";

export const regionalise111Link = (region: RegionClassification) => {
    switch (region) {
        case RegionClassification.Wales:
            return SharedUrls.WalesNHS111;
        case RegionClassification.Scotland:
            return SharedUrls.ScotlandNHS111;
        case RegionClassification.NorthernIreland:
            return SharedUrls.NorthernIrelandNHS111;
        default:
            return SharedUrls.NHS111;
    }
};

export const regionalise999Link = (region: RegionClassification) => {
    switch (region) {
        case RegionClassification.Wales:
            return SharedUrls.WalesWhenTo999;
        case RegionClassification.Scotland:
            return SharedUrls.ScotlandWhenTo999;
        case RegionClassification.NorthernIreland:
            return SharedUrls.NorthernIrelandWhenTo999;
        default:
            return SharedUrls.WhenTo999;
    }
};
