import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { TermsAndConditions } from "app/account/TermsAndConditions/TermsAndConditions";
import { Container } from "app/layout/Container";
import TwoFactor from "app/twoFactorAuth/TwoFactor";
import { CreateWorkspacePage } from "app/workspace/pages/CreateWorkspacePage/CreateWorkspacePage";
import { JoinPharmacyPage } from "app/workspace/pages/JoinPharmacy/JoinPharmacyPage";
import { JoinWorkspacePage } from "app/workspace/pages/JoinWorkspace/JoinWorkspacePage";
import { AddOrganisationPage } from "app/workspace/pages/Organisation/AddOrganisation/AddOrganisationPage";
import { JoinOrganisationPage } from "app/workspace/pages/Organisation/JoinOrganisationPage";
import { WorkspaceInvitationPage } from "app/workspaceSettings/WorkspaceInvitationPage";
import { ROUTES } from "shared/Routes";

import { GuardedRoute } from "../GuardedRoute";

export const ONBOARDING_ROUTES = [
    ROUTES.two_factor_auth,
    ROUTES.joinOrganisation,
    ROUTES.addOrganisation,
    ROUTES.terms_and_conditions,
    ROUTES.workspaceInvitation,
    ROUTES.joinPharmacy,
    ROUTES.joinWorkspace,
    ROUTES.createWorkspace,
];

export const OnboardingRoutesSection = () => {
    return (
        <Switch>
            <SimpleRoute exact path={ROUTES.two_factor_auth}>
                <TwoFactor />
            </SimpleRoute>
            <SimpleRoute exact path={ROUTES.joinOrganisation}>
                <JoinOrganisationPage />
            </SimpleRoute>
            <SimpleRoute exact path={ROUTES.addOrganisation}>
                <AddOrganisationPage />
            </SimpleRoute>
            <SimpleRoute exact path={ROUTES.terms_and_conditions}>
                <Container>
                    <TermsAndConditions />
                </Container>
            </SimpleRoute>
            <SimpleRoute path={ROUTES.workspaceInvitation}>
                <WorkspaceInvitationPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES.joinPharmacy}>
                <JoinPharmacyPage />
            </SimpleRoute>
            <GuardedRoute
                path={ROUTES.joinWorkspace}
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
            >
                <JoinWorkspacePage />
            </GuardedRoute>
            <GuardedRoute
                path={ROUTES.createWorkspace}
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
            >
                <CreateWorkspacePage />
            </GuardedRoute>
        </Switch>
    );
};
