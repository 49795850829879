import { useMemo } from "react";

import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { StateChangeItem } from "@accurx/concierge/types";
import { getAllSaveToRecordEvents } from "@accurx/concierge/util/getAllSaveToRecordEvents";
import { format } from "date-fns";
import {
    ConversationItemContent,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { StyledStateChangeEventContainer } from "domains/inbox/components/ConversationItem/itemTypes/StateChangeEvent/StateChangeEvent.styles";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import {
    formatAssigneeDisplayName,
    formatUserDisplayName,
} from "domains/inbox/util/format/names";
import orderBy from "lodash/orderBy";

import { NoteMessage } from "../NoteMessage/NoteMessage";
import { SaveToRecordUnmatchWarning } from "./SaveToRecordUnmatchWarning";

export const StateChangeEvent = ({
    item,
    conversation,
}: ConversationItemComponent<StateChangeItem>) => {
    const allSaveToRecordEvents = getAllSaveToRecordEvents({
        conversation,
    });

    const mostRecentSaveToRecordEvent = orderBy(
        allSaveToRecordEvents,
        "savedAt",
        "desc",
    ).find((event) => event.savedAt < item.createdAt);

    const senderId = item.createdBy.type === "User" ? item.createdBy.id : "";
    const sender = useUser({ userId: senderId });

    const newAssignee = useAssignee(item.assignedTo ?? { type: "None" });
    const newAssigneeName = formatAssigneeDisplayName(newAssignee);

    const shouldDefaultToAccurx =
        item.changeType === "Done" ||
        item.changeType === "ReOpened" ||
        item.changeType === "Assignment";
    const senderName = formatUserDisplayName({
        user: sender,
        defaultToAccurx: shouldDefaultToAccurx,
    });

    const creationTime = useMemo(
        () => format(new Date(item.createdAt), "h:mmaaa"),
        [item.createdAt],
    );

    return (
        <>
            <ConversationThreadListItem
                $align={getItemAlignment({
                    item,
                })}
            >
                <StyledStateChangeEventContainer
                    data-testid={`conversation-item-${item.id}`}
                >
                    <ConversationItemContent>
                        {((): JSX.Element => {
                            switch (item.changeType) {
                                case "Assignment":
                                    return (
                                        <>
                                            <b>{senderName}</b> assigned this
                                            conversation to{" "}
                                            <b>{newAssigneeName}</b> at{" "}
                                            <b>{creationTime}</b>
                                        </>
                                    );
                                case "Done":
                                    return (
                                        <>
                                            <b>{senderName}</b> marked this
                                            conversation as done at{" "}
                                            <b>{creationTime}</b>
                                        </>
                                    );
                                case "ReOpened":
                                    return (
                                        <>
                                            <b>{senderName}</b> reopened this
                                            conversation at{" "}
                                            <b>{creationTime}</b>
                                        </>
                                    );
                                case "Urgent":
                                    return (
                                        <>
                                            <b>{senderName}</b> marked this
                                            conversation as urgent at{" "}
                                            <b>{creationTime}</b>
                                        </>
                                    );
                                case "NonUrgent":
                                    return (
                                        <>
                                            <b>{senderName}</b> marked this
                                            conversation as not urgent at{" "}
                                            <b>{creationTime}</b>
                                        </>
                                    );
                                case "PatientMatch":
                                    return (
                                        <>
                                            <b>{senderName}</b> matched this
                                            conversation to a patient at{" "}
                                            <b>{creationTime}</b>
                                        </>
                                    );
                                case "PatientUnmatch":
                                    if (mostRecentSaveToRecordEvent) {
                                        return (
                                            <SaveToRecordUnmatchWarning
                                                senderName={senderName}
                                                creationTime={creationTime}
                                                saveToRecordEvent={
                                                    mostRecentSaveToRecordEvent
                                                }
                                            />
                                        );
                                    }
                                    return (
                                        <>
                                            <b>{senderName}</b> unmatched this
                                            conversation from the patient at{" "}
                                            <b>{creationTime}</b>
                                        </>
                                    );
                            }
                        })()}
                    </ConversationItemContent>
                </StyledStateChangeEventContainer>
            </ConversationThreadListItem>
            {item.note && (
                <NoteMessage
                    isReadReceiptShown={false}
                    conversation={conversation}
                    item={{
                        ...item,
                        contentType: "Note",
                        segments: [{ type: "Text", text: item.note }],
                    }}
                />
            )}
        </>
    );
};
