import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { ComposeProvider } from "@accurx/compose";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import { ReplyState } from "domains/inbox/components/ConversationView/ConversationView.types";
import { generateClinicianGreeting } from "domains/inbox/util/greeting";
import { generateSignature } from "domains/inbox/util/signature";

import { useButtonClickAnalytics } from "../../useButtonClickAnalytics";
import { ClinicianReplyContents } from "../Contents/ClinicianReplyContents";
import { ReplyActionButton } from "../MessageActionButton/MessageActionButton";

type ReplyProps = {
    conversation: Conversation;
    replyState: ReplyState;
    setReplyState: (state: ReplyState) => void;
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const ClinicianReply = ({
    conversation,
    replyState,
    setReplyState,
    isExpanded,
    onExpandClick,
}: ReplyProps) => {
    const { user } = useCurrentUser();
    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const userSignature = generateSignature(user);
    const greeting = generateClinicianGreeting(patient);

    const { organisationName } = useCurrentWorkspace();
    const signature = `${userSignature}\n${organisationName}`;

    const { trackReplyButtonClick } = useButtonClickAnalytics(conversation);

    if (replyState === "Closed") {
        return (
            <ReplyActionButton
                text="Reply"
                onClick={() => {
                    setReplyState("Open");
                    trackReplyButtonClick();
                }}
            />
        );
    }

    return (
        <ComposeProvider
            settings={{
                greeting,
                editableBody: "\n", // Start with an extra empty space
                editableSignature: signature,
                maxAttachmentCount: 4,
                canUseQuestionnaires: false,
                conversationParticipant: "WithHcp",
            }}
        >
            <ClinicianReplyContents
                patient={patient}
                conversation={conversation}
                replyState={replyState}
                setReplyState={setReplyState}
                isExpanded={isExpanded}
                onExpandClick={onExpandClick}
            />
        </ComposeProvider>
    );
};
