import { useMemo } from "react";

import { TicketIdentity } from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import { useTransport } from "@accurx/transport";
import throttle from "lodash/throttle";

import {
    NoteTypingSend,
    TypingSendThrottleMs,
} from "shared/hubClient/payload.types";

type SendUserIsTypingMutationVariables = {
    workspaceId: number;
    conversationId: string;
    patientId: string;
};

const regex = /^t-(\d+)-(\w+)$/;
const extractTicketIdentityFromConversationId = (
    conversationId: string,
): Required<TicketIdentity> | undefined => {
    const match = conversationId.match(regex);
    if (match) {
        return {
            type: parseInt(match[1], 10),
            id: match[2],
        };
    }
    Log.warn(
        "Failed to send user typing to signalr as unhandled conversation id",
        {
            tags: {
                conversationId,
            },
        },
    );
    return undefined;
};

export const useSendUserIsTyping = () => {
    const { transport, connectionState } = useTransport();

    return useMemo(
        () =>
            throttle(
                ({
                    workspaceId,
                    conversationId,
                    patientId,
                }: SendUserIsTypingMutationVariables) => {
                    if (connectionState !== "Connected") return;

                    const ticketIdentity =
                        extractTicketIdentityFromConversationId(conversationId);
                    if (ticketIdentity) {
                        const noteTypingSend: NoteTypingSend = {
                            organisationId: workspaceId,
                            ticketIdentity: ticketIdentity,
                            patient: {
                                accuRxId: patientId,
                            },
                        };
                        void transport?.send({
                            methodName: "SendTyping",
                            payload: noteTypingSend,
                        });
                    }
                },
                TypingSendThrottleMs,
                { leading: true, trailing: false },
            ),
        [transport, connectionState],
    );
};
