import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { Redirect, Route, Switch, generatePath } from "react-router";

import {
    AppointmentChangesPage,
    CreateReminderPage,
    EditReminderPage,
    ManageRemindersPage,
    OverviewPage,
    SentRemindersPage,
} from "./pages";

const basePath = "/w/:workspaceId/settings/appointment-reminders";
const createPagePath = `${basePath}/create`;
const editPagePath = `${basePath}/manage/:reminderId`;
const managePagePath = `${basePath}/manage`;
const sentPagePath = `${basePath}/sent`;
const changesPagePath = `${basePath}/changes`;

export function AppointmentRemindersRoutes() {
    const { orgId: workspaceId } = useCurrentWorkspace();

    const isUnifiedNavigationEnabled = useFeatureFlag(
        FeatureName.UnifiedNavigation,
    );

    return (
        <Switch>
            <Route path={createPagePath} exact>
                <CreateReminderPage />
            </Route>
            <Route path={editPagePath} exact>
                <EditReminderPage />
            </Route>
            {isUnifiedNavigationEnabled ? (
                <>
                    <Redirect
                        from={basePath}
                        to={generatePath(managePagePath, { workspaceId })}
                        exact
                    />
                    <Route path={managePagePath} exact>
                        <ManageRemindersPage />
                    </Route>
                    <Route path={sentPagePath} exact>
                        <SentRemindersPage />
                    </Route>
                    <Route path={changesPagePath} exact>
                        <AppointmentChangesPage />
                    </Route>
                </>
            ) : (
                <Route path={basePath} exact>
                    <OverviewPage />
                </Route>
            )}
            <Route
                exact
                path={`${basePath}/:reminderId`}
                render={({ match }) => (
                    <Redirect
                        to={generatePath(editPagePath, {
                            workspaceId,
                            reminderId: match.params.reminderId,
                        })}
                    />
                )}
            />
        </Switch>
    );
}

export const getOverviewPagePath = (workspaceId: number | string) => {
    return generatePath(basePath, { workspaceId });
};

export const getCreatePagePath = (workspaceId: number | string) => {
    return generatePath(createPagePath, { workspaceId });
};

export const getEditPagePath = (
    workspaceId: number | string,
    reminderId: number,
) => {
    return generatePath(editPagePath, { workspaceId, reminderId });
};
