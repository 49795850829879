import { useClinicianMessagingUnassignedTeamPoller } from "../api/clinicianMessaging/pollers/useClinicianMessagingUnassignedTeamPoller";

/**
 * Sets up a poller to fetch unassigned team for clinician messaging
 */
export const ConciergeClinicianUnassignedTeamInitializer = () => {
    useClinicianMessagingUnassignedTeamPoller();

    return null;
};
