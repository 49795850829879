/* eslint-disable */
/* tslint:disable */
/*
 * -------------------- /!\ WARNING /!\ --------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API  ##
 * ## DO NOT EDIT MANUALLY                                ##
 * ## YOUR CHANGES WILL BE OVERWRITTEN                    ##
 * -------------------- /!\ WARNING /!\ --------------------
 */

export type ConversationItem = BaseConversationItem &
    (
        | BaseConversationItemDiscriminatorMapping<
              "outgoingMessage",
              OutgoingMessageItem
          >
        | BaseConversationItemDiscriminatorMapping<
              "incomingMessage",
              IncomingMessageItem
          >
        | BaseConversationItemDiscriminatorMapping<
              "assignedEvent",
              AssignedEventItem
          >
    );

export type Member = BaseMember &
    (
        | BaseMemberDiscriminatorMapping<"user", UserMember>
        | BaseMemberDiscriminatorMapping<"contact", ContactMember>
        | BaseMemberDiscriminatorMapping<"system", SystemMember>
    );

export interface AccreditedEmailDomainsResponse {
    accreditedEmailDomains: AllowedDomain[];
    blockedContacts: BlockedContact[];
}

export interface AllowedDomain {
    name: string;
    allowSubdomains: boolean;
    isExternal: boolean;
}

export interface BlockedContact {
    email: string;
}

export interface ExternalEmailUploadAttachmentResponse {
    externalEmailAttachmentId?: string | null;
    emailAddresses: string[];
    scannedAddresses: ScannedAddress[];
}

export interface ScannedAddress {
    emailAddress?: string | null;
    contact?: ExternalEmailContact | null;
}

export interface ExternalEmailContact {
    id?: string | null;
    displayName?: string | null;
    email?: string | null;
    organisationName?: string | null;
    /** @deprecated */
    isHidden?: boolean | null;
    /** @deprecated */
    serviceName?: string | null;
    service?: ExternalEmailContactService | null;
    /** @deprecated */
    institutionName?: string | null;
    institution?: ExternalEmailContactInstitution | null;
    notes?: string | null;
    phoneNumber?: string | null;
    websiteUrl?: string | null;
    rowVersionBase64?: string | null;
    organisationContactDataRowVersionBase64?: string | null;
}

export interface ExternalEmailContactService {
    /** @format int32 */
    id: number;
    name?: string | null;
}

export interface ExternalEmailContactInstitution {
    /** @format int32 */
    id: number;
    name?: string | null;
}

export enum ExternalEmailAttachmentSource {
    None = 0,
    LocalFile = 1,
    MedicalRecord = 2,
    DocumentStorage = 3,
    PatientRecordPdf = 4,
}

export interface GetConversationsResponse {
    conversations: ConversationSummary[];
    /** @format date-time */
    nextCursor?: string | null;
}

export interface ConversationSummary {
    id: string;
    caseReference: string;
    patientId?: string | null;
    patientExternalId?: PatientExternalIdentityDto | null;
    patientDisplayName?: string | null;
    patient?: PatientThreadPatientDetails | null;
    assignedUserId?: string | null;
    assignee?: PatientThreadUser | null;
    assignedGroupId?: string | null;
    from: string;
    to?: string | null;
    preview: string;
    items: ConversationItem[];
    /** @format int32 */
    messageCount: number;
    /** @format int32 */
    unreadCount: number;
    /** @format date-time */
    lastUpdated: string;
    hasIncoming: boolean;
    hasOutgoing: boolean;
    isArchived: boolean;
    rowVersion: string;
}

export interface PatientExternalIdentityDto {
    patientExternalIds?: PatientExternalIdDto[] | null;
}

export interface PatientExternalIdDto {
    type: IdType;
    /** @minLength 1 */
    value: string;
}

export enum IdType {
    None = 0,
    NhsNumber = 1,
    Emis = 2,
    Dummy = 3,
    SystmOne = 4,
    Vision = 5,
    Hospital = 6,
    ChiNumber = 7,
}

export type PatientThreadPatientDetails = PatientThreadPatientId & {
    names?: PatientName[] | null;
    gender?: string | null;
    /** @format date-time */
    dateOfBirth?: string | null;
    /** @format date-time */
    demographicsLastRefreshed?: string | null;
};

export interface PatientName {
    use: PatientNameUse;
    prefix?: string[] | null;
    given?: string[] | null;
    family?: string[] | null;
}

export enum PatientNameUse {
    None = 0,
    Usual = 1,
    Official = 2,
    Temp = 3,
    Nickname = 4,
    Anonymous = 5,
    Old = 6,
    Maiden = 7,
}

export interface PatientThreadPatientId {
    accuRxId?: string | null;
    patientExternalIdentity?: PatientExternalIdentityDto | null;
}

export interface PatientThreadUser {
    accuRxId?: string | null;
    email?: string | null;
    name?: string | null;
}

export enum ItemType {
    None = 0,
    OutgoingMessage = 1,
    IncomingMessage = 2,
    AssignedEvent = 3,
}

export enum MemberType {
    None = 0,
    User = 1,
    Contact = 2,
    System = 3,
}

export type UserMember = BaseMember & {
    userId: string;
    workspaceId: string;
    displayName: string;
    emailAddress: string;
};

export type ContactMember = BaseMember & {
    contactId: string;
    displayName?: string | null;
    emailAddress: string;
};

export type SystemMember = BaseMember & object;

export type OutgoingMessageItem = BaseConversationItem & {
    text: string;
    trimmedContent?: string | null;
    attachments: Attachment[];
    readBy: ReadState[];
};

export interface Attachment {
    /** @format int32 */
    id: number;
    displayName: string;
}

export interface ReadState {
    userId: string;
    workspaceId: string;
    /** @format date-time */
    readAt?: string | null;
}

export type IncomingMessageItem = BaseConversationItem & {
    text: string;
    trimmedContent?: string | null;
    /** @format date-time */
    dateReadByGp?: string | null;
    attachments: Attachment[];
    readBy: ReadState[];
};

export type AssignedEventItem = BaseConversationItem & {
    text: string;
    assignee: Assignee;
    readBy: ReadState[];
};

export interface Assignee {
    assigneeType: AssigneeType;
    assignedUser?: PatientThreadUser | null;
    assignedUserGroup?: PatientThreadUserGroup | null;
}

export enum AssigneeType {
    None = 0,
    User = 1,
    UserGroup = 2,
}

export interface PatientThreadUserGroup {
    id?: string | null;
    name?: string | null;
    groupType?: UserGroupType | null;
}

export enum UserGroupType {
    None = 0,
    Team = 1,
    FailedDeliveryReceipts = 2,
    Florey = 3,
    PatientSingleResponse = 4,
    ToAssign = 5,
    DefaultRoles = 6,
    PracticeGroup = 7,
}

export interface GetConversationsRequest {
    patient?: PatientFilter | null;
    excludeRead?: boolean | null;
    hasIncoming?: boolean | null;
    hasOutgoing?: boolean | null;
    isArchived?: boolean | null;
    assigneeUserId?: string | null;
    assigneeGroupId?: string | null;
    /** @format date-time */
    updatedBeforeDate?: string | null;
    /** @format int32 */
    limit?: number | null;
}

export interface PatientFilter {
    patient?: PatientExternalIdentityDto | null;
}

export type ClinicianConversation = ClinicianConversationBase & {
    workspaceConversationId?: string | null;
};

export interface ClinicianConversationBase {
    /** @format int32 */
    id: number;
    caseReference: string;
    subject: string;
    messages: ClinicianMessage[];
    patient?: PatientInfo | null;
    participantWorkspaces: MemberWorkspace[];
    individualParticipants: ContactMember[];
    urlId?: string | null;
    items: ConversationItem[];
}

export interface ClinicianMessage {
    messageId: string;
    senderDisplayName: string;
    senderEmailAddress: string;
    body: string;
    previousMessages?: string | null;
    /** @format date-time */
    dateSent: string;
    /** @format date-time */
    dateRead?: string | null;
    attachments: Attachment[];
}

export interface PatientInfo {
    patientId: string;
    nhsNumber?: string | null;
    prefixName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    /** @format date-time */
    dateOfBirth?: string | null;
    gender?: string | null;
}

export interface MemberWorkspace {
    /** @format int32 */
    workspaceId: number;
    workspaceName: string;
    participants: UserMember[];
}

export type ClinicianConversationWithWorkspace = ClinicianConversationBase & {
    workspaceConversationId: string;
    isArchived: boolean;
    /** @format date-time */
    lastUpdated: string;
    assignee: Assignee;
    rowVersion: string;
};

export interface SendResponse {
    summary: ConversationSummary;
}

export interface SendRequest {
    /** @minLength 1 */
    textContent: string;
    externalEmailAttachmentIds: string[];
    metadata?: SendMessageMetadata | null;
}

export interface SendMessageMetadata {
    isPresetTemplate: boolean;
    isPostPatientMessage: boolean;
    templateLevel?: string | null;
    templateGroup?: string | null;
    templateName?: string | null;
}

export interface SendMessageResponse {
    message: ClinicianMessage;
}

export interface SendMessageRequest {
    /** @format int32 */
    organisationId: number;
    /** @format int32 */
    conversationId: number;
    textContent: string;
    externalEmailAttachmentIds?: string[] | null;
    metadata?: SendMessageMetadata | null;
}

export interface ConversationUpdateCollection {
    updates: ConversationUpdate[];
}

export interface ConversationUpdate {
    conversationId?: string | null;
    workspaceConversationId?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    caseReference?: string | null;
    conversationSubject?: string | null;
    patientThreadPatientId?: PatientThreadPatientId | null;
    conversationIsArchived: boolean;
    assignee?: Assignee | null;
    recordViewData?: RecordViewAccuMailData | null;
    assignedEvent?: AssignedEvent | null;
    event?: ConversationEvent | null;
    message?: ExternalEmailMessageItem | null;
    /** @format date-time */
    lastUpdated?: string | null;
    workspaceConversationRowVersion?: string | null;
}

export interface RecordViewAccuMailData {
    type?: RecordViewConversationType | null;
}

export enum RecordViewConversationType {
    PromptGpOptIn = 0,
}

export type AssignedEvent = Event & {
    assignee?: Assignee | null;
};

export interface Event {
    id?: string | null;
    raisedByUser?: PatientThreadUser | null;
    /** @format date-time */
    raisedAt: string;
    notes?: string | null;
    eventNotifications: EventNotification[];
}

export interface EventNotification {
    userId?: string | null;
    hasRead: boolean;
    /** @format date-time */
    readAt?: string | null;
}

export interface ConversationEvent {
    type: EventType;
    /** @minLength 1 */
    id: string;
    /**
     * @format date-time
     * @minLength 1
     */
    raisedAt: string;
    eventNotifications: EventNotification[];
    notes?: string | null;
    assignee?: Assignee | null;
    raisedByUser?: PatientThreadUser | null;
}

export enum EventType {
    Assigned = 0,
    Archived = 1,
    Unarchived = 2,
}

export interface ExternalEmailMessageItem {
    type: ExternalEmailMessageType;
    outgoing?: ExternalEmailOutgoingMessage | null;
    incoming?: ExternalEmailIncomingMessage | null;
}

export enum ExternalEmailMessageType {
    None = 0,
    Outgoing = 1,
    Incoming = 2,
}

export type ExternalEmailOutgoingMessage = ExternalEmailMessage & {
    type: ExternalEmailMessageType;
    sender?: PatientThreadUser | null;
    recipient?: ExternalEmailContact | null;
    /** @format date-time */
    sentTime?: string | null;
};

export interface ExternalEmailMessage {
    id?: string | null;
    emailConversationId?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    caseReference?: string | null;
    patientAccuRxId?: string | null;
    ticketIdentity?: TicketIdentity | null;
    type: ExternalEmailMessageType;
    bodyTextContent?: string | null;
    messageBody?: MessageBody | null;
    isTruncated?: boolean | null;
    writeToRecordState?: ExternalEmailWriteToRecordState | null;
    attachments?: ExternalEmailAttachment[] | null;
    otherRecipients?: ExternalEmailContact[] | null;
    userStates?: ExternalEmailUserState[] | null;
    archiveState?: ExternalEmailArchiveState | null;
}

export interface TicketIdentity {
    type: TicketIdentityType;
    id?: string | null;
}

export enum TicketIdentityType {
    None = 0,
    SMS = 1,
    Note = 2,
    HealthcareEmail = 3,
    PatientEmail = 4,
    NhsAppMessage = 5,
}

export interface MessageBody {
    messagePrefix?: string | null;
    mainMessage?: string | null;
    previousMessages?: string | null;
}

export interface ExternalEmailWriteToRecordState {
    hasWrittenToRecord?: boolean | null;
    writtenByUserId?: string | null;
    writtenByUserName?: string | null;
    /** @format date-time */
    writeTime?: string | null;
}

export interface ExternalEmailAttachment {
    id?: string | null;
    displayName?: string | null;
}

export interface ExternalEmailUserState {
    userId?: string | null;
    hasRead: boolean;
    /** @format date-time */
    readAt?: string | null;
}

export interface ExternalEmailArchiveState {
    isArchived: boolean;
}

export type ExternalEmailIncomingMessage = ExternalEmailMessage & {
    type: ExternalEmailMessageType;
    sender?: ExternalEmailContact | null;
    recipient?: PatientThreadUser | null;
    /** @format date-time */
    receivedTime?: string | null;
    /** @format date-time */
    dateReadByGp?: string | null;
};

export interface AssignConversationRequest {
    newAssigneeUserId?: string | null;
    /** @format int32 */
    assigneeId?: number | null;
    assigneeType?: AssigneeType | null;
    notes?: string | null;
}

export interface AssignResponse {
    summary: ConversationSummary;
    notificationUpdates: ConversationUpdate[];
}

export interface MarkAsReadRequest {
    incomingMessageIds: string[];
    outgoingMessageIds?: string[] | null;
    eventIds: string[];
}

export interface MarkResponse {
    summary: ConversationSummary;
    notificationUpdates: ConversationUpdate[];
}

export interface UserGroupResponse {
    userGroups: UserGroupSummary[];
}

export interface UserGroupSummary {
    userGroupId?: string | null;
    currentUserIsInUserGroup: boolean;
    userGroupType: UserGroupType;
}

export interface Summary {
    notificationState?: NotificationState | null;
    unassignedNotificationState?: NotificationState | null;
    assignedToUserNotificationState?: NotificationState | null;
    userGroups: UserGroupSummary[];
}

export interface NotificationState {
    unreadMessageIds: string[];
    unreadEventIds: string[];
    unreadOutgoingMessageIds: string[];
}

export interface UnreadSummaryResponse {
    unreadSummaries: UnreadSummary[];
}

export interface UnreadSummary {
    conversationId: string;
    patientId?: string | null;
    assignedUserId?: string | null;
    assignedGroupId?: string | null;
    itemIds: ConversationItemId[];
    /** @format date-time */
    conversationLastUpdated: string;
}

export interface ConversationItemId {
    type: ItemType;
    id: string;
}

export interface ArchiveRequest {
    outgoingMessageIds: string[];
    incomingMessageIds: string[];
}

export interface ArchiveResponse {
    summary: ConversationSummary;
    notificationUpdates: ConversationUpdate[];
}

export interface ArchiveRequest2 {
    rowVersionBase64?: string | null;
}

export interface UnarchiveResponse {
    summary: ConversationSummary;
    notificationUpdates: ConversationUpdate[];
}

export interface UnarchiveRequest {
    rowVersionBase64?: string | null;
}

export interface PatientMatchingRequest {
    patientId?: PatientExternalIdentityDto | null;
}

export interface GetContactsResponse {
    contacts: ExternalEmailContact[];
    /** @format int32 */
    totalContactCount?: number | null;
    appliedSearchTerm?: string | null;
}

export enum ContactSortParam {
    MessageFrequency = "MessageFrequency",
    Distance = "Distance",
    RecentlyContacted = "RecentlyContacted",
}

export interface ExternalEmailConversation {
    emailConversationId?: string | null;
    workspaceConversationId?: string | null;
    isArchived?: boolean | null;
    caseReference?: string | null;
    conversationSubject?: string | null;
    patientThreadPatient?: PatientThreadPatientDetails | null;
    assignee?: Assignee | null;
    messages?: ExternalEmailMessageItem[] | null;
    assignedEvents: AssignedEvent[];
    events: ConversationEvent[];
    /** @format date-time */
    lastUpdated: string;
    workspaceConversationRowVersion: string;
}

export interface ExternalEmailContactsResponse {
    organisationContacts?: ExternalEmailContact[] | null;
    nationalContacts?: ExternalEmailContact[] | null;
}

export interface GetContactResponse {
    contact?: ExternalEmailContact | null;
}

export interface GetNdrContactResponse {
    ndrDetails?: NdrDetails | null;
}

export interface NdrDetails {
    /** @format date-time */
    ndrReceivedAt: string;
}

export interface AddContactRequest {
    emailAddress?: string | null;
    displayName?: string | null;
    notes?: string | null;
    phoneNumber?: string | null;
    websiteUrl?: string | null;
    /** @format int32 */
    serviceId?: number | null;
    /** @format int32 */
    institutionId?: number | null;
}

export interface UpdateContactRequest {
    displayName?: string | null;
    notes?: string | null;
    phoneNumber?: string | null;
    websiteUrl?: string | null;
    /** @format int32 */
    serviceId?: number | null;
    /** @format int32 */
    institutionId?: number | null;
    rowVersionBase64?: string | null;
    organisationContactDataRowVersionBase64?: string | null;
}

export interface UpdateGlobalContactResponse {
    contact?: ExternalEmailContact | null;
}

export interface UpdateGlobalContactRequest {
    rowVersionBase64?: string | null;
    /** @format int32 */
    serviceId?: number | null;
    /** @format int32 */
    institutionId?: number | null;
}

export interface UpdateOrganisationContactDataResponse {
    contact?: ExternalEmailContact | null;
}

export interface UpdateOrganisationContactDataRequest {
    rowVersionBase64?: string | null;
    displayName?: string | null;
    notes?: string | null;
    phoneNumber?: string | null;
    websiteUrl?: string | null;
}

export interface GetContactMetadataResponse {
    numberOfMessagesSentToContact?: string | null;
    openRate?: string | null;
    responseRate?: string | null;
}

export interface GetContactDirectoryMetadataResponse {
    canDisplayMetadata?: boolean | null;
    numberOfMessagesSentToContact?: string | null;
    numberOfMessagesBadge?: string | null;
    averageResponseTime?: string | null;
    responseTimeBadge?: string | null;
    /** @format double */
    responseTimeDays?: number | null;
    /** @format double */
    daysSinceLastMessage?: number | null;
    /** @format int32 */
    numberOfMessagesSentInLastMonth?: number | null;
}

export interface ExternalEmailSentResponse {
    id?: string | null;
    caseReference?: string | null;
    workspaceConversationId?: string | null;
    emailConversationId?: string | null;
    externalEmailContactId?: string | null;
}

export interface ExternalEmailSendInitialRequest {
    /** @format int32 */
    accuRxOrgId?: number | null;
    subject?: string | null;
    bodyTextContent?: string | null;
    patientId?: PatientExternalIdentityDto | null;
    recipient?: ExternalEmailContact | null;
    externalEmailAttachmentIds?: string[] | null;
    withPatientRecordSummary?: boolean | null;
    metadata?: SendMessageMetadata | null;
}

export interface ExternalEmailSendReplyRequest {
    /** @format int32 */
    accuRxOrgId?: number | null;
    bodyTextContent?: string | null;
    recipient?: ExternalEmailContact | null;
    caseReference?: string | null;
    externalEmailAttachmentIds?: string[] | null;
    withPatientRecordSummary?: boolean | null;
    metadata?: SendMessageMetadata | null;
}

export type ExternalEmailMarkAsWrittenToRecordRequest = ExternalEmailId & {
    /** @format int32 */
    accuRxOrgId?: number | null;
    patientExternalIdentity?: PatientExternalIdentityDto | null;
    caseReference?: string | null;
};

export interface ExternalEmailId {
    emailId?: string | null;
    type?: ExternalEmailMessageType | null;
}

export interface ExternalEmailContactServicesResponse {
    services: ExternalEmailContactService[];
}

export interface ExternalEmailContactInstitutionsResponse {
    institutions: ExternalEmailContactInstitution[];
}

export interface GetSubscribedDirectoriesResponse {
    subscribedDirectories: Directory[];
    organisationDirectoryId?: string | null;
}

export interface Directory {
    id?: string | null;
    name?: string | null;
}

export interface MessagePracticeRequest {
    /** @minLength 1 */
    patientToken: string;
    /** @minLength 1 */
    nhsNumber: string;
    /** @minLength 1 */
    practiceCode: string;
    messageBody?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    externalEmailAttachmentIds?: string[] | null;
    metadata?: SendMessageMetadata | null;
}

export interface ClinicianConversationSummary {
    /** @format int32 */
    id: number;
    lastMessage: ClinicianMessage;
    urlId: string;
}

export interface PracticeActivityResponse {
    practiceUserOnlineStatus: PracticeUserOnlineStatus[];
    isOpenToReplies: boolean;
}

export interface PracticeUserOnlineStatus {
    /** @format date-time */
    lastOnlineAt: string;
}

export interface CanMessagePracticeResponse {
    enabled: boolean;
    reason?: string | null;
}

export interface GetTemplatesResponse {
    templates: Template[];
}

export interface Template {
    id: string;
    title: string;
    content: string;
    templateType: TemplateType;
    snomedCodes: SnomedCodeFull[];
}

export enum TemplateType {
    GlobalTemplate = "GlobalTemplate",
    WorkspaceTemplate = "WorkspaceTemplate",
    UserTemplate = "UserTemplate",
}

export type SnomedCodeFull = SnomedCode & {
    term?: string | null;
    descriptionId?: string | null;
};

export interface SnomedCode {
    conceptId?: string | null;
}

export interface AddTemplateRequest {
    /** @minLength 1 */
    title: string;
    /** @minLength 1 */
    content: string;
    templateType: TemplateType;
    snomedCodes?: SnomedCode[] | null;
}

export interface EditTemplateRequest {
    title?: string | null;
    content?: string | null;
    snomedCodes?: SnomedCode[] | null;
}

interface BaseConversationItem {
    type: ItemType;
    id: string;
    createdBy: Member;
    /** @format date-time */
    dateSent: string;
    discriminator: string;
}

type BaseConversationItemDiscriminatorMapping<Key, Type> = {
    discriminator: Key;
} & Type;

interface BaseMember {
    /** @deprecated */
    type: MemberType;
    discriminator: string;
}

type BaseMemberDiscriminatorMapping<Key, Type> = {
    discriminator: Key;
} & Type;
