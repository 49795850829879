import React from "react";

import {
    Accordion,
    Button,
    Card,
    Feedback,
    Text,
    Tokens,
} from "@accurx/design";
import { i } from "images";
import { TextareaAutosize } from "react-autosize-textarea/lib/TextareaAutosize";

import { UpdatingStatusType } from "shared/LoadingStatus";

import { Settings } from "../../constants";
import { PatientTriageStatus } from "../../types";
import { UrgentHelpSection } from "../UrgentHelpSection/UrgentHelpSection";

export type ChangeSubmissionPageFormPropsType = {
    status: PatientTriageStatus;
    triageSettingsUpdatingStatus: UpdatingStatusType;
    submissionPageMessage: string;
    handleUpdateCustomCopy: (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>,
        name: string,
        resetToDefault: boolean | null,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleUpdateString: (event: any, name: string) => void;
    hasClickedEditButton: (
        event: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
    handleAccordionClick: (
        e: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
    hasClickedEditSubmissionPage: boolean;
    handleSubmissionConfirmationMessageCancel: () => void;
};

// Renders the submission page edit form
export const ChangeSubmissionPageForm = ({
    status,
    triageSettingsUpdatingStatus,
    submissionPageMessage,
    hasClickedEditSubmissionPage,
    handleSubmissionConfirmationMessageCancel,
    hasClickedEditButton,
    handleUpdateCustomCopy,
    handleUpdateString,
    handleAccordionClick,
}: ChangeSubmissionPageFormPropsType): JSX.Element => {
    const isDisabled =
        status.isAdmin === false || triageSettingsUpdatingStatus.updating;

    return (
        <form
            onSubmit={(e): void =>
                handleUpdateCustomCopy(e, Settings.TEXT_SUBMISSION_PAGE, false)
            }
        >
            <Card
                props={{ className: "mb-3" }}
                header={
                    <Text as="h3" skinny variant="subtitle">
                        Edit submission confirmation message
                    </Text>
                }
                footer={
                    <div className="d-flex justify-content-end">
                        {hasClickedEditSubmissionPage ? (
                            <>
                                <Button
                                    dimension="medium"
                                    theme="primary"
                                    data-testid="submissionMessageSave"
                                    disabled={isDisabled}
                                    type="submit"
                                    text="Save changes"
                                />
                                <Button
                                    className="ml-3"
                                    dimension="medium"
                                    theme="secondary"
                                    onClick={(e): void =>
                                        handleUpdateCustomCopy(
                                            e,
                                            Settings.TEXT_SUBMISSION_PAGE,
                                            true,
                                        )
                                    }
                                    disabled={isDisabled}
                                    data-testid="submissionMessageReset"
                                    text="Reset to default"
                                />
                                <Button
                                    className="ml-3"
                                    dimension="medium"
                                    theme="transparent"
                                    onClick={
                                        handleSubmissionConfirmationMessageCancel
                                    }
                                    data-testid="submissionMessageCancel"
                                    text="Cancel"
                                    type="button"
                                />
                            </>
                        ) : (
                            <Button
                                dimension="medium"
                                theme="primary"
                                icon={{ name: "Pencil" }}
                                onClick={(e) =>
                                    hasClickedEditButton(
                                        e,
                                        Settings.TEXT_SUBMISSION_PAGE,
                                    )
                                }
                                disabled={isDisabled}
                                data-testid="submissionMessageEdit"
                                text="Edit"
                                type="button"
                            />
                        )}
                    </div>
                }
            >
                <>
                    <div>
                        <Text>
                            This is the message patients see once they have
                            submitted their request.
                        </Text>

                        <Accordion
                            header="See where this is on the patient form"
                            icon={{
                                colour: "night",
                                name: "Photo",
                            }}
                            className="mb-3"
                            buttonProps={{
                                onClick: (e): void =>
                                    handleAccordionClick(
                                        e,
                                        Settings.TEXT_SUBMISSION_PAGE,
                                    ),
                            }}
                        >
                            <img
                                className="w-100"
                                src={i(
                                    "patientTriage/submission-message-location.png",
                                )}
                                alt="Location of welcome message in patient triage flow"
                            />
                        </Accordion>

                        <div className="mb-3">
                            <Feedback
                                colour="secondary"
                                title="When editing the message, remember to:"
                                content={
                                    <ul className="pl-3 mb-0">
                                        <li>
                                            specify within how long you will
                                            respond to the request
                                        </li>
                                        <li>
                                            specify what to do if the patients
                                            have not heard from you within the
                                            expected timeframe
                                        </li>
                                        <li>not include any website links</li>
                                    </ul>
                                }
                            />
                        </div>

                        <div
                            className="p-2"
                            style={{
                                backgroundColor: Tokens.COLOURS.greyscale.cloud,
                            }}
                        >
                            <Text variant="label" props={{ className: "mb-2" }}>
                                Your current message:
                            </Text>

                            <div>
                                <Text variant="subtitle" colour="metal">
                                    What happens next?
                                </Text>

                                {hasClickedEditSubmissionPage ? (
                                    <TextareaAutosize
                                        cols={80}
                                        name="submissionPageMessage"
                                        id="submissionPageMessage"
                                        data-testid="submissionPageMessageCopy"
                                        disabled={isDisabled}
                                        onChange={(e): void =>
                                            handleUpdateString(
                                                e,
                                                Settings.TEXT_SUBMISSION_PAGE,
                                            )
                                        }
                                        value={submissionPageMessage}
                                    />
                                ) : (
                                    <Text>{submissionPageMessage}</Text>
                                )}

                                <UrgentHelpSection region={status.region} />
                            </div>
                        </div>
                    </div>
                </>
            </Card>
        </form>
    );
};
