import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";

import {
    StyledLoadingInnerContainer,
    StyledOuterContainer,
    StyledPaddedSkeleton,
} from "./MessageActionsLoading.styles";

export const MessageActionsLoading = () => {
    return (
        <StyledOuterContainer>
            <StyledLoadingInnerContainer>
                <StyledPaddedSkeleton>
                    <SkeletonLoader height="24px" width={"40%"} />
                </StyledPaddedSkeleton>
            </StyledLoadingInnerContainer>
        </StyledOuterContainer>
    );
};
