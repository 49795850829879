import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import CapacityDashboard from "app/practices/vaccine/capacityDashboard/CapacityDashboard";

export const AccubookDashboardPage = () => {
    return (
        <StyledContainer>
            <PageHeader title="Dashboard" type={PageHeaderType.ListPage} />
            <CapacityDashboard noHeader />
        </StyledContainer>
    );
};
