import { TemplateItem } from "domains/compose/types";
import groupBy from "lodash/groupBy";

import { sortTemplates } from "./sortTemplates";

type Heading =
    | { type: "HeadingLevelOne"; value: string }
    | { type: "HeadingLevelTwo"; value: string };

type TemplateItemWithOriginalIndex = TemplateItem & { index: number };
type TemplatesWithHeadings = (TemplateItemWithOriginalIndex | Heading)[];

const ownerToHeadingValue: Record<string, string> = {
    User: "Created by you",
    Organisation: "Created by your workspace",
    Accurx: "Created by Accurx",
};

/**
 * Recursively add headings level one and two to
 * the array of templates so we have a structure like so:
 * - 1. template.value.owner
 * - 2. template.value.heading
 * - ...templates with that owner and heading
 * - 2. template.value.heading
 * - ...templates with that owner and heading
 * - 1. template.value.owner
 * ....etc
 */
const groupByOwnerAndHeadings = (
    templates: TemplateItemWithOriginalIndex[],
) => {
    const groupWithHeadings = (
        templates: TemplateItemWithOriginalIndex[],
        iteratee: ((item: TemplateItem) => string) | string,
        heading: Heading["type"],
    ): TemplatesWithHeadings => {
        const groups = groupBy(templates, iteratee);

        return Object.keys(groups).flatMap((groupName) => {
            const items = groups[groupName];
            const groupItems =
                heading === "HeadingLevelOne"
                    ? groupWithHeadings(
                          items,
                          ({ value }) => value.heading ?? "",
                          "HeadingLevelTwo",
                      )
                    : items;

            const headingValue =
                heading === "HeadingLevelOne"
                    ? ownerToHeadingValue[groupName] || groupName
                    : groupName;

            return [
                {
                    value: headingValue,
                    type: heading,
                } as Heading,
                ...groupItems,
            ];
        });
    };

    return groupWithHeadings(templates, "value.owner", "HeadingLevelOne");
};

export const addHeadings = (
    templates: TemplateItem[],
): TemplatesWithHeadings => {
    const sorted = sortTemplates(templates);

    return groupByOwnerAndHeadings(
        sorted.map<TemplateItemWithOriginalIndex>((t, i) => ({
            ...t,
            // Add the original index to the templates array so that
            // we can mark the template item as highlighted
            index: i,
        })),
    );
};
