import { useState } from "react";

import {
    Feedback,
    Input,
    Pagination,
    SelectItem,
    SingleSelect,
    Spinner,
    Table,
    Text,
    usePagination,
    useTable,
} from "@accurx/design";
import { useFuzzyFilter } from "@accurx/hooks";
import { useAccurxWebTitle } from "@accurx/navigation";

import { useDeliveryReceipts } from "../hooks";
import {
    AppointmentReminderSelectWrapper,
    AppointmentReminderStatusWrapper,
    StyledPaginationContainer,
} from "./AppointmentReminder.styles";

const DeliveryStatus = {
    delivered: "Delivered",
    sent: "Sent",
    failed: "Failed",
    all: "All",
};

export const PAGE_SIZE = 50;

interface DeliveryReceiptsProps {
    workspaceId: string;
}

export const DeliveryReceipts = ({ workspaceId }: DeliveryReceiptsProps) => {
    useAccurxWebTitle("View sent appointment reminders");

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (searchInput: string) => {
        goToPage(1);
        setSearchTerm(searchInput);
    };

    const { data, status } = useDeliveryReceipts({
        workspaceId,
    });

    const deliveryReceipts =
        status === "success" ? data.appointmentReminderDeliveryReceipts : [];

    const tableRows = useFuzzyFilter(deliveryReceipts, searchTerm, {
        keys: [
            "patientName",
            "patientIdDisplay",
            "status",
            "appointmentStartTime",
        ],
        threshold: 0.1, // default is 0.6. 1.0 Matches everything https://fusejs.io/api/options.html#threshold
    });

    const table = useTable({
        data: tableRows,
        columnDefs: [
            {
                id: "patientIdDisplay",
                header: "NHS number",
            },
            {
                id: "patientName",
                header: "Patient name",
            },
            {
                id: "appointmentStartTime",
                header: "Appointment date",
            },
            {
                id: "status",
                header: "Status",
            },
        ],
    });

    const { currentPage, totalPages, onNext, onPrevious, paginate, goToPage } =
        usePagination({
            defaultPage: 1,
            defaultPageSize: PAGE_SIZE,
            total: table.rows.length,
            isCycleEnabled: false,
        });

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "error") {
        return (
            <Feedback
                colour="error"
                iconName="Failed"
                title="Error loading sent reminders"
            >
                <Text skinny>
                    There was an issue loading sent appointment reminders, try
                    again later.
                </Text>
            </Feedback>
        );
    }

    if (deliveryReceipts.length === 0) {
        return (
            <Feedback colour="information" title="There are no sent reminders">
                <Text skinny>
                    You will be able to see all the appointment reminders sent
                    here.
                </Text>
            </Feedback>
        );
    }

    const handleStatusFilter: (filter: string) => void = (filter: string) => {
        goToPage(1);
        table.actions.filter(
            (data) => filter === DeliveryStatus.all || data.status === filter,
        );
    };

    return (
        <>
            <div>
                <div className="appointment-reminder-search-bar-wrapper">
                    <Input
                        type="search"
                        placeholder="Search by NHS Number or name"
                        value={searchTerm}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <AppointmentReminderStatusWrapper>
                    <Text
                        variant="label"
                        as="label"
                        props={{ htmlFor: "delivery-status" }}
                        skinny
                    >
                        Showing
                    </Text>
                    <AppointmentReminderSelectWrapper>
                        <SingleSelect
                            id="delivery-status"
                            defaultValue={DeliveryStatus.all}
                            onValueChange={handleStatusFilter}
                            dimension="medium"
                        >
                            <SelectItem value={DeliveryStatus.all}>
                                {DeliveryStatus.all}
                            </SelectItem>
                            <SelectItem value={DeliveryStatus.delivered}>
                                {DeliveryStatus.delivered}
                            </SelectItem>
                            <SelectItem value={DeliveryStatus.sent}>
                                {DeliveryStatus.sent}
                            </SelectItem>
                            <SelectItem value={DeliveryStatus.failed}>
                                {DeliveryStatus.failed}
                            </SelectItem>
                        </SingleSelect>
                    </AppointmentReminderSelectWrapper>
                </AppointmentReminderStatusWrapper>
            </div>
            <Table>
                <Table.Head>
                    <Table.Row>
                        {table.columns.map((column) => (
                            <Table.Header key={column.id} scope="col">
                                {column.header}
                            </Table.Header>
                        ))}
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {paginate(table.rows).map((row) => (
                        <Table.Row key={row.id}>
                            <Table.DataCell>
                                {row.patientIdDisplay}
                            </Table.DataCell>
                            <Table.DataCell>{row.patientName}</Table.DataCell>
                            <Table.DataCell>
                                {row.appointmentStartTime}
                            </Table.DataCell>
                            <Table.DataCell>
                                {row.status === "Failed" ? (
                                    <span className="appointment-reminder-failed-badge">
                                        {row.status.toLocaleUpperCase()}
                                    </span>
                                ) : (
                                    <span>{row.status}</span>
                                )}
                            </Table.DataCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <StyledPaginationContainer>
                <Pagination>
                    <Pagination.Navigation
                        currentPage={currentPage}
                        totalPages={totalPages}
                    >
                        {currentPage > 1 && (
                            <>
                                <Pagination.PreviousButton
                                    onPrevious={onPrevious}
                                />
                            </>
                        )}
                        {currentPage < totalPages && (
                            <>
                                <Pagination.NextButton onNext={onNext} />
                            </>
                        )}
                    </Pagination.Navigation>
                </Pagination>
            </StyledPaginationContainer>
        </>
    );
};
