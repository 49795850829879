import { FeatureName } from "@accurx/auth";
import NotFound from "NotFound";

import { IOrganisation } from "api/FlemingDtos";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

type RequiredFlags =
    | {
          /**
           * any - a list of feature flags, at least one of them must be enabled for the children to render
           */
          any: FeatureName[];
          all?: never;
      }
    | {
          /**
           * all - a list of feature flags that must all be enabled for the children to render
           */
          all: FeatureName[];
          any?: never;
      };

type HasFeatureArgs = {
    feature: FeatureName;
    organisation: IOrganisation | null;
};

const hasFeature = ({ feature, organisation }: HasFeatureArgs) => {
    return OrganisationHelper.isFeatureEnabled(organisation, feature);
};

type FeatureFlagsCheckArgs = {
    flags: RequiredFlags;
    organisation: IOrganisation | null;
};

const hasRequiredFeatureFlags = ({
    organisation,
    flags,
}: FeatureFlagsCheckArgs) => {
    // Allow route if no feature flags have been specified
    if ((flags.any || flags.all).length === 0) {
        return true;
    }
    if (flags.any) {
        return flags.any.some((feature) =>
            hasFeature({ feature, organisation }),
        );
    }
    return flags.all.every((feature) => hasFeature({ feature, organisation }));
};

export const getMissingFeatureFlags = ({
    flags,
    organisation,
}: FeatureFlagsCheckArgs) => {
    if (flags.any) {
        return flags.any;
    } else {
        return flags.all.filter(
            (feature) => hasFeature({ feature, organisation }) === false,
        );
    }
};

export type FeatureGuardProps = RequiredFlags & { children: JSX.Element };

/**
 * @deprecated - try to use `WorkspaceFeatureGuard` instead
 *
 *
 * Will render children only if the current organisation has the required feature flags enabled.
 * Otherwise will render the 'page not found' page
 * Accepts as props a list of required feature flags, either being features that are all required, or where any are required.
 */
export const FeatureGuard = ({ children, ...flags }: FeatureGuardProps) => {
    const organisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );

    const hasRequiredFlags = hasRequiredFeatureFlags({
        organisation,
        flags,
    });

    return hasRequiredFlags ? (
        children
    ) : (
        <NotFound
            missingFeatureFlags={getMissingFeatureFlags({
                organisation,
                flags,
            })}
        />
    );
};
