/* eslint-disable */
/* tslint:disable */
/*
 * -------------------- /!\ WARNING /!\ --------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API  ##
 * ## DO NOT EDIT MANUALLY                                ##
 * ## YOUR CHANGES WILL BE OVERWRITTEN                    ##
 * -------------------- /!\ WARNING /!\ --------------------
 */

export type UserResponse = IsLoggedInResponse & {
    organisationExternalId?: string | null;
    messageSignature?: string | null;
    organisations?: Organisation[] | null;
    intercom?: IntercomUser | null;
    isSecureEmail: boolean;
    healthcareRole: string;
    specialistArea: string;
    is2FAed: boolean;
    isSetupFor2Fa: boolean;
    onboarding?: WebOnboardingResponse | null;
    /** @format int32 */
    lastActiveWorkspaceId?: number | null;
};

export interface Organisation {
    /** @format int32 */
    orgId?: number | null;
    organisationName: string;
    description?: string | null;
    roleCode?: string | null;
    nationalCode: string;
    organisationNodeName: string;
    settings: FlemingSettings;
    /** @format int32 */
    defaultWorkspaceId: number;
    workspaceSpecialties: string[];
    status: WorkspaceStatus;
    latestIntegratedSystem?: string | null;
}

export type FlemingSettings = SettingsFeaturesBase & {
    isApprovedUser: boolean;
    isAdminUser: boolean;
};

export interface SettingsFeaturesBase {
    features: Feature[];
}

export interface Feature {
    name: string;
    isEnabled: boolean;
}

export enum WorkspaceStatus {
    Active = 0,
    Archived = 1,
    Deprecated = 2,
}

export interface IntercomUser {
    userHash: string;
    userIdHash: string;
    /** @format int64 */
    createdAtUnixTimeSeconds: number;
}

export interface WebOnboardingResponse {
    tours: TourResponse[];
    isFirstTimeUser: boolean;
    hasAcceptedTermsService: boolean;
    hasAcceptedCookies?: boolean | null;
}

export interface TourResponse {
    name: string;
    isComplete: boolean;
}

export type IsLoggedInResponse = UserResult & {
    isLoggedIn: boolean;
    isTrustedDevice?: boolean | null;
};

export interface UserResult {
    accuRxUserId: string;
    analyticsUserIdentifier: string;
    email: string;
    fullName: string;
}

export interface LoginRequest {
    /** @minLength 1 */
    userName: string;
    /** @minLength 1 */
    password: string;
    product?: RegisterAccountProductType | null;
}

export enum RegisterAccountProductType {
    None = 0,
    Chain = 1,
    Fleming = 2,
    MobileApp = 3,
}

export interface RegisterResult {
    registered: boolean;
    emailSent: boolean;
    failureType?: RegisterFailureType | null;
    organisationExternalId?: string | null;
}

export enum RegisterFailureType {
    IdentityPasswordTooShort = 0,
    IdentityPasswordRequiresUniqueChars = 1,
    IdentityPasswordRequiresNonAlphanumeric = 2,
    IdentityPasswordRequiresDigit = 3,
    IdentityPasswordRequiresLower = 4,
    IdentityPasswordRequiresUpper = 5,
    PasswordDoesNotMatch = 6,
    EmailDoesNotMatch = 7,
    EmailNotAllowed = 8,
    NoFeedback = 9,
    EmailWithOrganisationIntegration = 10,
}

export interface RegisterRequest {
    /**
     * @minLength 2
     * @maxLength 2147483647
     */
    name: string;
    email: string;
    confirmEmail: string;
    /**
     * @minLength 8
     * @maxLength 2147483647
     * @pattern (?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*$
     */
    password: string;
    /**
     * @minLength 8
     * @maxLength 2147483647
     */
    confirmPassword: string;
    referralSource?: string | null;
    product?: RegisterAccountProductType | null;
    utmParameters?: UtmParameters | null;
    hasAcceptedTermsService?: boolean | null;
}

export interface UtmParameters {
    utmSource?: string | null;
    utmMedium?: string | null;
    utmCampaign?: string | null;
    utmTerm?: string | null;
    utmContent?: string | null;
    externalReferrer?: string | null;
    next?: string | null;
    userSource?: string | null;
}

export type LoginResult = UserResult & object;

export interface LoginWithPasswordlessRequest {
    email: string;
    /** @minLength 1 */
    passwordlessToken: string;
}

export interface RegisterOrRequestPasswordlessRequest {
    /** @format email */
    email: string;
    product?: ProductType | null;
}

export enum ProductType {
    None = 0,
    Chain = 1,
    Updater = 2,
}

export enum AccountProductType {
    None = 0,
    Chain = 1,
    Fleming = 2,
    MobileApp = 3,
}

export interface ForgotPasswordViewModel {
    /**
     * @format email
     * @minLength 1
     */
    email: string;
    product?: AccountProductType | null;
    utmParameters?: UtmParameters | null;
}

export interface ResetPasswordResponse {
    UserIdValid?: boolean | null;
    CodeValid?: boolean | null;
}

export interface ResetPasswordResponseV2 {
    userIdValid?: boolean | null;
    codeValid?: boolean | null;
}

export interface ResetPasswordViewModel {
    userId: string;
    code: string;
    product?: AccountProductType | null;
    /**
     * @minLength 8
     * @maxLength 100
     */
    password: string;
    /** Confirm password */
    confirmPassword: string;
}

export interface ChangePasswordViewModel {
    /**
     * Current password
     * @minLength 1
     */
    oldPassword: string;
    /**
     * New password
     * @minLength 8
     * @maxLength 100
     */
    newPassword: string;
    /** Confirm new password */
    confirmPassword: string;
}

export interface ResendModel {
    /** @minLength 1 */
    userId: string;
    product?: RegisterAccountProductType | null;
}

export interface ConfirmEmailLinkViewModel {
    /** @minLength 1 */
    userId: string;
    /** @minLength 1 */
    code: string;
    product?: AccountProductType | null;
}

export interface HeartBeatData {
    cookieRefreshTimeSpan: TimeSpanDto;
}

export interface TimeSpanDto {
    /** @format int64 */
    ticks?: number | null;
}

export interface MagicLinkResponse {
    EmailConfirmed?: boolean | null;
    TokenValid?: boolean | null;
    LaunchUrl?: string | null;
}

export interface MagicLinkResponseV2 {
    emailConfirmed?: boolean | null;
    tokenValid?: boolean | null;
    launchUrl?: string | null;
}

export interface IndexViewModel {
    /** Full name */
    fullName?: string | null;
    isEmailConfirmed?: boolean | null;
    /**
     * @format email
     * @minLength 1
     */
    email: string;
}

export interface SetMessageSignatureViewModel {
    /**
     * Signature
     * @minLength 0
     * @maxLength 40
     */
    signature?: string | null;
}

export interface SendUserEmailRequest {
    /** @minLength 1 */
    email: string;
    resendType?: ResendType | null;
    product?: RegisterAccountProductType | null;
}

export enum ResendType {
    ConfirmationEmail = 0,
    Password = 1,
    MagicLink = 2,
}

export interface TwoFactorResponse {
    obfuscatedPhoneNumber?: string | null;
    obfuscatedSecondaryEmail?: string | null;
}

export interface VerifyTwoFactorRequest {
    accessCode?: string | null;
    rememberTwoFactor?: boolean | null;
}

export interface UserPhoneNumberUpdate {
    mobileNumber?: string | null;
}

export interface UserSecondaryEmailUpdate {
    secondaryEmail?: string | null;
}

export interface UserCookiesRequest {
    hasAcceptedCookies: boolean;
}

export interface UserProfileResponse {
    organisationResponse?: UserProfileOrganisationResponse | null;
}

export interface UserProfileOrganisationResponse {
    /** @format int32 */
    organisationId: number;
    organisationName: string;
    organisations: Organisation[];
}

export interface UpdateUserProfileRequest {
    healthcareRole?: string | null;
    specialistArea?: string | null;
    hasAcceptedTermsService?: boolean | null;
    nationalCode?: string | null;
}

export interface HealthcareProfilesOptionsResponse {
    healthcareRoles: string[];
    specialistAreas: string[];
}

export interface ExampleWorkspace {
    name: string;
    specialties: Specialty[];
    isRecommended: boolean;
}

export interface Specialty {
    /** @format int32 */
    id: number;
    name: string;
}

export interface CollaborativeWorkspaceCreationResponse {
    /** @format int32 */
    workspaceId?: number | null;
    workspaceName?: string | null;
    description?: string | null;
    failedWorkspaceCreation?: FailedWorkspaceCreation | null;
    workspace: Organisation;
}

export enum FailedWorkspaceCreation {
    InvalidRequest = 0,
    WorkspaceNameDuplicate = 1,
}

export interface CollaborativeWorkspaceCreationRequest {
    /** @format int32 */
    organisationId?: number | null;
    workspaceName: string;
    description?: string | null;
    specialties?: number[] | null;
    isExample?: boolean | null;
    isRecommended?: boolean | null;
}

export interface CollaborativeWorkspaceUpdateRequest {
    name: string;
    description: string;
    specialties?: number[] | null;
}

export interface CollaborativeWorkspaceResponse {
    /** @format int32 */
    id: number;
    name: string;
    description?: string | null;
    users: BasicUserResponse[];
    specialties: string[];
    isRecommended: boolean;
}

export interface BasicUserResponse {
    /** @format int32 */
    id: number;
    applicationUserId: string;
    email: string;
    fullName?: string | null;
    status: BasicUserStatus;
}

export enum BasicUserStatus {
    Unapproved = 0,
    Approved = 1,
    Archived = 2,
    Deleted = 3,
}

export interface WorkspaceCreationResponse {
    workspace: Organisation;
}

export interface WorkspaceCreationRequest {
    nationalCode: string;
    name: string;
    description?: string | null;
    specialities?: number[] | null;
    isExample?: boolean | null;
    isRecommended?: boolean | null;
}

export interface AllWorkspacesInvitesResponse {
    invites: AllWorkspacesInviteResponse[];
}

export interface AllWorkspacesInviteResponse {
    /** @format int32 */
    workspaceInviteId: number;
    invitedByUser: string;
    workspace: WorkspaceInviteWorkspace;
}

export interface WorkspaceInviteWorkspace {
    /** @format int32 */
    id: number;
    name: string;
    organisationNodeName: string;
}

export interface UpdateWorkspaceInviteRequest {
    /** @format int32 */
    workspaceId: number;
}

export interface NhsOrganisationsResponse {
    name: string;
    nationalCode: string;
    supportsMultipleWorkspaces: boolean;
}

export interface AllowedOrganisationsResponse {
    nationalCode: string;
    organisationName: string;
    postCode: string;
    isSuggested: boolean;
}

export interface NhsOrganisationWorkspaces {
    workspaces: CollaborativeWorkspaceResponse[];
}

export interface NhsOrganisationExampleWorkspaces {
    exampleWorkspaces: ExampleWorkspace[];
}

export interface NhsPharmaciesResponse {
    nhsPharmacies: NhsPharmacy[];
    /** @format int32 */
    totalCount: number;
    /** @format int32 */
    totalPages: number;
    appliedSearchTerm: string;
    /** @format int32 */
    appliedLimit: number;
    /** @format int32 */
    currentPage: number;
}

export interface NhsPharmacy {
    id: string;
    name: string;
    address: Address;
    odsCode: string;
    hasMembers: boolean;
}

export interface Address {
    line1: string;
    line2?: string | null;
    town?: string | null;
    county?: string | null;
    postCode: string;
    country?: string | null;
}

export interface JoinOrganisationFormRequest {
    organisationName?: string | null;
    organisationPostCode?: string | null;
    organisationType?: string | null;
    reasonForAccess?: string | null;
    ssoOdsCode?: string | null;
}

export interface IdentityRegisterRequest {
    /**
     * @format email
     * @minLength 1
     */
    email: string;
    /** @minLength 1 */
    password: string;
    username: string;
}

export interface IdentityIsRegisteredResult {
    loginType: string;
    hasConfirmedEmail: boolean;
}

export interface IdentityAccessTokenResponse {
    tokenType: string;
    accessToken: string;
    /** @format int64 */
    expiresIn: number;
    refreshToken: string;
}

export interface IdentityLoginRequest {
    /**
     * @format email
     * @minLength 1
     */
    email: string;
    /** @minLength 1 */
    password: string;
}
