import { useCallback, useEffect } from "react";

import {
    Conversation,
    ConversationItem,
    PatientSummary,
} from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { useSaveToRecordMutation } from "domains/inbox/hooks/mutations/useSaveToRecordMutation";
import { toast } from "react-toastify";

export type SaveToRecordActionProps = {
    patientId: PatientSummary["patientId"];
    patientExternalIds: PatientSummary["externalIds"];
    onSaveToRecordInitiated?: () => void;
    itemServerId: ConversationItem["serverId"];
    contentType: ConversationItem["contentType"];
    conversationSource: Conversation["source"];
    snomedCodeIds?: string[];
};

export const SaveToRecordAction = ({
    patientId,
    patientExternalIds,
    onSaveToRecordInitiated,
    itemServerId,
    contentType,
    conversationSource,
    snomedCodeIds,
}: SaveToRecordActionProps) => {
    const saveToRecord = useSaveToRecordMutation({
        itemServerId,
    });

    const initateSaveToRecord = useCallback(async () => {
        if (
            contentType !== "PatientSms" &&
            contentType !== "PatientEmail" &&
            contentType !== "NhsAppMessage"
        ) {
            return;
        }

        try {
            await saveToRecord.mutateAsync({
                conversationSource,
                contentType,
                createAttachments: false,
                patientId,
                patientExternalIds,
                snomedCodes: snomedCodeIds ?? [],
            });
        } catch (e) {
            toast(
                <UI.Feedback
                    title="Could not save message to record"
                    colour="error"
                >
                    <UI.Text skinny>
                        Please try saving to record from the sent message
                    </UI.Text>
                </UI.Feedback>,
            );
        }
    }, [
        contentType,
        conversationSource,
        patientId,
        patientExternalIds,
        snomedCodeIds,
        saveToRecord,
    ]);

    useEffect(() => {
        if (saveToRecord.isIdle) {
            void initateSaveToRecord();
            onSaveToRecordInitiated?.();
        }
    }, [saveToRecord.isIdle, onSaveToRecordInitiated, initateSaveToRecord]);

    return (
        <div
            // Only adding inline style so it's easy to test
            style={{ display: "none" }}
            data-testid={`compose-save-to-record-${saveToRecord.status}`}
        />
    );
};
