import { useFeatureFlag } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { PatientName } from "domains/inbox/components/PatientName/PatientName";
import { formatDateShortWithHyphens } from "domains/inbox/util/format/date";
import { formatPatientName } from "domains/inbox/util/format/patientDisplayInfo";
import { getPatientMatchState } from "domains/inbox/util/getPatientMatchState";

import { PatientMatchingActions } from "../PatientHeader/PatientMatchingActions";
import {
    StyledConversationActionsFeedback,
    StyledFeedback,
    StyledFeedbackContent,
    StyledPatientDetails,
} from "./ConversationActionsFeedback.styles";

export const PatientConversationActionsFeedback = ({
    conversation,
    onClickSearchForPatient,
}: {
    conversation: Conversation;
    onClickSearchForPatient: () => void;
}) => {
    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const patientMatchState = getPatientMatchState(conversation);
    const isPatientMessagingDisabled = useFeatureFlag(
        "DisableSingleSendSmsAndVideoInvites",
    );

    if (conversation.status === "Done") {
        return (
            <StyledConversationActionsFeedback>
                <StyledFeedback
                    colour="information"
                    title={
                        "To reply or change assignee, reopen the conversation"
                    }
                />
            </StyledConversationActionsFeedback>
        );
    }

    if (!patient && patientMatchState?.type === "NoMatch") {
        return (
            <StyledConversationActionsFeedback>
                <StyledFeedback
                    colour="warning"
                    title={
                        "For more message actions, search for a patient to match to this conversation"
                    }
                >
                    <StyledFeedbackContent>
                        <PatientMatchingActions
                            patientMatchState={patientMatchState}
                            conversation={conversation}
                            onClickSearchForPatient={onClickSearchForPatient}
                        />
                    </StyledFeedbackContent>
                </StyledFeedback>
            </StyledConversationActionsFeedback>
        );
    }

    if (!patient && patientMatchState?.type === "SuggestedMatch") {
        const suggestedPatient = patientMatchState.suggestedMatch;

        const patientName = formatPatientName({
            firstName: suggestedPatient.firstName,
            familyName: suggestedPatient.familyName,
        });

        const nhsNumber = suggestedPatient.externalIds.find(
            (externalId) => externalId.type === "NhsNumber",
        )?.value;

        const dateOfBirth = suggestedPatient.dateOfBirth;

        return (
            <StyledConversationActionsFeedback>
                <StyledFeedback
                    colour="warning"
                    title={
                        <span>
                            For more conversation actions, please check and
                            confirm the suggested patient match.{" "}
                            <UI.Link
                                href="https://support.accurx.com/en/articles/4247693-patient-triage-how-does-patient-identification-work"
                                openInNewTab
                            >
                                <UI.Link.Text text="How we suggest patients" />
                                <UI.Link.Icon />
                            </UI.Link>
                        </span>
                    }
                >
                    <StyledPatientDetails>
                        <PatientName
                            name={patientName}
                            ageYear={suggestedPatient.ageYear}
                            gender={suggestedPatient.gender}
                        />
                        <UI.Flex gap="0.5">
                            {nhsNumber && (
                                <>
                                    <UI.Text skinny colour="night">
                                        {`NHS: ${formatNhsNumber(nhsNumber)}`}
                                    </UI.Text>
                                    &middot;
                                </>
                            )}
                            {!!dateOfBirth && (
                                <UI.Text skinny colour="night">
                                    {`DOB: ${formatDateShortWithHyphens(
                                        dateOfBirth,
                                    )}`}
                                </UI.Text>
                            )}
                        </UI.Flex>
                    </StyledPatientDetails>
                    <StyledFeedbackContent>
                        <PatientMatchingActions
                            patientMatchState={patientMatchState}
                            conversation={conversation}
                            onClickSearchForPatient={onClickSearchForPatient}
                        />
                    </StyledFeedbackContent>
                </StyledFeedback>
            </StyledConversationActionsFeedback>
        );
    }

    if (isPatientMessagingDisabled) {
        return (
            <StyledConversationActionsFeedback>
                <StyledFeedback
                    colour="information"
                    title={
                        <span>
                            Patient messaging isn’t available as your
                            organisation has exceeded its monthly limit. Limits
                            reset on the first day of each month.{" "}
                            <UI.Link
                                href="https://support.accurx.com/en/articles/9929962-accurx-lite-limits-for-community-and-mental-health-trusts"
                                openInNewTab
                            >
                                <UI.Link.Text text="Learn more" />
                                <UI.Link.Icon />
                            </UI.Link>
                        </span>
                    }
                />
            </StyledConversationActionsFeedback>
        );
    }

    // Conversation is Open and is matched to a patient so we show nothing here.
    return null;
};
