import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
} from "@accurx/auth";

export const PatientTriageFeatureFlags = [
    FeatureName.PatientInitiatedInbox,
    FeatureName.AccurxPlusPatientTriage,
    FeatureName.PatientTriageSolicitedAppointmentBooking,
    FeatureName.PatientTriageSolicitedAppointmentBookingSendLink,
    FeatureName.PatientTriageReceptionFlow,
    FeatureName.PatientTriageDashboards,
];

export const isPatientTriageEnabled = (workspace: Organisation) => {
    return PatientTriageFeatureFlags.every((featureFlag) => {
        return isFeatureEnabledForWorkspace(featureFlag, workspace);
    });
};
