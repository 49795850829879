import { mapUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapUserGroupToTeamSummary";
import { SubscriptionParams } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { TeamSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

import { Event, SocketEvents } from "shared/hubClient/HubClient";

const mapToTeamSummary = (
    update: Event<"OnUserGroupChanged">,
    currentUserId: string,
): TeamSummary | undefined => {
    if (isNil(update.payload.organisationId)) {
        return undefined;
    }

    if (isNil(update.payload.userMembership)) {
        return undefined;
    }

    const isMember = !!update.payload.userMembership.find(
        (membership) => membership.userId === currentUserId,
    )?.userHasConfirmedMemberShip;

    return mapUserGroupToTeamSummary({
        id: update.payload.userGroup?.id,
        name: update.payload.userGroup?.name,
        type: update.payload.userGroup?.groupType,
        isMember,
    });
};

export const OnUserGroupChanged = ({
    transport,
    workspaceId,
    currentUserId,
    onUserGroupChanged,
}: Pick<SubscriptionParams, "transport" | "workspaceId" | "currentUserId"> & {
    onUserGroupChanged: (teamUpdate: TeamSummary) => void;
}) => {
    const subscription = transport.subscribe({
        methodName: SocketEvents.OnUserGroupChanged,
        eventFilter: (event) => event.payload.organisationId === workspaceId,
        onEvent: (event) => {
            const teamUpdate = mapToTeamSummary(event, currentUserId);
            if (!teamUpdate) return;

            onUserGroupChanged(teamUpdate);
        },
    });

    return subscription.unsubscribe.bind(subscription);
};
