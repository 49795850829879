import React, { createContext, useState } from "react";

import noop from "lodash/noop";

import { IAllowedNhsOrganisationResponse } from "api/FlemingDtos";

type allowedOrganisationValue = {
    allowedOrganisation: IAllowedNhsOrganisationResponse | null;
    setAllowedOrganisation: (v: IAllowedNhsOrganisationResponse | null) => void;
};

const initialState: allowedOrganisationValue = {
    allowedOrganisation: null,
    setAllowedOrganisation: noop,
};

export const SelectedAllowedOrganisationContext =
    createContext<allowedOrganisationValue>(initialState);

export const SelectedAllowedOrganisationProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [allowedOrganisation, setAllowedOrganisation] =
        useState<IAllowedNhsOrganisationResponse | null>(null);

    return (
        <SelectedAllowedOrganisationContext.Provider
            value={{
                allowedOrganisation,
                setAllowedOrganisation,
            }}
        >
            {children}
        </SelectedAllowedOrganisationContext.Provider>
    );
};
