import { useConversationGroupGarbageCollection } from "../hooks/useConversationGroupGarbageCollection";
import { useStateReconciler } from "../hooks/useStateReconciler";

/**
 * - Recalculate periodically unread counts and conversation groups
 * - Clean stale conversation groups
 */
export const ConciergeUnreadsAndConversationGroupCountCleanupInitializer =
    () => {
        // Periodic recalculation of unread counts & conversation groups
        useStateReconciler();
        // Clean stale conversation groups
        useConversationGroupGarbageCollection();
        return null;
    };
