import { OnPatientThreadItemChanged } from "../api/ticket/subscriptions/OnPatientThreadItemChanged";
import { useSignalRSubscription } from "../hooks/useSignalRSubscription";

/**
 * Listens to live updates to a conversation item and updates the store
 */
export const ConciergeConversationItemUpdatesInitializer = () => {
    useSignalRSubscription(OnPatientThreadItemChanged);
    return null;
};
