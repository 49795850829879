import { ReactNode, createContext, useState } from "react";

import { ReplyState } from "../ConversationView.types";

export type ComposeAreaVisibilityContextType = {
    replyState: ReplyState;
    noteState: ReplyState;
    setReplyState: (state: ReplyState) => void;
    setNoteState: (state: ReplyState) => void;
};

export const ComposeAreaVisibilityContext =
    createContext<ComposeAreaVisibilityContextType | null>(null);

/**
 * This provider keeps track of if the Reply and Note areas are Visible, Open,
 * Opening or minimised. This is stored in a provider since the visibility
 * states of the components can be set and read at various levels.
 */
export const ComposeAreaVisibilityProvider = ({
    replyState: initialReplyState = "Closed",
    noteState: initialNoteState = "Closed",
    children,
}: {
    replyState?: ReplyState;
    noteState?: ReplyState;
    children: ReactNode;
}) => {
    const [replyState, setReplyState] = useState<ReplyState>(initialReplyState);
    const [noteState, setNoteState] = useState<ReplyState>(initialNoteState);

    return (
        <ComposeAreaVisibilityContext.Provider
            value={{
                replyState,
                noteState,
                setReplyState,
                setNoteState,
            }}
        >
            {children}
        </ComposeAreaVisibilityContext.Provider>
    );
};
