import { useMemo } from "react";

import { ContactDetail } from "@accurx/compose";
import { Conversation, PatientSummary } from "@accurx/concierge/types";
import { useBrowserEnvironment } from "@accurx/native";
import { MobileNumberHelper } from "@accurx/shared";
import { getContactDetailsFromConversation } from "domains/inbox/components/ConversationView/components/MessageActionsNew/util/reply.utils";
import { usePatientDemographicsQuery } from "domains/inbox/hooks/queries/usePatientDemographicsQuery";
import { useSearchForPatientQuery } from "domains/inbox/hooks/queries/useSearchForPatientQuery";
import { PatientDemographicsDto } from "domains/inbox/schemas/PatientDemographicsDtoSchema";
import { SearchForPatientSuccess } from "domains/inbox/schemas/SearchForPatientSchema";
import uniqBy from "lodash/uniqBy";

const emrDataToContactDetails = (
    emrData: PatientDemographicsDto["patientDemographics"],
): ContactDetail[] => {
    const contactDetails: ContactDetail[] = [];

    const mobileNumber = emrData.contact.mobileTelephone;

    const isValidMobileNumber = MobileNumberHelper.isValidMobileNumber(
        mobileNumber ?? undefined,
    );

    if (mobileNumber && isValidMobileNumber) {
        contactDetails.push({
            method: "Mobile",
            value: mobileNumber,
            origin: "EMR",
        });
    }

    if (emrData.contact.email) {
        contactDetails.push({
            method: "Email",
            value: emrData.contact.email,
            origin: "EMR",
        });
    }

    return contactDetails;
};

const pdsDataToContactDetails = (
    pdsData: SearchForPatientSuccess,
): ContactDetail[] => {
    if (!pdsData.searchedResult.patient?.mobileNumber) {
        return [];
    }

    // We don't validate mobile numbers from PDS. This is because the server obfuscates them before returning
    // E.g. ********645 - which would always fail validation.
    return [
        {
            method: "Mobile",
            value: pdsData.searchedResult.patient.mobileNumber,
            origin: "PDS",
        },
    ];
};

/** This hook will return patient contact details from either the medical record or a PDS search,
 * If there is a medical record connection, we'll query the the medical record. If there is not a
 * medical record connection, we'll do a PDS search.
 *
 * It will also return contact details from the provided conversation.
 */
export const usePatientContactDetails = ({
    patient,
    conversation,
    enabled = true,
}: {
    patient: PatientSummary;
    conversation: Conversation;
    enabled?: boolean;
}) => {
    const browserEnv = useBrowserEnvironment();
    const shouldFetchDemographicsFromMedicalRecord = browserEnv === "WebView";

    const contactDetailsFromConversation = useMemo(
        () => getContactDetailsFromConversation(conversation),
        [conversation],
    );

    const { data: emrData, status: emrStatus } = usePatientDemographicsQuery<
        ContactDetail[]
    >({
        patientId: patient.patientId,
        externalIds: patient.externalIds,
        enabled: shouldFetchDemographicsFromMedicalRecord && enabled,
        select: emrDataToContactDetails,
    });

    const { data: pdsData, status: pdsStatus } = useSearchForPatientQuery<
        ContactDetail[]
    >({
        patient,
        enabled: !shouldFetchDemographicsFromMedicalRecord && enabled,
        select: pdsDataToContactDetails,
    });

    const uniqueDetailsFromConversation = uniqBy(
        contactDetailsFromConversation,
        "value",
    );

    if (shouldFetchDemographicsFromMedicalRecord) {
        return {
            data: [...uniqueDetailsFromConversation, ...(emrData ?? [])],
            status: emrStatus,
        };
    }

    return {
        data: [...uniqueDetailsFromConversation, ...(pdsData ?? [])],
        status: pdsStatus,
    };
};
