import React, { useEffect, useMemo } from "react";

import { BatchMessageItemSummary } from "@accurx/api/patient-messaging";
import { FeatureName } from "@accurx/auth";
import { Feedback, Text } from "@accurx/design";
import { useAccurxWebTitle, useIsManageOrg } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";

import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import {
    createBeastInvites,
    resetState,
    sendMessage,
} from "app/batchMessage/gp/BatchMessage.actions";
import { StyledLayoutWithFooter } from "app/batchMessage/gp/BatchMessage.styles";
import {
    BatchType,
    CreateBeastInvitesParameters,
    SendAt,
} from "app/batchMessage/gp/BatchMessage.types";
import {
    BatchRoute,
    findBatchRoute,
    getSelfBookLinkExpiryDays,
} from "app/batchMessage/gp/BatchMessage.utils";
import { BatchMessageHeader } from "app/batchMessage/gp/components/BatchMessageHeader";
import { prepareSendRequest } from "app/batchMessage/gp/components/review/BatchMessageReview.utils";
import { BatchMessageReviewTable } from "app/batchMessage/gp/components/review/BatchMessageReviewTable";
import { useTrackBatchPatientMessageSendClick } from "app/batchMessage/gp/components/review/trackBatchPatientMessageSendClick";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { ROUTES_ORGS, ROUTES_WORKSPACE } from "shared/Routes";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

export const BatchMessageReview = (): JSX.Element => {
    useAccurxWebTitle("Review batch message details");

    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const isManageOrg = useIsManageOrg();

    // Feature flags
    const canUseEmisGPSoCServer = useIsFeatureEnabled(
        FeatureName.EmisGPSoCServer,
    );
    const batchSelfBookEnabled = useIsFeatureEnabled(FeatureName.BatchSelfBook);
    const waitingListValidationEnabled = useIsFeatureEnabled(
        FeatureName.WebWaitingListValidation,
    );
    const selfBook7DaysExpiryLinksEnabled = useIsFeatureEnabled(
        FeatureName.SelfBook7DaysExpiryLinks,
    );

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const {
        selectedTemplateName,
        patientMessage,
        selectedFloreySnomedCode,
        selectedTemplateSnomedCode,
        uploadedFileName,
        batchMessageId,
        selectedFloreyId,
        uploadedFileAttachmentId,
        sendAt,
        staggerOverDays,
        selectedFloreySaveToRecord,
        selectedBatchOptionSaveToRecord,
        selectedTemplateOwner,
        selectedFloreyName,
        selectedTemplateCanReply,
        selectedBeastSeries,
        selectedSlotType,
        declineSettings,
        selectedTemplateId,
        isFileAttachmentFromTemplate,
    } = useAppSelector(({ batchMessage }) => batchMessage);

    const isDeclineSettingsAvailable = Boolean(
        declineSettings?.confirmationText && declineSettings?.snomedConceptId,
    );

    const shouldSaveBatchToRecord = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedBatchOptionSaveToRecord,
    );

    const batchType = useAppSelector(
        ({ batchMessage }) => batchMessage.batchType,
    );

    const patientList = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageDetails.items,
        shallowEqual,
    );
    const selectedAssigneeUserGroupId = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedAssigneeUserGroupId, //the group chosen by the user for florey responses to come into
    );
    const selectedFloreyDefaultAssigneeId = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreyDefaultAssigneeId, //the default assigned group for this florey
    );

    const getSelectedSnomedCode = (): string | null => {
        if (selectedFloreySnomedCode !== "") {
            return selectedFloreySnomedCode;
        }
        if (selectedTemplateSnomedCode !== "") {
            return selectedTemplateSnomedCode;
        }
        return null;
    };

    const getSelectedAssigneeUserGroupId = (): string | null => {
        if (selectedAssigneeUserGroupId !== "") {
            return selectedAssigneeUserGroupId;
        }
        return null;
    };

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchMessageReviewPageView({
            ...analyticsLoggedInProps,
            slotName: selectedSlotType.type,
            batchSlotAvailable: selectedSlotType.availability,
            batchType: batchType,
        });
    }, [analyticsLoggedInProps, selectedSlotType, batchType]);

    const { trackBatchPatientMessageSendClick } =
        useTrackBatchPatientMessageSendClick();

    const handleSendMessage = () => {
        const request = prepareSendRequest({
            orgId: practiceId,
            batchId: batchMessageId,
            messageTemplate: patientMessage,
            snomedCode: getSelectedSnomedCode(),
            batchType,
            selectedFloreyId,
            uploadedFileAttachmentId,
            sendAt: sendAt as SendAt,
            staggerOverDays: staggerOverDays as number,
            assignedUserGroupId: getSelectedAssigneeUserGroupId(),
            selectedFloreySaveToRecord,
            batchSelfBookEnabled,
            selectedSlotType,
            isDeclineSettingsAvailable,
            declineSettings,
            shouldSaveBatchToRecord: selectedBatchOptionSaveToRecord,
            selfBookTimeoutDays: getSelfBookLinkExpiryDays(
                selfBook7DaysExpiryLinksEnabled,
            ),
            selectedTemplateId,
            isFileAttachmentFromTemplate,
        });
        dispatch(sendMessage(request));

        const messageTemplateDescription =
            selectedTemplateOwner !== "" && selectedTemplateName !== ""
                ? `${selectedTemplateOwner} Template - ${selectedTemplateName}`
                : "";
        const patientsSentTo = patientList.filter(
            (item: BatchMessageItemSummary) => item.isTextable,
        ).length;
        const analyticsProps: ChainAnalyticsTracker.BatchMessageSentProps = {
            ...analyticsLoggedInProps,
            batchId: batchMessageId,
            batchType: AnalyticsMapper.getBatchType(batchType),
            snomedCode: getSelectedSnomedCode() || "",
            floreyId: selectedFloreyId,
            floreyName: selectedFloreyName,
            messageTemplateDescription: messageTemplateDescription,
            patientResponseOnTemplate: selectedTemplateCanReply,
            withDocument: !!uploadedFileName,
            patientsSentTo: patientsSentTo,
            autoSaveEnabled: selectedFloreySaveToRecord,
            selfBookSlotType: request.slotType ?? "",
            appointmentsAvailableWhenSent: selectedSlotType?.availability,
            changedDefaultAssignee:
                batchType === BatchType.FLOREY &&
                selectedFloreyDefaultAssigneeId !== selectedAssigneeUserGroupId,
        };

        ChainAnalyticsTracker.trackBatchMessageSent(analyticsProps);

        trackBatchPatientMessageSendClick({
            snomedCode: getSelectedSnomedCode(),
            countTotal: patientsSentTo,
            isTrustFlow: false,
        });

        // Resetting batch messaging data.
        // This is to stop users from sending a batch message then clicking the back button and resubmitting.
        dispatch(resetState());
    };

    const handleCreateBeastInvites = () => {
        const request: CreateBeastInvitesParameters = {
            practiceId: practiceId,
            batchMessageId: batchMessageId,
            beastSeriesId: selectedBeastSeries?.id ?? "",
        };
        dispatch(createBeastInvites(request));
        const analyticsProps: ChainAnalyticsTracker.BatchBeastInvitesCreatedProps =
            {
                ...analyticsLoggedInProps,
                beastSeriesId: selectedBeastSeries?.id ?? "",
                beastSeriesName: selectedBeastSeries?.name ?? "",
                patientsSentTo: patientList.filter(
                    (item: BatchMessageItemSummary) => item.isTextable,
                ).length,
            };
        ChainAnalyticsTracker.trackBatchBeastInvitesCreated(analyticsProps);
    };

    const submitButtonCopy = useMemo(() => {
        if (sendAt) {
            return "Confirm and save";
        }

        return "Send message";
    }, [sendAt]);

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: true,
        });
    };

    const manageOrgBatchRoute = generatePath(ROUTES_ORGS.allMessages, {
        orgId: practiceId,
        tab: "batch-messages",
    });
    const accurxWebBatchRoute = generatePath(
        ROUTES_WORKSPACE.batchMessageTrustSentMessages,
        { workspaceId: practiceId },
    );

    const routes = {
        back: findBatchRoute(BatchRoute.REVIEW, batchType).back,
        forward: isManageOrg ? manageOrgBatchRoute : accurxWebBatchRoute,
    };

    return (
        <StyledLayoutWithFooter>
            <Breadcrumb title="Batch Messaging" />
            <div className="row mb-4">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <BatchMessageHeader title={"Review message"} />
                    <BatchMessageReviewTable />
                    {canUseEmisGPSoCServer === false &&
                        !selectedBeastSeries &&
                        waitingListValidationEnabled === false &&
                        shouldSaveBatchToRecord && (
                            <div className="mt-4">
                                <Feedback
                                    data-testid="saveToRecordFeedback"
                                    colour="information"
                                    title="Saving to Record"
                                >
                                    <Text skinny>
                                        Keep your clinical system and Accurx
                                        toolbar open to allow saving the message
                                        to record.
                                    </Text>
                                </Feedback>
                            </div>
                        )}
                </div>
                <StepsFooter
                    backText={"Back"}
                    backLink={routes.back}
                    backClickFunction={handleBack}
                    forwardText={submitButtonCopy}
                    forwardLink={routes.forward}
                    forwardClickFunction={
                        selectedBeastSeries
                            ? handleCreateBeastInvites
                            : handleSendMessage
                    }
                    disabled={false}
                />
            </div>
        </StyledLayoutWithFooter>
    );
};

export default BatchMessageReview;
