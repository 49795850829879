import { FeatureName } from "@accurx/auth";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

export const useIsGpBatchEnabled = () => {
    const isGskFloreyEnabled = useFeatureFlag(FeatureName.BatchGskFlorey);
    const isFloreyEnabled = useFeatureFlag(FeatureName.BatchMessagingFlorey);
    const isSelfBookEnabled = useFeatureFlag(FeatureName.BatchSelfBook);

    return isGskFloreyEnabled || isFloreyEnabled || isSelfBookEnabled;
};
