// Will be removed once we finish releasing web inbox
// eslint-disable-next-line no-restricted-imports
import { ROUTES_INBOX } from "@accurx/inbox/routes";
import { WORKSPACE_MANAGEMENT_ROUTES } from "@accurx/workspace-management";
import { Location } from "history";

export type Route =
    | (typeof ROUTES)[keyof typeof ROUTES]
    | (typeof ROUTES_BASE)[keyof typeof ROUTES_BASE]
    | (typeof ROUTES_PRIMARY)[keyof typeof ROUTES_PRIMARY];

export const ROUTES = {
    postSsoRedirect: "/post-sso-redirect",
    postlogin: "/postlogin",
    terms_and_conditions: "/terms-and-conditions",
    delivery_receipts: "/delivery-receipts",
    home: "/",
    joinOrganisation: "/join-organisation",
    addOrganisation: "/add-organisation",
    joinPharmacy: "/join-pharmacy",
    joinWorkspace: "/join-workspace",
    createWorkspace: "/create-workspace",
    get_started: "/get-started",
    message_practice: "/message-practice",
    message_templates: "/message-templates",
    patient_lists: "/patient-lists",
    patient_message_history: "/message-history",
    patient_conversations: "/conversations/patient",
    conversation: "/conversation/:conversationId",
    patientsList: "/patients/lists/:patientListId",

    // Public links
    register: "/register",
    checkYourInbox: "/check-your-inbox",
    confirmEmailVerify: "/confirm-email-verify",
    downloadDesktop: "/download-desktop",
    forgotPassword: "/forgot-password",
    magicLink: "/magic-link",
    resetPassword: "/reset-password",
    resetPasswordConfirm: "/reset-password-confirm",
    login: "/login",
    loginOrganisations: "/login/organisations",
    loginRecordView: "/login/record-view",
    loginActiveDirectory: "/login/:trustId(uhl)",

    // record view
    /**
     * @deprecated Please use `ROUTES_WORKSPACE.remoteRecordView` instead
     */
    record_view_request_medical_record: "/remote-record-view",
    record_view_medical_record: "/remote-record-view/medical-record",
    record_view_medical_record_test_patient:
        "/remote-record-view/medical-record?request=example",
    record_view_medical_record_requests: "/medical-record-requests",

    send_message: "/send-message",
    sso_failed: "/sso-failed",
    noOdsCode: "/no-ods-code",
    unableToLookupOds: "/unable-to-lookup-ods",
    two_factor_auth: "/two-factor-auth",
    userSignoutConfirm: "/Account/SignOutEverywhereConfirmation", // BE redirect to this route
    video_confirmation: "/video-confirmation",
    reply_from_web: "/clinician-conversation/:urlId",
    message_gp: "/clinician-conversation",
    message_gp_test: "/clinician-conversation-test",

    // Workspace settings
    workspaceInvitation: "/workspace-invitation",

    //custom florey imports
    importChooseOrg: "/import/:shareId",
    confirmChangeOrg: "/import/:shareId/switching-org",

    inbox: "/inbox/w/:workspaceId",
} as const;

// ROUTES_PRIMARY entries do not route to any pages and are only used for finding the base route of each path
export const ROUTES_PRIMARY = {
    patients: "/patients",
    workspaceConversations: "/w/:workspaceId/conversations",
    workspacePatientProfile: "/w/:workspaceId/:path*/patient-profile",
    workspaceSettings: "/w/:workspaceId/settings",
    account: "/account",
    workspaceBase: "/w/:workspaceId",
    // Trust wide
    batchMessageTrust: "/w/:workspaceId/batch-messages",
    // UHL specific
    batchMessages: "/batch-messages",
    questionnaireScoreboard: "/w/:workspaceId/questionnaires",
} as const;

// All of ROUTES_BASE entries should reflect a highlighted path in the side navigation
export const ROUTES_BASE = {
    // Navigation Secondary Patients
    patientsSearch: "/patients/search",
    integratedClinicListsAll: `${ROUTES_PRIMARY.workspaceBase}/patients/clinics`,
    integratedClinic: `${ROUTES_PRIMARY.workspaceBase}/patients/clinics/:clinicCode/:clinicDay/:location`,
    integratedClinicSelectPatients: `${ROUTES_PRIMARY.workspaceBase}/patients/clinics/:clinicCode/:clinicDay/:location/batch-messages/select-patients`,
    patientsListsAll: "/patients/lists/all",
    patientsListsId: "/patients/lists/:patientListId",

    // Navigation Secondary Account
    accountUserNameAndEmail: "/account/user/name-and-email",
    accountUserWorkspaceAccess: "/account/user/workspace-access",
    accountUserManageSignature: "/account/user/manage-signature",
    accountUserCookies: "/account/user/cookies",
    accountUserSecurity: "/account/user/security",
    accountUserTermsAndConditions: "/account/user/terms-and-conditions",

    // Navigation Secondary Batch Messages (UHL only)
    batchMessagesOverview: "/batch-messages/overview",
    batchMessageSend: "/batch-messages/send",
    batchMessageChoose: "/batch-messages/send/choose",
    batchMessageComposeSms: "/batch-messages/send/compose-sms",
    batchMessageComposeFlorey: "/batch-messages/send/compose-florey",
    batchMessagePatients: "/batch-messages/send/patients",
    batchMessageUpload: "/batch-messages/send/upload",
    batchMessageCopyPaste: "/batch-messages/send/copy-paste",
    batchMessagePatientList: "/batch-messages/send/patient-list",
    batchMessageSchedule: "/batch-messages/send/schedule",
    batchMessageReview: "/batch-messages/send/review",

    // Navigation Secondary (trust) batch messages
    batchMessageTrustSend: `${ROUTES_PRIMARY.batchMessageTrust}/send`,
} as const;

export const PATIENT_LISTS_ID_ROUTE = `${ROUTES_BASE.patientsListsId}(\\d+)`;

// Those routes will be appended to a route defined in ROUTES_BASE
export const ROUTES_EXTENSION = {
    messagePractice: "/message-practice",
    clinicianConversation: "/clinician-conversation",
    patientProfile: "/patient-profile",
    remoteRecordView: "/remote-record-view",
    remoteRecordViewMedicalRecord: "/remote-record-view/medical-record",
    remoteRecordViewMedicalRecordTestPatient:
        "/remote-record-view/medical-record?request=example",
    sendMessage: "/send-message",
    videoConfirmation: "/video-confirmation",
    workspaceInviteForm: "/invite",
} as const;

export const SLUGS_GP_BATCH = {
    choose: "batch-messaging-choose",
    composeCopd: "batch-messaging-compose-copd",
    composeFlorey: "batch-messaging-compose-florey",
    composeInhaler: "batch-messaging-compose-inhaler",
    composeSelfbook: "batch-messaging-compose-selfbook",
    composeSms: "batch-messaging-compose-sms",
    review: "batch-messaging-review",
    upload: "batch-messaging-upload",
    schedule: "batch-messaging-schedule",
};

// List of depreciated routes that we need to redirect (ie: old desktop app, old emails, old flyers...)
export type RedirectionPaths = {
    from: string;
    to: string;
};

// All the routes associated to Primary care users (Chain)
export const ROUTES_CHAIN = {
    practices: "/practices",
    practicesAppointmentReminder: "/practices/:orgId/appointment-reminder",
    practicesAppointmentReminderCompose:
        "/practices/:orgId/appointment-reminder-compose",
    practicesBatchMessaging: "/practices/:orgId/batch-messaging",
    practicesBatchMessagingChoose: `/practices/:orgId/${SLUGS_GP_BATCH.choose}`,
    practicesBatchMessagingComposeCopd: `/practices/:orgId/${SLUGS_GP_BATCH.composeCopd}`,
    practicesBatchMessagingComposeFlorey: `/practices/:orgId/${SLUGS_GP_BATCH.composeFlorey}`,
    practicesBatchMessagingComposeInhaler: `/practices/:orgId/${SLUGS_GP_BATCH.composeInhaler}`,
    practicesBatchMessagingComposeSelfbook: `/practices/:orgId/${SLUGS_GP_BATCH.composeSelfbook}`,
    practicesBatchMessagingComposeSms: `/practices/:orgId/${SLUGS_GP_BATCH.composeSms}`,
    practicesBatchMessagingReview: `/practices/:orgId/${SLUGS_GP_BATCH.review}`,
    practicesBatchMessagingUpload: `/practices/:orgId/${SLUGS_GP_BATCH.upload}`,
    practicesBatchMessagingSchedule: `/practices/:orgId/${SLUGS_GP_BATCH.schedule}`,
    practicesDelayedMessages: "/practices/:orgId/delayed-messages",
    practicesDeliveryReceipts: "/practices/:orgId/delivery-receipts",
    practicesDetails: "/practices/:orgId/details",
    practicesInhalerReminders: "/practices/:orgId/inhaler-reminders",
    practicesInstalledComputers: "/practices/:orgId/installed-computers",
    practicesModuleAccess: "/practices/:orgId/modules",
    practicesPracticeUsers: "/practices/:orgId/practice-users",
    practicesOrgId: "/practices/:orgId",
    practicesManageTemplatesCreate: "/practices/:orgId/manage-templates/create",
    practicesManageTemplates: "/practices/:orgId/manage-templates",
    practicesManageTemplatesForm:
        "/practices/:orgId/manage-templates/:templateId/:action(edit|delete|copy)",
    practicesPatientTriage: "/practices/:orgId/patient-triage",
    practicesPatientTriageSetup: "/practices/:orgId/patient-triage-setup",
    practicesPatientTriageSettings: "/practices/:orgId/patient-triage-settings",
    practicesPatientTriageDashboards:
        "/practices/:orgId/patient-triage-dashboards",
    practicesRecordViewOptIn: "/practices/:orgId/record-view-opt-in",
    practicesRecordViewRequests: "/practices/:orgId/record-view-requests",
    practicesSettings: "/practices/settings/:tab",
    practicesSenderId: "/practices/:orgId/sender-id",
    practicesTeamNotifications: "/practices/:orgId/team-notifications",
    practicesTeamUsage: "/practices/:orgId/team-usage",
    practicesSelfBook: "/practices/:orgId/self-book",
    practicesVaccineAllPatientsInvited:
        "/practices/:orgId/vaccine-all-patients-invited",
    practicesVaccineApprovalRequests:
        "/practices/:orgId/vaccine-approval-requests",
    practicesVaccineBookedClinics: "/practices/:orgId/vaccine-booked-clinics",
    practicesVaccineBookedClinicsId:
        "/practices/:orgId/vaccine-booked-clinics/:sessionId",
    practicesVaccineCancelBookings: "/practices/:orgId/vaccine-cancel",
    practicesVaccineCapacityDashboard:
        "/practices/:orgId/vaccine-capacity-dashboard",
    practicesVaccineInvitesComposeFirst:
        "/practices/:orgId/vaccine-invites-compose-first",
    practicesVaccineInvitesComposeSecond:
        "/practices/:orgId/vaccine-invites-compose-second",
    practicesVaccineInvitesReviewInvites:
        "/practices/:orgId/vaccine-invites-review-invites",
    practicesVaccineInvitesSelectSecond:
        "/practices/:orgId/vaccine-invites-select-second",
    practicesVaccineInvitesSingle: "/practices/:orgId/vaccine-invites-single",
    practicesVaccineInvitesUploadAndReview:
        "/practices/:orgId/vaccine-invites-upload-and-review",
    practicesVaccineInvitesUploadSecond:
        "/practices/:orgId/vaccine-invites-upload-second",
    practicesVaccineSiteSetup: "/practices/:orgId/vaccine-site-setup",
    practicesVaccineSmsPreview: "/practices/:orgId/vaccine-sms-preview",
    practicesVaccineReviewPatients: "/practices/:orgId/vaccine-review-patients",
    practicesVoip: "/practices/:orgId/voip",
    practicesFloreyBuilderBase: "/practices/:orgId/questionnaire-builder",
    practicesBeastPatients: "/practices/:orgId/rct-patients",
} as const;

export const ROUTES_ORGS = {
    root: "/organisations",
    org: "/organisations/:orgId",
    accountSettings: "/organisations/settings/:tab?",
    settings: "/organisations/:orgId?/settings/:tab?",
    accubook: "/organisations/:orgId/accubook/:tab?",
    allMessages: "/organisations/:orgId/all-messages/:tab?",
    appointmentReminders: "/organisations/:orgId/appointment-reminders/:tab?",
    accumailManageTemplatesCreate:
        "/organisations/:orgId/accumail-manage-templates/create/:type(user|workspace)",
    accumailManageTemplates:
        "/organisations/:orgId/accumail-manage-templates/:tab(my-templates|workspace-templates|accurx-templates)?",
    accumailManageTemplatesEdit:
        "/organisations/:orgId/accumail-manage-templates/:templateId/edit",
    accumailManageTemplatesCopy:
        "/organisations/:orgId/accumail-manage-templates/:templateId/copy",
    fragmentsDashboard: "/organisations/:orgId/fragments-dashboard",
    usageDashboard:
        "/organisations/:orgId/usage-dashboard/:tab(team|fragments)?",
    reporting: "/organisations/:orgId/reporting",
    reportResponseRates: "/organisations/:orgId/reporting/response-rates/:tab?",
    reportFragments: "/organisations/:orgId/reporting/fragments/:tab?",
    reportPatientTriage: "/organisations/:orgId/reporting/patient-triage/:tab?",
    // TODO: Deprecate when new reporting section is ready
    powerBIReporting: "/organisations/:orgId/reporting/:tab?",
} as const;

/**
 * Temporary list of all possible paths for old webinbox
 * we use these in our WorspaceConversationSection to redirect
 * from the old pages to the new pages
 */
export const TEMP_ROUTES_WEBINBOX_REDIRECTS = {
    mainConversationPage: {
        oldUrl: "/w/:workspaceId/conversations",
        newUrl: ROUTES_INBOX.MyInbox,
    },
    meAssignedFolder: {
        oldUrl: "/w/:workspaceId/conversations/me/assigned",
        newUrl: ROUTES_INBOX.MyInbox,
    },
    conversationInMeAssignedFolder: {
        oldUrl: "/w/:workspaceId/conversations/me/assigned/conversation/:conversationId",
        newUrl: ROUTES_INBOX.MyInbox,
    },

    meSentFolder: {
        oldUrl: "/w/:workspaceId/conversations/me/sent",
        newUrl: ROUTES_INBOX.MyInboxSent,
    },
    conversationInMeSentFolder: {
        oldUrl: "/w/:workspaceId/conversations/me/sent/conversation/:conversationId",
        newUrl: ROUTES_INBOX.MyInboxSent,
    },

    userSentFolder: {
        oldUrl: "/w/:workspaceId/conversations/user/:userId/sent",
        newUrl: ROUTES_INBOX.AllSent,
    },
    conversationUserSent: {
        oldUrl: "/w/:workspaceId/conversations/user/:userId/sent/conversation/:conversationId",
        newUrl: ROUTES_INBOX.AllSent,
    },
    userAssignedFolder: {
        oldUrl: "/w/:workspaceId/conversations/user/:userId/assigned",
        newUrl: ROUTES_INBOX.Colleagues,
    },
    conversationInUserAssignedFolder: {
        oldUrl: "/w/:workspaceId/conversations/user/:userId/assigned/conversation/:conversationId",
        newUrl: ROUTES_INBOX.Colleagues,
    },
    teamAssignedFolder: {
        oldUrl: "/w/:workspaceId/conversations/team/:teamId/assigned",
        newUrl: ROUTES_INBOX.Teams,
    },
    conversationInTeamAssignedFolder: {
        oldUrl: "/w/:workspaceId/conversations/team/:teamId/assigned/conversation/:conversationId",
        newUrl: ROUTES_INBOX.Teams,
    },

    allAssignedFolder: {
        oldUrl: "/w/:workspaceId/conversations/all/assigned",
        newUrl: ROUTES_INBOX.All,
    },
    conversationInAllAssignedFolder: {
        oldUrl: "/w/:workspaceId/conversations/all/assigned/conversation/:conversationId",
        newUrl: ROUTES_INBOX.All,
    },
    allSentFolder: {
        oldUrl: "/w/:workspaceId/conversations/all/sent",
        newUrl: ROUTES_INBOX.AllSent,
    },
    conversationInAllSentFolder: {
        oldUrl: "/w/:workspaceId/conversations/all/sent/conversation/:conversationId",
        newUrl: ROUTES_INBOX.AllSent,
    },
    conversationWithoutAFolder: {
        oldUrl: "/w/:workspaceId/conversations/conversation/:conversationId",
        newUrl: ROUTES_INBOX.MyInbox,
    },
} as const;

export const ROUTES_WORKSPACE = {
    // Settings
    workspaceGeneralSettings: `${ROUTES_PRIMARY.workspaceSettings}/general`,
    workspaceEdit: `${ROUTES_PRIMARY.workspaceSettings}/edit-workspace`,
    workspaceUserManagement: `${ROUTES_PRIMARY.workspaceSettings}/users`,
    workspaceSharedTemplates: `${ROUTES_PRIMARY.workspaceSettings}/shared-templates`,
    workspacePersonalTemplates: `${ROUTES_PRIMARY.workspaceSettings}/personal-templates`,
    workspaceAppointmentReminders: `${ROUTES_PRIMARY.workspaceSettings}/appointment-reminders`,
    workspaceAppointmentRemindersManage: `${ROUTES_PRIMARY.workspaceSettings}/appointment-reminders/manage`,
    workspaceAppointmentRemindersSent: `${ROUTES_PRIMARY.workspaceSettings}/appointment-reminders/sent`,
    workspaceAppointmentRemindersChanges: `${ROUTES_PRIMARY.workspaceSettings}/appointment-reminders/changes`,
    workspacePatientTriage: `${ROUTES_PRIMARY.workspaceSettings}/patient-triage`,
    workspacePatientTriageSettings: `${ROUTES_PRIMARY.workspaceSettings}/patient-triage/settings`,
    workspacePatientTriageSetup: `${ROUTES_PRIMARY.workspaceSettings}/patient-triage/setup`,
    workspaceMedicalRecords: `${ROUTES_PRIMARY.workspaceSettings}/medical-records`,
    workspaceDeliveryReceipts: `${ROUTES_PRIMARY.workspaceSettings}/delivery-receipts/:tab?`,
    workspaceResources: `${ROUTES_PRIMARY.workspaceSettings}/resources`,

    // Reporting
    reporting: `${ROUTES_PRIMARY.workspaceSettings}/reporting`,
    reportingPatientTriage: `${ROUTES_PRIMARY.workspaceSettings}/reporting/patient-triage/:tab?`,
    reportingResponseRates: `${ROUTES_PRIMARY.workspaceSettings}/reporting/response-rates/:tab?`,
    reportingFragments: `${ROUTES_PRIMARY.workspaceSettings}/reporting/fragments/:tab?`,
    reportingTeamUsage: `${ROUTES_PRIMARY.workspaceSettings}/reporting/team`,

    // AccuBook
    accubook: `${ROUTES_PRIMARY.workspaceSettings}/accubook`,
    accubookManagePatients: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-patients`,
    accubookInvitesUpload: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-patients/upload`,
    accubookInvitesSelect: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-patients/select`,
    accubookInvitesComposeFirst: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-patients/compose-first`,
    accubookInvitesComposeSecond: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-patients/compose-second`,
    accubookInvitesReview: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-patients/review`,
    accubookInvitesSingle: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-patients/invite-single`,
    accubookSendReminder: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-patients/send-reminder`,
    accubookManageClinics: `${ROUTES_PRIMARY.workspaceSettings}/accubook/manage-clinics`,
    accubookBookedPatients: `${ROUTES_PRIMARY.workspaceSettings}/accubook/booked-patients`,
    accubookBookedPatientsSession: `${ROUTES_PRIMARY.workspaceSettings}/accubook/booked-patients/:sessionId`,
    accubookDeliverySites: `${ROUTES_PRIMARY.workspaceSettings}/accubook/delivery-sites`,
    accubookDashboard: `${ROUTES_PRIMARY.workspaceSettings}/accubook/dashboard`,

    // Conversations
    conversationsMe:
        "/w/:workspaceId/conversations/me/:relationship(assigned|sent)",
    conversationsUser:
        "/w/:workspaceId/conversations/user/:userId/:relationship(assigned|sent)",
    conversationsTeam: "/w/:workspaceId/conversations/team/:teamId/assigned",
    conversationsAll:
        "/w/:workspaceId/conversations/all/:relationship(assigned|sent)",

    // A single conversation can exist in any kind of group
    // We don't want to be prescriptive over the shape of the group path so
    // We use "/:path*" here to say that anything ending with
    // "/conversation/:conversationId" is a single conversation page.
    //
    // Some examples may be:
    // "/w/88/conversations/user/12/conversation/2572345"
    // "/w/88/conversations/search/conversation/2572345"
    // "/w/88/conversations/conversation/2572345"
    conversation:
        "/w/:workspaceId/conversations/:path*/conversation/:conversationId",

    // Searched patient
    patientProfile: "/w/:workspaceId/:path*/patient-profile/:selectedTab?",
    searchedPatientConversationNew:
        "/w/:workspaceId/:path*/patient-profile/:selectedTab?/conversation/new",
    searchedPatientConversationNewWithId:
        "/w/:workspaceId/:path*/patient-profile/:selectedTab?/conversation/new/:conversationId",
    searchedPatientConversation:
        "/w/:workspaceId/:path*/patient-profile/:selectedTab?/conversation/:conversationId",

    remoteRecordView: `${ROUTES_PRIMARY.workspaceBase}/:path*${ROUTES_EXTENSION.remoteRecordView}`,

    // Compose batch trust web user (non-UHL specific)
    batchMessageTrustSentMessages: `${ROUTES_PRIMARY.batchMessageTrust}/sent`,
    batchMessageTrustUpload: `${ROUTES_BASE.batchMessageTrustSend}/upload`,
    batchMessageTrustUploadViaFile: `${ROUTES_BASE.batchMessageTrustSend}/upload/file`,
    batchMessageTrustUploadImportPatientList: `${ROUTES_BASE.batchMessageTrustSend}/upload/import-patient-list`,
    batchMessageTrustUploadViaCopyPaste: `${ROUTES_BASE.batchMessageTrustSend}/upload/copy-paste`,
    batchMessageTrustReview: `${ROUTES_BASE.batchMessageTrustSend}/review/:batchId`,
    batchMessageTrustCompose: `${ROUTES_BASE.batchMessageTrustSend}/compose/:batchId`,
    batchMessageTrustConfirm: `${ROUTES_BASE.batchMessageTrustSend}/confirm/:batchId`,

    // GP Batch
    batchMessageGpChoose: `${ROUTES_PRIMARY.batchMessageTrust}/${SLUGS_GP_BATCH.choose}`,
} as const;

export const ROUTES_API = {
    downloadDesktop: "/api/desktop/download/chain",
};

export const ROUTES_REDIRECTED: RedirectionPaths[] = [
    {
        from: "/ConfirmBasicPlanEmail/Index", // Emails redirect to this route
        to: ROUTES.confirmEmailVerify,
    },
    {
        from: "/Account/ResetPassword", // Emails redirect to this route
        to: ROUTES.resetPassword,
    },
    {
        from: "/Account/ForgotPasswordWithUserId", // Emails redirect to this route
        to: ROUTES.resetPassword,
    },
    {
        from: "/search-for-patient", // Old landing page
        to: ROUTES_PRIMARY.patients,
    },
    { from: "/select-product", to: ROUTES_BASE.patientsSearch }, // Old select product page
    { from: "/patients/search/select-product", to: ROUTES_BASE.patientsSearch }, // Old select product page
    {
        from: "/patients/lists/:patientListId/select-product",
        to: ROUTES_BASE.patientsListsAll,
    },
    {
        from: "/Account/ForgotPassword", // Desktop app forgotten password
        to: ROUTES.forgotPassword,
    },
    {
        from: "/download/index", // Shouldn't have any reference anymore but adding just in case
        to: ROUTES.downloadDesktop,
    },
    {
        from: "/download/chain", // Marketing website
        to: ROUTES.downloadDesktop,
    },
    {
        from: "/login/practices", // Bookmarked
        to: ROUTES.loginOrganisations,
    },
    {
        from: "/Account/Register", // Shouldn't have any reference anymore but adding just in case
        to: ROUTES.register,
    },
    {
        from: "/MagicLink/Index", // Old desktop app & email
        to: ROUTES.magicLink,
    },
    {
        from: "/PreMagicLinkConfirmEmail/ConfirmEmail", // Old desktop app & email
        to: ROUTES.confirmEmailVerify,
    },
    {
        from: "/account/join-organisation",
        to: ROUTES.joinOrganisation,
    },
];

export const ORGANISATION_ROUTES_REDIRECTED: RedirectionPaths[] = [
    {
        from: "/message-templates",
        to: ROUTES_WORKSPACE.workspacePersonalTemplates,
    },
    {
        from: "/delivery-receipts",
        to: ROUTES_WORKSPACE.workspaceDeliveryReceipts,
    },
    /**
     * ACCOUNT PAGES TO WORKSPACE REDIRECTS
     *
     * If you're seeing this after Jan 1st 2023 please check
     * if these account pages are still being visited. If not
     * delete these redirects.
     */
    {
        from: "/account/message-templates",
        to: ROUTES_WORKSPACE.workspacePersonalTemplates,
    },
    {
        from: "/account/organisation-message-templates",
        to: ROUTES_WORKSPACE.workspaceSharedTemplates,
    },
    {
        from: "/account/medical-record",
        to: ROUTES_WORKSPACE.workspaceMedicalRecords,
    },
    {
        from: "/account/delivery-receipts",
        to: ROUTES_WORKSPACE.workspaceDeliveryReceipts,
    },
    {
        from: "/account/organisation-resources",
        to: ROUTES_WORKSPACE.workspaceResources,
    },
    /**
     * WORKSPACE REDIRECTS
     *
     * If you're seeing this after Nov 1st 2023 please check
     * if these workspace pages are still being visited. If not
     * delete these redirects.
     */
    {
        from: "/workspace/users",
        to: ROUTES_WORKSPACE.workspaceUserManagement,
    },
    {
        from: "/workspace/shared-templates",
        to: ROUTES_WORKSPACE.workspaceSharedTemplates,
    },
    {
        from: "/workspace/personal-templates",
        to: ROUTES_WORKSPACE.workspacePersonalTemplates,
    },
    {
        from: "/workspace/medical-records",
        to: ROUTES_WORKSPACE.workspaceMedicalRecords,
    },
    {
        from: "/workspace/delivery-receipts",
        to: ROUTES_WORKSPACE.workspaceDeliveryReceipts,
    },
    {
        from: "/workspace/resources",
        to: ROUTES_WORKSPACE.workspaceResources,
    },
    {
        from: "/workspace/join-workspace",
        to: WORKSPACE_MANAGEMENT_ROUTES.workspaceJoin,
    },
    {
        from: "/workspace/create-workspace",
        to: WORKSPACE_MANAGEMENT_ROUTES.workspaceCreate,
    },
    {
        from: "/workspace/join-organisation",
        to: ROUTES.joinOrganisation,
    },
    {
        from: "/workspace/add-organisation",
        to: ROUTES.addOrganisation,
    },
];

export const redirectUserToChain = (location: Location): boolean => {
    // Old desktop link: We can't use react-router redirect as it's a file download
    if (location.pathname.toLowerCase().includes("/api/utilityexe")) {
        window.location.assign("/api/desktop/download/utilityexe");
        return false;
    }

    return false;
};
