import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import ApprovalRequests from "app/practices/vaccine/approvalRequests/ApprovalRequests";

export const AccubookDeliverySitesPage = () => {
    return (
        <StyledContainer>
            <PageHeader title="Delivery sites" type={PageHeaderType.ListPage} />
            <ApprovalRequests noHeader />
        </StyledContainer>
    );
};
