import {
    AppointmentTypeFilter,
    CreateAppointmentReminderRequest,
    UpdateAppointmentReminderRequest,
} from "@accurx/api/appointment";

import { FormValues } from "../components/ReminderFormFields/";

type FormRequest =
    | CreateAppointmentReminderRequest
    | Exclude<UpdateAppointmentReminderRequest, "appointmentReminderId">;

export const mapFormValuesToRequest = (formValues: FormValues): FormRequest => {
    const isPostAppointmentEnabled = formValues.postAppointmentMessageEnabled;

    return {
        siteNames: formValues.selectedSiteNames,
        slotTypes: formValues.selectedSlotTypes.map(
            (slotType) => slotType.value,
        ),
        ...(formValues.selectedAppointmentType
            ? {
                  appointmentTypeFilters: [
                      formValues.selectedAppointmentType as AppointmentTypeFilter,
                  ],
              }
            : {}),
        type: formValues.templateType,
        customMessageBody: formValues.customMessage,
        threeWorkingDayReminderEnabled: formValues.threeDaysEnabled,
        oneWeekReminderEnabled: formValues.oneWeekEnabled,
        postAppointmentMessageEnabled: isPostAppointmentEnabled,
        postAppointmentCustomMessage: isPostAppointmentEnabled
            ? formValues.postAppointmentMessageBody
            : "",
    };
};
