import * as UI from "@accurx/design";

import { StyledContainer, StyledSkeletonLoader } from "./PatientHeader.styles";

export const PatientHeaderLoadingState = () => (
    <StyledContainer>
        <UI.Flex flexDirection="column" gap="1">
            <StyledSkeletonLoader height="20px" width="64%" theme="dark" />
            <UI.Flex gap="3">
                <StyledSkeletonLoader
                    height={UI.Tokens.SIZES[2]}
                    width="22%"
                    theme="dark"
                />
                <StyledSkeletonLoader
                    height={UI.Tokens.SIZES[2]}
                    width="22%"
                    theme="dark"
                />
            </UI.Flex>
        </UI.Flex>
    </StyledContainer>
);
