import { useEffect } from "react";

import {
    FeatureName,
    isFeatureEnabledForWorkspace,
    useAuth,
} from "@accurx/auth";
import { Button, Ds, Flex, Text } from "@accurx/design";
import {
    WORKSPACE_MANAGEMENT_ROUTES,
    isMyWorkspace,
    isUserOnlyInDefaultWorkspaceWithinOrg,
} from "@accurx/workspace-management";
import { Link, generatePath, useRouteMatch } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { ONBOARDING_ROUTES } from "app/guardedRoutes/routes/OnboardingRoutesSection";
import { ROUTES_BASE, ROUTES_ORGS } from "shared/Routes";

import { StyledList } from "./GlobalMyWorkspaceModal.styles";

export const GlobalMyWorkspaceModal = () => {
    const { user } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();

    const track = useAnalytics();

    const isMyWorkspaceModalEnabledOnRoute = !useRouteMatch({
        path: [
            ...ONBOARDING_ROUTES,
            ...Object.values(ROUTES_ORGS),
            ...Object.values(WORKSPACE_MANAGEMENT_ROUTES),
            ROUTES_BASE.accountUserWorkspaceAccess,
        ],
    });

    const workspace = user?.isLoggedIn
        ? user.organisations.find(({ orgId }) => orgId === selectedOrgId)
        : null;

    const isFeatureEnabled = workspace
        ? isFeatureEnabledForWorkspace(
              FeatureName.DissuadeFromUsingMyWorkspace,
              workspace,
          )
        : false;

    const showModal =
        user.isLoggedIn &&
        !!workspace &&
        isMyWorkspaceModalEnabledOnRoute &&
        isFeatureEnabled &&
        isMyWorkspace(workspace) &&
        isUserOnlyInDefaultWorkspaceWithinOrg({
            organisation: workspace,
            user,
        });

    useEffect(() => {
        if (showModal) {
            track("WorkspaceDefault Component View", {
                modalView: "workspaceSpecialtyJoin",
            });
            if (window.Intercom) {
                window.Intercom("trackEvent", "view-must-join-workspace-modal");
            }
        }
    }, [showModal, track]);

    if (!selectedOrgId) {
        return null;
    }

    const handleClick = () => {
        track("WorkspaceSpecialtyJoin Button Click", {});
        if (window.Intercom) {
            window.Intercom("trackEvent", "click-must-join-workspace");
        }
    };

    return (
        <Ds.Modal open={showModal} onOpenChange={() => null}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Join a workspace dedicated to your specialty
                    </Ds.Modal.Title>
                    <Text>
                        Your account is currently affiliated with your wider NHS
                        Trust workspace, which is now separating into
                        specialty-based workspaces. This lets you:
                    </Text>
                    <StyledList>
                        <Text as="li" skinny>
                            communicate more personally with patients
                        </Text>
                        <Text as="li" skinny>
                            collaborate with relevant colleagues
                        </Text>
                    </StyledList>
                    <Text>
                        Your past conversations will not be transferred, but
                        you’ll still have access to them.
                    </Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Link
                            to={{
                                pathname: generatePath(
                                    WORKSPACE_MANAGEMENT_ROUTES.workspaceJoin,
                                    { workspaceId: selectedOrgId },
                                ),
                            }}
                            onClick={handleClick}
                        >
                            <Button
                                as="span"
                                text="Join your specialty’s workspace "
                            />
                        </Link>
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
