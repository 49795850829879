import { CrossOrgBookableOrganisation } from "@accurx/api/appointment";
import { Option } from "@accurx/design";

import { SlotTypeAvailabilityDto } from "../queries/useAppointmentAvailability";
import { convertAvailabilityPeriodToDays } from "./appointmentAvailabilityPeriodConversions";
import {
    AppointmentAvailabilityPeriod,
    ChooseClinicianValue,
    ClinicianOption,
    CliniciansForSlotType,
    KeyValueDTO,
    OrganisationOption,
    QueryStatus,
    SelfBookFormData,
    SelfbookConfigurationPayload,
} from "./types";

export const DEFAULT_LINK_TIMEOUT_DAYS = 7;

export const transformToSlotTypesOptions = (
    selfbookAvailability: KeyValueDTO | undefined,
): Option[] =>
    // Transforming the test object into an array of objects with label and value properties
    Object.entries(selfbookAvailability || {}).map(([key, value]) => ({
        label: key,
        value: `${value}`,
    }));

export const transformToOrganisationOptions = (
    orgId: number,
    organisationName: string,
    crossOrgBookableOrganisations: CrossOrgBookableOrganisation[],
): OrganisationOption[] => {
    const currentOrgObj: OrganisationOption = {
        label: `${organisationName} (Default)`,
        value: `${orgId}`,
        isDefault: true,
    };

    // Transforming the test object into an array of objects with label, value and isDefault properties
    const crossOrgs: OrganisationOption[] = crossOrgBookableOrganisations.map(
        (value) => ({
            label: value.name,
            value: value.odsCode,
            isDefault: false,
        }),
    );

    return [currentOrgObj, ...crossOrgs];
};

export const transformToClinicianOptions = (
    slotTypesWithClinicians: SlotTypeAvailabilityDto[] | undefined,
): CliniciansForSlotType[] => {
    // Transforming the test object into an array of objects with label and value properties
    if (!slotTypesWithClinicians) {
        return [];
    }

    return slotTypesWithClinicians.map((item) => ({
        label: item.slotTypeName,
        value: item.clinicianGroups,
        appointmentCount: item.appointmentCount,
    }));
};

export const getClinicianOptions = (
    clinicianListForSlotTypes: CliniciansForSlotType[],
    slotType: Option | undefined,
): ClinicianOption[] => {
    if (!slotType) {
        return [];
    }

    const cliniciansObj: CliniciansForSlotType | undefined =
        clinicianListForSlotTypes.find((item) => item.label === slotType.label);

    if (!cliniciansObj || !cliniciansObj.value) {
        return [];
    }
    const clinicianOptions: ClinicianOption[] = [];
    const groupOptions: ClinicianOption[] = [];

    cliniciansObj.value.forEach((item) => {
        const clinicianNames: string[] = [];
        item.clinicians.map((clinician) => {
            return clinicianNames.push(clinician.displayName);
        });

        const isClinicianOption = item.clinicians.length === 1;

        const clinicianOption = {
            label:
                item.appointmentCount === 0
                    ? `${clinicianNames.join(", ")} (no availability)`
                    : clinicianNames.join(", "),
            value: `${item.clinicianGroupId}`,
            clinicianNames: clinicianNames,
            grouping: isClinicianOption ? "Clinicians" : "Groups",
            appointmentCount: item.appointmentCount,
        };
        // push to clinicians or group options based on clinicians array length
        isClinicianOption
            ? clinicianOptions.push(clinicianOption)
            : groupOptions.push(clinicianOption);
    });

    // join list of clinicians and groups
    return [...clinicianOptions, ...groupOptions];
};

export const shouldShowSlotTypeError = (
    slotType: Option | undefined,
    addBookingLinkClicked: boolean,
): string[] | undefined => {
    if (addBookingLinkClicked && slotType === undefined) {
        return ["Select a slot type that patients will book into."];
    }
    if (slotType && slotType.value === "0") {
        return [
            "No appointments. Make more available in your clinical system.",
        ];
    }
    return undefined;
};

export const shouldShowChooseClinicianError = (
    clinicians:
        | {
              clinicians: ClinicianOption[] | undefined;
              clinicianType: ChooseClinicianValue;
          }
        | undefined,
    addBookingLinkClicked: boolean,
): string[] | undefined => {
    if (
        addBookingLinkClicked &&
        clinicians?.clinicianType === "SpecificClinicians" &&
        clinicians.clinicians?.length === 0
    ) {
        return ["Select at least one clinician."];
    } else if (
        addBookingLinkClicked &&
        clinicians?.clinicianType === "SpecificClinicians" &&
        clinicians.clinicians?.every((item) => item.appointmentCount === 0)
    ) {
        return clinicians.clinicians.length === 1
            ? ["Selected clinician has no availability"]
            : ["Selected clinicians have no availability"];
    }
    return undefined;
};

const getTimeFrameValue = (
    appointmentAvailabilityPeriod: AppointmentAvailabilityPeriod,
    maxAppointmentAvailabilityWeeks: number,
): number => {
    switch (appointmentAvailabilityPeriod.units) {
        case "days":
            return maxAppointmentAvailabilityWeeks * 7;
        case "weeks":
            return maxAppointmentAvailabilityWeeks;
        default:
            return maxAppointmentAvailabilityWeeks;
    }
};

export const shouldShowAppointmentAvailabilityError = (
    appointmentAvailabilityPeriod: AppointmentAvailabilityPeriod,
    maxAppointmentAvailabilityWeeks: number,
    hasAvailability: boolean,
): string[] | undefined => {
    const isAvailabilityZero = appointmentAvailabilityPeriod.value === 0;
    const timeAvailability = getTimeFrameValue(
        appointmentAvailabilityPeriod,
        maxAppointmentAvailabilityWeeks,
    );
    const isAvailabilityExceedingMax =
        appointmentAvailabilityPeriod.value > timeAvailability;

    if (isAvailabilityZero || isAvailabilityExceedingMax) {
        return [
            `Enter a time frame within ${timeAvailability} ${appointmentAvailabilityPeriod.units}`,
        ];
    }
    if (!hasAvailability) {
        return [
            `No appointments available in this time frame. Try increasing to up to ${timeAvailability} ${appointmentAvailabilityPeriod.units}`,
        ];
    }
};

const clinicianTranformation = (
    clinicians:
        | { clinicians: ClinicianOption[]; clinicianType: ChooseClinicianValue }
        | undefined,
): { clinicianGroupId: number; clinicianNames: string[] }[] => {
    if (!clinicians) {
        return [];
    }

    return clinicians.clinicians.map((clinician) => {
        return {
            clinicianGroupId: Number(clinician.value),
            clinicianNames: clinician.clinicianNames,
        };
    });
};

export const getSelfbookConfigObject = (
    selfBookData: SelfBookFormData,
    appointmentAvailabilityStatus: QueryStatus,
    crossOrgStatus: QueryStatus,
    showCrossOrg: boolean,
    maxAppointmentAvailabilityWeeks: number,
): SelfbookConfigurationPayload => {
    const clinician = clinicianTranformation(selfBookData.clinicians);

    const validationSuccess = isFormValid(
        selfBookData.slotType?.value,
        selfBookData.clinicians,
        appointmentAvailabilityStatus,
        crossOrgStatus,
        selfBookData.appointmentAvailabilityPeriod,
        maxAppointmentAvailabilityWeeks,
    );
    return {
        validationSuccess: validationSuccess,
        appointmentType: selfBookData.appointmentType,
        slotType: selfBookData.slotType?.label,
        appointmentAvailabilityPeriod:
            selfBookData.appointmentAvailabilityPeriod,
        specificClinician:
            selfBookData.clinicians?.clinicianType === "SpecificClinicians"
                ? clinician
                : undefined,
        crossOrgBooking:
            showCrossOrg && !selfBookData.organisation.isDefault
                ? {
                      targetOdsCode: selfBookData.organisation.value,
                      targetOdsName: selfBookData.organisation.label,
                  }
                : undefined,
        timeoutDays: Math.min(
            DEFAULT_LINK_TIMEOUT_DAYS,
            convertAvailabilityPeriodToDays(
                selfBookData.appointmentAvailabilityPeriod,
            ),
        ),
    };
};

export const isCrossOrgLoading = (
    crossOrgStatus: QueryStatus,
    organisationIsDefault: boolean,
) => {
    return !organisationIsDefault && crossOrgStatus === "loading";
};

export const isFormValid = (
    slotType: string | undefined,
    clinicians:
        | {
              clinicians: ClinicianOption[];
              clinicianType: ChooseClinicianValue;
          }
        | undefined,
    appointmentAvailabilityStatus: QueryStatus,
    crossOrgStatus: QueryStatus,
    appointmentAvailabilityPeriod: AppointmentAvailabilityPeriod,
    maxAppointmentAvailabilityWeeks: number,
): boolean => {
    const isSlotTypeValid = slotType
        ? slotType !== "0" &&
          appointmentAvailabilityStatus === "success" &&
          crossOrgStatus !== "error"
        : false;

    const isClinicianValid =
        clinicians && clinicians.clinicianType === "SpecificClinicians"
            ? clinicians.clinicians.length > 0 &&
              clinicians.clinicians.some((item) => item.appointmentCount !== 0)
            : true;

    const timeAvailability = getTimeFrameValue(
        appointmentAvailabilityPeriod,
        maxAppointmentAvailabilityWeeks,
    );

    const isAppointmentAvailabilityValid =
        appointmentAvailabilityPeriod.value !== 0 &&
        appointmentAvailabilityPeriod.value <= timeAvailability;

    return (
        isSlotTypeValid && isClinicianValid && isAppointmentAvailabilityValid
    );
};
