import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";

type Variables = {
    itemServerId: string;
};

export const useSaveFloreyPdfToRecordMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation(
        "SaveFloreyToPdf",
        async ({ itemServerId }: Variables) => {
            const { updates } = await api.saveFloreyPdfToRecord({
                workspaceId,
                floreyNoteId: itemServerId,
            });

            dispatch(
                actions.processUpdates({
                    source: "Mutation:SaveFloreyToPdf",
                    ...updates,
                }),
            );
        },
        options,
    );
};
