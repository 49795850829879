import { useContext, useMemo, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    BasicUserStatus,
    CollaborativeWorkspaceResponse,
} from "@accurx/api/account";
import { useCurrentUser } from "@accurx/auth";
import { Button, Ds, Feedback, Flex, Text } from "@accurx/design";
import { getNextUrlOrDefault } from "@accurx/navigation";
import { QuickViewPortal } from "@accurx/quick-view";
import {
    NO_APPLICABLE_SPECIALTY_NAME,
    useJoinWorkspaceMutation,
} from "@accurx/workspace-management";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { SelectedAllowedOrganisationContext } from "app/workspace/context/SelectedAllowedOrganisationProvider";

import { StyledCardItem, StyledWorkspaceDetails } from "../Layout.styles";
import { WorkspaceQuickView } from "../WorkspaceQuickView/WorkspaceQuickView";
import { UserflowIds } from "../constants";
import { sortUsersByCurrentUser } from "../helpers";
import {
    StyledActionSection,
    StyledBadge,
    StyledButton,
    StyledLeftHandSide,
    StyledList,
    StyledMembersWrapper,
} from "./WorkspaceItem.styles";

export type ExistingWorkspaceProps = Pick<
    CollaborativeWorkspaceResponse,
    "users" | "description" | "name" | "id" | "isRecommended"
> & {
    specialty?: string;
    defaultOrgName?: string;
    currentWorkspaceId?: number;
};

export const ExistingWorkspaceItem = ({
    name,
    id,
    description,
    users,
    isRecommended,
    specialty,
    defaultOrgName,
    currentWorkspaceId,
}: ExistingWorkspaceProps) => {
    const history = useHistory();

    const track = useAnalytics();
    const { setSelectedOrgId } = useSelectedOrganisation();
    const { user } = useCurrentUser();

    const [showModal, setShowModal] = useState(false);
    const [showMembers, setShowMembers] = useState(false);

    const currentUserId = Number(user.accuRxUserId);

    const showSpecialtyLabel =
        specialty && specialty !== NO_APPLICABLE_SPECIALTY_NAME;

    const isCurrentWorkspace = id === currentWorkspaceId;

    const currentUserIndex = users.findIndex(({ id }) => id === currentUserId);

    const isCurrentUserInWorkspace = currentUserIndex >= 0;

    const isCurrentUserArchived =
        isCurrentUserInWorkspace &&
        users[currentUserIndex].status === BasicUserStatus.Archived;

    const activeUsers = users.filter(
        ({ status }) => status !== BasicUserStatus.Archived,
    );

    const orderedUsers =
        isCurrentUserInWorkspace && !isCurrentUserArchived
            ? sortUsersByCurrentUser({ currentUserIndex, users: activeUsers })
            : activeUsers;

    const numberOfMembers = activeUsers.length;

    const membersButtonText = useMemo(
        () =>
            `${numberOfMembers} ${numberOfMembers > 1 ? "members" : "member"}`,
        [numberOfMembers],
    );

    const handleMembersClick = () => {
        setShowMembers(true);
        track("WorkspaceMemberListView Button Click", {});
    };

    const handleOpenSupportChat = () => {
        window.Intercom &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window.Intercom(
                "showNewMessage",
                `Hi, I would like to rejoin ${name} at ${defaultOrgName}`,
            );
    };

    const handleSwitchToWorkspaceClick = () => {
        setSelectedOrgId(id);
        history.push("/");
    };

    return (
        <>
            <StyledCardItem as="li">
                <StyledLeftHandSide>
                    <div>
                        <Text variant="label" skinny>
                            {name}
                        </Text>
                        <Flex flexWrap="wrap" flexDirection="row">
                            {Boolean(description) && (
                                <Text variant="preview" skinny>
                                    {description}
                                    {showSpecialtyLabel && <>&nbsp;•&nbsp;</>}
                                </Text>
                            )}
                            {showSpecialtyLabel && (
                                <Text variant="preview" skinny>
                                    Specialty: {specialty}
                                </Text>
                            )}
                        </Flex>
                    </div>
                    {isRecommended ? (
                        <StyledBadge color="green">
                            Related to your specialty
                        </StyledBadge>
                    ) : null}
                </StyledLeftHandSide>
                <StyledWorkspaceDetails>
                    <StyledMembersWrapper>
                        <StyledButton
                            type="button"
                            theme="transparent"
                            text={membersButtonText}
                            onClick={handleMembersClick}
                            aria-label={`View ${membersButtonText} in ${name}`}
                        />
                    </StyledMembersWrapper>
                    <StyledActionSection>
                        {isCurrentWorkspace ? (
                            <Text skinny>Current workspace</Text>
                        ) : isCurrentUserArchived ? (
                            <Button
                                theme="secondary"
                                text="Rejoin"
                                onClick={handleOpenSupportChat}
                                aria-label={`Rejoin ${name} workspace`}
                            />
                        ) : isCurrentUserInWorkspace ? (
                            <Button
                                theme="secondary"
                                text="Switch to workspace"
                                onClick={handleSwitchToWorkspaceClick}
                                aria-label={`Switch to ${name} workspace`}
                            />
                        ) : (
                            <Button
                                theme="secondary"
                                text="Join"
                                aria-label={`Join ${name}`}
                                onClick={() => setShowModal(true)}
                                data-userflow-id={
                                    UserflowIds.joinWorkspace.requestJoinButton
                                }
                            />
                        )}
                    </StyledActionSection>
                </StyledWorkspaceDetails>
            </StyledCardItem>
            {showModal && (
                <JoinWorkspaceModal
                    open={showModal}
                    onOpenChange={setShowModal}
                    name={name}
                    workspaceId={id}
                />
            )}
            {showMembers && (
                <QuickViewPortal
                    isOpen={showMembers}
                    onClose={() => setShowMembers(false)}
                >
                    <QuickViewPortal.Header>
                        <Button
                            onClick={() => setShowMembers(false)}
                            text="Close"
                            icon={{
                                name: "Cross",
                                colour: "metal",
                                style: "Line",
                            }}
                            theme="transparent"
                        />
                    </QuickViewPortal.Header>
                    <QuickViewPortal.Body>
                        <WorkspaceQuickView
                            name={name}
                            description={description}
                            users={orderedUsers}
                        />
                    </QuickViewPortal.Body>
                </QuickViewPortal>
            )}
        </>
    );
};

const JoinWorkspaceModal = ({
    open,
    onOpenChange,
    name,
    workspaceId,
}: Ds.ModalProps & { name: string; workspaceId: number }) => {
    const history = useHistory();
    const track = useAnalytics();
    const { ref, handleFocusRef } = Ds.useModalAutoFocus<HTMLButtonElement>();

    const { setAllowedOrganisation } = useContext(
        SelectedAllowedOrganisationContext,
    );

    const { mutate, isLoading } = useJoinWorkspaceMutation({
        onSuccess: () => {
            toast(
                <Feedback colour="success" title={`Joined ${name}`}>
                    Some features are locked until an admin approves your
                    membership. Admins have been notified.
                </Feedback>,
            );
            setAllowedOrganisation(null);
            history.replace(getNextUrlOrDefault(history.location.search, "/"));
        },
        onError: () => {
            toast(
                <Feedback colour="error" title="There was a network issue">
                    Please refresh the page and try again.
                </Feedback>,
            );
            onOpenChange(false);
        },
        onSettled: (_data, error) =>
            track("WorkspaceJoinConfirm Button Click", {
                hasError: Boolean(error),
            }),
    });

    const handleCancel = () => {
        track("WorkspaceJoinCancel Button Click", {});
        onOpenChange(false);
    };

    const handleConfirm = () => {
        if (window.Intercom) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            window.Intercom(
                "trackEvent",
                "click-confirm-join-or-create-workspace",
            );
        }
        mutate({ workspaceId });
    };

    return (
        <Ds.Modal
            open={open}
            onOpenChange={onOpenChange}
            onOpenAutoFocus={handleFocusRef}
        >
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>Join {name}</Ds.Modal.Title>
                    <Text skinny>
                        Your membership will be pending approval from an admin.
                        Until then you can:
                    </Text>
                    <StyledList>
                        <Text as="li" skinny>
                            start sending messages from the workspace
                        </Text>
                        <Text as="li" skinny>
                            create message templates and patient lists
                        </Text>
                    </StyledList>
                    <Text skinny>
                        Approved members will be able to see the messages you
                        send.
                    </Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            text="Cancel"
                            disabled={isLoading}
                            onClick={handleCancel}
                        />
                        <Button
                            ref={ref}
                            theme="primary"
                            text={isLoading ? "Joining" : "Join"}
                            aria-label={`Join ${name}`}
                            disabled={isLoading}
                            onClick={handleConfirm}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
