import React from "react";

import {
    Accordion,
    Button,
    Card,
    Feedback,
    Text,
    Tokens,
} from "@accurx/design";
import { i } from "images";
import { TextareaAutosize } from "react-autosize-textarea/lib/TextareaAutosize";

import { UpdatingStatusType } from "shared/LoadingStatus";

import { Settings } from "../../constants";
import { PatientTriageStatus } from "../../types";
import { UrgentHelpSection } from "../UrgentHelpSection/UrgentHelpSection";

export type ChangeOohPageFormPropsType = {
    status: PatientTriageStatus;
    triageSettingsUpdatingStatus: UpdatingStatusType;
    hasClickedEditOohPage: boolean;
    defaultOohText: string;
    outOfHoursPageMessage: string | null;
    handleUpdateCustomCopy: (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>,
        name: string,
        resetToDefault: boolean | null,
    ) => void;

    handleOohPageMessageCancel: () => void;
    hasClickedEditButton: (
        event: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
    handleAccordionClick: (
        e: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleUpdateString: (event: any, name: string) => void;
};

export const ChangeOohPageForm = ({
    status,
    triageSettingsUpdatingStatus,
    defaultOohText,
    outOfHoursPageMessage,
    hasClickedEditOohPage,
    handleOohPageMessageCancel,
    hasClickedEditButton,
    handleAccordionClick,
    handleUpdateCustomCopy,
    handleUpdateString,
}: ChangeOohPageFormPropsType): JSX.Element => {
    const isDisabled =
        status.isAdmin === false || triageSettingsUpdatingStatus.updating;

    return (
        <form
            onSubmit={(e): void =>
                handleUpdateCustomCopy(e, Settings.TEXT_OOH_PAGE, false)
            }
        >
            <Card
                props={{ className: "mb-3" }}
                header={
                    <Text as="h3" skinny variant="subtitle">
                        Edit out of hours message
                    </Text>
                }
                footer={
                    <div className="d-flex justify-content-end">
                        {hasClickedEditOohPage ? (
                            <>
                                <Button
                                    theme="primary"
                                    data-testid="outOfHoursMessageSave"
                                    disabled={isDisabled}
                                    type="submit"
                                    text="Save Changes"
                                />
                                <Button
                                    className="ml-3"
                                    theme="secondary"
                                    onClick={(e): void =>
                                        handleUpdateCustomCopy(
                                            e,
                                            Settings.TEXT_OOH_PAGE,
                                            true,
                                        )
                                    }
                                    data-testid="outOfHoursMessageReset"
                                    disabled={isDisabled}
                                    type="button"
                                    text="Reset to default"
                                />
                                <Button
                                    className="ml-3"
                                    dimension="medium"
                                    theme="transparent"
                                    onClick={handleOohPageMessageCancel}
                                    data-testid="outOfHoursMessageCancel"
                                    text="Cancel"
                                    type="button"
                                />
                            </>
                        ) : (
                            <Button
                                icon={{ name: "Pencil" }}
                                onClick={(e): void =>
                                    hasClickedEditButton(
                                        e,
                                        Settings.TEXT_OOH_PAGE,
                                    )
                                }
                                disabled={isDisabled}
                                data-testid="outOfHoursMessageEdit"
                                type="button"
                                text="Edit"
                            />
                        )}{" "}
                    </div>
                }
            >
                <div>
                    <Text>
                        This is the message at the top of the out of hours page.
                        The message is only available during out of hours.
                    </Text>

                    <Accordion
                        header="See where this is on the patient form"
                        icon={{
                            colour: "night",
                            name: "Photo",
                        }}
                        className="mb-3"
                        buttonProps={{
                            onClick: (e): void =>
                                handleAccordionClick(e, Settings.TEXT_OOH_PAGE),
                        }}
                    >
                        <img
                            className="w-100"
                            src={i("patientTriage/ooh-message-location.png")}
                            alt="Location of out of hours message in patient triage flow"
                        />
                    </Accordion>

                    <div className="mb-3">
                        <Feedback
                            colour="secondary"
                            title="When editing the message, remember to:"
                            content={
                                <ul className="pl-3 mb-0">
                                    <li>
                                        remind there may be a delay in
                                        responding because of the time of day
                                    </li>
                                    <li>
                                        remind the patients that they can still
                                        submit a non-urgent request
                                    </li>
                                    <li>
                                        encourage to use other local services
                                    </li>
                                    <li>not include any website links</li>
                                </ul>
                            }
                        />
                    </div>

                    <div
                        className="p-2"
                        style={{
                            backgroundColor: Tokens.COLOURS.greyscale.cloud,
                        }}
                    >
                        <Text variant="label" props={{ className: "mb-2" }}>
                            Your current message:
                        </Text>

                        <Feedback
                            colour="warning"
                            content={
                                <>
                                    {hasClickedEditOohPage ? (
                                        <TextareaAutosize
                                            cols={80}
                                            className="w-100"
                                            name="outOfHoursCopy"
                                            id="outOfHoursCopy"
                                            data-testid="outOfHoursCopy"
                                            disabled={isDisabled}
                                            onChange={(e) =>
                                                handleUpdateString(
                                                    e,
                                                    Settings.TEXT_OOH_PAGE,
                                                )
                                            }
                                            value={
                                                outOfHoursPageMessage ??
                                                status.outOfHoursPageMessage ??
                                                defaultOohText
                                            }
                                        />
                                    ) : (
                                        <Text skinny>
                                            {outOfHoursPageMessage ??
                                                status.outOfHoursPageMessage ??
                                                defaultOohText}
                                        </Text>
                                    )}

                                    <UrgentHelpSection region={status.region} />
                                </>
                            }
                        />
                    </div>
                </div>
            </Card>
        </form>
    );
};
