import {
    AppSettings,
    Log,
    initialiseAppInsightService,
    initialiseRudderStackService,
    isAppInsightsInitialized,
    isRudderStackInitialized,
} from "@accurx/shared";
import * as Sentry from "@sentry/browser";
import { Organisation } from "domains/auth/types/Organisation";
import userflow from "userflow.js";

import { User } from "./types/User";

export const registerUserflowGroup = ({
    user,
    workspace,
}: {
    user: User;
    workspace: Organisation;
}) => {
    let isInit = false;

    if (!userflow.isIdentified()) {
        isInit = loadUserflow(user);
    }

    /**
     * Identifying a user is an async operation in Userflow, but we still want to send workspace data so the user's
     * first event can be associated to a given workspace. Hence, as long as the `loadUserflow` function returns
     * `true`, it means that Userflow has been initialised synchronously, and we can safely send workspace data.
     */
    if (userflow.isIdentified() || isInit)
        void userflow.group(
            `${workspace.orgId}`,
            {
                name: workspace.organisationName,
                nationalCode: workspace.nationalCode,
                workspaceName: workspace.organisationName,
                userOrganisationId: workspace.orgId,
                organisationName: workspace.organisationNodeName,
            },
            {
                membership: {
                    isAdminUser: workspace.settings.isAdminUser,
                    isApprovedUser: workspace.settings.isApprovedUser,
                },
            },
        );
};

const loadUserflow = (user: User) => {
    const USERFLOW_TOKEN =
        AppSettings.accurxEnvironment === "production"
            ? process.env.REACT_APP_WEB_CLIENT_PRODUCTION_USERFLOW_TOKEN
            : process.env.REACT_APP_WEB_CLIENT_DEVELOPMENT_USERFLOW_TOKEN;

    if (USERFLOW_TOKEN === undefined) {
        Log.error("No Userflow token found");
        return false;
    }

    try {
        const userId = user.analyticsUserIdentifier;

        if (!userflow.isIdentified() && userId) {
            userflow.init(USERFLOW_TOKEN);

            void userflow.identify(userId, {
                userId,
                is2FAed: {
                    set: String(user.is2FAed),
                    data_type: "boolean",
                },
                isSetupFor2Fa: {
                    set: String(user.isSetupFor2Fa ?? false),
                    data_type: "boolean",
                },
            });
        }

        return true;
    } catch (e) {
        Log.error("Error while initialising Userflow", {
            originalException: e,
        });
        return false;
    }
};

/**
 * Helper function to load all analytics
 * Currently we are loading Rudderstack and AppInsight
 */
const loadAnalytics = () => {
    // Load Rudderstack analytics
    if (!isRudderStackInitialized()) {
        initialiseRudderStackService();
    }

    // Load AppInsight analytics
    if (!isAppInsightsInitialized()) {
        initialiseAppInsightService();
    }
};

export const identify = ({
    user,
    isDesktopUser = false,
}: {
    user: User;
    isDesktopUser?: boolean;
}) => {
    if (user.analyticsUserIdentifier) {
        window.accurxUserId = user.analyticsUserIdentifier;
        Sentry.setUser({ id: user.analyticsUserIdentifier });
    }

    // If the user is on a desktop device, they have already consented to desktop cookies and
    // they do not need to also accept cookies in Accurx Web.
    if (isDesktopUser || user.onboarding?.hasAcceptedCookies === true) {
        loadAnalytics();

        // If user is in a workspace, then the SelectedOrganisationProvider will be responsible for loading Userflow
        // to include workspace data
        if (user.analyticsUserIdentifier && !user.organisations.length) {
            loadUserflow(user);
        }
    }

    if (window.Intercom && user.intercom !== undefined) {
        // Relevant Intercom docs
        // Identity verification - https://developers.intercom.com/installing-intercom/docs/enable-identity-verification-on-your-web-product
        // Logged in users - https://www.intercom.com/help/en/articles/168-install-intercom-in-your-product-for-logged-in-users
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.Intercom("update", {
            name: user.fullName,
            email: user.email,
            // Set user_id because there are duplicate users for some email addresses in intercom and this causes issues.
            // user_id identifies the user, user_hash authenticates the user. Both are required by intercom.
            user_id: user.analyticsUserIdentifier,
            // user_hash is a HMAC (https://en.wikipedia.org/wiki/HMAC) which has been generated in Accurx's Server,
            // using a secret from intercom. This allows intercom to verify the user was logged into Accurx and haven't
            // faked the value of user_id
            user_hash: user.intercom.userIdHash,
            created_at: user.intercom.createdAtUnixTimeSeconds,
        });
    }
};
