import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { useLoadPractices } from "app/organisations/hooks";
import { getPatientTriageStatus } from "app/practices/patientTriage/PatientTriage.actions";

import { PatientTriageSettingsContainer } from "../components/PatientTriageSettings.container";

export const PatientTriageSettingsPage = () => {
    useLoadPractices();

    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch();
    const { orgId } = useCurrentWorkspace();

    useEffect(() => {
        /**
         * Temporary: Data is fetched in this parent wrapper rather than the container to replicate the same behaviour
         * as the PatientTriage component in Manage Org.
         */
        dispatch(getPatientTriageStatus(String(orgId)));
    }, [dispatch, orgId]);

    return (
        <StyledContainer>
            <PageHeader title="Settings" type={PageHeaderType.ListPage} />
            <PatientTriageSettingsContainer
                history={history}
                location={history.location}
                match={match}
            />
        </StyledContainer>
    );
};
