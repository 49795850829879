import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";

export const mutateCloseWindow = createEndpoint<unknown>(
    "MutateCloseWindow",
    async (context) => {
        await NativeApi.request({
            transport: context.transport,
            request: {
                type: "MutateCloseWindow",
                workspaceId: context.workspaceId,
            },
        });
    },
);
