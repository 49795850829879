import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledDoneButton = styled(Pill.Button)`
    position: relative;
`;

export const StyledDoneLoadingIconWrapper = styled.span<{
    $visibility?: "visible" | "hidden";
}>`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ $visibility = "visible" }) => `visibility: ${$visibility}`}
`;

export const StyledReopenText = styled(Pill.Text)`
    padding: 0;
`;
