import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import { usePoller } from "domains/concierge/internal/hooks/usePoller";
import { ConciergeUpdates } from "domains/concierge/testing";

export const useInitialSummariesPoller = ({
    onSuccess,
}: {
    onSuccess: (updates: ConciergeUpdates) => void;
}) => {
    const { workspaceId } = useConciergeMeta();

    const fetchFn = useCallback(async () => {
        const { updates } = await api.getInitialSummaries({
            workspaceId: workspaceId,
        });

        onSuccess(updates);
    }, [workspaceId, onSuccess]);

    return usePoller({
        name: "InitialSummariesPoller",
        onlyPollWhenOffline: true,
        refreshRate: 1_800_000, // 30 minutes
        fetchFn,
    });
};
