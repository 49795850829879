import { Conversation } from "domains/concierge/types";

export type UpdatedConversation = {
    updatedConversation: Conversation;
};

export const getConversationForSaveToRecord = (
    conversations: Conversation[],
): UpdatedConversation => {
    const conversation = conversations[0];
    const item = conversation.items[0];

    if (
        !item?.contentType ||
        (item?.contentType !== "PatientSms" &&
            item.contentType !== "PatientEmail" &&
            item.contentType !== "NhsAppMessage")
    ) {
        throw new Error("Save to record error! Item missing");
    }

    return {
        updatedConversation: conversation,
    };
};
