import { ReactNode } from "react";

import { useTransport } from "@accurx/transport";

import { ConciergeReduxProvider } from "./conciergeReduxProvider";
import { ConciergeClinicianUnassignedTeamInitializer } from "./initialisers/ConciergeClinicianUnassignedTeamInitializer";
import { ConciergeClinicianUnreadItemsInitializer } from "./initialisers/ConciergeClinicianUnreadItemsInitializer";
import { ConciergeConnectionStateChangeInitializer } from "./initialisers/ConciergeConnectionStateChangeInitializer";
import { ConciergeConversationItemUpdatesInitializer } from "./initialisers/ConciergeConversationItemUpdatesInitializer";
import { ConciergeInitialSummariesInitializer } from "./initialisers/ConciergeInitialSummariesInitializer";
import { ConciergeTeamUpdatesInitializer } from "./initialisers/ConciergeTeamUpdatesInitializer";
import { ConciergeTicketUnreadItemsInitialiser } from "./initialisers/ConciergeTicketUnreadItemsInitializer";
import { ConciergeUnreadsAndConversationGroupCountCleanupInitializer } from "./initialisers/ConciergeUnreadsAndConversationGroupCountCleanupInitializer";
import { ConciergeUserUpdatesInitializer } from "./initialisers/ConciergeUserUpdatesInitializer";
import { ConciergeMeta } from "./types/ConciergeMeta";

export const ConciergeProvider = ({
    workspaceId,
    userId,
    isTrustedDevice,
    children,
    withClinicianMessaging,
    shouldInitialiseUnreadsAndConversationGroups,
}: ConciergeMeta & {
    withClinicianMessaging?: boolean;
    children: ReactNode;
    /**
     * In compose-toolbar, we want a lighter version of the inbox
     * so we spin up a concierge layer that does not care about
     * recalculating unread counts and conversation groups
     */
    shouldInitialiseUnreadsAndConversationGroups: boolean;
}) => {
    const { connectionState } = useTransport();

    return (
        <ConciergeReduxProvider
            workspaceId={workspaceId}
            userId={userId}
            isTrustedDevice={isTrustedDevice}
        >
            {children}

            {/* Respond to signalR changes and set the in concierge state */}
            <ConciergeConnectionStateChangeInitializer />
            {shouldInitialiseUnreadsAndConversationGroups && (
                <ConciergeUnreadsAndConversationGroupCountCleanupInitializer />
            )}

            {/* CLINICIAN MESSAGING 
                no difference between trusted or untrusted devices
            */}
            {withClinicianMessaging && (
                <>
                    {/* Pollers */}
                    {shouldInitialiseUnreadsAndConversationGroups && (
                        <ConciergeClinicianUnreadItemsInitializer />
                    )}
                    <ConciergeClinicianUnassignedTeamInitializer />
                </>
            )}

            {/* PATIENT MESSAGING
                For trusted devices, the Concierge layer is initialized after 
                SignalR initialization is complete 
                
                Run initialization code needed by "trusted" concierge layer clients.
                Trusted, as of Jan 2024, = 2fa'd or with a Desktop cookie.
                We currently only spin up unread items + initial summaries pollers, and
                SignalR subscriptions, for trusted users.
                *
                We delay this initialization until after we have a confirmed SignalR
                connection, (or have failed to connect three times) to avoid the case
                where the initial unread items endpoint returns before SignalR has
                connected and is immediately marked as stale.
                *
                This is modelled as a null component rather than a hook so it can
                sit within the ConciergeReduxProvider more easily.
            */}
            {isTrustedDevice && connectionState !== "Initialising" && (
                <>
                    {/* Pollers */}
                    <ConciergeInitialSummariesInitializer
                        recalculateUnreadCounts={
                            shouldInitialiseUnreadsAndConversationGroups
                        }
                    />
                    {shouldInitialiseUnreadsAndConversationGroups && (
                        <ConciergeTicketUnreadItemsInitialiser />
                    )}
                    {/* SignalR updates */}
                    <ConciergeConversationItemUpdatesInitializer />
                    <ConciergeTeamUpdatesInitializer
                        recalculateUnreadCounts={
                            shouldInitialiseUnreadsAndConversationGroups
                        }
                    />
                    <ConciergeUserUpdatesInitializer />
                </>
            )}
        </ConciergeReduxProvider>
    );
};
