import { ReactNode, useMemo } from "react";

import {
    ConciergeContext,
    ConciergeMetaProvider,
} from "domains/concierge/internal/context";
import { createStore } from "domains/concierge/internal/store";
import { Provider as ReduxProvider } from "react-redux";

import { ConciergeMeta } from "./types/ConciergeMeta";

export const ConciergeReduxProvider = ({
    workspaceId,
    userId,
    isTrustedDevice,
    children,
}: ConciergeMeta & { children: ReactNode }) => {
    const store = useMemo(
        () => createStore({ workspaceId, userId, isTrustedDevice: true }),
        [workspaceId, userId],
    );

    return (
        <ConciergeMetaProvider value={{ workspaceId, userId, isTrustedDevice }}>
            <ReduxProvider context={ConciergeContext} store={store}>
                {children}
            </ReduxProvider>
        </ConciergeMetaProvider>
    );
};
