import { useCurrentWorkspace } from "@accurx/auth";
import { TextArea } from "domains/compose/components/Textarea";

import { ComposeMessageContentPlaceholder } from "./components/ComposeMessageContentPlaceholder";
import { MessageHeader } from "./components/MessageHeader";

export const ComposeTextArea = ({
    onUserTyping,
}: {
    onUserTyping?: () => void;
}) => {
    const workspace = useCurrentWorkspace();

    return (
        <TextArea.Container>
            <MessageHeader />
            <TextArea.EditableMessage onUserTyping={onUserTyping}>
                <ComposeMessageContentPlaceholder />
            </TextArea.EditableMessage>
            <TextArea.Footer>
                <TextArea.UneditableText>
                    {workspace.organisationName}
                </TextArea.UneditableText>
            </TextArea.Footer>
        </TextArea.Container>
    );
};
