import { useCurrentWorkspace } from "@accurx/auth";
import { useMessageTemplatesQuery } from "@accurx/message-templates";
import { Log } from "@accurx/shared";

import { SocketEvents } from "shared/hubClient/HubClient";
import { OrganisationEntityChangedType } from "shared/hubClient/payload.types";

import { useSignalRSubscription } from "./useSignalRSubscription";

/** Listens for SignalR events that say templates have been updated and refetches data */
export const useMessageTemplatesSubscription = () => {
    const workspace = useCurrentWorkspace();
    const { refetch } = useMessageTemplatesQuery(
        {
            workspaceId: workspace.orgId,
        },
        {
            enabled: false,
        },
    );

    useSignalRSubscription(({ transport }) => {
        const subscription = transport.subscribe({
            methodName: SocketEvents.OrganisationEntityChanged,
            eventFilter: (event) =>
                event.payload.organisationId === workspace.orgId,
            onEvent: (event) => {
                const isTemplateChange =
                    event.payload.type ===
                    OrganisationEntityChangedType.Templates;

                if (isTemplateChange) {
                    Log.info("Refetching templates after update");
                    void refetch();
                }
            },
        });

        return subscription.unsubscribe;
    });
};
