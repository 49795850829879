import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import DeliveryReceipts from "./DeliveryReceipts";
import * as actions from "./DeliveryReceipts.actions";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => ({
    practiceId: state.account.selectedOrganisation,
    loadingStatus: state.deliveryReceiptsChain.loadingStatus,
    receipts: state.deliveryReceiptsChain.receipts,
    receiptHeaderType: state.deliveryReceiptsChain.receiptHeaderType,
    isRestrictedToOwnReceipts:
        state.deliveryReceiptsChain.isRestrictedToOwnReceipts,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export const DeliveryReceiptsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeliveryReceipts);

export default DeliveryReceiptsContainer;
