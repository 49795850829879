import React from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { useCompose } from "domains/compose/context";
import { TemplateItem } from "domains/compose/types";
import { userflowIds } from "domains/compose/userflow";
import { getTemplateType } from "domains/compose/utils/getTemplateType";

import { EllipsisText } from "./RemoteTemplateButton.styles";

type RemoveTemplateButtonProps = {
    template: TemplateItem;
    onClick: () => void;
};

export const RemoveTemplateButton = React.forwardRef<
    HTMLButtonElement,
    RemoveTemplateButtonProps
>(({ template, onClick }, ref) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { state } = useCompose();

    const isQuestionnaire = template.type === "QuestionnaireTemplate";

    return (
        <Pill.Button
            ref={ref}
            radius="regular"
            onClick={() => {
                onClick();
                track("TemplateRemove Button Click", {
                    ...nativeTrackingFields,
                    templateId: template.value.id,
                    templateName: template.value.title,
                    templateGroup: template.value.heading,
                    templateType: getTemplateType(template),
                    conversationParticipant: state.conversationParticipant,
                    eventVersion: 3,
                });
            }}
            aria-label={
                isQuestionnaire
                    ? "Remove questionnaire"
                    : "Remove message template"
            }
            data-userflow-id={userflowIds.templatesButton}
        >
            <UI.Ds.Grid columns="min-content 1fr min-content">
                <Pill.Icon
                    name={isQuestionnaire ? "Questionnaire" : "MessageWrite"}
                    colour="zinc"
                    theme="Line"
                    props={{
                        "aria-hidden": true,
                    }}
                />
                <EllipsisText skinny variant="preview" colour="metal">
                    {template.value.title}
                </EllipsisText>
                <Pill.Icon
                    name="Cross"
                    colour="red"
                    theme="Line"
                    props={{
                        "aria-hidden": true,
                    }}
                />
            </UI.Ds.Grid>
        </Pill.Button>
    );
});
