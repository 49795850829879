import { useFeatureFlag } from "@accurx/auth";

import { ChangeLinkType, TemplateType } from "../types";
import {
    getChangeLinkText,
    getCharacterAndFragmentCountInfo,
    getCharacterCount,
    getDefaultAppointmentReminderSignature,
    getGreetingPreviewText,
    getTemplatePreviewText,
} from "../utils";

type MessagePartsProps = {
    templateType: TemplateType;
    customMessage: string;
    postAppointmentMessageBody: string;
    workspaceHasMultipleSites: boolean;
    workspaceName: string;
    changeLinkType: ChangeLinkType;
};

export const useMessageParts = ({
    templateType,
    customMessage,
    postAppointmentMessageBody,
    workspaceHasMultipleSites,
    workspaceName,
    changeLinkType,
}: MessagePartsProps) => {
    const useTelephoneOrVideoCopy = useFeatureFlag(
        "AppointmentRemindersUseTelephoneAndVideoCopy",
    );

    const greeting = getGreetingPreviewText();

    const templateText = getTemplatePreviewText({
        templateType,
        withLocation: workspaceHasMultipleSites,
        withTelephoneOrVideoCopy: useTelephoneOrVideoCopy,
    });
    const messageSignoff =
        getDefaultAppointmentReminderSignature(workspaceName);

    const getFullReminderMessage = (messageValue: string) => {
        return [
            greeting,
            templateText,
            messageValue,
            getChangeLinkText(changeLinkType),
            messageSignoff,
        ].join("\n");
    };

    const reminderMessage = getFullReminderMessage(customMessage);

    const getFullPostAppointmentMessage = (messageValue: string) =>
        [greeting, messageValue, messageSignoff].join("\n");

    const postAppointmentMessage = getFullPostAppointmentMessage(
        postAppointmentMessageBody,
    );

    return {
        fullMessage: reminderMessage,
        messageParts: {
            greeting,
            templateText,
            changeLinkText: getChangeLinkText(changeLinkType),
            messageSignoff,
        },
        reminderMessage: {
            getFullMessageLength: (updatedMessageBody: string) =>
                getCharacterCount(getFullReminderMessage(updatedMessageBody)),
            /**
             * This is an inaccurate way of calculating the character/fragment count
             * Actual character count is likely to be higher when including
             * fields such as patient name, date/time/location and rebook/cancel link
             */
            characterCount: getCharacterCount(reminderMessage),
            charAndFragmentInfo:
                getCharacterAndFragmentCountInfo(reminderMessage),
        },
        postAppointment: {
            getFullMessageLength: (updatedMessageBody: string) =>
                getCharacterCount(
                    getFullPostAppointmentMessage(updatedMessageBody),
                ),
            characterCount: getCharacterCount(postAppointmentMessage),
            charAndFragmentInfo: getCharacterAndFragmentCountInfo(
                postAppointmentMessage,
            ),
        },
    };
};
