import { ChangeLinkType } from "../types";

/**
 * @deprecated use `getGreetingPreviewText` and `getTemplateTextPreview` instead
 */
export const getDefaultAppointmentReminderGreeting = (
    withLocation: boolean,
): string =>
    `Dear [Patient],\nThis is a reminder of your appointment which is booked for [date] at [time]${
        withLocation ? " at [location]." : "."
    }`;

/**
 * @deprecated use `getGreetingPreviewText` and `getTemplateTextPreview` instead
 */
export const getTelephoneAppointmentReminderGreeting = () =>
    `Dear [Patient],\nThis is a reminder of your telephone appointment which is booked for [date], between [2-hour time window].`;

export const defaultCancellationString =
    "If you need to cancel, please follow this link: (link will autogenerate here)";
export const getChangeLinkText = (changeLinkType: ChangeLinkType) =>
    `If you need to ${changeLinkType}, please follow this link: (link will autogenerate here)`;

export const getDefaultAppointmentReminderSignature = (
    practiceName: string,
): string => `Thanks,\n${practiceName}`;

export const getGreetingPreviewText = () => `Dear [Patient],`;

export function getTemplatePreviewText({
    templateType,
    withLocation,
    withTelephoneOrVideoCopy,
}: {
    templateType: "Telephone" | "FaceToFace";
    withLocation?: boolean;
    withTelephoneOrVideoCopy?: boolean;
}) {
    if (templateType === "Telephone" && withTelephoneOrVideoCopy === true) {
        return "This is a reminder of your telephone or video appointment which is booked for [date], between [2-hour time window].";
    }

    if (templateType === "Telephone") {
        return "This is a reminder of your telephone appointment which is booked for [date], between [2-hour time window].";
    }

    return `This is a reminder of your appointment which is booked for [date] at [time]${
        withLocation ? " at [location]" : ""
    }.`;
}
