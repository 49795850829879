import { TextArea } from "domains/compose/components/Textarea";
import { NHSAdviceLink } from "domains/compose/types";

import {
    NHS_ADVICE_TEXT,
    PATIENT_RESPONSE_TEXT,
    QUESTIONNAIRE_TEXT,
    SELFBOOK_TEXT,
    VIEW_ATTACHMENT_TEXT,
    VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT,
} from "../../Compose.constants";
import { StyledMessageContentPlaceholderWrapper } from "./MessageContentPlaceholder.styles";

const LINK_WILL_APPEAR_HERE_PLACEHOLDER = "[link will appear here]";

const PATIENT_RESPONSE_PLACEHOLDER = `${PATIENT_RESPONSE_TEXT.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;
const VIEW_ATTACHMENTS_PLACEHOLDER = `${VIEW_ATTACHMENT_TEXT.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;
const VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_PLACEHOLDER = `${VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;
const NHS_ADVICE_PLACEHOLDER = NHS_ADVICE_TEXT.trim();
const QUESTIONNAIRE_PLACEHOLDER = `${QUESTIONNAIRE_TEXT.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER}`;

const generateSelfBookPlaceholder = (timeoutDays: number) => {
    const puralText = timeoutDays === 1 ? "" : "s";
    return `${SELFBOOK_TEXT.prepend.trim()} ${LINK_WILL_APPEAR_HERE_PLACEHOLDER} ${SELFBOOK_TEXT.append
        .replace("X", timeoutDays.toString())
        .replace("S", puralText)
        .trim()}`;
};

export const MessageContentPlaceholder = ({
    nhsAdviceLink,
    isPatientResponseEnabled,
    hasAttachments,
    isQuestionnaire,
    hasSelfBookLink,
    selfBookTimeoutDays,
}: {
    nhsAdviceLink: NHSAdviceLink | null;
    isPatientResponseEnabled: boolean;
    hasAttachments: boolean;
    isQuestionnaire: boolean;
    hasSelfBookLink: boolean;
    selfBookTimeoutDays?: number;
}) => {
    if (
        !nhsAdviceLink &&
        !isPatientResponseEnabled &&
        !hasAttachments &&
        !isQuestionnaire &&
        !hasSelfBookLink
    ) {
        return null;
    }

    return (
        <StyledMessageContentPlaceholderWrapper>
            {nhsAdviceLink !== null && (
                <TextArea.UneditableText>
                    {NHS_ADVICE_PLACEHOLDER} {nhsAdviceLink.name} <br />
                    {nhsAdviceLink.url}
                </TextArea.UneditableText>
            )}
            {(isPatientResponseEnabled || hasAttachments) && (
                <TextArea.UneditableText>
                    {isPatientResponseEnabled && hasAttachments ? (
                        VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_PLACEHOLDER
                    ) : (
                        <>
                            {isPatientResponseEnabled &&
                                PATIENT_RESPONSE_PLACEHOLDER}
                            {hasAttachments && VIEW_ATTACHMENTS_PLACEHOLDER}
                        </>
                    )}
                </TextArea.UneditableText>
            )}
            {isQuestionnaire && (
                <TextArea.UneditableText>
                    {QUESTIONNAIRE_PLACEHOLDER}
                </TextArea.UneditableText>
            )}
            {hasSelfBookLink && selfBookTimeoutDays && (
                <TextArea.UneditableText>
                    {generateSelfBookPlaceholder(selfBookTimeoutDays)}
                </TextArea.UneditableText>
            )}
        </StyledMessageContentPlaceholderWrapper>
    );
};
