import React, { ChangeEvent, useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import {
    Card,
    FormFieldWrapper,
    Option,
    SelectItem,
    SingleSelect,
    StackPanel,
    Text,
    Tokens,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { calculateFragments } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    fetchFloreySnomedCodes,
    fetchUserGroups,
    updateMessage,
    updateSelectedAssigneeUserGroupId,
    updateSelectedAssigneeUserGroupName,
    updateSelectedBatchOptionToSaveToRecord,
    updateSelectedFloreyDefaultAssigneeId,
    updateSelectedFloreyId,
    updateSelectedFloreyName,
    updateSelectedFloreyOwner,
    updateSelectedFloreySaveToRecord,
    updateSelectedFloreySnomed,
} from "app/batchMessage/gp/BatchMessage.actions";
import {
    StyledLayoutWithFooter,
    StyledTextareaAutosize,
} from "app/batchMessage/gp/BatchMessage.styles";
import {
    BatchType,
    FloreyOwner,
    FloreyTemplate,
} from "app/batchMessage/gp/BatchMessage.types";
import {
    BatchRoute,
    findBatchRoute,
} from "app/batchMessage/gp/BatchMessage.utils";
import { BatchMessageHeader } from "app/batchMessage/gp/components/BatchMessageHeader";
import { ComposeMsgInfo } from "app/batchMessage/gp/components/ComposeMsgInfo";
import {
    ComposeOptionToSaveToRecord,
    useSetSaveBatchToRecord,
} from "app/batchMessage/gp/components/ComposeOptionToSaveToRecord";
import {
    DEFAULT_FLOREY_HEADER,
    FOOTER_FLOREY,
    PLACEHOLDER_LINK_AUTO,
} from "app/batchMessage/gp/components/compose/BatchMessageCompose.constants";
import { Z_INDEX } from "app/batchMessage/gp/components/compose/BatchMessageCompose.styles";
import { MessageErrorType } from "app/batchMessage/gp/components/compose/BatchMessageCompose.types";
import {
    calculateMessageLength,
    isValidMessage,
} from "app/batchMessage/gp/components/compose/BatchMessageCompose.utils";
import { SnomedCodeFeedback } from "app/batchMessage/gp/components/snomedCodes/SnomedCodeFeedback";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { MessageComposeWrapper } from "../components/MessageComposeWrapper";
import { ComposeFloreyDropdown } from "./ComposeFloreyDropdown";

export const ComposeFlorey = (): JSX.Element => {
    useAccurxWebTitle("Write a batch Florey invite");

    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Feature flags
    const waitingListValidationEnabled = useIsFeatureEnabled(
        FeatureName.WebWaitingListValidation,
    );

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const practiceName = useAppSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices?.selectedPractice,
            )?.name ?? "",
    );
    const initialSelectedFloreyName = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreyName,
    );
    const initialSelectedFloreyId = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreyId,
    );
    const maximumPatientGreetingLength = useAppSelector(
        ({ batchMessage }) =>
            batchMessage.batchMessageDetails.maximumPatientGreetingLength,
    );
    const patientMessage = useAppSelector(
        ({ batchMessage }) => batchMessage.patientMessage,
    );
    const selectedFloreySnomedCode = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreySnomedCode,
    );
    const selectedFloreySaveToRecord = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreySaveToRecord,
    );
    const initialSelectedFloreyOwner = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreyOwner,
    );
    const initialSelectedFloreyDefaultAssigneeId = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreyDefaultAssigneeId,
    );
    const gettingFloreySnomedCodesStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingFloreySnomedCodesStatus,
        shallowEqual,
    );
    const gettingUserGroupsStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingUserGroupsStatus,
        shallowEqual,
    );
    const floreySnomedCodes = useAppSelector(
        ({ batchMessage }) => batchMessage.floreySnomedCodes,
        shallowEqual,
    );
    const userGroups = useAppSelector(
        ({ batchMessage }) => batchMessage.userGroups,
        shallowEqual,
    );
    const savedSelectedAssigneeId = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedAssigneeUserGroupId,
        shallowEqual,
    );

    // States: to be saved to redux
    const [messageBody, setMessageBody] = useState(patientMessage);
    const [saveFloreyToRecord, setSaveFloreyToRecord] = useState(
        selectedFloreySaveToRecord,
    );
    const [selectedSnomedCode, setSelectedSnomedCode] = useState(
        selectedFloreySnomedCode,
    );
    const [selectedFloreyId, setSelectedFloreyId] = useState(
        initialSelectedFloreyId,
    );
    const [selectedFloreyName, setSelectedFloreyName] = useState(
        initialSelectedFloreyName,
    );
    const [selectedFloreyOwner, setSelectedFloreyOwner] = useState(
        initialSelectedFloreyOwner,
    );
    const [selectedAssigneeUserGroupId, setSelectedAssigneeUserGroupId] =
        useState(savedSelectedAssigneeId);
    const [selectedAssigneeUserGroupName, setSelectedAssigneeUserGroupName] =
        useState("");
    const [selectedFloreyDefaultAssigneeId, setSelectedDefaultAssigneeId] =
        useState(initialSelectedFloreyDefaultAssigneeId);

    // States: local only
    const [validMessageBody, setValidMessageBody] = useState(false);
    const [messageErrorMessage, setMessageErrorMessage] = useState("");
    const [messageErrorType, setMessageErrorType] = useState(
        MessageErrorType.None,
    );
    const [characterCount, setCharacterCount] = useState(0);
    const [selectedFloreyDisplay, setSelectedFloreyDisplay] = useState(
        initialSelectedFloreyName || DEFAULT_FLOREY_HEADER,
    );

    function optionsWithDefaultGroupMarked(defaultAssigneeId: string) {
        return userGroups.map((option: Option) => {
            return option.value === defaultAssigneeId
                ? {
                      label: `${option.label} (default)`,
                      value: option.value,
                  }
                : option;
        });
    }

    const optionsWithDefault = optionsWithDefaultGroupMarked(
        selectedFloreyDefaultAssigneeId.toString(),
    );

    useEffect(() => {
        checkMessageTextAndSetState(messageBody);
    });

    // Fetch Florey Snomed Codes
    useEffect(() => {
        if (
            gettingFloreySnomedCodesStatus === UpdatingStatus.Initial &&
            practiceId
        ) {
            dispatch(fetchFloreySnomedCodes(practiceId));
        }
    }, [dispatch, practiceId, gettingFloreySnomedCodesStatus]);

    // Fetch User Groups
    useEffect(() => {
        if (gettingUserGroupsStatus === UpdatingStatus.Initial && practiceId) {
            dispatch(fetchUserGroups(practiceId));
        }
    }, [dispatch, practiceId, gettingUserGroupsStatus]);

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchPatientMessageReviewPageView({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
        });
    }, [analyticsLoggedInProps, history.location.pathname]);

    const { shouldSaveBatchToRecord, setShouldSaveBatchToRecord } =
        useSetSaveBatchToRecord();

    const selectFlorey = (florey: FloreyTemplate, floreyOwner: FloreyOwner) => {
        setSelectedFloreyId(florey.id ?? "");
        setSelectedFloreyName(florey.title ?? "");
        setSelectedFloreyDisplay(florey.title ?? "");

        setSelectedSnomedCode(florey.medicalCodeIdentity?.code ?? "");
        setMessageBody(florey.templateBody);
        setSelectedFloreyOwner(floreyOwner);
        setSelectedDefaultAssigneeId(florey.defaultUserGroupId);
        setSelectedAssigneeUserGroupId(florey.defaultUserGroupId);

        const selectedUserGroupNames = userGroups
            .filter(
                (option) =>
                    option.value === florey.defaultUserGroupId.toString(),
            )
            .map((option) => option.label);
        if (selectedUserGroupNames.length > 0) {
            setSelectedAssigneeUserGroupName(selectedUserGroupNames[0] ?? "");
        }
    };

    const selectAssigneeUserGroup = (option: Option) => {
        setSelectedAssigneeUserGroupId(option.value ?? "");
        setSelectedAssigneeUserGroupName(option.label ?? "");
    };

    const updateMessageText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        checkAndSetMessageText(e.target.value);
    };

    const checkAndSetMessageText = (messageText: string) => {
        checkMessageTextAndSetState(messageText);
        setMessageBody(messageText);
    };

    const checkMessageTextAndSetState = (textToCheck: string) => {
        const totalCharacterCount = calculateMessageLength(
            BatchType.FLOREY,
            maximumPatientGreetingLength,
            textToCheck.length,
            practiceName.length,
        );
        setCharacterCount(totalCharacterCount);

        const validation = isValidMessage(textToCheck, totalCharacterCount);
        setValidMessageBody(validation.validMessageBody);
        setMessageErrorMessage(validation.messageErrorMessage);
        setMessageErrorType(validation.messageErrorType);
    };

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: false,
        });
    };

    const handleSave = () => {
        // TODO: This needs to be refactored into one action
        dispatch(updateMessage(messageBody));
        dispatch(updateSelectedFloreySaveToRecord(saveFloreyToRecord));
        dispatch(
            updateSelectedFloreyDefaultAssigneeId(
                selectedFloreyDefaultAssigneeId,
            ),
        );
        dispatch(updateSelectedFloreySnomed(selectedSnomedCode));
        dispatch(updateSelectedFloreyId(selectedFloreyId));
        dispatch(updateSelectedFloreyName(selectedFloreyName));
        dispatch(updateSelectedFloreyOwner(selectedFloreyOwner));
        dispatch(
            updateSelectedAssigneeUserGroupId(selectedAssigneeUserGroupId),
        );
        dispatch(
            updateSelectedAssigneeUserGroupName(selectedAssigneeUserGroupName),
        );
        dispatch(
            updateSelectedBatchOptionToSaveToRecord(shouldSaveBatchToRecord),
        );
    };

    const handleSelectedGroup = (selected: string) => {
        const option = userGroups.filter((x) => x.value === selected)[0];
        selectAssigneeUserGroup(option);
    };

    /**
     * calculate fragments by passing in the body of the
     * batch message as well as padding it to length we assume it is
     * accounting for things like practice name and message footer
     */
    const fragmentCount = calculateFragments(
        `${messageBody}${"a".repeat(
            Math.abs(characterCount - messageBody.length),
        )}`,
    );

    const routes = findBatchRoute(BatchRoute.COMPOSE);
    const noFloreySelected =
        !selectedFloreyDefaultAssigneeId && !savedSelectedAssigneeId;
    return (
        <StyledLayoutWithFooter>
            <Breadcrumb title="Batch Messaging Florey" />
            <div className="row mb-4">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <BatchMessageHeader
                        title={"Write a Florey invite"}
                        stepNumber={1}
                    />
                </div>
                <div className="col-12 col-lg-6 offset-lg-3">
                    <Card isElevated={false} spacing={2}>
                        <ComposeFloreyDropdown
                            selectedFloreyId={selectedFloreyId}
                            saveFloreyToRecord={saveFloreyToRecord}
                            selectedFloreyDisplay={selectedFloreyDisplay}
                            selectFlorey={selectFlorey}
                            setSaveFloreyToRecord={setSaveFloreyToRecord}
                            orgId={practiceId}
                        />
                        <FormFieldWrapper
                            label={"Message"}
                            subLabel={
                                "This message will be sent to multiple patients, so please do not include information relating to individual patients."
                            }
                        >
                            <MessageComposeWrapper>
                                <StyledTextareaAutosize
                                    id="message-body"
                                    style={{
                                        outline: "none",
                                        color: Tokens.COLOURS.greyscale.zinc,
                                        marginTop: `${Tokens.SIZES[1]}`,
                                        marginBottom: `${Tokens.SIZES[1]}`,
                                    }}
                                    value={messageBody}
                                    onChange={updateMessageText}
                                    placeholder={"Please enter a message"}
                                    data-testid="message-body"
                                    disabled={false}
                                    rows={4}
                                />
                                {!!selectedFloreyId && (
                                    <Text data-testid="message-footer">
                                        {FOOTER_FLOREY + PLACEHOLDER_LINK_AUTO}
                                    </Text>
                                )}
                            </MessageComposeWrapper>
                        </FormFieldWrapper>
                        <ComposeMsgInfo
                            characterCount={characterCount}
                            fragmentCount={fragmentCount}
                            messageErrorMessage={messageErrorMessage}
                            messageErrorType={messageErrorType}
                        />
                        <div className="mb-4">
                            <ComposeOptionToSaveToRecord
                                shouldSaveBatchToRecord={
                                    shouldSaveBatchToRecord
                                }
                                setShouldSaveBatchToRecord={
                                    setShouldSaveBatchToRecord
                                }
                                batchType={BatchType.FLOREY}
                            />
                        </div>
                        <StackPanel gutter={2}>
                            <FormFieldWrapper
                                label={"Assign responses"}
                                subLabel={
                                    "Assign a group to handle the responses to this batch message."
                                }
                                data-testid="assign-to"
                            >
                                {noFloreySelected && (
                                    <SingleSelect
                                        aria-disabled
                                        placeholder={"No Florey selected"}
                                        disabled
                                        id={"assign-to-disabled"}
                                        onValueChange={(
                                            selected: string,
                                        ): void =>
                                            handleSelectedGroup(selected)
                                        }
                                    >
                                        {userGroups.map((option) => (
                                            <SelectItem
                                                key={option.label}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </SelectItem>
                                        ))}
                                    </SingleSelect>
                                )}
                                {!noFloreySelected && (
                                    <SingleSelect
                                        defaultValue={
                                            selectedAssigneeUserGroupId !== ""
                                                ? selectedAssigneeUserGroupId.toString()
                                                : selectedFloreyDefaultAssigneeId.toString()
                                        }
                                        id={`assign-to-${selectedFloreyName}`}
                                        key={`assign-to-${selectedFloreyName}`}
                                        onValueChange={(
                                            selected: string,
                                        ): void =>
                                            handleSelectedGroup(selected)
                                        }
                                        displayOptionsAboveTrigger
                                    >
                                        {optionsWithDefault.map((option) => (
                                            <SelectItem
                                                key={option.label}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </SelectItem>
                                        ))}
                                    </SingleSelect>
                                )}
                            </FormFieldWrapper>
                            {!!selectedFloreyId &&
                                waitingListValidationEnabled !== true && (
                                    <SnomedCodeFeedback
                                        selectedSnomedCode={selectedSnomedCode}
                                        snomedCodes={floreySnomedCodes}
                                    />
                                )}
                        </StackPanel>
                    </Card>
                    <StepsFooter
                        backText={"Back"}
                        backLink={routes.back}
                        backClickFunction={handleBack}
                        forwardText={"Add a patient list"}
                        forwardLink={routes.forward}
                        forwardClickFunction={handleSave}
                        disabled={!selectedFloreyId || !validMessageBody}
                        zIndex={Z_INDEX.footer}
                    />
                </div>
            </div>
        </StyledLayoutWithFooter>
    );
};

export default ComposeFlorey;
