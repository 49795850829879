import * as UI from "@accurx/design";
import styled from "styled-components";

const StyledSubHeadingContainer = styled(UI.Flex).attrs({
    justifyContent: "space-between",
    alignItems: "center",
})`
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[2]};

    border-bottom: ${UI.Tokens.BORDERS.normal.silver};
    border-top: ${UI.Tokens.BORDERS.normal.silver};
`;

const StyledList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const StyledHeading = styled.div`
    z-index: 1;
    position: sticky;
    top: 0;
    background: ${UI.Tokens.COLOURS.greyscale.cloud};
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[2]};
`;

export const StyledTemplateListSection = {
    Heading: StyledHeading,
    List: StyledList,
    SubHeading: StyledSubHeadingContainer,
};
