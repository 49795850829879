import { useMemo } from "react";

import { useLocation } from "react-router";

export interface SelfBookSettings {
    showCrossOrg: boolean;
    showAppointmentAvailability: boolean;
}

export const initialState: SelfBookSettings = {
    showCrossOrg: false,
    showAppointmentAvailability: false,
};

export const useSelfBookSettings = (): SelfBookSettings => {
    const location = useLocation();

    const query = location.search;

    return useMemo(() => {
        const params = new URLSearchParams(query);
        const retVal = { ...initialState };

        params
            .getAll("selfBookFeatures")
            .flatMap((x) => x.split(","))
            .forEach((flag) => {
                if (flag in initialState) {
                    retVal[flag as keyof SelfBookSettings] = true;
                }
            });

        return retVal;
    }, [query]);
};
