import { ReactNode } from "react";

import {
    QueryClient,
    QueryClientConfig,
    QueryClientProvider,
} from "@tanstack/react-query";

import { queryClientOptions } from "./getOrCreateQueryClient";

const errorSilencer = (): void => undefined;

const queryClientTestOptions: QueryClientConfig = {
    ...queryClientOptions,
    logger: {
        log: console.log,
        warn: console.warn,
        // N.B: Error logging needs to be surpressed inside test environment,
        // because we need to be able to test error cases without the logger
        // cluttering our test output.
        error: errorSilencer,
    },
};

export const createQueryClientTestWrapper = () => {
    const queryClient = new QueryClient(queryClientTestOptions);

    const wrapper = ({ children }: { children: ReactNode }) => (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );

    return { wrapper, queryClient };
};
