import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { useBatchConfiguration } from "@accurx/batch-messaging";

export const useAvailableFilters = () => {
    const workspace = useCurrentWorkspace();

    const isUnifiedNavigationEnabled = useFeatureFlag(
        FeatureName.UnifiedNavigation,
    );

    const { data } = useBatchConfiguration(
        { workspaceId: workspace.orgId, batchEmailFlow: false },
        { enabled: isUnifiedNavigationEnabled, refetchOnMount: false },
    );

    return data?.integratedSystem
        ? "site-name-slot-type"
        : "clinic-appointment-type";
};
