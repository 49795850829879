import { useEffect, useRef, useState } from "react";

import * as UI from "@accurx/design";
import {
    FormFieldFeedback,
    Input,
    SelectItem,
    SingleSelect,
} from "@accurx/inbox-design-library";
import { format } from "date-fns";
import { useAvailableHourOptions } from "domains/compose/hooks/useAvailableHourOptions";

import { DEFAULT_TIME, NUMBER_REGEXP } from "../../constants/constants";
import { ScheduleSendFrame } from "../../types/ScheduleSend.types";
import { getSelectedDateTimeUK } from "../../utils/getSelectedDateTimeUK";
import {
    StyledInputContainer,
    StyledLabelContainer,
    StyledSelectContainer,
} from "./ScheduleSendCustomDate.styles";

const TIME_FRAME_OPTIONS = [
    { label: "days", value: "days" },
    { label: "weeks", value: "weeks" },
    { label: "months", value: "months" },
];

type TimeFrameInput = {
    type: ScheduleSendFrame["timeFrame"]["type"];
    value: string;
};

type AfterTimeFrameProps = {
    dateOverDaysInAdvanceError?: string;
    onChangeValue: (value: ScheduleSendFrame | null) => void;
    setShowEmptyError: (isError: boolean) => void;
    showEmptyError: boolean;
    initialValue?: { timeFrame: TimeFrameInput; sendAtDateTime: string };
};

export const AfterTimeFrame = ({
    dateOverDaysInAdvanceError,
    onChangeValue,
    setShowEmptyError,
    showEmptyError,
    initialValue,
}: AfterTimeFrameProps) => {
    const [timeFrame, setTimeFrame] = useState<TimeFrameInput>(
        initialValue?.timeFrame ?? {
            type: "weeks",
            value: "",
        },
    );

    const initialTime = initialValue?.sendAtDateTime
        ? format(new Date(initialValue.sendAtDateTime), "H")
        : DEFAULT_TIME;

    const [time, setTime] = useState(initialTime);

    const availableTimeOptions = useAvailableHourOptions({
        isToday: timeFrame.value === "0",
    });

    const loaded = useRef(false);

    const [isValueInvalid, setIsValueInvalid] = useState(false);

    useEffect(() => {
        setShowEmptyError(false);

        // If input is cleared
        if (timeFrame.value === "") {
            // not to show this error on initial component load
            if (loaded.current) {
                setIsValueInvalid(true);
            } else {
                loaded.current = true;
            }
            onChangeValue(null);
            return;
        }

        // If input is a number
        if (NUMBER_REGEXP.test(timeFrame.value)) {
            setIsValueInvalid(false);
            const selectedTimeframe = {
                type: timeFrame.type,
                value: parseInt(timeFrame.value, 10),
            };
            const sendAtDateTime = getSelectedDateTimeUK({
                type: "frame",
                timeFrame: selectedTimeframe,
                time,
            });

            onChangeValue({
                type: "frame",
                timeFrame: selectedTimeframe,
                time,
                sendAtDateTime: sendAtDateTime ?? "",
            });
            return;
        } else {
            setIsValueInvalid(true);
            onChangeValue(null);
        }
    }, [timeFrame, time, onChangeValue, setShowEmptyError]);

    useEffect(() => {
        // Reset the selected time if the previous time is no longer available
        if (!availableTimeOptions.some(({ value }) => value === time)) {
            setTime(availableTimeOptions[0].value);
        }
    }, [availableTimeOptions, time]);

    return (
        <UI.Flex gap="1" flexDirection="column">
            <UI.Flex gap="1" alignItems="center">
                <StyledLabelContainer>
                    <UI.Text
                        as="label"
                        variant="preview"
                        props={{ htmlFor: "time-frame-value" }}
                        skinny
                    >
                        In
                    </UI.Text>
                </StyledLabelContainer>
                <StyledInputContainer>
                    <Input
                        id="time-frame-value"
                        value={timeFrame.value}
                        onChange={(e) => {
                            const value = e.target.value;
                            setTimeFrame((t) => ({
                                ...t,
                                value,
                            }));
                        }}
                        placeholder="e.g. 2"
                        aria-invalid={
                            !!dateOverDaysInAdvanceError || isValueInvalid
                        }
                        aria-describedby={
                            dateOverDaysInAdvanceError
                                ? "overDaysInAdvanceError"
                                : "invalidValueError"
                        }
                    />
                </StyledInputContainer>
                <StyledSelectContainer width="126px">
                    <UI.VisuallyHidden>
                        <UI.Text
                            as="label"
                            props={{ htmlFor: "time-frame-type" }}
                            skinny
                        >
                            Time frame type
                        </UI.Text>
                    </UI.VisuallyHidden>
                    <SingleSelect
                        id="time-frame-type"
                        onValueChange={(type: TimeFrameInput["type"]) => {
                            setTimeFrame((t) => ({ ...t, type }));
                        }}
                        value={timeFrame.type}
                        name="time-frame-type"
                    >
                        {TIME_FRAME_OPTIONS.map((option) => (
                            <SelectItem key={option.label} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </SingleSelect>
                </StyledSelectContainer>
            </UI.Flex>
            {(isValueInvalid || showEmptyError) && (
                <FormFieldFeedback
                    id="invalidValueError"
                    text="Enter a valid number to schedule this message"
                    variant="error"
                />
            )}
            {!!dateOverDaysInAdvanceError && (
                <FormFieldFeedback
                    id="overOneYearError"
                    text={dateOverDaysInAdvanceError}
                    variant="error"
                />
            )}
            <UI.Flex gap="1" alignItems="center">
                <StyledLabelContainer>
                    <UI.Text
                        as="label"
                        variant="preview"
                        props={{ htmlFor: "time-frame-time" }}
                        skinny
                    >
                        At
                    </UI.Text>
                </StyledLabelContainer>

                <StyledSelectContainer width="95px">
                    <SingleSelect
                        id="time-frame-time"
                        onValueChange={setTime}
                        value={time}
                        name="frame-time"
                    >
                        {availableTimeOptions.map((option) => (
                            <SelectItem key={option.label} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </SingleSelect>
                </StyledSelectContainer>
            </UI.Flex>
        </UI.Flex>
    );
};
