import { useEffect, useRef } from "react";

import { Transport, useTransport } from "@accurx/transport";

type Subscription = (arg: { transport: Transport }) => () => void;

export const useSignalRSubscription = (subscription: Subscription) => {
    const { transport, connectionState } = useTransport();
    const unsubscribeRef = useRef<() => void>();

    useEffect(() => {
        if (transport && connectionState === "Connected") {
            unsubscribeRef.current = subscription({
                transport,
            });
        }

        return () => {
            unsubscribeRef.current?.();
        };
    }, [subscription, transport, connectionState]);
};
