import { ChangeEvent, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { clinicianMessageTemplates } from "domains/compose/components/Compose/components/MessageTemplates/constants/clinicianMessagingTemplates";
import { useCompose } from "domains/compose/context";
import { Attachment } from "domains/compose/reducer.types";
import { v4 as uuid } from "uuid";

import { TextArea } from "../Textarea";
import {
    StyledContainer,
    StyledFlexGrowContainer,
    StyledScrollableFixedHeightContainer,
} from "./Compose.styles";
import { ComposeActionStack } from "./ComposeActionStack";
import { AttachmentFailedMessage } from "./components/Attach/components/AttachFeedback/AttachmentFailedMessage";
import { AttachSelector } from "./components/Attach/components/AttachSelector/AttachSelector";
import { AttachFileFromDesktopButton } from "./components/Attach/components/AttachSelector/components/AttachFileFromDesktopButton";
import {
    MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
    SUPPORTED_FILE_EXTENSIONS,
} from "./components/Attach/upload.constants";
import { useUploadFromDesktop } from "./components/Attach/useUploadFromDesktop";
import { ComposeErrors } from "./components/ComposeErrors/ComposeErrors";
import { ExpandMinimiseBar } from "./components/ExpandMinimiseBar/ExpandMinimiseBar";
import { TemplatesBrowser } from "./components/MessageTemplates/components/TemplatesBrowser/TemplatesBrowser";
import { ClinicianSendMessage } from "./components/SendMessage/ClinicianSendMessage";
import { ClinicianComposeProps } from "./types/Compose.types";

export const ClinicianCompose = ({
    onMessageSend,
    isMessageSending,
    recipient,
    conversationId,
    patient,
    countParticipant,
    onMinimiseClick,
    isHeightRestricted,
    onExpandClick,
}: ClinicianComposeProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { state, dispatch } = useCompose();

    const [isAttachSelectorOpen, setIsAttachSelectorOpen] = useState(false);

    const { onUpload } = useUploadFromDesktop("ClinicianMessaging");
    const [uploadErrors, setUploadErrors] = useState<string[] | null>(null);

    const hasReachedUploadLimit =
        state.attachments.length === state.maxAttachmentCount;

    const uploadFile = async (file: File) => {
        const fileName = file.name;
        const tempAttachment: Attachment = {
            name: fileName,
            origin: "TempUpload",
            id: uuid(),
            size: file.size,
        };
        dispatch({
            type: "ADD_ATTACHMENT",
            payload: { attachment: tempAttachment },
        });

        const result = await onUpload(file, {
            maxFileSizeInMegabytes: MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
            allowedFileExtensions: SUPPORTED_FILE_EXTENSIONS,
        });

        if (result.status === "error") {
            dispatch({
                type: "REMOVE_ATTACHMENT",
                payload: { attachment: tempAttachment },
            });
            setUploadErrors(result.result.errors);
        } else {
            dispatch({
                type: "UPDATE_ATTACHMENT",
                payload: {
                    attachmentId: tempAttachment.id,
                    attachmentOrigin: "TempUpload",
                    attachment: {
                        origin: "Upload",
                        name: fileName,
                        ...result.result,
                        size: file.size,
                    },
                },
            });
        }
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setIsAttachSelectorOpen(false);

        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            void uploadFile(file);
        }
    };

    return (
        <StyledContainer>
            <ExpandMinimiseBar
                onMinimiseClick={onMinimiseClick}
                onExpandClick={onExpandClick}
                isHeightRestricted={isHeightRestricted ?? false}
            >
                <UI.Flex alignItems={"center"} gap={"0.5"}>
                    <UI.Text variant="note" skinny>
                        To:
                    </UI.Text>
                    <UI.Text variant="preview" skinny>
                        {recipient}
                    </UI.Text>
                </UI.Flex>
            </ExpandMinimiseBar>
            <StyledFlexGrowContainer>
                <UI.Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={"1"}
                >
                    <TemplatesBrowser
                        templates={clinicianMessageTemplates}
                        patientExternalIds={[]}
                    />
                </UI.Flex>
                <ComposeErrors />
                <StyledScrollableFixedHeightContainer
                    $isHeightRestricted={isHeightRestricted || false}
                >
                    <TextArea.Container>
                        <TextArea.EditableMessage />
                    </TextArea.Container>
                    <ComposeActionStack canSaveToRecord={false} />
                </StyledScrollableFixedHeightContainer>
                <UI.Flex flexDirection={"column"} gap="0.5">
                    {uploadErrors !== null && (
                        <AttachmentFailedMessage errors={uploadErrors} />
                    )}
                    <UI.Flex gap="1" justifyContent="space-between">
                        <AttachSelector
                            isOpen={isAttachSelectorOpen}
                            iconOnlyButton
                            toggle={() => {
                                // only track when its being opened
                                if (!isAttachSelectorOpen) {
                                    track("Attachment Button Click", {
                                        ...nativeTrackingFields,
                                        conversationParticipant:
                                            state.conversationParticipant,
                                        eventVersion: 2,
                                    });
                                }

                                setIsAttachSelectorOpen((prev) => !prev);
                            }}
                            disabled={hasReachedUploadLimit}
                        >
                            <AttachFileFromDesktopButton
                                isDisabled={hasReachedUploadLimit}
                                accepts={`.${SUPPORTED_FILE_EXTENSIONS.join(
                                    ",.",
                                )}`}
                                onClick={() => {
                                    track("Attachment MenuItem Click", {
                                        eventVersion: 2,
                                        attachmentType: "LocalFile",
                                        conversationParticipant:
                                            state.conversationParticipant,
                                        ...nativeTrackingFields,
                                    });

                                    setUploadErrors(null);
                                }}
                                onChange={handleFileUpload}
                            />
                        </AttachSelector>

                        <ClinicianSendMessage
                            isLoading={isMessageSending}
                            onMessageSend={onMessageSend}
                            countParticipant={countParticipant}
                            conversationId={conversationId}
                            patient={patient}
                        />
                    </UI.Flex>
                </UI.Flex>
            </StyledFlexGrowContainer>
        </StyledContainer>
    );
};
