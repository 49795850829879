import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { useLoadPractices } from "app/organisations/hooks";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { AccubookDashboardPage } from "./pages/AccubookDashboardPage";
import { AccubookDeliverySitesPage } from "./pages/AccubookDeliverySitesPage";

export const AccubookRoutesSection = () => {
    useLoadPractices();

    return (
        <Switch>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookManagePatients} exact>
                <h1>Manage patients</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesUpload}>
                <h1>Invite patients</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesSelect}>
                <h1>Invite list</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesReview}>
                <h1>Invite list</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookInvitesSingle}>
                <h1>Add individual patient</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookSendReminder}>
                <h1>Send reminder</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookManageClinics}>
                <h1>Manage clinics</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookBookedPatients} exact>
                <h1>Booked patients</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookBookedPatientsSession}>
                <h1>Session</h1>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookDeliverySites}>
                <AccubookDeliverySitesPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.accubookDashboard}>
                <AccubookDashboardPage />
            </SimpleRoute>
        </Switch>
    );
};
