import { useFeatureFlag } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import { usePatientRecordAttachmentsQuery } from "@accurx/native";
import { useShouldPrefetchPatientRecordAttachments } from "domains/inbox/hooks/util/useShouldPrefetchPatientRecordAttachments";

import { useComposeAreaVisibility } from "../../ComposeAreaVisibilityContext/useComposeAreaVisibility";
import { StyledExpandableContainer } from "./MessageActions.styles";
import { Note } from "./components/Actions/Note";
import { Reply } from "./components/Actions/Reply";

export const MessageActions = ({
    conversation,
    onExpandClick,
    isExpanded,
    setSentNoteStatus,
}: {
    conversation: Conversation;
    onExpandClick: () => void;
    isExpanded: boolean;
    setSentNoteStatus: (state: string | null) => void;
}) => {
    const { noteState, replyState } = useComposeAreaVisibility();
    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const isPatientMessagingDisabled = useFeatureFlag(
        "DisableSingleSendSmsAndVideoInvites",
    );

    const showNote = replyState !== "Open";
    const showReply =
        noteState !== "Open" && !!patient && !isPatientMessagingDisabled;

    // When the conversation opens we want to prefetch patient record
    // attachments if the medical record supports it because that request can
    // potentially take a looooong time so doing it early increases perceived
    // performance.
    const shouldPrefetchPatientRecordAttachments =
        useShouldPrefetchPatientRecordAttachments(patient?.externalIds ?? []);
    usePatientRecordAttachmentsQuery(
        {
            patientExternalIds: patient?.externalIds ?? [],
        },
        {
            enabled: !!patient && shouldPrefetchPatientRecordAttachments,
            refetchOnMount: true,
        },
    );

    // No message actions are available when the conversation is Done
    if (conversation.status === "Done") {
        return null;
    }

    return (
        <StyledExpandableContainer
            $isExpanded={isExpanded}
            $isFloating={replyState !== "Open" && noteState !== "Open"}
        >
            {showReply && (
                <Reply
                    patient={patient}
                    conversation={conversation}
                    isExpanded={isExpanded}
                    onExpandClick={onExpandClick}
                />
            )}
            {showNote && (
                <Note
                    patient={patient}
                    conversation={conversation}
                    setSentNoteStatus={setSentNoteStatus}
                    isExpanded={isExpanded}
                    onExpandClick={onExpandClick}
                />
            )}
        </StyledExpandableContainer>
    );
};
