import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Link, Spinner, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import {
    DateFormatOptions,
    DateHelpers,
    Log,
    SupportUrls,
} from "@accurx/shared/dist";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { IDeliveryReceiptRequest } from "api/FlemingDtos";
import { StyledContainer } from "app/layout/Container";
import { ReactRouterLink } from "app/sharedComponents/reactRouterLink/ReactRouterLink";
import { ROUTES } from "shared/Routes";
import { ReactComponent as ArrowIcon } from "shared/images/Arrow.svg";
import { ReactComponent as InfoIcon } from "shared/images/Info.svg";

import { PageHeader, PageHeaderType } from "../../layout/PageHeader";
import { actionCreators } from "./DeliveryReceiptsActions";

const DeliveryReceipts = () => {
    useAccurxWebTitle("View message delivery receipts");

    const [showMore, setShowMore] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {
        isLoggedIn,
        selectedOrganisation,
        hasDeliveryReceiptsEverLoaded,
        lastDeliveryReceiptsOrganisation,
        deliveryReceipts,
        isDeliveryReceiptsLoading,
        lastGetDeliveryReceiptsFailed,
    } = useSelector(
        ({ deliveryReceipts, account }: ApplicationState) => ({
            ...account,
            ...deliveryReceipts,
        }),
        shallowEqual,
    );

    const request = useMemo(
        () =>
            hasDeliveryReceiptsEverLoaded === false ||
            lastDeliveryReceiptsOrganisation !== selectedOrganisation
                ? ({
                      organisationId: selectedOrganisation,
                  } as IDeliveryReceiptRequest)
                : null,
        [
            selectedOrganisation,
            hasDeliveryReceiptsEverLoaded,
            lastDeliveryReceiptsOrganisation,
        ],
    );

    useEffect(() => {
        if (request) {
            dispatch(actionCreators.getDeliveryReceipts(request));
        }
    }, [dispatch, request]);

    const showMoreDetails = useCallback(() => {
        setShowMore((prevValue) => !prevValue);
    }, []);

    const formatDate = useCallback((date) => {
        if (Date.parse(date) > 0) {
            return DateHelpers.formatDate(
                date,
                DateFormatOptions.DATE_TIME_WITH_SECONDS,
            );
        }
        return date;
    }, []);

    const getTable = useCallback(
        () => (
            <table className="table text-sm-left table-bordered">
                <thead className="thead-dark">
                    <tr className="d-flex">
                        <th className="col-lg-5" scope="col">
                            <Text variant="label" as="span" colour="white">
                                NHS number
                            </Text>
                        </th>
                        <th className="col-lg-4" scope="col">
                            <Text variant="label" as="span" colour="white">
                                Sent time
                            </Text>
                        </th>
                        <th className="col-lg-3" scope="col">
                            <Text variant="label" as="span" colour="white">
                                Status
                            </Text>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {deliveryReceipts.map((deliveryReceipt, index) => {
                        const stateStyle =
                            deliveryReceipt.status === "Failed"
                                ? "table-danger"
                                : "table-light";
                        return (
                            <tr key={index} className="d-flex">
                                <td className="col-lg-5 table-light">
                                    <Text as="span">
                                        {deliveryReceipt.nhsNumber}
                                    </Text>
                                </td>
                                <td className="col-lg-4 table-light">
                                    <Text as="span">
                                        {formatDate(deliveryReceipt.sentTime)}
                                    </Text>
                                </td>
                                <td className={"col-lg-3 " + stateStyle}>
                                    <Text as="span">
                                        {deliveryReceipt.status}
                                    </Text>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        ),
        [deliveryReceipts, formatDate],
    );

    if (isLoggedIn === null || !isLoggedIn) {
        Log.error("DeliveryReceipts expects user to be logged in");
        return <Redirect to="/" />;
    }

    if (selectedOrganisation === null) {
        return (
            <div className="col-sm-12 col-lg-6">
                <div className="row">
                    <div className="col-sm-12 text-sm-center text-lg-left">
                        <Text variant="title" as="h1">
                            Delivery receipts
                        </Text>
                        <Text props={{ "data-testid": "select-org-message" }}>
                            You must select an organisation before you can view
                            your delivery receipts.
                            <br />
                            <ReactRouterLink
                                data-testid={"select-org-link"}
                                to={ROUTES.joinOrganisation}
                            >
                                Please select an organisation here
                            </ReactRouterLink>
                        </Text>
                    </div>
                </div>
            </div>
        );
    }

    if (isDeliveryReceiptsLoading === true) {
        return <Spinner />;
    }

    return (
        <StyledContainer>
            <PageHeader
                type={PageHeaderType.ListPage}
                title="Delivery receipts"
            />
            <Text>
                Here you can see the delivery status for your SMS messages
            </Text>
            <div
                className="ax–accordian mb-2"
                data-testid="show-more-accordion"
                onClick={showMoreDetails}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <InfoIcon
                            style={{
                                height: "1.5em",
                                width: "1.5em",
                                marginRight: "8px",
                            }}
                        />
                        <Text skinny>About delivery status</Text>
                    </div>
                    <ArrowIcon
                        style={{
                            height: "1.5em",
                            width: "1.5em",
                            transform: `rotate(${showMore ? "180deg" : "0"})`,
                        }}
                    />
                </div>
                {showMore ? (
                    <div className="mt-4">
                        <Text>
                            <b>Sent</b>: SMS sent but not yet delivered to
                            recipent's phone.
                        </Text>
                        <Text>
                            <b>Delivered</b>: SMS has been successfully
                            delivered to recipent's phone.
                        </Text>
                        <Text>
                            <b>Failed</b>: We were unable to deliver this SMS to
                            the recipent's phone.
                        </Text>
                        <Link
                            href={SupportUrls.DeliveryStateMeaning}
                            openInNewTab
                        >
                            See this article for more details
                            <Link.Icon />
                        </Link>
                    </div>
                ) : null}
            </div>

            {lastGetDeliveryReceiptsFailed && (
                <Text
                    colour="red"
                    props={{ "data-testid": "receipts-failed-message" }}
                >
                    Oops! We're sorry something went wrong getting your delivery
                    receipts
                </Text>
            )}
            {!lastGetDeliveryReceiptsFailed && getTable()}
        </StyledContainer>
    );
};

export default DeliveryReceipts;
