import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
} from "@accurx/auth";

export const AccubookFeatureFlags = [
    FeatureName.VaccinePractice,
    FeatureName.VaccineDeliverySite,
];

export const isAccubookEnabled = (workspace: Organisation) => {
    return AccubookFeatureFlags.some((feature) =>
        isFeatureEnabledForWorkspace(feature, workspace),
    );
};
