import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { useIsGpBatchEnabled } from "@accurx/batch-messaging";
import { Ds, TabProps, Tabs } from "@accurx/design";
import { useParams } from "react-router";
import { generatePath, useHistory } from "react-router-dom";

import { BatchMessageStatuses } from "app/batchMessage/gp/components/overview/BatchMessageStatuses";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { DelayedMessages } from "app/practices/delayedMessages/DelayedMessages";
import { DeliveryReceiptsContainer } from "app/practices/deliveryReceipts/DeliveryReceipts.container";
import { StyledTabListWrapper } from "app/workspaceSettings/pages/UserManagement/UserManagementPage.styles";
import { ROUTES_WORKSPACE } from "shared/Routes";

export const TextMessageStatusPage = () => {
    const history = useHistory();
    const { tab } = useParams<{ tab?: string }>();
    const { orgId: workspaceId, settings } = useCurrentWorkspace();

    const isTrustBatchEnabled = useFeatureFlag(FeatureName.WebBatchMessaging);
    const isGpBatchEnabled = useIsGpBatchEnabled();

    const isBatchEnabled =
        (isTrustBatchEnabled || isGpBatchEnabled) && settings.isApprovedUser;

    const tabs: TabProps[] = [
        { tabId: "sent", text: "Sent" },
        ...(settings.isApprovedUser
            ? [{ tabId: "scheduled", text: "Scheduled" }]
            : []),
        ...(isBatchEnabled
            ? [{ tabId: "batch-messages", text: "Batch messages" }]
            : []),
    ];

    const handleTabChange = (tab: string) => {
        history.push(
            generatePath(ROUTES_WORKSPACE.workspaceDeliveryReceipts, {
                workspaceId,
                tab,
            }),
        );
    };

    return (
        <StyledContainer>
            <PageHeader
                title="Text message status"
                type={PageHeaderType.ListPage}
            />
            <Ds.Flex flexDirection="column" gap="3">
                <Tabs selected={tab || "sent"} onTabChange={handleTabChange}>
                    <StyledTabListWrapper>
                        <Tabs.TabList tabs={tabs} />
                    </StyledTabListWrapper>
                    <Tabs.Panel tabId="sent">
                        <DeliveryReceiptsContainer noHeader />
                    </Tabs.Panel>
                    {settings.isApprovedUser && (
                        <Tabs.Panel tabId="scheduled">
                            <DelayedMessages noHeader />
                        </Tabs.Panel>
                    )}
                    {isBatchEnabled && (
                        <Tabs.Panel tabId="batch-messages">
                            <BatchMessageStatuses
                                workspaceId={String(workspaceId)}
                                pageOrigin="AccurxWeb"
                            />
                        </Tabs.Panel>
                    )}
                </Tabs>
            </Ds.Flex>
        </StyledContainer>
    );
};
