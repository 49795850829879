import React, { useContext } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Button, Feedback, Text } from "@accurx/design";
import { ROOT_ROUTES } from "@accurx/workspace-management";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { IAllowedNhsOrganisationResponse } from "api/FlemingDtos";
import { SelectedAllowedOrganisationContext } from "app/workspace/context/SelectedAllowedOrganisationProvider";
import {
    StyledCardItem,
    StyledWorkspaceDetails,
} from "app/workspace/pages/shared/Layout/Layout.styles";
import { ROUTES } from "shared/Routes";

import { useJoinOrganisation } from "../../hooks/useJoinOrganisation";

export const AllowedOrganisationCard = ({
    allowedOrganisation,
}: {
    allowedOrganisation: IAllowedNhsOrganisationResponse;
}) => {
    const history = useHistory();
    const track = useAnalytics();

    const { name, nationalCode, supportsMultipleWorkspaces } =
        allowedOrganisation;

    const { isLoading, mutate } = useJoinOrganisation();

    const { setAllowedOrganisation } = useContext(
        SelectedAllowedOrganisationContext,
    );

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();

        track("AccountOrganisationSelect Button Click", {
            onboarding: false,
            pageOrigin: "OrganisationWorkspaceFlow",
            organisationActionSelected: "Join",
        });

        if (supportsMultipleWorkspaces) {
            setAllowedOrganisation(allowedOrganisation);

            history.push({
                pathname: ROOT_ROUTES.workspaceJoin,
                search: history.location.search,
            });
            return;
        }

        mutate(
            { nationalCode },
            {
                onSuccess: () => {
                    toast(
                        <Feedback
                            colour="error"
                            title="This organisation does not support workspaces"
                        />,
                    );
                    history.push(ROUTES.home);
                },
                onError: () => {
                    toast(
                        <Feedback
                            colour="error"
                            title="Failed to update account"
                        >
                            Please refresh page and try again
                        </Feedback>,
                    );
                },
            },
        );
    };

    return (
        <StyledCardItem as="li">
            <Text variant="label" skinny>
                {name}
            </Text>
            <StyledWorkspaceDetails>
                <Button
                    theme="secondary"
                    text="Select"
                    disabled={isLoading}
                    onClick={handleClick}
                    aria-label={`Select ${name} to view workspaces`}
                />
            </StyledWorkspaceDetails>
        </StyledCardItem>
    );
};
