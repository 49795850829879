import { addLabelToTicket } from "./TicketApiClient";
import { addNote } from "./mutations/addNote";
import { cancelScheduledMessage } from "./mutations/cancelScheduledMessage";
import { deleteAttachment } from "./mutations/deleteAttachment";
import { markAsDone } from "./mutations/markAsDone";
import { markAsNonUrgent } from "./mutations/markAsNonUrgent";
import { markAsOpen } from "./mutations/markAsOpen";
import { markAsUrgent } from "./mutations/markAsUrgent";
import { markCommunicationAsWrittenToRecord } from "./mutations/markCommunicationAsWrittenToRecord";
import { markNoteAsWrittenToRecord } from "./mutations/markNoteAsWrittenToRecord";
import { markNotesAsRead } from "./mutations/markNotesAsRead";
import { markTicketAsUnread } from "./mutations/markTicketAsUnread";
import { markTicketLabelAsWrittenToRecord } from "./mutations/markTicketLabelAsWrittenToRecord";
import { matchTicketToPatient } from "./mutations/matchTicketToPatient";
import { matchTicketToPatientWithToken } from "./mutations/matchTicketToPatientWithToken";
import { saveFloreyPdfToRecord } from "./mutations/saveFloreyPdfToRecord";
import { toggleNotifications } from "./mutations/toggleNotifications";
import { unmatchTicketFromPatient } from "./mutations/unmatchTicketFromPatient";
import { updateAssignee } from "./mutations/updateAssignee";
import { getConversation } from "./queries/getConversation";
import { getFilteredTicketView } from "./queries/getFilteredTicketView";
import { getFolderTicketView } from "./queries/getFolderTicketView";
import { getInitialSummaries } from "./queries/getInitialSummaries";
import { getInitialUnreadItems } from "./queries/getInitialUnreadItems";
import { getUnreadTickets } from "./queries/getUnreadTickets";

export const api = {
    getConversation,
    addLabelToTicket,
    getFilteredTicketView,
    getFolderTicketView,
    getInitialSummaries,
    getInitialUnreadItems,
    markAsDone,
    markAsOpen,
    markAsUrgent,
    markAsNonUrgent,
    markNotesAsRead,
    markTicketAsUnread,
    matchTicketToPatient,
    matchTicketToPatientWithToken,
    unmatchTicketFromPatient,
    toggleNotifications,
    updateAssignee,
    markCommunicationAsWrittenToRecord,
    markNoteAsWrittenToRecord,
    markTicketLabelAsWrittenToRecord,
    saveFloreyPdfToRecord,
    addNote,
    deleteAttachment,
    cancelScheduledMessage,
    getUnreadTickets,
};
