import { FloreyResponseItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    useIsSavingToRecord,
    useSaveFloreyPdfToRecordMutation,
} from "domains/inbox/hooks/mutations/useSaveToRecordMutation";
import { toast } from "react-toastify";

export const SaveFloreyPdfToRecordButton = ({
    item,
}: {
    item: FloreyResponseItem;
}) => {
    const isLoading = useIsSavingToRecord({ itemServerId: item.serverId });
    const mutation = useSaveFloreyPdfToRecordMutation({
        itemServerId: item.serverId,
    });

    const hasFailed =
        item.saveToRecordState.status === "Failed" ||
        mutation.status === "error";

    const handleClick = async (): Promise<void> => {
        try {
            await mutation.mutateAsync();
        } catch (e) {
            toast(
                <UI.Feedback
                    colour="error"
                    title="Something went wrong while trying to save to document store, please try again"
                />,
            );
        }
    };

    return (
        <>
            <Pill.SecondaryButton
                dimension="small"
                onClick={() => void handleClick()}
                disabled={isLoading}
            >
                <Pill.Icon
                    isLoading={isLoading}
                    name="Save"
                    colour="blue"
                    size={3}
                />
                <Pill.Text>Save to document store</Pill.Text>
            </Pill.SecondaryButton>
            {hasFailed && !isLoading && (
                <UI.Flex alignItems="center" gap="0.5">
                    <UI.Icon name="Error" theme="Fill" size={3} colour="red" />
                    <UI.Text skinny colour="zinc">
                        Failed to save PDF to document store
                    </UI.Text>
                </UI.Flex>
            )}
        </>
    );
};
