import React, { useEffect, useState } from "react";

import { Card, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { calculateFragments } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    updateMessage,
    updateSelectedBatchOptionToSaveToRecord,
    updateSelectedBeastSeries,
} from "app/batchMessage/gp/BatchMessage.actions";
import { StyledLayoutWithFooter } from "app/batchMessage/gp/BatchMessage.styles";
import {
    BatchType,
    BeastSeriesDto,
} from "app/batchMessage/gp/BatchMessage.types";
import {
    BatchRoute,
    findBatchRoute,
} from "app/batchMessage/gp/BatchMessage.utils";
import { BatchMessageHeader } from "app/batchMessage/gp/components/BatchMessageHeader";
import { ComposeInhalerDropdown } from "app/batchMessage/gp/components/ComposeInhalerDropdown";
import { ComposeMsgInfo } from "app/batchMessage/gp/components/ComposeMsgInfo";
import { useSetSaveBatchToRecord } from "app/batchMessage/gp/components/ComposeOptionToSaveToRecord";
import { calculateMessageLength } from "app/batchMessage/gp/components/compose/BatchMessageCompose.utils";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { useAppSelector } from "store/hooks";

import { MessageComposeWrapper } from "./components/MessageComposeWrapper";

export const ComposeInhaler = (): JSX.Element => {
    useAccurxWebTitle("Invite patients to Inhaler Adherence Study");

    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const practiceName = useAppSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices?.selectedPractice,
            )?.name ?? "",
    );
    const patientMessage = useAppSelector(
        ({ batchMessage }) => batchMessage.patientMessage,
    );
    const selectedBeastSeries = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedBeastSeries,
        shallowEqual,
    );
    const maximumPatientGreetingLength = useAppSelector(
        ({ batchMessage }) =>
            batchMessage.batchMessageDetails.maximumPatientGreetingLength,
    );

    // State
    const [selectedSeries, setSelectedSeries] = useState(selectedBeastSeries);
    const [messageBody, setMessageBody] = useState(patientMessage);

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchPatientMessageReviewPageView({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
        });
    }, [analyticsLoggedInProps, history.location.pathname]);

    const selectBeastSeries = (series: BeastSeriesDto) => {
        setSelectedSeries(series);
        setMessageBody(series.inviteSmsBody ?? "");
    };

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: false,
        });
    };

    const { shouldSaveBatchToRecord } = useSetSaveBatchToRecord();

    const handleSave = () => {
        // TODO: This needs to be refactored into one action
        dispatch(updateMessage(messageBody));
        dispatch(updateSelectedBeastSeries(selectedSeries));
        dispatch(
            updateSelectedBatchOptionToSaveToRecord(shouldSaveBatchToRecord),
        );
    };

    const characterCount = calculateMessageLength(
        BatchType.INHALER,
        maximumPatientGreetingLength,
        messageBody.length,
        practiceName.length,
    );
    const routes = findBatchRoute(BatchRoute.COMPOSE);
    return (
        <StyledLayoutWithFooter>
            <Breadcrumb title="Batch Messaging Inhaler" />
            <div className="row mb-4">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <BatchMessageHeader
                        title={
                            "Invite patients to sign up to the Accurx Inhaler Adherence Study"
                        }
                        stepNumber={1}
                    />
                </div>
                <div className="col-12 col-lg-6 offset-lg-3">
                    <Card isElevated={false} spacing={1.5}>
                        <ComposeInhalerDropdown
                            selectedBeastSeries={selectedSeries}
                            selectBeastSeries={selectBeastSeries}
                        />
                        <Text
                            as="label"
                            variant="label"
                            props={{ htmlFor: "message-body" }}
                        >
                            Message
                        </Text>
                        <MessageComposeWrapper>
                            <Text skinny data-testid="message-body">
                                {messageBody}
                            </Text>
                        </MessageComposeWrapper>
                        <ComposeMsgInfo
                            characterCount={characterCount}
                            fragmentCount={calculateFragments(messageBody)}
                        />
                    </Card>
                    <StepsFooter
                        backText={"Back"}
                        backLink={routes.back}
                        backClickFunction={handleBack}
                        forwardText={"Add a patient list"}
                        forwardLink={routes.forward}
                        forwardClickFunction={handleSave}
                        disabled={!selectedSeries || !messageBody}
                    />
                </div>
            </div>
        </StyledLayoutWithFooter>
    );
};

export default ComposeInhaler;
