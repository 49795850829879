import { useCurrentWorkspace } from "@accurx/auth";
import {
    MessageTemplate,
    QuestionnaireTemplate,
    useMessageTemplatesQuery,
    useQuestionnaireTemplatesQuery,
} from "@accurx/message-templates";
import { useCompose } from "domains/compose/context";
import { useMessageTemplatesSubscription } from "domains/compose/hooks/subscriptions/useMessageTemplatesSubscription";
import { useQuestionnairesSubscription } from "domains/compose/hooks/subscriptions/useQuestionnairesSubscription";
import { PatientExternalId } from "domains/compose/types";

import { customIsTestPatient } from "../util/customIsTestPatient";

type TemplatesQueryResult =
    | {
          status: "success";
          data: {
              messageTemplates: MessageTemplate[];
              questionnaireTemplates: QuestionnaireTemplate[];
          };
      }
    | { status: "loading"; data?: undefined }
    | { status: "error"; refetch: () => void; data?: undefined };

export const useAllTemplates = ({
    patientExternalIds,
}: {
    patientExternalIds: PatientExternalId[];
}): TemplatesQueryResult => {
    const workspace = useCurrentWorkspace();
    const { state } = useCompose();
    const messageTemplatesQuery = useMessageTemplatesQuery({
        workspaceId: workspace.orgId,
    });
    const questionnaireTemplatesQuery = useQuestionnaireTemplatesQuery(
        { workspaceId: workspace.orgId },
        { enabled: state.canUseQuestionnaires },
    );
    // Subscribe to changes from signalR events
    useMessageTemplatesSubscription();
    useQuestionnairesSubscription();

    if (
        questionnaireTemplatesQuery.isInitialLoading ||
        messageTemplatesQuery.isInitialLoading
    ) {
        return {
            status: "loading",
        };
    }

    if (
        questionnaireTemplatesQuery.status === "error" ||
        messageTemplatesQuery.status === "error"
    ) {
        const refetch = () => {
            if (messageTemplatesQuery.status === "error") {
                void messageTemplatesQuery.refetch();
            }

            if (questionnaireTemplatesQuery.status === "error") {
                void questionnaireTemplatesQuery.refetch();
            }
        };

        return {
            status: "error",
            refetch,
        };
    }

    const isCurrentPatientTestPatient = customIsTestPatient(patientExternalIds);

    const questionnairesData = questionnaireTemplatesQuery.data ?? [];
    const filteredQuestionnaires = isCurrentPatientTestPatient
        ? questionnairesData
        : questionnairesData.filter(
              (t) => t.availability !== "AvailableForTestPatient",
          );

    return {
        status: "success",
        data: {
            messageTemplates: messageTemplatesQuery.data ?? [],
            questionnaireTemplates: filteredQuestionnaires,
        },
    };
};
