import React from "react";

import {
    ContentClassification,
    ContentClassificationGroup,
} from "@accurx/api/florey-builder";
import {
    Button,
    Ds,
    FormFieldWrapper,
    Option,
    SearchSelect,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import cloneDeep from "lodash/cloneDeep";

import { StyledClickableAreaButton } from "../components/AddQuestionButton";

type ContentTagSelectProps = {
    index: number;
    allContentClassifications: ContentClassification[];
    selectedContentClassificationGroups: ContentClassificationGroup;
    onChange: (tags: ContentClassification[]) => void;
    onRemove: () => void;
};

type ContentTagsProps = {
    allContentClassifications: ContentClassification[];
    contentClassificationGroups: ContentClassificationGroup[];
    onChange: (groups: ContentClassificationGroup[]) => void;
};

function getTagGroupKey(tagGroup: ContentClassificationGroup) {
    return `${tagGroup.contentClassifications
        .map(
            (contentClassification) =>
                `${contentClassification.type}-${contentClassification.name}`,
        )
        .join(",")}`;
}

const formatValue = (classification: ContentClassification) => {
    return `${classification.name}#${classification.type}`;
};

export const ContentTags = ({
    allContentClassifications,
    contentClassificationGroups,
    onChange,
}: ContentTagsProps) => {
    return (
        <div>
            <Text variant="label">Select tags:</Text>
            <Text skinny>
                Tags are used in groups to determine which workspaces see the
                content.
            </Text>
            <Text skinny>
                Select multiple tags in one group to show it to workspaces with
                all selected.
            </Text>
            {contentClassificationGroups.map((group, i) => (
                <ContentTagSelect
                    key={getTagGroupKey(group)}
                    index={i}
                    allContentClassifications={allContentClassifications}
                    selectedContentClassificationGroups={group}
                    onRemove={() => {
                        const clonedGroups = cloneDeep(
                            contentClassificationGroups,
                        );
                        clonedGroups.splice(i, 1);
                        onChange(clonedGroups);
                    }}
                    onChange={(contentClassifications) => {
                        const clonedGroups = cloneDeep(
                            contentClassificationGroups,
                        );
                        clonedGroups[i] = {
                            contentClassifications: contentClassifications,
                        };
                        onChange(clonedGroups);
                    }}
                />
            ))}
            <StyledClickableAreaButton
                onClick={() => {
                    const clonedGroups = cloneDeep(contentClassificationGroups);
                    clonedGroups.push({ contentClassifications: [] });
                    onChange(clonedGroups);
                }}
            >
                <Ds.Halo color="blue" luminosity="high">
                    <Ds.Icon name="Plus" />
                </Ds.Halo>
                <Text variant="label">Add tag group</Text>
            </StyledClickableAreaButton>
        </div>
    );
};

const ContentTagSelect = ({
    index,
    allContentClassifications,
    selectedContentClassificationGroups,
    onChange,
    onRemove,
}: ContentTagSelectProps) => {
    const allOptions: Option[] = allContentClassifications.map(
        (classification) => ({
            label: classification.name,
            value: formatValue(classification),
            grouping: classification.type,
        }),
    );

    const selectedOptions: Option[] =
        selectedContentClassificationGroups.contentClassifications.map(
            (selectedClassification) => ({
                label: selectedClassification.name,
                value: formatValue(selectedClassification),
                grouping: selectedClassification.type,
            }),
        );

    const handleChange: (selected: Option | Option[]) => void = (selected) => {
        const newlySelectedContentClassifications = (
            Array.isArray(selected) ? selected : [selected]
        ).map((option) => {
            if (!option.grouping) {
                Log.warn("Missing grouping for content classification", {
                    tags: { classificationName: option.label },
                });
            }

            const [name, type] = option.value.split("#");
            return {
                name,
                type,
            };
        });
        onChange(newlySelectedContentClassifications);
    };

    return (
        <Ds.Flex gap="0.5" flexDirection="column">
            <FormFieldWrapper
                label={`Tag Group #${index + 1}`}
                labelProps={{
                    htmlFor: `select-tag-${index + 1}`,
                }}
                subLabel={
                    "Workspaces need to have all tags in the list in order to match this group."
                }
            >
                <SearchSelect
                    options={allOptions}
                    id={`select-tag-${index + 1}`}
                    onChangeHandler={handleChange}
                    theme="MULTISELECT"
                    initSelectedValues={selectedOptions}
                    defaultCopy={{
                        placeholder: "Select tags here",
                    }}
                    // minor hack to fix z index issues with having multiple selects from
                    // @accurx/design on the same page.
                    zIndex={800 - index}
                />
            </FormFieldWrapper>
            <Ds.Flex justifyContent="flex-end">
                <Button
                    text="Remove Group"
                    dimension="small"
                    theme="danger"
                    icon={{ name: "Bin" }}
                    onClick={onRemove}
                />
            </Ds.Flex>
            <hr />
        </Ds.Flex>
    );
};
