import { Attachment, ComposeErrorType } from "../reducer.types";

export type ValidateClinicianMessageProps = {
    messageBody: string;
    attachments: Attachment[];
    originalGreeting: string;
    withTemplate: boolean;
};

const placeholderRegex = /\*{5}(\w|\s)*\*{5}/;
export const validateClinicianMessage = (
    arg: ValidateClinicianMessageProps,
): ComposeErrorType => {
    const errors: ComposeErrorType = {};

    // Do not allow sending if there are attachments in progress
    if (arg.attachments.find((a) => a.origin === "TempUpload")) {
        errors.attachmentLoading = "ATTACHMENT_STILL_LOADING";
    }

    if (placeholderRegex.test(arg.messageBody) && arg.withTemplate) {
        errors.placeholderRemains = "TEMPLATE_PLACEHOLDER_REMAINS";
    }

    const textNoWhiteSpaces = arg.messageBody.trim();
    const isBodyEmpty = textNoWhiteSpaces === "";
    const isBodyTextEdited = textNoWhiteSpaces !== arg.originalGreeting;
    /**
     * A message is considered edited if the user
     * - somehow changed the body area to be different that the plain greeting
     * - attached a document
     */
    const hasBeenEdited = isBodyTextEdited || arg.attachments.length > 0;
    if (isBodyEmpty || !hasBeenEdited) {
        errors.messageLength = "EMPTY_MESSAGE";
    }

    return errors;
};
