import React, { useEffect, useMemo, useState } from "react";

import { CrossOrgBookableOrganisation } from "@accurx/api/appointment";
import * as UI from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { useSlotTypeForTargetOrganisationQuery } from "domains/self-book/queries/useSlotTypeForTargetOrganisation";

import {
    AppOrigin,
    useSelfBookConfigurationAnalytics,
} from "../hooks/useSelfbookAnalytics";
import { useAppointmentsConfigurationQuery } from "../queries";
import { useAppointmentAvailabilityQuery } from "../queries/useAppointmentAvailability";
import {
    getClinicianOptions,
    getSelfbookConfigObject,
    isCrossOrgLoading,
    shouldShowAppointmentAvailabilityError,
    shouldShowChooseClinicianError,
    shouldShowSlotTypeError,
    transformToOrganisationOptions,
} from "../utils/SelfbookFormComponentUtils";
import {
    AppointmentAvailabilityPeriod,
    AppointmentTypeValue,
    ChooseClinicianValue,
    ClinicianOption,
    OrganisationOption,
    SelfBookFormData,
    SelfbookConfigurationPayload,
} from "../utils/types";
import { AppointmentAvailability } from "./AppointmentAvailability";
import { AppointmentType } from "./AppointmentType";
import { ChooseClinician } from "./ChooseClinician";
import { ChooseOrganisation } from "./ChooseOrganisation";
import {
    SpacingWrapper,
    SpinnerPositioning,
    StyledButton,
    StyledFooter,
    StyledInnerWrapper,
    StyledLinkedText,
    StyledMiddleWrapper,
    StyledOuterWrapper,
    StyledSelfbookForm,
    TelephoneDetailsWrapper,
} from "./SelfbookConfigurationForm.styles";
import { SlotType } from "./SlotType";
import { TelephoneBookingDetails } from "./TelephoneBookingDetails";

type SelfbookFormProps = {
    orgId: number;
    organisationName: string;
    showCrossOrg: boolean;
    showAppointmentAvailability: boolean;
    appOrigin: AppOrigin;
    header?: JSX.Element;
    isTelephoneFlow?: boolean;
    marginBottom?: number | string;
    sendAt: string;
    onSupportLinkClick?: (supportLinkUri: string) => void;
    onSelfbookConfigComplete: (
        selfbookConfigData: SelfbookConfigurationPayload,
        defaultAvailabilityPeriod: AppointmentAvailabilityPeriod,
    ) => void;
    onSelfbookConfigChange?: (
        selfbookConfigData: SelfbookConfigurationPayload,
    ) => void;
};

function LoadingSpinner() {
    return (
        <SpinnerPositioning>
            <UI.Spinner />
        </SpinnerPositioning>
    );
}

export const SelfbookConfigurationForm = (props: SelfbookFormProps) => {
    const {
        data: appointmentConfiguration,
        status: appointmentConfigurationStatus,
        error: appointmentConfigurationError,
    } = useAppointmentsConfigurationQuery();

    if (appointmentConfigurationStatus === "loading") {
        return <LoadingSpinner />;
    }

    if (appointmentConfigurationStatus === "error") {
        return (
            <UI.Feedback
                title="An error occurred while trying to get self book configuration"
                colour="error"
            >
                {appointmentConfigurationError.message}
            </UI.Feedback>
        );
    }

    return (
        <SelfbookConfigurationFormInternal
            {...props}
            selfBookMaxAppointmentAvailabilityWeeks={
                appointmentConfiguration.selfBookMaxAppointmentAvailabilityWeeks
            }
            selfBookCrossOrgBookableOrganisations={
                appointmentConfiguration.selfBookCrossOrgBookableOrganisations
            }
        />
    );
};

type SelfbookFormInternalProps = SelfbookFormProps & {
    selfBookMaxAppointmentAvailabilityWeeks: number;
    selfBookCrossOrgBookableOrganisations: CrossOrgBookableOrganisation[];
};

const SelfbookConfigurationFormInternal = ({
    orgId,
    organisationName,
    showCrossOrg,
    showAppointmentAvailability,
    appOrigin,
    header,
    isTelephoneFlow,
    marginBottom,
    sendAt,
    onSupportLinkClick,
    onSelfbookConfigComplete,
    onSelfbookConfigChange,
    selfBookMaxAppointmentAvailabilityWeeks,
    selfBookCrossOrgBookableOrganisations,
}: SelfbookFormInternalProps) => {
    const defaultAppointmentAvailabilityPeriod: AppointmentAvailabilityPeriod =
        {
            value: selfBookMaxAppointmentAvailabilityWeeks,
            units: "weeks",
        };

    const analytics = useSelfBookConfigurationAnalytics({ appOrigin });

    const selfBookBookableOrganisations = useMemo(
        () =>
            transformToOrganisationOptions(
                orgId,
                organisationName,
                selfBookCrossOrgBookableOrganisations,
            ),
        [orgId, organisationName, selfBookCrossOrgBookableOrganisations],
    );

    const [selfBookData, setSelfbookData] = useState<SelfBookFormData>({
        appointmentType: "FaceToFace",
        clinicians: { clinicians: [], clinicianType: "AnyClinician" },
        organisation: {
            label: `${organisationName} (Default)`,
            value: `${orgId}`,
            isDefault: true,
        },
        appointmentAvailabilityPeriod: defaultAppointmentAvailabilityPeriod,
    });

    const [hasButtonClicked, setHasButtonClicked] = useState<boolean>(false);

    const {
        data: appointmentAvailabilityData,
        status: appointmentAvailabilityStatus,
        error: appointmentAvailabilityError,
    } = useAppointmentAvailabilityQuery(orgId);
    const {
        data: slotTypeOptionsForTargetOrganisation,
        status: crossOrgStatus,
    } = useSlotTypeForTargetOrganisationQuery(
        orgId,
        selfBookData.organisation.isDefault,
        selfBookData.organisation.value,
        selfBookData.appointmentType,
    );

    // Effect triggered on status changes and data changes
    useEffect(() => {
        const selfbookConfigObject: SelfbookConfigurationPayload =
            getSelfbookConfigObject(
                selfBookData,
                appointmentAvailabilityStatus,
                crossOrgStatus,
                showCrossOrg,
                selfBookMaxAppointmentAvailabilityWeeks,
            );

        const appointmentLoading = appointmentAvailabilityStatus === "loading";
        const crossOrgLoading = isCrossOrgLoading(
            crossOrgStatus,
            selfBookData.organisation.isDefault,
        );
        const pageLoading = appointmentLoading || crossOrgLoading;
        if (!pageLoading) {
            onSelfbookConfigChange &&
                onSelfbookConfigChange(selfbookConfigObject);
        }
    }, [
        selfBookData,
        appointmentAvailabilityStatus,
        crossOrgStatus,
        showCrossOrg,
        onSelfbookConfigChange,
        selfBookMaxAppointmentAvailabilityWeeks,
    ]);

    const crossOrgLoading = isCrossOrgLoading(
        crossOrgStatus,
        selfBookData.organisation.isDefault,
    );

    if (appointmentAvailabilityStatus === "loading" || crossOrgLoading) {
        return <LoadingSpinner />;
    }

    if (appointmentAvailabilityStatus === "error") {
        return (
            <UI.Feedback
                title="An error occurred while trying to get self book availability"
                colour="error"
            >
                {appointmentAvailabilityError.message}
            </UI.Feedback>
        );
    }

    const {
        slotTypeOptions: defaultOrgSlotTypeOptions,
        clinicianListForSlotTypes,
    } = appointmentAvailabilityData;

    const slotTypeOptions = selfBookData.organisation.isDefault
        ? defaultOrgSlotTypeOptions
        : slotTypeOptionsForTargetOrganisation ?? [];

    const appointmentTypeErrors =
        crossOrgStatus === "error"
            ? [
                  "Appointment type is not available. Choose another option or select a different practice.",
              ]
            : undefined;

    const onAppointmentTypeChanged = (selectedType: AppointmentTypeValue) => {
        analytics.trackAppointmentTypeOptionSelect({
            appointmentType: selectedType,
        });
        setHasButtonClicked(false);
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            appointmentType: selectedType,
            slotType: undefined,
            clinicians: {
                clinicianType: "AnyClinician",
                clinicians: [],
            },
        };
        setSelfbookData(updatedSelfbookData);
    };
    const onSlotTypeChanged = (selected: UI.Option) => {
        const slotTypeError = shouldShowSlotTypeError(selected, false);

        analytics.trackSlotTypeMenuItemClick({
            slotName: selected.label ?? selected.value,
            error: slotTypeError,
        });

        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            slotType: selected,
            clinicians: {
                clinicianType: "AnyClinician",
                clinicians: [],
            },
        };

        setSelfbookData(updatedSelfbookData);
    };

    const onAppoinmentAvailabilityChanged = (
        selectedType: AppointmentAvailabilityPeriod,
        maxAppointmentAvailabilityWeeks: number,
        hasAvailability: boolean,
    ) => {
        shouldShowAppointmentAvailabilityError(
            selectedType,
            maxAppointmentAvailabilityWeeks,
            hasAvailability,
        );
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            appointmentAvailabilityPeriod: selectedType,
        };

        setSelfbookData(updatedSelfbookData);
    };

    const onSelfbookFormConfigButtonClick = () => {
        // to trigger validation on button click
        setHasButtonClicked(true);

        const selfBookConfig = getSelfbookConfigObject(
            selfBookData,
            appointmentAvailabilityStatus,
            crossOrgStatus,
            showCrossOrg,
            selfBookMaxAppointmentAvailabilityWeeks,
        );
        if (selfBookConfig.validationSuccess) {
            onSelfbookConfigComplete(
                selfBookConfig,
                defaultAppointmentAvailabilityPeriod,
            );
        }
    };

    const onOrganisationChange = (selected: OrganisationOption) => {
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            organisation: selected,
            slotType: undefined,
            clinicians: {
                clinicianType: "AnyClinician",
                clinicians: [],
            },
            appointmentAvailabilityPeriod: defaultAppointmentAvailabilityPeriod,
        };
        setHasButtonClicked(false);
        setSelfbookData(updatedSelfbookData);
    };

    const onClinicianChange = (
        selected: ClinicianOption[] | undefined,
        chooseClinicianType: ChooseClinicianValue,
    ) => {
        const clinicianObject = {
            clinicians: selected ?? [],
            clinicianType: chooseClinicianType,
        };
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            clinicians: clinicianObject,
        };
        setHasButtonClicked(false);
        setSelfbookData(updatedSelfbookData);
    };

    const renderConfigForm = (): JSX.Element => {
        return (
            <>
                {showCrossOrg && (
                    <ChooseOrganisation
                        onChange={(selected) => {
                            onOrganisationChange(selected);
                        }}
                        options={selfBookBookableOrganisations}
                        initialOption={selfBookData.organisation}
                        errors={undefined}
                    />
                )}
                <AppointmentType
                    selectedAppointmentType={selfBookData.appointmentType}
                    onChange={(event) =>
                        onAppointmentTypeChanged(
                            event.target.value as AppointmentTypeValue,
                        )
                    }
                    errors={appointmentTypeErrors}
                />

                <SlotType
                    onChange={(selected) => onSlotTypeChanged(selected)}
                    initialOption={selfBookData.slotType}
                    options={slotTypeOptions}
                    errors={shouldShowSlotTypeError(
                        selfBookData.slotType,
                        hasButtonClicked,
                    )}
                />
                {selfBookData.organisation.isDefault && (
                    <ChooseClinician
                        onChange={(selected, chooseClinicianType) =>
                            onClinicianChange(selected, chooseClinicianType)
                        }
                        initialOption={selfBookData.clinicians}
                        options={getClinicianOptions(
                            clinicianListForSlotTypes,
                            selfBookData.slotType,
                        )}
                        errors={shouldShowChooseClinicianError(
                            selfBookData.clinicians,
                            hasButtonClicked,
                        )}
                        isSlotTypeAvailabilityError={
                            selfBookData.slotType &&
                            selfBookData.slotType.value === "0"
                        }
                    />
                )}

                {showAppointmentAvailability &&
                    selfBookData.organisation.isDefault && (
                        <AppointmentAvailability
                            initialValue={{
                                timeFrame: {
                                    type: selfBookData
                                        .appointmentAvailabilityPeriod.units,
                                    value: selfBookData.appointmentAvailabilityPeriod.value.toString(),
                                },
                            }}
                            onChangeValue={(selected) =>
                                onAppoinmentAvailabilityChanged(
                                    selected,
                                    selfBookMaxAppointmentAvailabilityWeeks,
                                    true, //TODO hasAvailability needs to be passed in, will be added in following tickets
                                )
                            }
                            sendTime={sendAt}
                            errors={shouldShowAppointmentAvailabilityError(
                                selfBookData.appointmentAvailabilityPeriod,
                                selfBookMaxAppointmentAvailabilityWeeks,
                                true, //TODO hasAvailability needs to be passed in, will be added in following tickets
                            )}
                        />
                    )}
            </>
        );
    };

    return (
        <StyledOuterWrapper>
            <StyledMiddleWrapper>
                <StyledInnerWrapper>
                    <StyledSelfbookForm>
                        {header}
                        {isTelephoneFlow ? (
                            <TelephoneDetailsWrapper>
                                <TelephoneBookingDetails />
                            </TelephoneDetailsWrapper>
                        ) : (
                            renderConfigForm()
                        )}
                        <SpacingWrapper>
                            <UI.Link
                                href={SupportUrls.BatchSelfBookProblem}
                                openInNewTab
                                onClick={() => {
                                    analytics.trackBookingResourceLinkClick({
                                        resourceLink:
                                            SupportUrls.BatchSelfBookProblem,
                                    });
                                    onSupportLinkClick &&
                                        onSupportLinkClick(
                                            SupportUrls.BatchSelfBookProblem,
                                        );
                                }}
                            >
                                <StyledLinkedText>
                                    <UI.Link.Text text="Having problems?" />
                                </StyledLinkedText>
                                <UI.Link.Icon />
                            </UI.Link>
                        </SpacingWrapper>
                    </StyledSelfbookForm>
                    <StyledFooter>
                        <StyledButton
                            theme="primary"
                            dimension="medium"
                            text={
                                selfBookData.appointmentType === "FaceToFace" ||
                                isTelephoneFlow
                                    ? "Add Self-Book invite"
                                    : "Save and next"
                            }
                            onClick={onSelfbookFormConfigButtonClick}
                            marginBottom={marginBottom}
                        />
                    </StyledFooter>
                </StyledInnerWrapper>
            </StyledMiddleWrapper>
        </StyledOuterWrapper>
    );
};
