import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, ErrorSummaryProvider, Feedback, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import { getOverviewPagePath } from "../../Routes";
import { FormValues, ReminderFormFields } from "../../components";
import {
    useReminderConfigOptions,
    useRemindersAndFilterOptions,
    useUpdateReminder,
} from "../../hooks";
import { useAvailableFilters } from "../../hooks/useAvailableFilters";
import { TemplateType } from "../../types";
import { mapFormValuesToRequest, validateInitialFormData } from "../../utils";
import { Page } from "./Page";

export const EditReminderPage = () => {
    useAccurxWebTitle("Edit your appointment reminder");

    const { orgId: id, organisationName } = useCurrentWorkspace();
    const history = useHistory();
    const workspaceId = id.toString();
    const { reminderId } = useParams<{ reminderId: string }>();

    const availableFilters = useAvailableFilters();
    const { data, status } = useRemindersAndFilterOptions({ workspaceId });
    const { data: configData, status: configStatus } = useReminderConfigOptions(
        { workspaceId },
    );
    const {
        mutate: updateReminder,
        isError,
        error,
    } = useUpdateReminder({
        workspaceId,
        reminderId,
    });

    if (status === "loading" || configStatus === "loading") {
        return (
            <Page>
                <Ds.Spinner />
            </Page>
        );
    }

    if (status === "error" || configStatus === "error") {
        return (
            <Page>
                <Feedback
                    colour="error"
                    iconName="Failed"
                    title="There was an issue retrieving reminder information"
                >
                    <Text skinny>
                        There was an issue retrieving the information, try again
                        later.
                    </Text>
                </Feedback>
            </Page>
        );
    }

    const parsedReminderId = parseInt(reminderId, 10);
    const selectedReminder = data.appointmentReminders.find(
        (reminder) => reminder.id === parsedReminderId,
    );

    if (!selectedReminder) {
        Log.error(
            new Error(
                `Invalid appointment reminder id provided: ${reminderId}`,
            ),
        );

        return (
            <Page>
                <Feedback colour="error" iconName="Failed">
                    <Text skinny>
                        We couldn't find this appointment reminder.
                    </Text>
                </Feedback>
            </Page>
        );
    }

    const initialFormValues = {
        selectedSiteNames: selectedReminder.siteNames,
        selectedSlotTypes: selectedReminder.slotTypes.map((clinic) => ({
            label: clinic,
            value: clinic,
            disabled: false,
        })),
        selectedAppointmentType: selectedReminder.appointmentTypeFilters[0],
        templateType: selectedReminder.type as TemplateType,
        customMessage: selectedReminder.customMessageBody ?? "",
        oneWeekEnabled: selectedReminder.oneWeekReminderEnabled,
        threeDaysEnabled: selectedReminder.threeWorkingDayReminderEnabled,
        postAppointmentMessageEnabled:
            selectedReminder.postAppointmentMessageEnabled,
        postAppointmentMessageBody:
            selectedReminder.postAppointmentCustomMessage ?? "",
    };

    const validationResult = validateInitialFormData(initialFormValues);

    if (!validationResult.success) {
        Log.error(
            new Error(
                "Invalid appointment reminder config provided to edit reminder form",
            ),
            {
                tags: {
                    reminderId: parsedReminderId,
                    ...validationResult.errors,
                },
            },
        );
    }

    const existingReminders = data.appointmentReminders.filter(
        (reminder) => reminder !== selectedReminder,
    );

    const onSubmit = (formValues: FormValues) => {
        void updateReminder(
            {
                appointmentReminderId: parsedReminderId,
                ...mapFormValuesToRequest(formValues),
            },
            {
                onSuccess: () => {
                    history.push(getOverviewPagePath(workspaceId));
                    toast(
                        <Feedback colour="success">Reminder updated!</Feedback>,
                    );
                },
            },
        );
    };

    return (
        <Page>
            <ErrorSummaryProvider>
                <ReminderFormFields
                    workspaceName={organisationName}
                    existingReminders={existingReminders}
                    siteNames={data.siteNames}
                    slotTypes={data.slotTypes}
                    appointmentTypes={data.appointmentTypes}
                    workspaceHasMultipleSites={data.siteNames.length > 1}
                    initialValues={initialFormValues}
                    submitButtonText="Update reminder"
                    onSubmit={onSubmit}
                    changeDeadline={configData.changeDeadline}
                    supportedChanges={configData.supportedChanges}
                    availableFilters={availableFilters}
                    cancelButton={{ show: false }}
                    deleteButton={{ show: false }}
                    isError={isError}
                    error={error as Error}
                    layout="two-columns"
                    workspaceId={workspaceId.toString()}
                />
            </ErrorSummaryProvider>
        </Page>
    );
};
