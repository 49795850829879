import { useCallback } from "react";

import { Option } from "@accurx/design";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import {
    transformToClinicianOptions,
    transformToSlotTypesOptions,
} from "../utils/SelfbookFormComponentUtils";
import {
    ClinicianValue,
    CliniciansForSlotType,
    KeyValueDTO,
} from "../utils/types";

export const GET_APPOINTMENT_AVAILABILITY_KEY = "AppointmentAvailability";

export type SlotTypeAvailabilityDto = {
    clinicianGroups?: ClinicianValue[];
    appointmentCount: number;
    slotTypeName: string;
};

export interface AppointmentAvailabilityResponse {
    appointmentCount: number;
    slotTypeAvailabilities: SlotTypeAvailabilityDto[];
    weeksAvailable: number;
    crossOrgAvailableOdsAndNames: KeyValueDTO;
}

export interface AppointmentAvailabilityParsedResponse {
    slotTypeOptions: Option[];
    clinicianListForSlotTypes: CliniciansForSlotType[];
    weeksAvailability: number;
}

const appointmentAvailabilityDataTransformer = (
    response: AppointmentAvailabilityResponse,
): AppointmentAvailabilityParsedResponse => {
    const slotTypes: { [key: string]: string } = {};

    response.slotTypeAvailabilities.forEach((item) => {
        slotTypes[item.slotTypeName] = `${item.appointmentCount}`;
    });

    const slotTypeOptions: Option[] = transformToSlotTypesOptions(slotTypes);

    const clinicianListForSlotTypes: CliniciansForSlotType[] =
        transformToClinicianOptions(response.slotTypeAvailabilities);

    const weeksAvailability = response.weeksAvailable;

    return {
        slotTypeOptions,
        clinicianListForSlotTypes,
        weeksAvailability,
    };
};

export const useAppointmentAvailabilityQuery = (
    orgId: number,
): UseQueryResult<AppointmentAvailabilityParsedResponse, Error> =>
    useQuery({
        queryKey: [GET_APPOINTMENT_AVAILABILITY_KEY, orgId],
        queryFn: async () =>
            await httpClient
                .getReturnJsonSafeAsync(
                    getApiEndpoint({
                        path: "/api/appointments/SelfBook/AppointmentAvailability",
                        query: `?organisationId=${orgId}`,
                    }),
                )
                .then(
                    (response) =>
                        returnDataOrThrow(
                            response,
                        ) as AppointmentAvailabilityResponse,
                ),
        refetchOnMount: true,
        // memoise to not run on every render
        select: useCallback(
            (data: AppointmentAvailabilityResponse) =>
                appointmentAvailabilityDataTransformer(data),
            [],
        ),
    });
