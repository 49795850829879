import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { loadPractices, selectPractice } from "app/practices/Practices.actions";
import { LoadingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

/*
 * We may be able to ditch this hook at some point,
 * but for now, some of the pages/tabs from the /practices
 * routes that we are embedding in /organisations pages
 * depend on the getPractices action having been dispatched
 * and the practices loaded, so this provides us with an
 * easy way to make sure that's happened where needed
 */
export const useLoadPractices = (): Optional<LoadingStatus> => {
    const dispatch = useDispatch();

    const { selectedOrgId } = useSelectedOrganisation();

    const loadingStatus = useAppSelector(
        ({ practices }) => practices.loadingStatus,
    );

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const hasEverLoaded = useAppSelector(
        ({ practices }) => practices.hasEverLoaded,
    );

    // call the api endpoint to load practices
    useEffect(() => {
        if (!hasEverLoaded && loadingStatus === LoadingStatus.Initial) {
            dispatch(loadPractices());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, hasEverLoaded]);

    // this is v annoying, but we currently have
    // both a `selectedOrganisation` _and_ a `selectedPractice`
    // in the redux state, each populated at different times,
    // by different actions, containing different data.
    // this ensures we have updated our selected practice to
    // match `selectedOrganisation` for pages that rely on it
    useEffect(() => {
        if (
            hasEverLoaded &&
            selectedOrgId &&
            selectedOrgId !== parseInt(selectedPractice, 10)
        ) {
            dispatch(selectPractice(selectedOrgId.toString()));
        }
    }, [dispatch, hasEverLoaded, selectedOrgId, selectedPractice]);

    return loadingStatus;
};
