import React, {
    MutableRefObject,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useRef,
    useState,
} from "react";

type OnClose = () => void;
type QuickViewEntry = {
    id: string;
    onCloseRef: MutableRefObject<OnClose>;
    autoFocus: boolean;
};
type RegisterQuckView = (entry: QuickViewEntry) => void;
type UnregisterQuckView = (
    id: string,
    returnFocusRef?: React.RefObject<HTMLElement>,
) => void;

const QuickViewContext = createContext<{
    registerQuickView: RegisterQuckView;
    unregisterQuickView: UnregisterQuckView;
    onClose: OnClose;
    isOpen: boolean;
} | null>(null);

/** This provider acts as a single place to manage quick view instances.
 *  It ensures only one instance is open at a time, and that the provided
 *  clean-up function is called when a new instance is opened.
 */
export const QuickViewProvider = ({ children }: { children: ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    /**
     * We store a ref to the element used to open quick view. This is
     * so we can manage focus when opening/closing quick view.
     */
    const lastActiveElementRef = useRef<HTMLElement>();

    /**
     * When opening quick view the portal will register an entry. That entry
     * contains a unique ID string and a ref to the onClose callback. We store
     * the latest entry locally as a ref so that we can close one quick view
     * component when another one opens. This does mean we're storing the
     * onClose ref inside another ref... which is a bit strange. But it works.
     */
    const latestEntryRef = useRef<QuickViewEntry | null>(null);

    /** We manage the current quick view instance here.
     *  When a new quick view is opened, the current
     *  onClose function is called.
     */
    const registerQuickView = useCallback<RegisterQuckView>((entry) => {
        if (entry.id !== latestEntryRef.current?.id) {
            // Close current quick view
            latestEntryRef.current?.onCloseRef.current();

            // Update current quick view entry
            latestEntryRef.current = entry;
            setIsOpen(true);

            // Store a reference to the element used to open quick view
            lastActiveElementRef.current =
                document.activeElement as HTMLElement;

            // Move focus into quick view when it opens
            if (entry.autoFocus) {
                document.getElementById(entry.id)?.focus();
            }
        }
    }, []);

    const unregisterQuickView = useCallback<UnregisterQuckView>(
        (id, returnFocusRef) => {
            if (id === latestEntryRef.current?.id) {
                latestEntryRef.current = null;
                setIsOpen(false);

                if (returnFocusRef?.current) {
                    // Return focus to the element from the provided ref
                    returnFocusRef.current.focus();
                } else {
                    // Return focus to the last active element when quick view opened
                    lastActiveElementRef.current?.focus();
                }
            }
        },
        [],
    );

    const onClose = useCallback<OnClose>(() => {
        latestEntryRef.current?.onCloseRef.current();
        setIsOpen(false);
    }, []);

    return (
        <QuickViewContext.Provider
            value={{
                registerQuickView,
                unregisterQuickView,
                onClose,
                isOpen,
            }}
        >
            {children}
        </QuickViewContext.Provider>
    );
};

export const useQuickViewContext = () => {
    const context = useContext(QuickViewContext);

    if (context === null) {
        throw new Error(
            "useQuickViewContext can only be used by a child of a QuickViewProvider.",
        );
    }

    return context;
};
