import { useCallback } from "react";

import { MessageTemplatesResponse } from "@accurx/api/patient-messaging";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { MessageTemplate } from "domains/message-templates/types/MessageTemplate";

import { mapMessageTemplateResponseToMessageTemplate } from "../utils/mapMessageTemplateResponseToMessageTemplate";

type UseMessageTemplateParams = { workspaceId: number };

export const useMessageTemplatesQuery = (
    { workspaceId }: UseMessageTemplateParams,
    options?: UseQueryOptions<
        MessageTemplatesResponse,
        Error,
        MessageTemplate[]
    >,
) => {
    const select = useCallback(
        (data: MessageTemplatesResponse) =>
            mapMessageTemplateResponseToMessageTemplate(data, workspaceId),
        [workspaceId],
    );

    return useQuery<
        MessageTemplatesResponse,
        Error,
        MessageTemplate[],
        QueryKey
    >(
        ["MessageTemplates", { workspaceId }],
        async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/patientmessaging/template/message/:organisationId/all",
                    params: { organisationId: workspaceId.toString() },
                }),
            );

            return returnDataOrThrow<MessageTemplatesResponse>(response);
        },
        {
            ...options,
            select,
        },
    );
};
