import { Tokens } from "@accurx/design";
import styled from "styled-components";

// Contain size of the select field
export const StyledSelectContainer = styled.div`
    width: 100%;
`;

export const StyledInputContainer = styled.div`
    width: 116.814px;
`;

export const StyledHeader = styled.div`
    display: flex;
`;

export const StyledTextComponent = styled.div`
    padding-top: ${Tokens.SIZES[0.5]};
`;
